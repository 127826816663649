import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "shared/Loader";
import Header, { HeaderLeft, HeaderCenter, HeaderRight } from "../../../../components/Dealer/Header";
import CustomerFooter from "./CustomerFooter";
import { IconBackArrow } from "assets/images";
import { getCustomerPaperWork, getHelloSignStatus, getPaperWorkProductSignatureDetails } from '../../../../redux/actions/dealer';
import { getFormattedDate } from '../../../../components/commons/utility'
import { getDealerAdditionalInfo, getDealersPaperwork } from '../../../../redux/actions/admin'
// import { IconFixIssueAwesomeSendRed } from '../../../../assets/images';
import { getCustomerApiInitiateForGenerateOrder } from 'redux/actions/sales';



function PaperWork(props) {

    const {
        history,
        getCustomerPaperWork,
        actionLoading,
        getPaperWorkProductSignatureDetails,
        getCustomerApiInitiateForGenerateOrder,
        selectedCustomer,
    } = props;

    console.log(selectedCustomer)

    useEffect(() => {
        if (selectedCustomer.app_id) {
            getCustomerPaperWork(selectedCustomer.app_id, selectedCustomer.cif_number).then(() => {

                if (selectedCustomer.paperwork && selectedCustomer.paperwork.length > 0) {

                    let signature_product = selectedCustomer.paperwork.filter(p => p.signature_request)

                    signature_product.map((p_info) => {

                        let temp = {
                            product_id: p_info.product_id
                        }
                        getPaperWorkProductSignatureDetails(temp);
                    })
                }
            });
        }
    }, []);


    let mainTab = localStorage.getItem('mainTab') ? localStorage.getItem('mainTab') : 0


    // const IconAwesomePaperPlane = <svg xmlns="http://www.w3.org/2000/svg" width="17.982" height="18.273" viewBox="0 0 17.982 18.273">
    //     <path d="M8.956 6.775v2.43c.931.211 1.759.557 2.626.8V7.567c-.928-.207-1.763-.553-2.626-.792zm7.969-4.389a10.42 10.42 0 0 1-4.175 1.138c-1.909 0-3.49-1.242-5.9-1.242a6.938 6.938 0 0 0-2.427.428 2 2 0 1 0-3.008.924v13.779a.854.854 0 0 0 .856.856h.571a.854.854 0 0 0 .856-.856v-3.369a9.95 9.95 0 0 1 4.082-.789c1.913 0 3.49 1.242 5.9 1.242a7.457 7.457 0 0 0 4.371-1.46 1.138 1.138 0 0 0 .492-.942V3.421a1.14 1.14 0 0 0-1.619-1.035zm-10.6 9.228a11.242 11.242 0 0 0-2.626.592V9.691a10.209 10.209 0 0 1 2.63-.621zm10.506-4.8a11.383 11.383 0 0 1-2.626.853V10.2a6.633 6.633 0 0 0 2.626-.928v2.516a5.766 5.766 0 0 1-2.626.967V10.2a6.036 6.036 0 0 1-2.626-.2v2.41a20.821 20.821 0 0 0-2.626-.76V9.205a7.932 7.932 0 0 0-2.624-.135v-2.5a12.593 12.593 0 0 0-2.629.748V4.8a10.219 10.219 0 0 1 2.626-.785v2.557a6.07 6.07 0 0 1 2.626.2V4.37a20.315 20.315 0 0 0 2.626.76v2.441a6.8 6.8 0 0 0 2.626.1V5.1a12.626 12.626 0 0 0 2.626-.8z" transform="translate(-.563 .003)" />
    // </svg>


    const IconEnvelopeOpen = <svg xmlns="http://www.w3.org/2000/svg" width="17.266" height="17.266" viewBox="0 0 17.266 17.266">
        <path d="M8.633 0L0 4.316v12.95h17.266V4.316zm0 2.439l6.475 3.237v4.058l-6.475 3.237-6.475-3.237V5.676zM4.316 6.5v2.155l4.316 2.158 4.316-2.158V6.5z" />
    </svg>


    const IconAwesomePenFancy = <svg xmlns="http://www.w3.org/2000/svg" width="17.266" height="17.266" viewBox="0 0 17.266 17.266">
        <g>
            <path d="M2.67 9.541a1.079 1.079 0 0 0-.683.683L0 16.187l.158.158 3.132-3.132a1.038 1.038 0 0 1-.053-.263 1.079 1.079 0 1 1 1.079 1.079 1.038 1.038 0 0 1-.263-.053L.921 17.108l.158.158 5.963-1.988a1.079 1.079 0 0 0 .683-.683l1.115-2.834-3.335-3.335zM12.452.955L6.277 7.682l3.3 3.3L16.3 4.807c2.868-2.531-1.336-6.693-3.848-3.852z" />
        </g>
    </svg>


    // const IconSend = <svg xmlns="http://www.w3.org/2000/svg" width="18.494" height="18.499" viewBox="0 0 18.494 18.499">
    //     <path d="M17.2.115L.452 9.774a.868.868 0 0 0 .079 1.561l3.84 1.611L14.75 3.8a.217.217 0 0 1 .311.3l-8.7 10.6v2.908a.867.867 0 0 0 1.535.571l2.294-2.792 4.5 1.886a.869.869 0 0 0 1.192-.657l2.6-15.606A.867.867 0 0 0 17.2.115z" transform="translate(-.001 .002)" />
    // </svg>


    const IconEnvelopeClosed = <svg xmlns="http://www.w3.org/2000/svg" width="17.331" height="12.998" viewBox="0 0 17.331 12.998">
        <path d="M0 0v2.166L8.665 6.5l8.665-4.333V0zm0 4.333V13h17.331V4.333L8.665 8.665z" />
    </svg>

    const IconAwesomePenFancyRight = <svg xmlns="http://www.w3.org/2000/svg" width="17.875" height="19.445" viewBox="0 0 17.875 19.445">
        <g>
            <path d="M2.527 9.029a1.021 1.021 0 0 0-.646.646L0 15.318l.15.15L3.114 12.5a.982.982 0 0 1-.05-.249 1.021 1.021 0 1 1 1.021 1.021.982.982 0 0 1-.249-.05L.872 16.19l.149.149 5.643-1.881a1.021 1.021 0 0 0 .646-.646l1.055-2.682L5.21 7.974zM11.784.9L5.94 7.27l3.123 3.123 6.366-5.844c2.71-2.395-1.268-6.334-3.645-3.649z" transform="translate(1.5) rotate(-90 8.188 8.151)" />
            <path stroke="#c8e0ed" stroke-linecap="round" stroke-width="3px" d="M0 0h14.769" transform="translate(1.5) translate(0 17.945)" />
        </g>
    </svg>


    const applicationSummaryRedirect = (item) => {
        console.log(item)
        let data = {
            "customer_id": selectedCustomer && selectedCustomer.customer_info && selectedCustomer.customer_info.main_app ? selectedCustomer.customer_info.main_app.id : null,
            "co_customer_id": selectedCustomer &&  selectedCustomer.co_app ? selectedCustomer.co_app.co_customer_id : null,
            "include_products": true
        }
        getDealerAdditionalInfo(item.dealer_id)
        getCustomerApiInitiateForGenerateOrder(data, history, '/admin/applyApplicationSummary')
        // getCustomerApiInitiateForGenerateOrder(data, history, '/admin/applyApplicationSummary')

    }

    return (
        <>
            {actionLoading && <Loader />}

            <div className="dealer">
                <Header>
                    <HeaderLeft>
                        <img src={IconBackArrow} alt="back" onClick={() => history.push({ pathname: '/', search: '?tab=' + mainTab })} />
                    </HeaderLeft>
                    <HeaderCenter>
                        <div className="center-text">{selectedCustomer.customer_info.main_app.full_name}</div>
                    </HeaderCenter>
                    <HeaderRight></HeaderRight>
                </Header>
            </div>

            <div className="main">
                <table style={{ width: '100%' }}>
                    {selectedCustomer && selectedCustomer.paperwork && selectedCustomer.paperwork.products && selectedCustomer.paperwork.products.map((app) => {
                        return (
                            <tr className="paper-work">
                                <td onClick={() => { app.app_status !== 'funded' && applicationSummaryRedirect(app) }}>

                                    {app.price &&
                                        (
                                            <h6 className="item">Purchase : ${app.price}</h6>
                                        )
                                    }

                                    {app.hello_sign && app.hello_sign.length > 0 &&
                                        app.signature_request &&
                                        <div className="d-flex hello-sign-status" style={{ height: 25 }}>
                                            {
                                                app.hello_sign &&
                                                app.hello_sign[0] &&
                                                app.hello_sign[0].last_viewed_at == null &&
                                                app.hello_sign[0].status_code == "error_invalid_email" &&
                                                <div style={{ display: 'flex' }}>
                                                    <button>
                                                        {IconEnvelopeClosed}
                                                    </button>
                                                    <button>
                                                        {IconAwesomePenFancy}
                                                    </button>
                                                </div>
                                            }

                                            {
                                                app.hello_sign &&
                                                app.hello_sign[0] &&
                                                app.hello_sign[0].last_viewed_at == null &&
                                                app.hello_sign[0].status_code == "awaiting_signature" &&
                                                <div style={{ display: 'flex' }}>
                                                    <button>
                                                        {IconEnvelopeClosed}
                                                    </button>
                                                    <button>
                                                        {IconAwesomePenFancy}
                                                    </button>
                                                </div>
                                            }


                                            {
                                                app.hello_sign &&
                                                app.hello_sign[0] &&
                                                app.hello_sign[0].last_viewed_at != null &&
                                                app.hello_sign[0].status_code == "signed" &&
                                                <div style={{ display: 'flex' }}>
                                                    <button className="active">
                                                        {IconEnvelopeOpen}
                                                    </button>
                                                    <button className="active">
                                                        {IconAwesomePenFancyRight}
                                                    </button>
                                                </div>
                                            }


                                            {
                                                app.hello_sign &&
                                                app.hello_sign[0] &&
                                                app.hello_sign[0].last_viewed_at != null &&
                                                app.hello_sign[0].status_code == "awaiting_signature" &&
                                                <div style={{ display: 'flex' }}>
                                                    <button className="active">
                                                        {IconEnvelopeOpen}
                                                    </button>
                                                    <button>
                                                        {IconAwesomePenFancy}
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                    }
                                </td>
                                <td style={{ textAlign: "right" }}>
                                    <h6 className="item-desc">{getFormattedDate(app.created_at)}</h6>
                                </td>
                            </tr>
                        );
                    })}
                    {
                        selectedCustomer && selectedCustomer.paperwork && selectedCustomer.paperwork.app_name &&
                        (
                            <tr className="paper-work">
                                <td>
                                    <h6>{selectedCustomer && selectedCustomer.paperwork && selectedCustomer.paperwork.app_name}</h6>
                                </td><td style={{ textAlign: "right" }}>
                                    <h6 className="item-desc">{getFormattedDate(selectedCustomer && selectedCustomer.paperwork && selectedCustomer.paperwork.created_at)}</h6>
                                </td>

                            </tr>
                        )

                    }
                </table>
            </div>

            <CustomerFooter history={history} activeTab={1} />
        </>
    );
}


const mapStateToProps = (state) => ({
    actionLoading: state.dealer.actionLoading,
    selectedCustomer: state.dealer.selectedCustomer,
    signStatus: state.dealer.signStatus,
    apps: state.sales.apps,
});
const mapDispatchToProps = (dispatch) => ({
    getDealerAdditionalInfo: (id) => dispatch(getDealerAdditionalInfo(id)),
    getCustomerPaperWork: (id, cif) => dispatch(getCustomerPaperWork(id, cif)),
    getHelloSignStatus: () => dispatch(getHelloSignStatus()),
    getCustomerApiInitiateForGenerateOrder: (data, history, path) => dispatch(getCustomerApiInitiateForGenerateOrder(data, history, path)),
    getPaperWorkProductSignatureDetails: (data) => dispatch(getPaperWorkProductSignatureDetails(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaperWork);

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import Header, { HeaderLeft, HeaderCenter, HeaderRight } from "../../../../components/Dealer/Header";
import { IconCommentBg, IconBackArrow, IconFixIssueAwesomeSendRed } from "assets/images";
import { getCustomerApiInitiateForGenerateOrder } from '../../../../redux/actions/sales';
import { requestPreApproval, getProductSignatureDetailsDealer } from '../../../../redux/actions/dealer';
import { commentReset, getCustomerProfile } from '../../../../redux/actions/admin';
import CustomerFooter from "./CustomerFooter";
import Loader from "shared/Loader";
import moment from 'moment';
import {
    CircleStatusIcon,
    HexagonStatusIcon,
    TriangleStatusIcon,
} from '../../../Admin/2.0/Home/Review/components/StatusIcons';
import "assets/css/dealer/profile.scss";

function Profile(props) {

    const {
        history,
        actionLoading,
        selectedCustomer: customer,
        getCustomerApiInitiateForGenerateOrder,
        getProductSignatureDetailsDealer,
        requestPreApproval,
        getCustomerProfile,
    } = props;
    const dispatch = useDispatch()
    const [currentData, setCurrentData] = useState(moment())
    const [targetDate , setTargetDate] = useState(null)


    console.log(customer)

    const data = useSelector((state) => console.log(state))

    useEffect(() => {

        if (customer && customer.app_inprogress && customer.app_inprogress.length > 0 && customer.app_inprogress[0].data && customer.app_inprogress[0].data.length > 0) {
            let signature_product = customer.app_inprogress[0].data.filter(p => p.signature_request)
            signature_product.map((p_info) => {
                let temp = {
                    product_id: p_info.product_id
                }
                getProductSignatureDetailsDealer(temp);
            })
        }
    }, [])

    useEffect(() => {

        const intervalId = setInterval(function () {
            if (window.location.pathname === '/dealer/profile') {
                getCustomerProfile(customer && customer.app_id, customer.cif_number, null, 'dealer', false)
            }
        }, 120000);

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    const generateOrder = () => {

        let data = {
            "customer_id": customer.customer_info.main_app.id,
            "co_customer_id": customer.customer_info.co_app && customer.customer_info.co_app.id ? customer.customer_info.co_app.id : null,
            "include_products": false
        }
        localStorage.setItem('redirectionPath', window.location.pathname + window.location.search)
        getCustomerApiInitiateForGenerateOrder(data, history, '/admin/applyApplicationSummary')
    }

    // const madePreApprovalAction = (id) => {
    //     requestPreApproval(id).then(() => {
    //         getCustomerProfile(id, null, 'dealer', false)
    //     })
    // }

    const goToComment = () => {
            dispatch(commentReset())
        history && history.push('/dealer/comments');
        // localStorage.setItem('redirectionPath', '/dealer/profile')
    }

    const IconAwesomePaperPlane = <svg xmlns="http://www.w3.org/2000/svg" width="17.982" height="18.273" viewBox="0 0 17.982 18.273">
        <path d="M8.956 6.775v2.43c.931.211 1.759.557 2.626.8V7.567c-.928-.207-1.763-.553-2.626-.792zm7.969-4.389a10.42 10.42 0 0 1-4.175 1.138c-1.909 0-3.49-1.242-5.9-1.242a6.938 6.938 0 0 0-2.427.428 2 2 0 1 0-3.008.924v13.779a.854.854 0 0 0 .856.856h.571a.854.854 0 0 0 .856-.856v-3.369a9.95 9.95 0 0 1 4.082-.789c1.913 0 3.49 1.242 5.9 1.242a7.457 7.457 0 0 0 4.371-1.46 1.138 1.138 0 0 0 .492-.942V3.421a1.14 1.14 0 0 0-1.619-1.035zm-10.6 9.228a11.242 11.242 0 0 0-2.626.592V9.691a10.209 10.209 0 0 1 2.63-.621zm10.506-4.8a11.383 11.383 0 0 1-2.626.853V10.2a6.633 6.633 0 0 0 2.626-.928v2.516a5.766 5.766 0 0 1-2.626.967V10.2a6.036 6.036 0 0 1-2.626-.2v2.41a20.821 20.821 0 0 0-2.626-.76V9.205a7.932 7.932 0 0 0-2.624-.135v-2.5a12.593 12.593 0 0 0-2.629.748V4.8a10.219 10.219 0 0 1 2.626-.785v2.557a6.07 6.07 0 0 1 2.626.2V4.37a20.315 20.315 0 0 0 2.626.76v2.441a6.8 6.8 0 0 0 2.626.1V5.1a12.626 12.626 0 0 0 2.626-.8z" transform="translate(-.563 .003)" />
    </svg>

    const IconEnvelopeOpen = <svg xmlns="http://www.w3.org/2000/svg" width="17.266" height="17.266" viewBox="0 0 17.266 17.266">
        <path d="M8.633 0L0 4.316v12.95h17.266V4.316zm0 2.439l6.475 3.237v4.058l-6.475 3.237-6.475-3.237V5.676zM4.316 6.5v2.155l4.316 2.158 4.316-2.158V6.5z" />
    </svg>

    const IconAwesomePenFancy = <svg xmlns="http://www.w3.org/2000/svg" width="17.266" height="17.266" viewBox="0 0 17.266 17.266">
        <g>
            <path d="M2.67 9.541a1.079 1.079 0 0 0-.683.683L0 16.187l.158.158 3.132-3.132a1.038 1.038 0 0 1-.053-.263 1.079 1.079 0 1 1 1.079 1.079 1.038 1.038 0 0 1-.263-.053L.921 17.108l.158.158 5.963-1.988a1.079 1.079 0 0 0 .683-.683l1.115-2.834-3.335-3.335zM12.452.955L6.277 7.682l3.3 3.3L16.3 4.807c2.868-2.531-1.336-6.693-3.848-3.852z" />
        </g>
    </svg>

    const IconSend = <svg xmlns="http://www.w3.org/2000/svg" width="18.494" height="18.499" viewBox="0 0 18.494 18.499">
        <path d="M17.2.115L.452 9.774a.868.868 0 0 0 .079 1.561l3.84 1.611L14.75 3.8a.217.217 0 0 1 .311.3l-8.7 10.6v2.908a.867.867 0 0 0 1.535.571l2.294-2.792 4.5 1.886a.869.869 0 0 0 1.192-.657l2.6-15.606A.867.867 0 0 0 17.2.115z" transform="translate(-.001 .002)" />
    </svg>

    const IconEnvelopeClosed = <svg xmlns="http://www.w3.org/2000/svg" width="17.331" height="12.998" viewBox="0 0 17.331 12.998">
        <path d="M0 0v2.166L8.665 6.5l8.665-4.333V0zm0 4.333V13h17.331V4.333L8.665 8.665z" />
    </svg>

    const IconAwesomePenFancyRight = <svg xmlns="http://www.w3.org/2000/svg" width="17.875" height="19.445" viewBox="0 0 17.875 19.445">
        <g>
            <path d="M2.527 9.029a1.021 1.021 0 0 0-.646.646L0 15.318l.15.15L3.114 12.5a.982.982 0 0 1-.05-.249 1.021 1.021 0 1 1 1.021 1.021.982.982 0 0 1-.249-.05L.872 16.19l.149.149 5.643-1.881a1.021 1.021 0 0 0 .646-.646l1.055-2.682L5.21 7.974zM11.784.9L5.94 7.27l3.123 3.123 6.366-5.844c2.71-2.395-1.268-6.334-3.645-3.649z" transform="translate(1.5) rotate(-90 8.188 8.151)" />
            <path stroke="#c8e0ed" stroke-linecap="round" stroke-width="3px" d="M0 0h14.769" transform="translate(1.5) translate(0 17.945)" />
        </g>
    </svg>

    let mainTab = localStorage.getItem('mainTab') ? localStorage.getItem('mainTab') : 0




    const madePreApprovalAction = (item, app_id) => {
        console.log(item)
        console.log(app_id)
        console.log(props)
        let data = {
            "contact": {
                "co_enabled": item.customer_info.co_app ? true : false,
                "product_type": [
                    "Food/Mac",
                    "Other Appliance"
                ],
                "main_app": {
                    "id": item.customer_info.main_app.id ? item.customer_info.main_app.id : null,
                    "cif_number": item.dealer_info.TCP_CIF_number || item.cif_number,
                    "nortridge_cif_number": item.customer_info.main_app.nortridge_cif_number || item.cif_number,
                    "name": item.customer_info.main_app.full_name,
                    "email": item.customer_info.main_app.email || "",
                    "phone": item.customer_info.main_app.phone || "",
                    "address": item.customer_info.main_app.address,
                },
                "co_app": item.customer_info.co_app ? {
                    "id": item.customer_info.co_app.id ? item.customer_info.co_app.id : null,
                    "name": item.customer_info.co_app.full_name,
                    "email": item.customer_info.co_app.email || "",
                    "phone": item.customer_info.co_app.phone || "",
                    "address": item.customer_info.co_app.address
                } : null
            }
        }
        requestPreApproval(data).then(() => {
            getCustomerProfile(app_id, item.customer_info.main_app.nortridge_cif_number || item.cif_number, 'dealer', false);
        });
    };

    useEffect(() => {
        let newArr = customer?.product_info.filter((item) => {
           return item.product_name == "Food/Mac"
        })
        if(newArr && newArr.length > 0) {
            let targetDataHere = moment(newArr?.[0]?.earliest_delivery_date);
            setTargetDate(targetDataHere)
            const isAfterTargetDate = currentData.isAfter(targetDataHere);
        }
    },[customer])

    return (
        customer ?
            <>
                <div className="dealer">
                    <Header>
                        <HeaderLeft>
                            <img src={IconBackArrow} alt="back"
                            //  onClick={() => history.push({ pathname: '/', search: '?tab=' + mainTab })} 
                            onClick={() => {history.goBack()}}
                             />
                        </HeaderLeft>
                        <HeaderCenter>
                            <div className="center-text">{customer.customer_info.main_app.full_name}</div>
                        </HeaderCenter>
                        <HeaderRight></HeaderRight>
                    </Header>
                </div>

                {actionLoading && <Loader />}

                <div className="c-profile m-3" style={{ paddingBottom: 65 }}>
                    <div className="profile">
                        <div>
                            <div className="personal-details">
                                <div>
                                    <h6 className="name">{customer.customer_info.main_app.name}</h6>
                                    <h6 className="date">
                                        {customer.customer_info.main_app.dob ==
                                            '00/00/00'
                                            ? 'DD/MM/YY'
                                            : customer.customer_info.main_app.dob}
                                    </h6>
                                </div>
                                <div className="mt-3">
                                    <div className="row">
                                        <h6 className="title">Address:</h6>
                                        <h6 className="sub-title">
                                            {customer.customer_info.main_app.street || customer.customer_info.main_app.city || customer.customer_info.main_app.state ? 
                                            <>
                                             {`${customer.customer_info.main_app.street && customer.customer_info.main_app.street.length > 0 ? (customer.customer_info.main_app.street + ", ") : ""}`}
                                            {/* {customer.customer_info.main_app.city},{" "} */}
                                            {`${customer.customer_info.main_app.city && customer.customer_info.main_app.city.length > 0 ? (customer.customer_info.main_app.city + ", ") : ""}`}
                                            {customer.customer_info.main_app.state}
                                            </>  :
                                            <>
                                            N/A
                                            </>
                                            }
                                           
                                            </h6>
                                    </div>
                                    <div className="row">
                                        <h6 className="title">Email:</h6>
                                        <h6 className="sub-title">{customer.customer_info.main_app.email}</h6>
                                    </div>
                                    <div className="row">
                                        <h6 className="title">Phone:</h6>
                                        <h6 className="sub-title">{customer.customer_info.main_app.phone}</h6>
                                    </div>
                                </div>
                            </div>
                            {customer && customer.customer_info && customer.customer_info.co_app &&
                                <>
                                    <hr style={{ background: "#3a5c77" }}></hr>
                                    <div className="profile-details pt-2">
                                        <div>
                                            <h6 className="name">{customer.customer_info.co_app.name}</h6>
                                        </div>
                                        <div className="mt-3">
                                            <div className="row">
                                                <h6 className="title">Email:</h6>
                                                <h6 className="sub-title">{customer.customer_info.co_app.email}</h6>
                                            </div>
                                            <div className="row">
                                                <h6 className="title">Phone:</h6>
                                                <h6 className="sub-title">{customer.customer_info.co_app.phone}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="credit">
                            <h6 className="sub-title">{customer.dealer_info.dealer_name}</h6>
                            <h6 className="title">TCP# {customer.dealer_info.TCP_CIF_number}</h6>
                            <Row className="credit-comment text-center" style={{minWidth : 112}}>
                                <Col xs="4">
                                    <img src={IconCommentBg} alt="comment" style={{position : 'relative', top : 2}}/>
                                </Col>
                                <Col xs="8" onClick={goToComment} style={{ cursor: 'pointer', fontSize : 11 }}>
                                    Comments <br /><span> {customer.comments_count == 0 ? '(0)' : `(${customer.comments_count})`}</span>
                                </Col>
                            </Row>
                            <div className="credit-system">
                                <h6>
                                    Credit App on File: <br />
                                    <span className="sub-title f-12">{customer.credit_app_on_file}</span>
                                </h6>
                                <div className="d-flex justify-content-between mt-3">
                                    {customer.product_info && customer.product_info.map(pp => {
                                        return (<h6>
                                            {pp.product_name == 'Other Appliance' ? 'Appliance' : pp.product_name}: <br />
                                            <span className="sub-title f-12">Tier {pp.tiers}</span>
                                        </h6>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="orders">
                        <h6 style={{ marginBottom: 0 }}>Latest Order</h6>
                        <h6 style={{ marginBottom: 0 }}>In Process</h6>
                    </div>
                    <div className="d-flex justify-content-between balance-details">
                        <div style={{ maxHeight: 210, overflow: 'scroll' }}>
                            {customer && customer.latest_order && customer.latest_order.orders && customer.latest_order.orders.map((lo) => {
                                return (
                                    <>
                                        <div>
                                            <h6 className="title">Food Balance:</h6>
                                            <h6 className="sub-title">${lo.loan_type == 'Food/Mac' ? lo.Total_balance : '0'}</h6>
                                        </div>
                                        <div>
                                            <h6 className="title">Appliance Balance:</h6>
                                            <h6 className="sub-title">${lo.loan_type == 'Other Appliance' ? lo.Total_balance : '0'}</h6>
                                        </div>
                                        <div>
                                            <h6 className="title">Past Due Balance:</h6>
                                            <h6 className="sub-title">${lo.Past_Due_Balance}</h6>
                                        </div>
                                        <div>
                                            <h6 className="title">Final Food Pmt Expected:</h6>
                                            <h6 className="sub-title">{lo.Final_payment_date == "MM/DD/YY" ? "MM/DD/YY" :
                                                moment(
                                                    lo.Final_payment_date
                                                ).format('MM/DD/YY')}</h6>
                                        </div>
                                    </>

                                )
                            })}
                            <div>
                                <h6 className="title">
                                    Total Past Due Balance:
                                </h6>
                                <h6 className="sub-title">
                                    ${customer && customer.latest_order && customer.latest_order.Total_Past_Due_Balance}
                                </h6>
                            </div>
                        </div>

                        {customer.preapproval_status == null  || customer.preapproval_status == 'order_generated' ? (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {customer.app_inprogress && customer.app_inprogress.length > 0 && (
                                    <div className="in-process-data">
                                        {/* <div className="status" style={{display : 'flex', flexWrap : 'wrap', justifyContent : 'center', alignItems : 'center'}}>
                                            <span className={`shape-circle ${customer.app_inprogress[0].funding_status == "sales" ? "border" : ""}`} style={{ fontSize : '1rem', width : 38, height : 38}} >
                                                {customer.app_inprogress[0].funding_status == "scheduled" ? "Sch" : customer.app_inprogress[0].funding_status == "sales" ? "S" : customer.app_inprogress[0].funding_status == "funded" ? "F" : "In"}
                                            </span>
                                        </div> */}
                                        <div 
                                            className={`${(customer.app_inprogress[0].funding_status != 'scheduled' &&
                                            customer.app_inprogress[0].funding_status != 'sales' &&
                                            customer.app_inprogress[0].funding_status != 'funded') ?
                                            'status-Width-Height-left' : 'status'  

                                            } d-flex flex-wrap justify-content-center align-items-center`}>
                                                <span className="positive-relative">
                                                    {customer.app_inprogress[0]
                                                        .funding_status ==
                                                        'scheduled' ? (
                                                        <span className="funding-status text-white">
                                                            Sch
                                                        </span>
                                                    ) : customer
                                                        .app_inprogress[0]
                                                        .funding_status ==
                                                        'sales' ? (
                                                        <>
                                                            <div
                                                            style={{
                                                                position: 'relative',
                                                                bottom: 4
                                                            }}
                                                            className='adminCustomerProfileApproveIcon'
                                                        >
                                                          <TriangleStatusIcon
                                                            symbol={'S'}
                                                            fill={true}
                                                            disabled={false}
                                                        />
                                                            {/* <span className="in-progress">
                                                                In
                                                            </span> */}
                                                        </div>
                                                        </>
                                                    ) : customer
                                                        .app_inprogress[0]
                                                        .funding_status ==
                                                        'funded' ? (
                                                        <span className="funding-status text-white">
                                                            F
                                                        </span>
                                                    ) : 
                                                    customer
                                                    .app_inprogress[0]
                                                    .funding_status ==
                                                    'rejection' ? (
                                                        <div
                                                            // style={{
                                                            //     marginTop: 6,
                                                            //     marginLeft: 5,
                                                            // }}
                                                            className='adminCustomerProfileApproveIcon'
                                                            style={{position : 'relative', top : '-1px'}}
                                                        >
                                                        <HexagonStatusIcon
                                                        symbol={'S'}
                                                        fill={true}
                                                        disabled={false}
                                                    />
                                                    </div>
                                                    )
                                                    :
                                                    (
                                                        <div
                                                            // style={{
                                                            //     marginTop: 6,
                                                            //     marginLeft: 5,
                                                            // }}
                                                            className='adminCustomerProfileApproveIcon'
                                                        >
                                                           <CircleStatusIcon
                                                                symbol={'S'}
                                                                fill={true}
                                                                disabled={false}
                                                                adminProfileTextSizeIncrease={true}
                                                            />
                                                            {/* <span className="in-progress">
                                                                In
                                                            </span> */}
                                                        </div>
                                                    )
                                                    
                                                    }
                                                </span>
                                            </div>
                                            {
                                                customer && customer.app_inprogress[1] && customer.app_inprogress[1].app_name && customer.app_inprogress[1].app_name.length > 0 &&
                                                <>
                                                <div
                                                                className="d-flex"
                                                                style={{
                                                                    justifyContent:
                                                                        'space-between',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                <div>
                                                                    <h6 className="title">
                                                                        {
                                                                            customer?.app_inprogress?.[1]?.app_name
                                                                        }
                                                                    </h6>
                                                                </div>
                                                                </div>
                                                </>
                                            }
                                        {customer.app_inprogress[0].data && customer.app_inprogress[0].data.map(app => {
                                            return (

                                                <div
                                                    className="d-flex"
                                                    style={{
                                                        justifyContent:
                                                            'space-between',
                                                        alignItems:
                                                            'center',
                                                    }}
                                                >
                                                    <div>
                                                        <h6 className="title">
                                                            {
                                                                app.product_name
                                                            }
                                                            :
                                                        </h6>
                                                        <h6 className="sub-title">
                                                            $
                                                            {
                                                                app.balance_amount
                                                            }
                                                        </h6>
                                                    </div>
                                                    {/* {app.hello_sign && */}


                                                    <div
                                                        className="d-flex hello-sign-status"
                                                        style={{
                                                            height: 10,
                                                        }}
                                                    >
                                                        {app && app.hello_sign && app.hello_sign.last_viewed_at ==
                                                            null &&
                                                            app && app.hello_sign && app.hello_sign.status_code ==
                                                            'error_invalid_email' && (
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <button>
                                                                        {
                                                                            IconAwesomePenFancy
                                                                        }
                                                                    </button>
                                                                    <button>
                                                                        {
                                                                            IconEnvelopeClosed
                                                                        }
                                                                    </button>
                                                                </div>
                                                            )}

                                                        {app && app.hello_sign && app.hello_sign.last_viewed_at == null &&
                                                            app && app.hello_sign && app.hello_sign.status_code == 'awaiting_signature' && (
                                                                <div style={{ display: 'flex', }}>
                                                                    <button>
                                                                        {IconAwesomePenFancy}
                                                                    </button>
                                                                    <button>
                                                                        {IconEnvelopeClosed}
                                                                    </button>
                                                                </div>
                                                            )}

                                                        {app && app.hello_sign && app.hello_sign.last_viewed_at != null &&
                                                            app && app.hello_sign && app.hello_sign.status_code == 'signed' && (
                                                                <div style={{ display: 'flex', }}>
                                                                    <button className="active">
                                                                        {IconAwesomePenFancyRight}
                                                                    </button>
                                                                    <button className="active">
                                                                        {IconEnvelopeOpen}
                                                                    </button>
                                                                </div>
                                                            )}

                                                        {app && app.hello_sign && app.hello_sign.last_viewed_at != null &&
                                                            app && app.hello_sign && app.hello_sign.status_code == 'awaiting_signature' && (
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <button>
                                                                        {
                                                                            IconAwesomePenFancy
                                                                        }
                                                                    </button>
                                                                    <button className="active">
                                                                        {
                                                                            IconEnvelopeOpen
                                                                        }
                                                                    </button>
                                                                </div>
                                                            )}
                                                    </div>

                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        )
                            :
                            (

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {customer.product_info &&
                                        customer.product_info.length > 0 && (
                                            <>
                                                {
                                                    customer.product_info.map(
                                                        (app) => {
                                                            return (
                                                                <div
                                                                    className="pre-inprocess"
                                                                    // onClick={() =>
                                                                    //     handleArrowBack()
                                                                    // }
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="d-flex"
                                                                        style={{
                                                                            justifyContent:
                                                                                'space-between',
                                                                            alignItems:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        <div className='w-100'>
                                                                            <h6 className="title mt-0">
                                                                                {
                                                                                    app.product_name
                                                                                }
                                                                                :
                                                                            </h6>

                                                                            <div className='mt-3'>
                                                                                <span className="positive-relative">
                                                                                    {
                                                                                        app.product_status == 'in_process' && <TriangleStatusIcon
                                                                                            symbol="In"
                                                                                            fill={true}
                                                                                            disabled={app.product_status === 'in_process' ? false : true}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        app.product_status == 'approval'
                                                                                        &&
                                                                                        <CircleStatusIcon
                                                                                            symbol="P"
                                                                                            fill={true}
                                                                                            disabled={app.product_status === 'approval' ? false : true}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        app.product_status == 'rejection' && <HexagonStatusIcon
                                                                                            symbol="P"
                                                                                            fill={true}
                                                                                            disabled={app.product_status === 'rejection' ? false : true}
                                                                                        />
                                                                                    }


                                                                                </span>
                                                                            </div>
                                                                            {app.product_name ==
                                                                                'Food/Mac' && (
                                                                                    <h6 className="title">
                                                                                        Earliest
                                                                                        delivery
                                                                                        date
                                                                                        <br />
                                                                                        {
                                                                                        customer.preapproval_status == 'rejection' ? 
                                                                                        <span style={{ color: "#fff" }}>Not Approved</span>
                                                                                        :
                                                                                        <span style={{ color: "#fff" }}>{app.earliest_delivery_date ==
                                                                                        '00/00/00' || app.earliest_delivery_date == null
                                                                                        ? 'N/A'
                                                                                        :
                                                                                        
                                                                                            currentData.isAfter(targetDate)
                                                                                        
                                                                                        ?
                                                                                        moment().format('MM/DD/YYYY')
                                                                                        : moment(app.earliest_delivery_date).format('MM/DD/YYYY')}</span>
                                                                                    }
                                                                                    </h6>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </>
                                        )}
                                </div>
                            )}
                    </div>

                    <div className="profile-button">
                        {/* {((customer['preapproval_status'] == "N/A" || customer['preapproval_status'] == null) && customer.app_inprogress[0].funding_status == "funded") &&
                            <> */}
                                <button className="request"
                                    onClick={() => madePreApprovalAction(customer, customer.app_id)}>
                                    Request PreApproval</button>
                                <button className="request-generate" onClick={() => generateOrder()}>Generate an Order</button>
                            {/* </>
                        } */}
                    </div>
                </div>

                <CustomerFooter history={history} activeTab={0} />
            </>
            :
            <></>
    );
}


const mapStateToProps = (state) => ({
    actionLoading: state.sales.actionLoading,
    selectedCustomer: state.dealer.selectedCustomer,
});
const mapDispatchToProps = (dispatch) => ({
    requestPreApproval: (id) => dispatch(requestPreApproval(id)),
    getProductSignatureDetailsDealer: (data) => dispatch(getProductSignatureDetailsDealer(data)),
    getCustomerApiInitiateForGenerateOrder: (data, history, path) => dispatch(getCustomerApiInitiateForGenerateOrder(data, history, path)),
    getCustomerProfile: (id, cif, history, profileType, action = true) => dispatch(getCustomerProfile(id, cif, history, profileType, action))
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
    deleteDealer,
    setDealer,
    setExpandedDealers,
    getDealerAcessRequest,
} from '../../../../../redux/actions/admin';
import { Form } from 'react-bootstrap';
import { DealerRow } from '../components/DealerRow';
import Header, {
    HeaderCenter,
    HeaderLeft,
    HeaderRight,
} from '../../../../../components/Dealer/Header';
import {
    IconSearchAdmin,
    IconArrowLeft,
    IconKey,
    IconSetting,
    IconCogsvg,
    keySvg,
} from '../../../../../assets/images';
import IconCog from '../../../../../assets/images/IconCog.png'
import keyIconHeader from '../../../../../assets/images/keyIconHeader.png'
import { CircledImageButton } from '../../../style';
import Loader from '../../../../../shared/Loader';

export function PureDealerReview(props) {
    const {
        history,
        dealer,
        actionLoading,
        setExpandHandler,
        setExpandedDealers,
        expandedDealers,
        getDealerAcessRequest,
        parentControlReview,
        changeParentControlReviewHandler,
        changeExandAllFundinhHandler
    } = props;

    console.log(dealer);
    console.log('reviewprops', props);

    const data = useSelector((state) => console.log(state));

    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
    const [search, setSearch] = useState('');
    const [filterDealer, setFilterDealer] = useState(null);

    const handleClickSearch = () => {
        setIsSearchBarVisible(!isSearchBarVisible);
    };

    useEffect(() => {
        if (search != null && search !== '') {
            let temp_dealers = [];

            dealer.forEach((dealer) => {
                let temp_sales_data = [];
                let temp_preapproval_data = [];

                if (
                    dealer.sales_data.length > 0 ||
                    dealer.preapproval_data.length > 0
                ) {
                    temp_sales_data = dealer.sales_data.filter((d) =>
                        d.customer_name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    );

                    temp_preapproval_data = dealer.preapproval_data.filter(
                        (d) =>
                            d.customer_name
                                .toLowerCase()
                                .includes(search.toLowerCase())
                    );
                }

                if (temp_sales_data.length > 0 || temp_preapproval_data > 0) {
                    temp_dealers =
                        temp_dealers.length > 0
                            ? [
                                ...temp_dealers,
                                {
                                    ...dealer,
                                    sales_data: temp_sales_data,
                                    preapproval_data: temp_preapproval_data,
                                },
                            ]
                            : [
                                {
                                    ...dealer,
                                    ...dealer.preapproval_data,
                                    sales_data: temp_sales_data,
                                    preapproval_data: temp_preapproval_data,
                                },
                            ];
                } else {
                    setFilterDealer([]);
                }
            });

            setFilterDealer(temp_dealers);
        } else {
            setFilterDealer(dealer);
        }
    }, [search]);

    useEffect(() => {
        setFilterDealer(dealer);
    }, [dealer]);

    useEffect(() => {
        setExpandHandler && setExpandHandler(handleExpand);
        return () => {
            setExpandHandler && setExpandHandler(() => { });
        };
    });

    const handleExpand = () => {
        const activeIndex =
            expandedDealers[toOpenIndex('preapproval_' + dealer[0].id)];
        const activeIndex2 =
            expandedDealers[toOpenIndex('order_' + dealer[0].id)];

        expandedDealers[toOpenIndex('preapproval_' + dealer[0].id)] =
            dealer[0].preapproval_data.length > 0 ? !activeIndex : false;
        expandedDealers[toOpenIndex('order_' + dealer[0].id)] =
            dealer[0].sales_data.length > 0 ? !activeIndex2 : false;

        setExpandedDealers({ ...expandedDealers });
    };

    const handleOpenDealerAction = (id) => {
        changeParentControlReviewHandler();
        console.log(id);
        const key = toOpenIndex(id);
        const currentValue = expandedDealers[key];
        console.log(currentValue);
        setExpandedDealers({
            ...expandedDealers,
            [key]: !currentValue,
        });
    };

    const renderDealers = (dealer, statusName) => {
        console.log('dealer', dealer, statusName);
        return (
            <DealerRow
                history={history}
                key={dealer.id}
                statusName={statusName}
                dealer={dealer}
                // expanded={statusName == "Pre Approvals" ?
                //     expandedDealers[toOpenIndex("preapproval_" +  (dealer.length > 0 && dealer[0].id))]
                //     : expandedDealers[toOpenIndex("order_" +  (dealer.length > 0 && dealer[0].id))]}
                // expanded={true}
                // expanded={
                //     parentControlReview ? false :
                //     statusName == "Pre Approvals" ?
                //     expandedDealers[toOpenIndex("preapproval_" +  (dealer.length > 0 && dealer[0].id))]
                //     : expandedDealers[toOpenIndex("order_" +  (dealer.length > 0 && dealer[0].id))]
                // }
                expanded={
                    parentControlReview == undefined
                        ? statusName == 'Pre Approvals'
                            ? expandedDealers[toOpenIndex('preapproval_' + (dealer.length > 0
                                && dealer[0].id))]
                            :
                            expandedDealers[toOpenIndex('order_' + (dealer.length > 0 &&
                                dealer[0].id))]
                        : parentControlReview
                }
                onClick={handleOpenDealerAction}
                type={statusName == 'Pre Approvals' ? 'preapproval' : (statusName == 'Incomplete' ? 'Incomplete' : 'order')}
                parentControlReview={parentControlReview}
                changeExandAllFundinhHandler={changeExandAllFundinhHandler}
            />
        );
    };

    const toOpenIndex = (id) => {
        return 'map' + id;
    };

    const handleArrowBack = () => {
        // let path = localStorage.getItem('redirectionPath') ? localStorage.getItem('redirectionPath') : '/?tab=1'
        // history.push(path)
        sessionStorage.clear();
        history && history.push('/');
    };

    const handleSetting = () => {
        // history && history.push('/admin/dealer/settings');
        window.location.assign('/admin/dealer/settings')
    };

    const handleAccess = () => {
        getDealerAcessRequest(dealer[0].id, history);
        // history && history.push('/login')
    };

    return (
        <>
            {actionLoading && <Loader />}

            <Header>
                <HeaderLeft>
                    <img
                        src={IconArrowLeft}
                        onClick={() => handleArrowBack()}
                        alt=""
                    />
                    <img
                        src={IconCogsvg}
                        width="22px"
                        className="ml-3"
                        onClick={handleSetting}
                    />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">
                        {dealer
                            ? dealer && dealer[0] && dealer[0].company_name
                            : ''}
                    </div>
                </HeaderCenter>
                <HeaderRight>
                    <img
                        src={keySvg}
                        width="27px"
                        className="mr-3"
                        onClick={handleAccess}
                    />
                    <CircledImageButton active={isSearchBarVisible}>
                        <img
                            src={IconSearchAdmin}
                            alt=""
                            onClick={handleClickSearch}
                        />
                    </CircledImageButton>
                </HeaderRight>
            </Header>

            {isSearchBarVisible && (
                <div className="search-header">
                    <form
                    //  action="javascript:void(0)"
                    >
                        <Form.Group>
                            <Form.Control
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            )}

            <div className="main">
                <div className={`list ${isSearchBarVisible ? 'expand' : ''}`}>
                    {filterDealer &&
                        renderDealers(filterDealer, 'Pre Approvals')}
                    {filterDealer &&
                        renderDealers(filterDealer, 'Application(s)')}
                    {filterDealer &&
                        renderDealers(filterDealer, 'Incomplete')}
                    {filterDealer && filterDealer.length === 0 && (
                        <div className="no-data text-center">No data found</div>
                    )}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    dealer: state.admin.dealer,
    expandedDealers: state.admin.expandedDealers,
    actionLoading: state.admin.actionLoading,
});

const mapDispatchToProps = (dispatch) => ({
    setDealer: (data) => dispatch(setDealer(data)),
    deleteDealer: (id) => dispatch(deleteDealer(id)),
    setExpandedDealers: (data) => dispatch(setExpandedDealers(data)),
    getDealerAcessRequest: (data, history) =>
        dispatch(getDealerAcessRequest(data, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PureDealerReview);

import { SliderContainer, SliderItem } from '../../../style';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

// ${localStorage.getItem('role') == 'sales,dealer,admin' && activeTab == 0 && 'admin-review-footer'}
export function HomeTabBar({ activeTab, onClickTab, badges }) {
    return (
        <div className={`footer-container p-3 
        ${localStorage.getItem('role') == 'sales,dealer,admin' && activeTab == 0 && 'admin-review-footer'}
        `}>
            <SliderContainer>
                <SliderItem
                    className="col-6"
                    active={activeTab === 0}
                    onClick={() => onClickTab(0)}
                >
                    <div className="slider-item--left" style={{ fontSize: 14 }}>
                        <span className="badge-icon">{badges[0]}</span>
                    </div>
                    <span style={{ fontSize: 15 }}>Review</span>
                </SliderItem>
                <SliderItem
                    className="col-6"
                    active={activeTab === 1}
                    onClick={() => onClickTab(1)}
                >
                    <span style={{ fontSize: 15 }}>Funding</span>
                    <div
                        className="slider-item--right"
                        style={{ fontSize: 14 }}
                    >
                        <span className="badge-icon">{badges[1]}</span>
                    </div>
                </SliderItem>
            </SliderContainer>
        </div>
    );
}

HomeTabBar.propTypes = {
    activeTab: PropTypes.number,
    onClickTab: PropTypes.func,
    badges: PropTypes.array.isRequired,
};

import React, { useEffect, useState } from 'react';
import { crossSvg, IconMenu, IconProfileEmptyUser } from '../../../../../../assets/images';
import sidebarCross from '../../../../../../assets/images/sidebarCross.png'
import dealer from 'redux/reducers/dealer';

export function SideMenu(props) {

    const {
        history, dealers, fundings
    } = props;

    // console.log(fundings.data[0].admin_name)

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuClick = (e) => {
        e.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const redirect = () => {
        history && history.push('/admin/dealers')
    }

    const onHistory = () => {
        history && history.push('/admin/history')
    }

    const onLogout = () => {
        localStorage.removeItem('token');
        localStorage.clear()
        sessionStorage.clear()
        history.replace('/login');
    }

    const goToProfile = () => {
        history && history.push('/profile');
    }

    return (
        <>
            <img
                className="icon-menu"
                src={IconMenu}
                onClick={handleMenuClick}
                alt=""
            />
            {isMenuOpen && (
                <div className="nav-menu-overlay" onClick={handleMenuClick} />
            )}
            <nav className={isMenuOpen ? 'nav-menu active' : 'nav-menu'}>
                <div className="nav-menu-header">
                    <button
                        className="btn-menu-close"
                        onClick={handleMenuClick}
                    >
                         <img
                src={crossSvg}
                alt=""
            />
                    </button>
                    <div className='center-align' style={{ flexDirection: 'column' }} onClick={goToProfile}>
                        <img
                            className=""
                            src={IconProfileEmptyUser}
                            alt=""
                            width={55}
                        />
                        <label style={{ marginTop: 10 }}>{dealers && dealers.data[0] && dealers.data[0].admin_name || fundings && fundings.data[0] && fundings.data[0].admin_name}</label>
                    </div>
                </div>
                <div className="nav-menu-content">
                    <ul className="nav-menu-items">
                        <li onClick={() => redirect()}>Dealers Management</li>
                        <li onClick={() => onHistory()}>History</li>
                        {/* <li onClick={() => onLogout()} style={{position : 'absolute', bottom : 30, borderTop : 'solid 2px #3a5b77', width : 15, paddingTop : 8}}>Logout</li> */}
                    </ul>
                    <span onClick={() => onLogout()}  style={{position : 'absolute', bottom : 26, borderTop : 'solid 3px #3a5b77', width : '100%', paddingTop : 10, paddingLeft : 56, fontSize : 14, cursor : 'pointer'}}><span style={{position : 'relative', top : 6}}>Logout</span></span>
                   
                </div>
            </nav>
        </>
    );
}


export default SideMenu;


import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
    getDealersFunding,
    getFundingRequests,
    setExpandedDealers,
    setSelectedDealer
} from '../../../../../redux/actions/admin';
import Header, {
    HeaderCenter,
    HeaderLeft,
    HeaderRight,
} from '../../../../../components/Dealer/Header';
import { SideMenu } from '../Review/components/SideMenu';
import { IconSearchAdmin } from '../../../../../assets/images';
import { CircledImageButton } from '../../../style';
import { Form } from 'react-bootstrap';
import { DealerRow } from '../Review/components/DealerRow';
import Loader from '../../../../../shared/Loader';
import DateSelect from '../../../../../components/commons/DateSelect';

export function PureFunding({
    history,
    setExpandHandler,
    getFundingRequests,
    fundings,
    setExpandedDealers,
    expandedDealers,
    actionLoading,
    setSelectedDealer
}) {
    
    var tDate = new Date();
    tDate.setDate(tDate.getDate() + 1);

    const data = useSelector((state) => console.log(state));

    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
    const [search, setSearch] = useState('');
    const [filterDealer, setFilterDealer] = useState([]);
    const [disableDays, setDisableDays] = useState([]);

    const [dateRange, setDateRange] = useState([new Date(), tDate]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        localStorage.setItem('selectedFundedRange', JSON.stringify([startDate, endDate]))
        getFundingRequests(startDate, endDate);

        const intervalId = setInterval(function () {
            if(window.location.pathname === '/') {
                const temp_date = JSON.parse(localStorage.getItem('selectedFundedRange'));
                getFundingRequests(temp_date[0], temp_date[1], false);
            }
        }, 15000);

        return () => {
            clearInterval(intervalId)
        }
    }, []);

    const handleClickSearch = () => {
        setIsSearchBarVisible(!isSearchBarVisible);
    };

    useEffect(() => {
        if (search != null && search !== '') {
            let temp_dealers = [];

            fundings.data.forEach(customer => {

                let temp_sales_customer_data = [];

                if (customer.customer_data.length > 0) {
                     temp_sales_customer_data =  customer.customer_data.filter((d) =>
                        d.customer_name.toLowerCase().includes(search.toLowerCase())
                    );
                }

                if(temp_sales_customer_data.length > 0 ){
                    temp_dealers = temp_dealers.length > 0 ? [...temp_dealers , {...customer, customer_data: temp_sales_customer_data } ] : [{...customer, customer_data: temp_sales_customer_data }]
                }

            });
            setFilterDealer(temp_dealers)
        } else {
            setFilterDealer(fundings.data);
        }
    }, [search]);


    useEffect(() => {
        setFilterDealer(fundings.data);
    }, [fundings]);

    useEffect(() => {
        setExpandHandler && setExpandHandler(handleExpand);
        return () => {
            setExpandHandler && setExpandHandler(() => {});
        };
    });

    const handleExpand = () => {
        const activeIndex = filterDealer.findIndex(
            (dealer) => expandedDealers[toOpenIndex(dealer.id)]
        );
        if (activeIndex > -1) {
            filterDealer.forEach((dealer) => {
                expandedDealers[toOpenIndex(dealer.id)] = false;
            });
        } else {
            filterDealer.forEach((dealer) => {
                if (dealer.num_customers === 0) return;
                expandedDealers[toOpenIndex(dealer.id)] = true;
            });
        }
        setExpandedDealers({ ...expandedDealers });
    };

    const handleOpenDealerAction = (dealer) => {
        console.log('dealer', dealer)
        if (dealer.num_customers > 0 || 1===1) {
            const key = toOpenIndex(dealer.id);
            const currentValue = expandedDealers[key];
            setExpandedDealers({
                ...expandedDealers,
                [key]: !currentValue,
            });
        }
    };

    const getFundingFrequency = (values) => {
        setDisableDays(values)
    }

    const renderDealers = (dealers) => {
        return dealers.map((item) => (
            <DealerRow
                history={history}
                key={item.id}
                dealer={item}
                expanded={expandedDealers[toOpenIndex(item.id)]}
                selectDealer={setSelectedDealer}
                onClick={handleOpenDealerAction}
                type = "funding"
                getFundingFrequency = {getFundingFrequency}
            />
        ));
    };

    const toOpenIndex = (id) => {
        return 'map' + id;
    };

    const handleDatePickerClose = () => {
        getFundingRequests(startDate, endDate);
    }

    const forwardClick = () => {

        var sDate = new Date(startDate);
        sDate.setDate(sDate.getDate() + 1);

        var eDate = new Date(endDate);
        eDate.setDate(eDate.getDate() + 1);

        setDateRange([sDate, eDate])
        localStorage.setItem('selectedFundedRange', JSON.stringify([sDate, eDate]))
        getFundingRequests(sDate, eDate);
    }

    const backwardClick = () => {

        var sDate = new Date(startDate);
        sDate.setDate(sDate.getDate() - 1);

        var eDate = new Date(endDate);
        eDate.setDate(eDate.getDate() - 1);

        setDateRange([sDate, eDate])
        localStorage.setItem('selectedFundedRange', JSON.stringify([sDate, eDate]))
        getFundingRequests(sDate, eDate);
    }

    return (
        <>
            {(fundings.loading || actionLoading) && <Loader />}

            <Header>
                <HeaderLeft>
                    <SideMenu history={history} activeTab={1} fundings={fundings} />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main header-main-date">
                        <DateSelect 
                            handleDatePickerClose={() => handleDatePickerClose()}
                            handleDateChange={(date) => {
                                setDateRange(date)                                
                                localStorage.setItem('selectedFundedRange', JSON.stringify(date))
                            }}
                            forwardClick={() => forwardClick()}
                            backwardClick={() => backwardClick()}
                            startDate={startDate}
                            endDate={endDate}
                            disableDays={disableDays}
                        />
                    </div>
                </HeaderCenter>
                <HeaderRight>
                    <CircledImageButton active={isSearchBarVisible}>
                        <img
                            src={IconSearchAdmin}
                            alt=""
                            onClick={handleClickSearch}
                        />
                    </CircledImageButton>
                </HeaderRight>
            </Header>

            {isSearchBarVisible && (
                <div className="search-header">
                    <form action="javascript:void(0)">
                        <Form.Group>
                            <Form.Control
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            )}
            
            <div className="main">
                <div className="list">
                     {filterDealer && renderDealers(filterDealer)}
                </div>
            </div>
        </>
    );
}

PureFunding.propTypes = {
    history: PropTypes.object,
    dealers: PropTypes.object,
    fundings: PropTypes.object,
    setExpandHandler: PropTypes.func,
    getFundingRequests: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    actionLoading: state.admin.actionLoading,
    expandedDealers: state.admin.expandedDealers,
    fundings: state.admin.fundings,
});

const mapDispatchToProps = (dispatch) => ({
    getFundingRequests: (startDate, endDate, action = true) => dispatch(getFundingRequests(startDate, endDate, action)),
    setExpandedDealers: (data) => dispatch(setExpandedDealers(data)),
    setSelectedDealer: (data, history) => dispatch(setSelectedDealer(data, history))
});

export default connect(mapStateToProps, mapDispatchToProps)(PureFunding);

import React, { useEffect, useState } from 'react';
import { pushNotification } from 'utils/notification';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Header from '../../../components/Sales/Header';
import Loader from 'shared/Loader';
import { IconList, IconHome, IconSend, IconArrowLeft, TCPLogo } from '../../../assets/images';

import { updateApplicationFilledStatus, submiCreditApplicationByMail } from '../../../redux/actions/sales';

function HomeScreen(props) {

    const {
        history,
        avatar,
        customer,
        actionLoading,
        updateApplicationFilledStatus,
        submiCreditApplicationByMail
    } = props;

    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        localStorage.removeItem('salesInProcessSavedId')
    },[])


    const handleCustomerSearchScreen = () => {
        history.replace('/applyCustomerSearch');
    }

    const handleHomeClick = () => {
        pushNotification("You are at the home screen", 'success', 'TOP_RIGHT', 3000);
    }

    return (
        <div className="sales">

            {actionLoading && <Loader />}

            <Header isHome={true} history={history} avatar={avatar}>
                {/* <img src={IconHome} alt="home" className="icon-logo" onClick={() => handleHomeClick()} width={22} /> */}
                <img src={TCPLogo} alt="logo" className="main-logo" />
            </Header>

            <Modal show={showWarning} onHide={() => setShowWarning(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body> If you choose to proceed, your customer will receive an email at the email address you have entered prompting them to press a link that will take them to a secure website where they will be asked to provide information required to submit a credit application. They will also be able to add a co-applicant should they choose to do so.</Modal.Body>
                <Modal.Footer>
                    <button class="secondary" onClick={() => {
                        setShowWarning(false);
                        updateApplicationFilledStatus('send_link', null, null);
                        submiCreditApplicationByMail(history, customer);
                    }}>
                        Proceed
                    </button>
                    <button class="secondary" onClick={() => setShowWarning(false)}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            <div className="apply-application">

                <button autoFocus={true} className="btn button" onClick={() => handleCustomerSearchScreen()} style={{ width: "100%" }}>
                    <div className="icon d-flex flex-wrap justify-content-center align-items-center">

                        <i className="fa fa-user-plus icon-new-customer" aria-hidden="true" style={{ transform: 'none', fontSize: '25px' }}></i>
                    </div>
                    <div className="label">Enter Customer Information</div>
                </button>


                <button className="btn button" style={{ width: "100%" }} onClick={() => history && history.push('/salesHistory')}>
                    <div className="icon d-flex flex-wrap justify-content-center align-items-center">
                        <i class="fa fa-history" aria-hidden="true" style={{ transform: 'none', fontSize: '25px' }}></i>
                    </div>
                    <div className="label">History</div>
                </button>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    avatar: state.auth.avatar,
    customer: state.sales.customer,
    actionLoading: state.sales.actionLoading
});

const mapDispatchToProps = dispatch => ({
    updateApplicationFilledStatus: (data, history, path) => dispatch(updateApplicationFilledStatus(data, history, path)),
    submiCreditApplicationByMail: (history, data) => dispatch(submiCreditApplicationByMail(history, data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeScreen);
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getParamsValue } from '../../../../utils/utility';
import { connect, useDispatch } from 'react-redux';
import { setSelectedDealer } from '../../../../redux/actions/admin';
import { DealerTabBar } from './components/DealerTabBar';
import DealerReview from './DealerReview/Review';
import DealerFunding from './DealerFunding/Funding';
import DealerSearch from './DealerSearch/Search';
import { updateSalesDataOfProduct } from 'redux/actions/sales';

export function PureDealerHome(props) {
    const { history, dealer, setSelectedDealer } = props;
    const dispatch = useDispatch()
    console.log('purehome', props);
    const customHistory = useHistory();

    // console.log(de])

    const [activeTab, setActiveTab] = useState(
        getParamsValue('tab') ? Number(getParamsValue('tab')) : 0
    );
    const [expandAllFunding, setExpandingAllFunding] = useState(true);
    const [parentControlReview, setParentControlReview] = useState(null);
    const reviewHandlerRef = useRef(() => {});

    const goToPreviousPath = (value) => {
        history.goBack(value);
    };

    useEffect(() => {
        console.log('historytrack', history);
            dispatch(updateSalesDataOfProduct([]))
        // if (history.location.pathname ==='/admin/dealer') {
        // window.history.pushState(null, null, document.URL);
        window.addEventListener('popstate', function (event) {
            // customHistory.goBack();
            //   history.push('/');
            //   console.log('historyh',history)
            this.sessionStorage.clear();
            // this.sessionStorage.removeItem('impersonatedDealerToken');
            // this.sessionStorage.removeItem('dealer_name');
            // goToPreviousPath(history.length);
        });
        // }

        const intervalId = setInterval(function () {
            if (window.location.pathname === '/admin/dealer') {
                setSelectedDealer(dealer[0].id, null, false);
            }
        }, 15000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    // useEffect(() => {
    //     if(history.action === "POP") {
    //         sessionStorage.removeItem('impersonatedDealerToken');
    //         history.goBack()
    //     }
    // },[history])

    const handleClickTab = (tab) => {
        if (tab === 0 || tab === 1 || (tab === 2 && activeTab === tab)) {
            reviewHandlerRef.current.call();
        }

        localStorage.setItem('mainTab', tab);
        setActiveTab(tab);

        if (tab === 0) {
            if (parentControlReview == undefined) {
                setParentControlReview(false);
            } else {
                setParentControlReview(!parentControlReview);
            }
            history.push({
                pathname: '/admin/dealer',
                search: '?tab=0',
            });
        } else if (tab === 1) {
            setExpandingAllFunding(!expandAllFunding);
            if (expandAllFunding == false) {
                localStorage.setItem('activeCustomer', null);
            }
            setParentControlReview(undefined);
            history.push({
                pathname: '/admin/dealer',
                search: '?tab=1',
            });
        } else if (tab === 2) {
            setParentControlReview(undefined);
            history.push({
                pathname: '/admin/dealer',
                search: '?tab=1',
            });
        }
    };

    const changeExandAllFundinhHandler = () => {
        setExpandingAllFunding(false);
    };

    const changeParentControlReviewHandler = () => {
        setParentControlReview(undefined);
    };

    const handleExpandHandler = (func) => {
        reviewHandlerRef.current = func;
    };

    const getDealerCount = () => {
        return dealer[0] && dealer[0].preapproval_data
            ? dealer[0].preapproval_data.length + dealer[0].sales_data.length
            : 0;
    };

    const getFundingCount = () => {
        return dealer[0] && dealer[0].funding_data
            ? dealer[0].funding_data.length
            : 0;
    };

    return (
        <div className="admin dealer-seperate-list">
            {activeTab === 0 && (
                <DealerReview
                    {...props}
                    setExpandHandler={handleExpandHandler}
                    parentControlReview={parentControlReview}
                    changeParentControlReviewHandler={
                        changeParentControlReviewHandler
                    }
                    changeExandAllFundinhHandler={changeExandAllFundinhHandler}
                />
            )}

            {activeTab === 1 && (
                <DealerFunding
                    {...props}
                    setExpandHandler={handleExpandHandler}
                    expandAllFunding={expandAllFunding}
                    changeExandAllFundinhHandler={changeExandAllFundinhHandler}
                />
            )}

            {activeTab === 2 && (
                <DealerSearch
                    {...props}
                    setExpandHandler={handleExpandHandler}
                />
            )}

            <DealerTabBar
                activeTab={activeTab}
                badges={[
                    dealer ? getDealerCount() : '0',
                    dealer ? getFundingCount() : '0',
                ]}
                onClickTab={handleClickTab}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    actionLoading: state.admin.actionLoading,
    dealer: state.admin.dealer,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDealer: (data, history, action = true) =>
        dispatch(setSelectedDealer(data, history, action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PureDealerHome);

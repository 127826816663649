import React from 'react';

import { Router, Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import history from './history';
import NetworkDetector from './NetworkDetector';

import LoginScreen from './screens/Auth/Login';
import RegisterScreen from './screens/Auth/Register';
import TwoFA from './screens/Auth/TwoFA';
import ForgotPassword from './screens/Auth/ForgotPassword';
import ResetPassword from './screens/Auth/ResetPassword';

import SalesHomeScreen from './screens/Sales/2.0/home';
import SalesCustomerSearchScreen from './screens/Sales/2.0/customerSearch';
import ContactScreen from './screens/Sales/Contact';
import ReorderScreen from './screens/Sales/Reorder';
import ProductScreen from './screens/Sales/Product';
import SummaryScreen from './screens/Sales/Summary';
import PrequalifyScreen from './screens/Sales/Prequalify';

import SalesListScreen from './screens/Dealer/Sales';
import AppDetailsScreen from './screens/Dealer/AppDetails';
import ApplicationDetailsScreen from './screens/Dealer/ApplicationDetails';
import CustomerDetailsScreen from './screens/Dealer/Customer';
import DealerPreApprovals from './screens/Dealer/DealerPreApprovals';
import DealerIncompletes from './screens/Dealer/DealerIncomplete';
import DealerFix from './screens/Dealer/DealerFix';
import PreFilled from './screens/Dealer/PreFilled';
import ApplyPreFilledPage from './screens/Dealer/ApplyPreFilledPage';

import AdminHome from './screens/Admin/2.0/Home/Home';
import AdminPendingApplications from './screens/Admin/AdminPendingApplications';
import AdminPendingApplicationItem from './screens/Admin/AdminPendingApplicationItem';
import AdminPreApprovals from './screens/Admin/AdminPreApprovals';
import AdminPreApprovalItem from './screens/Admin/AdminPreApprovalItem';
import AdminFundingRequests from './screens/Admin/FundingRequests';
import AdminFundingRequestItem from './screens/Admin/FundingRequestItem';

import StaffHomeScreen from './screens/Staff/Home';

// TCP 2.0 screens
import AdminDealerManagement from './screens/Admin/2.0/DealerManagement/DealerManagement';
import AdminHistory from './screens/Admin/2.0/History/History';
import AdminAddDealer from './screens/Admin/2.0/AddDealer';
import AdminDealerSection from './screens/Admin/2.0/Dealer/Dealer';
import AdminComments from './screens/Admin/2.0/Comments/Comments';

import DealerStaffManagement from './screens/Dealer/2.0/StaffManagement';
import DealerAddStaff from './screens/Dealer/2.0/AddStaff';
import DealerHome from './screens/Dealer/2.0/Home';
import PreApproval from 'screens/Dealer/2.0/Sales/PreApproval';
import Scheduled from './screens/Dealer/2.0/Funds/Scheduled';
import Funded from './screens/Dealer/2.0/Funds/Funded';
import SalesSection from './screens/Dealer/2.0/Sales/Sales';
import Profile from './screens/Dealer/2.0/CustomerProfile/Profile';
import PaperWork from './screens/Dealer/2.0/CustomerProfile/PaperWork';
import History from './screens/Dealer/2.0/CustomerProfile/History';
import DealerComments from './screens/Dealer/2.0/Comments/Comments';

import SalesApplyApplicationScreen from './screens/Sales/2.0/applyApplication';
import SalesFillApplicationBasicDetailsScreen from './screens/Sales/2.0/fillApplicationBasicDetails';
import SalesFillApplicationHomeDetailsScreen from './screens/Sales/2.0/fillApplicationHomeDetails';
import SalesFillApplicationEmployementDetailsScreen from './screens/Sales/2.0/fillApplicationEmployementDetails';
import SalesApplicationSummaryScreen from './screens/Sales/2.0/summary';
import SalesSlipScreen from './screens/Sales/2.0/salesSlip';
import PreSummaryScreen from './screens/Sales/2.0/preSummary';
import SalesHistory from './screens/Sales/2.0/history/history';
import SalesArchive from './screens/Sales/2.0/history/archive';

import ProfileScreen from './screens/User/Profile';
import ChangePassword from './screens/User/ChangePassword';

import CustomerApplicationWelcomeScreen from './screens/Customer/welcome';
import CustomerApplicationThankYouScreen from './screens/Customer/thankyou';
import CustomerApplicationBasicDetailsScreen from './screens/Customer/fillApplicationBasicDetails';
import CustomerApplicationHomeDetailscreen from './screens/Customer/fillApplicationHomeDetails';
import CustomerApplicationEmployementsDetailsScreen from './screens/Customer/fillApplicationEmployementDetails';
import CustomerSummaryScreen from './screens/Customer/summary';

import AdminApplicationWelcomeScreen from './screens/Admin/2.0/Home/Home.js';
import AdminCustomerProfile from './screens/Admin/2.0/Customer/Customer.js';
import AdminDealerSettings from './screens/Admin/2.0/DealerSettings/Home';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/css/index.scss';

import { getSearchValue } from 'utils/formUtils';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

interface IRouteProps extends Omit<RouteProps, 'components'> {
    role?: string;
    component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: IRouteProps) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                const token = localStorage.getItem('token');
                const role = localStorage.getItem('role');
                const impersonatedDealerToken = sessionStorage.getItem(
                    'impersonatedDealerToken'
                );

                if (
                    impersonatedDealerToken &&
                    role === 'sales,dealer,admin' &&
                    rest.path === '/'
                ) {
                    return <DealerHome {...props} />;
                }

                const isInRole = (roleName?: string) =>
                    roleName && role && role.indexOf(roleName) !== -1;

                if (token) {
                    const isAdmin = isInRole('admin');
                    const isDealer = isInRole('dealer');
                    const isSales = isInRole('sales');

                    if (isAdmin && rest.path === '/') {
                        return <AdminHome {...props} />;
                    }

                    if (isDealer && rest.path === '/') {
                        return <DealerHome {...props} />;
                    }

                    if ((isSales || isDealer) && rest.path === '/') {
                        return <SalesHomeScreen {...props} />;
                    }

                    if (isInRole(rest.role)) {
                        return <Component {...props} />;
                    }

                    if (isAdmin) {
                        return <AdminHome {...props} />;
                    }

                    if (isDealer) {
                        return <DealerHome {...props} />;
                    }

                    if (isSales || isDealer) {
                        return <SalesHomeScreen {...props} />;
                    }
                }

                return <Redirect to="/login" />;
            }}
        />
    );
};

const AuthRoute = ({ component: Component, ...rest }: IRouteProps) => (
    <Route
        {...rest}
        render={(props) => {
            const token = localStorage.getItem('token');

            if (!token) return <Component {...props} />;

            return <Redirect to="/" />;
        }}
    />
);

const AuthAppRoute = ({ component: Component, ...rest }: IRouteProps) => (
    <Route
        {...rest}
        render={(props) => {
            let customerToken = null;

            if (props.location.pathname === '/welcome') {
                customerToken = getSearchValue('token');
                const customerId = getSearchValue('customerId');
                const salesperson_email = getSearchValue('salesperson_email');
                const dealer_company_name = getSearchValue(
                    'dealer_company_name'
                );
                const dealer_company_number = getSearchValue(
                    'dealer_company_number'
                );

                localStorage.setItem('customerToken', customerToken ?? '');
                localStorage.setItem('customerId', customerId ?? '');
                localStorage.setItem(
                    'salesperson_email',
                    decodeURIComponent(salesperson_email ?? '')
                );
                localStorage.setItem(
                    'dealer_name',
                    decodeURIComponent(dealer_company_name ?? '')
                );
                localStorage.setItem(
                    'dealer_number',
                    decodeURIComponent(dealer_company_number ?? '')
                );
            } else {
                customerToken = localStorage.getItem('customerToken');
            }

            if (customerToken !== null) {
                return <Component {...props} />;
            }

            return <Redirect to="/login" />;
        }}
    />
);

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <Switch>
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/sales-list/:status"
                        component={SalesListScreen}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/preapprovals"
                        component={DealerPreApprovals}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/incomplete-page"
                        component={DealerIncompletes}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer-fix/:appId"
                        component={DealerFix}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/app-details/:appId"
                        component={AppDetailsScreen}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/application-details/:appId"
                        component={ApplicationDetailsScreen}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/customer/:id/:type"
                        component={CustomerDetailsScreen}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/prefilled/:id"
                        component={PreFilled}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/prefilled-view/:id"
                        component={ApplyPreFilledPage}
                    />

                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/staff"
                        component={StaffHomeScreen}
                    />

                    <PrivateRoute
                        exact
                        role="sales"
                        path="/"
                        component={SalesHomeScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/sales"
                        component={SalesHomeScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/contact"
                        component={ContactScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/reorder"
                        component={ReorderScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/product/:id"
                        component={ProductScreen}
                        key=":id"
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/summary"
                        component={SummaryScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/prequalify"
                        component={PrequalifyScreen}
                    />

                    <PrivateRoute
                        exact
                        role="admin"
                        path="/"
                        component={AdminHome}
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/pendingApplications"
                        component={AdminPendingApplications}
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/pendingApplication/:id"
                        component={AdminPendingApplicationItem}
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/preapprovals"
                        component={AdminPreApprovals}
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/preapproval/:id"
                        component={AdminPreApprovalItem}
                        key=":id"
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/fundings"
                        component={AdminFundingRequests}
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/funding/:id"
                        component={AdminFundingRequestItem}
                        key=":id"
                    />

                    {/* TCP 2.0 screens */}
                    <AuthRoute exact path="/login" component={LoginScreen} />
                    <AuthRoute
                        exact
                        path="/register"
                        component={RegisterScreen}
                    />
                    <AuthRoute path="/2fa" component={TwoFA} />
                    <AuthRoute
                        exact
                        path="/forgot"
                        component={ForgotPassword}
                    />
                    <AuthRoute
                        exact
                        path="/reset-password"
                        component={ResetPassword}
                    />

                    <PrivateRoute
                        exact
                        role="sales"
                        path="/profile"
                        component={ProfileScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/change-password"
                        component={ChangePassword}
                    />

                    <AuthAppRoute
                        exact
                        path="/welcome"
                        component={CustomerApplicationWelcomeScreen}
                    />
                    <AuthAppRoute
                        exact
                        path="/basic"
                        component={CustomerApplicationBasicDetailsScreen}
                    />
                    <AuthAppRoute
                        exact
                        path="/home"
                        component={CustomerApplicationHomeDetailscreen}
                    />
                    <AuthAppRoute
                        exact
                        path="/employement"
                        component={CustomerApplicationEmployementsDetailsScreen}
                    />
                    <AuthAppRoute
                        exact
                        path="/customerSummary"
                        component={CustomerSummaryScreen}
                    />
                    <AuthAppRoute
                        exact
                        path="/thankyou"
                        component={CustomerApplicationThankYouScreen}
                    />

                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/dealers"
                        component={AdminDealerManagement}
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/history"
                        component={AdminHistory}
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/addDealer"
                        component={AdminAddDealer}
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/dealer"
                        component={AdminDealerSection}
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/comments"
                        component={AdminComments}
                    />

                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/dealer/settings"
                        component={AdminDealerSettings}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/admin/applyApplicationSummary"
                        component={SalesApplicationSummaryScreen}
                    />

                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/"
                        component={DealerHome}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/pre-approval"
                        component={PreApproval}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/scheduled"
                        component={Scheduled}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/funded"
                        component={Funded}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/sales"
                        component={SalesSection}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/profile"
                        component={Profile}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/paperwork"
                        component={PaperWork}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/history"
                        component={History}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/staff"
                        component={DealerStaffManagement}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/addStaff"
                        component={DealerAddStaff}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/createSalesSlip"
                        component={SalesSlipScreen}
                    />
                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/applyApplicationSummary"
                        component={SalesApplicationSummaryScreen}
                    />

                    <PrivateRoute
                        exact
                        role="dealer"
                        path="/dealer/comments"
                        component={DealerComments}
                    />

                    <PrivateRoute
                        exact
                        role="sales"
                        path="/"
                        component={SalesHomeScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/applyHome"
                        component={SalesHomeScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/applyCustomerSearch"
                        component={SalesCustomerSearchScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/applyApplication"
                        component={SalesApplyApplicationScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/applyApplicationBasicDetails"
                        component={SalesFillApplicationBasicDetailsScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/applyApplicationHomeDetails"
                        component={SalesFillApplicationHomeDetailsScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/applyApplicationEmployement"
                        component={SalesFillApplicationEmployementDetailsScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/applyApplicationSummary"
                        component={SalesApplicationSummaryScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/createSalesSlip"
                        component={SalesSlipScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/preSummary"
                        component={PreSummaryScreen}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/salesHistory"
                        component={SalesHistory}
                    />
                    <PrivateRoute
                        exact
                        role="sales"
                        path="/archive"
                        component={SalesArchive}
                    />

                    <PrivateRoute
                        exact
                        role="admin"
                        path="/adminn"
                        component={AdminApplicationWelcomeScreen}
                    />
                    <PrivateRoute
                        exact
                        role="admin"
                        path="/admin/profile"
                        component={AdminCustomerProfile}
                    />
                </Switch>
            </Router>
        </ThemeProvider>
    );
};

export default NetworkDetector(App);

import React, { useState } from 'react';
import moment from 'moment';
import { IconCalenderHeader } from '../../assets/images';
import { IconLeft, IconCalenderRight } from 'assets/images';
import DatePicker from "react-datepicker";
import '../../../src/assets/css/dealer/schedule.scss';
import "react-datepicker/dist/react-datepicker.css";
import IconFeatherCalendar from '../../assets/images/IconFeatherCalendar.png';
import calendarSvgIcon from '../../assets/images/calendarSvgIcon.svg'


const DateSelect = (props) => {

    const {
        handleDateChange,
        handleDatePickerClose,
        forwardClick,
        backwardClick,
        startDate,
        endDate,
        disableDays
    } = props;

    // const notAvailableDays = [1,2,6];
    const isDisabled = (date) => {
        const day = date.getDay(date);
        return disableDays.includes(day);
     }

    const CustomInput = React.forwardRef((props, ref) => {

        let sd = startDate ? moment(new Date(startDate)).format("MMM DD YYYY") : ""
        let ed = endDate ? moment(new Date(endDate)).format("MMM DD YYYY") : ""
    
        return  <span onClick={props.onClick} style={{ display: "flex", alignItems: "center" }}>
                <input value={`${sd} - ${ed}`} style={{ textAlign: "center", color: '#aed8f7', fontSize: 14 }} readOnly/>
                <img src={calendarSvgIcon} alt="reload" width="15" />
                </span>
    })

    return (
        <div className="header-datepicker d-flex">
            <div className="leftArrow">
                <img src={IconLeft} alt="left" onClick={() => backwardClick()}/>
            </div>
            <DatePicker
                selectsRange={true}
                shouldCloseOnSelect={!(!startDate || Boolean(startDate && endDate))}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy/MM/dd"
                onChange={(update) => {
                    console.log(update)
                    handleDateChange(update);
                }}
                onCalendarClose={() => handleDatePickerClose()}
                withPortal
                customInput={<CustomInput />}
                filterDate={disableDays && disableDays.length > 0 ? isDisabled : null}
                // excludeDates={[new Date(), subDays(new Date(), 1)]}
            />
            <div className="rightArrow">
                <img src={IconCalenderRight} alt="right" onClick={() => forwardClick()}/>
            </div>
        </div>

    )
}


export default DateSelect;


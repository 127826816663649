import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CircularInactive, IconFixIssueAwesomeSendRed } from '../../../../../../assets/images';
import { StatusChoice, StatusText, TierSelect } from '../../../../style';
import Dropdown from '../../../../../../components/commons/dropdown';
import { getCustomerApiInitiateForGenerateOrder } from '../../../../../../redux/actions/sales';
import {
    commentReset,
    getDealerAdditionalInfo,
    getProductSignatureDetailDirectForAdminReview,
} from '../../../../../../redux/actions/admin';
import {
    CircleStatusIcon,
    HexagonStatusIcon,
    TriangleStatusIcon,
} from './StatusIcons';
import PropTypes from 'prop-types';
import {
    statusApproval,
    statusInProcess,
    statusRejection,
} from '../../../../constant';
import {
    updateProductTier,
    updatePreApprovalStatus,
    getCustomerProfile,
} from '../../../../../../redux/actions/admin';
import Loader from 'shared/Loader';

function OrderRequestCard(props) {
    const {
        history,
        customer,
        updateProductTier,
        actionLoading,
        dealer_id,
        helloSig1nData,
        getCustomerProfile,
        customer: {
            app_id,
            customer_id,
            co_customer_id,
            items,
            name,
            price,
            food_tier: foodTier,
            other_tier: otherTier,
            status,
            reviewMode = 'auto',
            onChangeStatus,
            onChangeFoodTier,
            onChangeOtherTier,
            products = [],
        },
        getCustomerApiInitiateForGenerateOrder,
        getProductSignatureDetailDirectForAdminReview,
        updatePreApprovalStatus,
        getDealerAdditionalInfo,
        isDealerScreen = 0,
        type,
    } = props;

    console.log(props);
    console.log(customer);
    const data = useSelector((state) => console.log(state));
    const dispatch = useDispatch()

    const tierOptions = [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },
    ];

    const IconAwesomePaperPlane = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.982"
            height="18.273"
            viewBox="0 0 17.982 18.273"
        >
            <path
                d="M8.956 6.775v2.43c.931.211 1.759.557 2.626.8V7.567c-.928-.207-1.763-.553-2.626-.792zm7.969-4.389a10.42 10.42 0 0 1-4.175 1.138c-1.909 0-3.49-1.242-5.9-1.242a6.938 6.938 0 0 0-2.427.428 2 2 0 1 0-3.008.924v13.779a.854.854 0 0 0 .856.856h.571a.854.854 0 0 0 .856-.856v-3.369a9.95 9.95 0 0 1 4.082-.789c1.913 0 3.49 1.242 5.9 1.242a7.457 7.457 0 0 0 4.371-1.46 1.138 1.138 0 0 0 .492-.942V3.421a1.14 1.14 0 0 0-1.619-1.035zm-10.6 9.228a11.242 11.242 0 0 0-2.626.592V9.691a10.209 10.209 0 0 1 2.63-.621zm10.506-4.8a11.383 11.383 0 0 1-2.626.853V10.2a6.633 6.633 0 0 0 2.626-.928v2.516a5.766 5.766 0 0 1-2.626.967V10.2a6.036 6.036 0 0 1-2.626-.2v2.41a20.821 20.821 0 0 0-2.626-.76V9.205a7.932 7.932 0 0 0-2.624-.135v-2.5a12.593 12.593 0 0 0-2.629.748V4.8a10.219 10.219 0 0 1 2.626-.785v2.557a6.07 6.07 0 0 1 2.626.2V4.37a20.315 20.315 0 0 0 2.626.76v2.441a6.8 6.8 0 0 0 2.626.1V5.1a12.626 12.626 0 0 0 2.626-.8z"
                transform="translate(-.563 .003)"
            />
        </svg>
    );

    const IconEnvelopeOpen = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.266"
            height="17.266"
            viewBox="0 0 17.266 17.266"
        >
            <path d="M8.633 0L0 4.316v12.95h17.266V4.316zm0 2.439l6.475 3.237v4.058l-6.475 3.237-6.475-3.237V5.676zM4.316 6.5v2.155l4.316 2.158 4.316-2.158V6.5z" />
        </svg>
    );

    const IconAwesomePenFancy = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.266"
            height="17.266"
            viewBox="0 0 17.266 17.266"
        >
            <g>
                <path d="M2.67 9.541a1.079 1.079 0 0 0-.683.683L0 16.187l.158.158 3.132-3.132a1.038 1.038 0 0 1-.053-.263 1.079 1.079 0 1 1 1.079 1.079 1.038 1.038 0 0 1-.263-.053L.921 17.108l.158.158 5.963-1.988a1.079 1.079 0 0 0 .683-.683l1.115-2.834-3.335-3.335zM12.452.955L6.277 7.682l3.3 3.3L16.3 4.807c2.868-2.531-1.336-6.693-3.848-3.852z" />
            </g>
        </svg>
    );

    const IconSend = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.494"
            height="18.499"
            viewBox="0 0 18.494 18.499"
        >
            <path
                d="M17.2.115L.452 9.774a.868.868 0 0 0 .079 1.561l3.84 1.611L14.75 3.8a.217.217 0 0 1 .311.3l-8.7 10.6v2.908a.867.867 0 0 0 1.535.571l2.294-2.792 4.5 1.886a.869.869 0 0 0 1.192-.657l2.6-15.606A.867.867 0 0 0 17.2.115z"
                transform="translate(-.001 .002)"
            />
        </svg>
    );

    const IconEnvelopeClosed = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.331"
            height="12.998"
            viewBox="0 0 17.331 12.998"
        >
            <path d="M0 0v2.166L8.665 6.5l8.665-4.333V0zm0 4.333V13h17.331V4.333L8.665 8.665z" />
        </svg>
    );

    const IconAwesomePenFancyRight = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.875"
            height="19.445"
            viewBox="0 0 17.875 19.445"
        >
            <g>
                <path
                    d="M2.527 9.029a1.021 1.021 0 0 0-.646.646L0 15.318l.15.15L3.114 12.5a.982.982 0 0 1-.05-.249 1.021 1.021 0 1 1 1.021 1.021.982.982 0 0 1-.249-.05L.872 16.19l.149.149 5.643-1.881a1.021 1.021 0 0 0 .646-.646l1.055-2.682L5.21 7.974zM11.784.9L5.94 7.27l3.123 3.123 6.366-5.844c2.71-2.395-1.268-6.334-3.645-3.649z"
                    transform="translate(1.5) rotate(-90 8.188 8.151)"
                />
                <path
                    stroke="#c8e0ed"
                    stroke-linecap="round"
                    stroke-width="3px"
                    d="M0 0h14.769"
                    transform="translate(1.5) translate(0 17.945)"
                />
            </g>
        </svg>
    );

    useEffect(() => {
        if (customer && products && products.length > 0) {
            let signature_product = products.filter((p) => p.signature_status);
            signature_product.map((p_info) => {
                let temp = {
                    dealer_id: dealer_id,
                    app_id: app_id,
                    product_id: p_info.id,
                };
                getProductSignatureDetailDirectForAdminReview(temp);
            });
        }
    }, []);

    const generateOrder = () => {
        let data = {
            customer_id: customer_id,
            co_customer_id: co_customer_id,
            include_products: true,
        };
        localStorage.setItem(
            'redirectionPath',
            window.location.pathname + window.location.search
        );
        getDealerAdditionalInfo(dealer_id);
        getCustomerApiInitiateForGenerateOrder(
            data,
            history,
            '/admin/applyApplicationSummary'
        );
    };

    const renderSaleItems = (items) => {
        console.log('items', items);
        return items.map((item) => {
            let hello_sign = helloSig1nData.filter(
                (h) =>
                    h.dealer_id == dealer_id &&
                    h.app_id == app_id &&
                    h.product_id == item.id
            );
            console.log('hello_sign', hello_sign);

            return (
                <li key={item.id}>
                    <Row>
                        <Col>
                            {item.product} - ${item.balance_amount}
                        </Col>
                        <Col className="text-right document-status">
                            {hello_sign && hello_sign.length > 0 && (
                                <div
                                    className="d-flex hello-sign-status"
                                    style={{
                                        height: 25,
                                        justifyContent: 'end',
                                        alignItems: 'center',
                                    }}
                                >
                                    {hello_sign &&
                                        hello_sign[0] &&
                                        hello_sign[0].last_viewed_at == null &&
                                        hello_sign[0].status_code ==
                                            'error_invalid_email' && (
                                            <div style={{ display: 'flex' }}>
                                                <button>
                                                    <span className='hellosigniconbprderright'>
                                                    {IconEnvelopeClosed}
                                                    </span>
                                                </button>
                                                <button>
                                                    {IconAwesomePenFancy}
                                                </button>
                                            </div>
                                        )}

                                    {hello_sign &&
                                        hello_sign[0] &&
                                        hello_sign[0].last_viewed_at == null &&
                                        hello_sign[0].status_code ==
                                            'awaiting_signature' && (
                                            <div style={{ display: 'flex' }}>
                                                <button>
                                                    <span className='hellosigniconbprderright'>
                                                    {IconEnvelopeClosed}
                                                    </span>
                                                </button>
                                                <button>
                                                    {IconAwesomePenFancy}
                                                </button>
                                            </div>
                                        )}

                                    {hello_sign &&
                                        hello_sign[0] &&
                                        hello_sign[0].last_viewed_at != null &&
                                        hello_sign[0].status_code ==
                                            'signed' && (
                                            <div style={{ display: 'flex' }}>
                                                <button className="active">
                                                    <span className='hellosigniconbprderright'>
                                                    {IconEnvelopeOpen}
                                                    </span>
                                                </button>
                                                <button className="active">
                                                    {IconAwesomePenFancyRight}
                                                </button>
                                            </div>
                                        )}

                                    {hello_sign &&
                                        hello_sign[0] &&
                                        hello_sign[0].last_viewed_at != null &&
                                        hello_sign[0].status_code ==
                                            'awaiting_signature' && (
                                            <div style={{ display: 'flex' }}>
                                                <button className="active">
                                                    <span className='hellosigniconbprderright'>
                                                    {IconEnvelopeOpen}
                                                    </span>
                                                </button>
                                                <button>
                                                    {IconAwesomePenFancy}
                                                </button>
                                            </div>
                                        )}
                                </div>
                            )}
                        </Col>
                    </Row>
                </li>
            );
        });
    };

    const getFoodTierDropDown = () => {
        let foodTier =
            products &&
            products.filter(
                (p) => p.product == 'Food/Mac' || p.product == 'Food'
                // || 'Food' || 'FOOD' || 'FOOD/MAC'
            );

        if (foodTier && foodTier.length > 0) {
            return (
                <TierSelect className="tier-input" inline={true}>
                    <label>Food Tier:</label>
                    <Dropdown
                        value={foodTier[0].tier}
                        defaultValue={foodTier[0].tier}
                        defaultText="Tiers"
                        label="Tiers"
                        options={tierOptions}
                        handleChange={(e) =>
                            updateProductTier(
                                {
                                    product_id: foodTier[0].id,
                                    product_tier: e,
                                    pre_app_product: false,
                                },
                                isDealerScreen
                            )
                        }
                    />
                   
                </TierSelect>
            );
        } else {
            return '';
        }
    };

    const otherTierArr =
        customer && products
            ? products.filter(
                  (p) => p.product !== 'Food/Mac' && p.product !== 'Food'
              )
            : [];
    const goToComments = (app_id) => {
        dispatch(commentReset())
        let data = {
            customer_id: props.customer.customer_id,
            co_customer_id: props.customer.co_customer_id,
            include_products: true,
            app_id: app_id,
        };
        // getCustomerProfile(app_id, null, 'admin', false).then(() => {
        //     history && history.push('/admin/comments');
        // })
        // getCustomerProfile(app_id, data, history, '/admin/comments');
        getCustomerProfile(
            app_id,
            null,
            history,
            'admin',
            false,
            '/admin/comments'
        );
        // getCustomerApiInitiateForGenerateOrder(data, history, null).then(() => {
        // getCustomerProfile(app_id, null, 'admin', false).then(() => {
        // history && history.push('/admin/comments');
        // getCustomerApiInitiateForGenerateOrder(data, history, '/admin/comments');
        // })
        // })
    };

    return (
        <>
            {actionLoading && <Loader />}

            <Row key={app_id} className="order-summary-row" style={{background : '#2A4051'}}>
                <Col className="order-summary">
                    <ul
                        className="order-documents"
                        onClick={() => generateOrder()}
                    >
                        {renderSaleItems(customer.products)}
                    </ul>
                    <div className="order-tiers">
                        {getFoodTierDropDown()}

                        {otherTierArr &&
                            otherTierArr.length > 0 &&
                            otherTierArr.map((o) => {
                                return (
                                    <TierSelect
                                        className="tier-input"
                                        inline={true}
                                    >
                                        <label>Other Tier:</label>
                                        <Dropdown
                                            value={o.tier}
                                            defaultValue={o.tier}
                                            defaultText="Tiers"
                                            label="Tiers"
                                            options={tierOptions}
                                            handleChange={(e) =>
                                                updateProductTier(
                                                    {
                                                        product_id: o.id,
                                                        product_tier: e,
                                                        pre_app_product: false,
                                                    },
                                                    isDealerScreen
                                                )
                                            }
                                        />
                                    </TierSelect>
                                );
                            })}
                    </div>
                    <div className="order-status">
                        <StatusChoice
                            onClick={() =>
                                updatePreApprovalStatus(
                                    {
                                        action: 'approve',
                                        app_id: customer.app_id,
                                    },
                                    isDealerScreen
                                )
                            }
                        >
                            <CircleStatusIcon
                                symbol="S"
                                fill={reviewMode === 'auto'}
                                disabled={
                                    customer.app_status === 'approval'
                                        ? false
                                        : true
                                }
                            />
                            <StatusText>Approve</StatusText>
                        </StatusChoice>
                        <StatusChoice
                            className="ml-4 ml-sm-5"
                            // onClick={() => updatePreApprovalStatus({ action: 'request_preapproval', app_id: customer.app_id })}
                        >
                            <TriangleStatusIcon
                                symbol="S"
                                fill={reviewMode === 'auto'}
                                disabled={
                                    customer.app_status === 'in_process'
                                        ? false
                                        : true
                                }
                            />
                            <StatusText>Req.Review</StatusText>
                        </StatusChoice>
                        <StatusChoice
                            className="ml-4 ml-sm-5"
                            onClick={() =>
                                updatePreApprovalStatus(
                                    {
                                        action: 'declined',
                                        app_id: customer.app_id,
                                    },
                                    isDealerScreen
                                )
                            }
                        >
                            <HexagonStatusIcon
                                symbol="S"
                                fill={reviewMode === 'auto'}
                                disabled={
                                    customer.app_status === 'rejection'
                                        ? false
                                        : true
                                }
                            />
                            <StatusText>Reject</StatusText>
                        </StatusChoice>
                    </div>
                    <button
                        className="btn btn-comments"
                        style={{
                            position : 'absolute',
                            left : '50%',
                            transform : 'translate(-50%,0)'
                        }}
                        onClick={() => goToComments(customer.app_id)}
                    >
                        <img
                            src={require('../../../../../../assets/images/IconAwesomeComments.svg')}
                        />{' '}
                        <span style={{ marginLeft: 10 }}>
                            Comments{' '}
                            <span>
                                {' '}
                                {customer.comments_count == 0
                                    ? '(0)'
                                    : `(${customer.comments_count})`}
                            </span>
                        </span>
                    </button>
                </Col>
            </Row>
        </>
    );
}

OrderRequestCard.propTypes = {
    customer: PropTypes.object.isRequired,
    updateProductTier: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    updatePreApprovalStatus: PropTypes.func.isRequired,
};

OrderRequestCard.defaultProps = {
    onChangeStatus: () => {},
    onChangeFoodTier: () => {},
    onChangeOtherTier: () => {},
};

const mapStateToProps = (state) => ({
    actionLoading: state.admin.actionLoading,
    helloSig1nData: state.admin.helloSig1nData,
});

const mapDispatchToProps = (dispatch) => ({
    getCustomerApiInitiateForGenerateOrder: (data, history, path) =>
        dispatch(getCustomerApiInitiateForGenerateOrder(data, history, path)),
    getProductSignatureDetailDirectForAdminReview: (data) =>
        dispatch(getProductSignatureDetailDirectForAdminReview(data)),
    updateProductTier: (data, page) => dispatch(updateProductTier(data, page)),
    updatePreApprovalStatus: (data, page) =>
        dispatch(updatePreApprovalStatus(data, page)),
    getDealerAdditionalInfo: (id) => dispatch(getDealerAdditionalInfo(id)),
    // getCustomerProfile: (id, history, profileType, action = true) => dispatch(getCustomerProfile(id, history, profileType, action))
    getCustomerProfile: (id, cif, history, profileType, makeEmpty, path) =>
        dispatch(
            getCustomerProfile(id, cif, history, profileType, makeEmpty, path)
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderRequestCard);

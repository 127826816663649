import actions from 'redux-form/lib/actions';
import { UPDATE_SALESDATA_OF_PRODUCT } from 'redux/actions/actionTypes';
import {
    SALES_LIST,
    GET_APP_DETAIL,
    GET_APP_DETAIL_SUCCESS,
    GET_APP_DETAIL_ERROR,
    SALES_LIST_SUCCESS,
    SALES_LIST_ERROR,
    CANCEL_TYPE_COUNT_REQUEST,
    CANCEL_TYPE_COUNT_SUCCESS,
    CANCEL_TYPE_COUNT_ERROR,
    PENDING_TYPE_COUNT_REQUEST,
    PENDING_TYPE_COUNT_SUCCESS,
    PENDING_TYPE_COUNT_ERROR,
    SET_INCOMPLETE_REMINDER_REQUEST,
    SET_INCOMPLETE_REMINDER_SUCCESS,
    SET_INCOMPLETE_REMINDER_ERROR,



    SET_SEARCH_CUSTOMER_SEARCH_REQUEST,
    RESET_SEARCH_CUSTOMER_FORM_REQUEST,
    REFRESH_CHECK_PAGE_LOAD,

    SET_CUSTOMER_SEARCH_REQUEST,

    GET_CUSTOMER_REQUEST,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_FAILED,

    GET_SEARCH_CUSTOMER_REQUEST,
    GET_SEARCH_CUSTOMER_SUCCESS,
    GET_SEARCH_CUSTOMER_FAILED,

    GET_GENERATE_ORDER_CUSTOMER_REQUEST,
    GET_GENERATE_ORDER_CUSTOMER_SUCCESS,
    GET_GENERATE_ORDER_CUSTOMER_FAILED,

    UPDATE_CUSTOMER_SEARCH_REQUEST,

    UPDATE_SALES_SILPS_REQUEST,

    SUBMIT_CREDIT_APP_REQUEST,
    SUBMIT_CREDIT_APP_SUCCESS,
    SUBMIT_CREDIT_APP_FAILED,

    SUBMIT_SALES_APP_REQUEST,
    SUBMIT_SALES_APP_SUCCESS,
    SUBMIT_SALES_APP_FAILED,

    SET_APP_FILLED_STATUS,

    SUBMIT_CUSTOMER_REPONSE_REQUEST,
    SUBMIT_CUSTOMER_REPONSE_SUCCESS,
    SUBMIT_CUSTOMER_REPONSE_FAILED,

    SEND_APP_LINK_REQUEST,
    SEND_APP_LINK_SUCCESS,
    SEND_APP_LINK_FAILED,

    VALIDATE_EMAIL_REQUEST,
    VALIDATE_EMAIL_SUCCESS,
    VALIDATE_EMAIL_FAILED,

    GET_DEALER_TEMPLATE_REQUEST,
    GET_DEALER_TEMPLATE_SUCCESS,
    GET_DEALER_TEMPLATE_FAILED,

    SUBMIT_CUSTOMER_REPONSE_TO_THANKYOU,

    SUBMIT_SALES_DOC_REQUEST,
    SUBMIT_SALES_DOC_SUCCESS,
    SUBMIT_SALES_DOC_FAILED,

    SUBMIT_CA_DOC_REQUEST,
    SUBMIT_CA_DOC_SUCCESS,
    SUBMIT_CA_DOC_FAILED,

    SUBMIT_APPLICATION_BASIC_DETAILS_REQUEST,
    SUBMIT_APPLICATION_BASIC_DETAILS_SUCCESS,
    SUBMIT_APPLICATION_BASIC_DETAILS_FAILED,

    GET_SALES_HISTORY_REQUEST,
    GET_SALES_HISTORY_SUCCESS,
    GET_SALES_HISTORY_FAILED,
    GET_SALES_HISTORY_REQUEST_WITH_LOADER,

    GET_SALES_ARCHIVE_REQUEST,
    GET_SALES_ARCHIVE_SUCCESS,
    GET_SALES_ARCHIVE_FAILED,

    TAX_REQUEST,
    TAX_SUCCESS,
    TAX_FAILED,
    searchCustomer,

    GET_FIELD_DATA_REQUEST,
    GET_FIELD_DATA_SUCCESS,
    GET_FIELD_DATA_FAILED,
    SAVEINPROGRESS_SALES_APP,
    SAVEINPROGRESS_SALES_APP_SUCCESS,
    SAVEINPROGRESS_SALES_APP_FAILED,
    GET_SALES_HISTORY_SAVE_PROGRESS_REQUEST,
    GET_SALES_HISTORY_SAVE_PROGRESS_SUCCESS,
    GET_SALES_HISTORY_SAVE_PROGRESS_FAILED

} from '../actions/sales';

const INIT_STATE = {
    apps: [],
    getListLoading: false,
    selectedApp: {
        loading: false,
        data: {}
    },
    cancelTypeCount: {
        declineCount: 0,
        approvedCount: 0,
        fundedCount: 0,
        cancelCount: 0,
    },
    cancelTypeLoading: false,
    pendingTypeCont: {
        pendingCount: 0,
        incompleteCount: 0,
    },
    pendingTypeLoading: false,
    isReminderLoading: false,
    salesHistory: {
        loading: false,
        data: []
    },
    salesSaveProgressHistory: {
        loading: false,
        data: []
    },
    salesArchive: {
        loading: false,
        data: []
    },
    saleData: {
        loading: false,
        data: []
    },








    refreshCheck: false,
    customer_search_result: [],
    customer: {main_app: {}, co_app: {}, invite_status: null, ssn: null, co_ssn: null, credit_agrment: [], credit_agreement_status: false, credit_agreement_saved: false },
    salesDataOfProduct : [],
    isCustomerFound: false,
    isCustomerSubmitted: false,
    searchCustomerApiInitiate: false,
    actionLoading: false,
    appFillStatus: 'in_app',
    emailValidate: true,
    tax: 0,

    templates: null,
    
    initialProduct: {
        product_type: null,
        makemodel: null,
        price: 0,
        add_discount: 0,
        coupon: 0,
        tax : 0,
        cash_credit: 0,
        check: 0,
        saved: false,
        down_payment: 0,
        finance_period: 0,
        product_doc_id: [],
        product_doc_url: [],
        product_doc_name: [],
        template_id: 0,
        template_name: '',
        signature_request: false,
    }


};

export default function(state = INIT_STATE, action){

    let temp_customer = {main_app: {}, co_app: {}}

    switch (action.type) {
        case SALES_LIST:
            return {
                ...state,
                apps: [],
                getListLoading: true
            }
        case SALES_LIST_SUCCESS:
            return {
                ...state,
                apps: action.payload,
                getListLoading: false

            }
        case SALES_LIST_ERROR:
            return {
                ...state,
                getListLoading: false
            }
        case GET_APP_DETAIL:
            return {
                ...state,
                selectedApp: {                    
                    loading: true,
                    data: {}
                }
            }
        case GET_APP_DETAIL_SUCCESS:
            return {
                ...state,
                selectedApp: {
                    loading: false,
                    data: action.payload
                }
            }
        case GET_APP_DETAIL_ERROR:
            return {
                ...state,
                selectedApp: {
                    loading: false,
                    data: {}
                }
            }
        case CANCEL_TYPE_COUNT_REQUEST:
            return {
                ...state,
                cancelTypeLoading: true,
            }
        case CANCEL_TYPE_COUNT_SUCCESS:
            return {
                ...state,
                cancelTypeCount: {
                    declineCount: action.payload.declineCount,
                    approvedCount: action.payload.approvedCount,
                    fundedCount: action.payload.fundedCount,
                    cancelCount: action.payload.cancelCount,
                },
                cancelTypeLoading: false,
            }
        case CANCEL_TYPE_COUNT_ERROR:
            return {
                ...state,
                cancelTypeLoading: false,
            }
        case PENDING_TYPE_COUNT_REQUEST:
            return {
                ...state,
                pendingTypeLoading: true,
            }
        case PENDING_TYPE_COUNT_SUCCESS:
            return {
                ...state,
                pendingTypeCont: {
                    pendingCount: action.payload.pendingCount,
                    incompleteCount: action.payload.incompleteCount,
                },
                pendingTypeLoading: false,
            }
        case PENDING_TYPE_COUNT_ERROR:
            return {
                ...state,
                pendingTypeLoading: false,
            }
        case SET_INCOMPLETE_REMINDER_REQUEST:
            return {
                ...state,
                isReminderLoading: true,
            }
        case SET_INCOMPLETE_REMINDER_SUCCESS:
            return {
                ...state,
                isReminderLoading: false,
            }
        case SET_INCOMPLETE_REMINDER_ERROR:
            return {
                ...state,
                isReminderLoading: false,
            }














        case SET_CUSTOMER_SEARCH_REQUEST:
            return {
                ...state,
                customer: temp_customer,
                isCustomerFound: false,
                searchCustomerApiInitiate: false,
                customer_search_result: [],
                emailValidate: true
            }
        case SET_APP_FILLED_STATUS:
            return {
                ...state,
                appFillStatus: action.payload,
            }


        

        case SET_SEARCH_CUSTOMER_SEARCH_REQUEST:
            return {
                ...state,
                customer_search_result: [],
                customer: temp_customer,
                isCustomerFound: false,
                searchCustomerApiInitiate: false,
                emailValidate: true
            }
        case RESET_SEARCH_CUSTOMER_FORM_REQUEST:
            return {
                ...state,
                customer_search_result: [],
                customer: temp_customer,
                isCustomerFound: false,
            }
        case REFRESH_CHECK_PAGE_LOAD:
            return {
                ...state,
                refreshCheck: true
            }


        
        case GET_SEARCH_CUSTOMER_REQUEST:
            
            return {
                ...state,
                customer_search_result: [],
                actionLoading: true,
                searchCustomerApiInitiate: true,
            }
        case GET_SEARCH_CUSTOMER_SUCCESS:

            let customer = [];
            
            if(action.payload.data && action.payload.data.length > 0) {

                action.payload.data.forEach(searchCustomer => {
                    customer.push({
                        ...searchCustomer,
                        main_app: {
                            ...searchCustomer.main_app,
                            ...(searchCustomer.main_app.dobM && searchCustomer.main_app.dobM !== "" && {
                                dobM: searchCustomer.main_app.dobM > 9 ? searchCustomer.main_app.dobM : "0"+searchCustomer.main_app.dobM,
                            }),
                            ...(searchCustomer.main_app.dobD && searchCustomer.main_app.dobD !== "" && {
                                dobD: searchCustomer.main_app.dobD > 9 ? searchCustomer.main_app.dobD : "0"+searchCustomer.main_app.dobD,
                            }),
                            ...(searchCustomer.main_app.state && searchCustomer.main_app.state !== "" && {
                                state: searchCustomer.main_app.state.toUpperCase()
                            }),
                            ssn: null
                        },
                        co_app: {
                            ...searchCustomer.co_app,
                            ...(searchCustomer.co_app.dobM && searchCustomer.co_app.dobM !== "" && {
                                dobM: searchCustomer.co_app.dobM > 9 ? searchCustomer.co_app.dobM : "0"+searchCustomer.co_app.dobM,
                            }),
                            ...(searchCustomer.co_app.dobD && searchCustomer.co_app.dobD !== "" && {
                                dobD: searchCustomer.co_app.dobD > 9 ? searchCustomer.co_app.dobD : "0"+searchCustomer.co_app.dobD,
                            }),
                            ...(searchCustomer.co_app.state && searchCustomer.co_app.state !== "" && {
                                state: searchCustomer.co_app.state.toUpperCase()
                            }),
                            ssn: null
                        },
                        ssn: searchCustomer.main_app.ssn, 
                        co_ssn: searchCustomer.co_app ? searchCustomer.co_app.ssn : null,
                        credit_agrment: [], 
                        credit_agrment_url: [],
                        credit_agrment_name: [],
                        credit_agreement_status: false,
                        credit_agreement_saved: false
                    })
                });

            }

            return {
                ...state,
                customer_search_result: customer,
                actionLoading: false,
                searchCustomerApiInitiate: true,
            }
        case GET_SEARCH_CUSTOMER_FAILED:
            return {
                ...state,
                customer_search_result: [],
                actionLoading: false,
                searchCustomerApiInitiate: true,
            }



       
        case GET_GENERATE_ORDER_CUSTOMER_REQUEST:
        
            return {
                ...state,
                appFillStatus: '',
                customer: temp_customer,
                actionLoading: true,
            }
        case GET_GENERATE_ORDER_CUSTOMER_SUCCESS:

            let tt_customer = temp_customer;
            
            if(action.payload) {
                console.log(action.payload)

                let searchCustomer = action.payload;
                let fileName = action.payload.credit_agreement ? action.payload.credit_agreement.doc && action.payload.credit_agreement.doc.split("/") : []
                tt_customer = {
                    id: searchCustomer.main_app && searchCustomer.main_app.id ? searchCustomer.main_app.id : null,
                    ...searchCustomer,
                    main_app: {
                        ...searchCustomer.main_app,
                        ...(searchCustomer.main_app.dobM && searchCustomer.main_app.dobM !== "" && {
                            dobM: searchCustomer.main_app.dobM  ? searchCustomer.main_app.dobM : "0"+searchCustomer.main_app.dobM,
                        }),
                        ...(searchCustomer.main_app.dobD && searchCustomer.main_app.dobD !== "" && {
                            dobD: searchCustomer.main_app.dobD  ? searchCustomer.main_app.dobD : "0"+searchCustomer.main_app.dobD,
                        }),
                        ...(searchCustomer.main_app.state && searchCustomer.main_app.state !== "" && {
                            state: searchCustomer.main_app.state.toUpperCase()
                        }),
                        ssn: searchCustomer.main_app.ssn
                    },
                    co_app: {
                        ...searchCustomer.co_app,
                        ...(searchCustomer.co_app.dobM && searchCustomer.co_app.dobM !== "" && {
                            dobM: searchCustomer.co_app.dobM  ? searchCustomer.co_app.dobM : "0"+searchCustomer.co_app.dobM,
                        }),
                        ...(searchCustomer.co_app.dobD && searchCustomer.co_app.dobD !== "" && {
                            dobD: searchCustomer.co_app.dobD  ? searchCustomer.co_app.dobD : "0"+searchCustomer.co_app.dobD,
                        }),
                        ...(searchCustomer.co_app.state && searchCustomer.co_app.state !== "" && {
                            state: searchCustomer.co_app.state.toUpperCase()
                        }),
                        ssn: searchCustomer.co_app.ssn
                    },
                    products: searchCustomer.products,
                    ssn: searchCustomer.main_app.ssn, 
                    co_ssn: searchCustomer.co_app ? searchCustomer.co_app.ssn : null,
                    credit_agrment:  action.payload.credit_agreement && action.payload.credit_agreement.doc_id ? [action.payload.credit_agreement.doc_id] : [], 
                    credit_agrment_url:action.payload.credit_agreement && action.payload.credit_agreement.doc ? [action.payload.credit_agreement.doc] : [],
                    credit_agrment_name: fileName ? [fileName[fileName.length - 1]] : [],
                    credit_agreement_status: action.payload.credit_agreement && action.payload.credit_agreement.signature_request ? action.payload.credit_agreement.signature_request : false,
                    credit_agreement_saved: false,
                    isCustomerForGenerateOrder: true,
                    credit_aggrement_signature_request : action.payload.credit_agreement && action.payload.credit_agreement.signature_request ? action.payload.credit_agreement.signature_request : false
                }
                

            }

            return {
                ...state,
                customer: tt_customer,
                appFillStatus: 'in_app',
                actionLoading: false,
            }
        case GET_GENERATE_ORDER_CUSTOMER_FAILED:
            return {
                ...state,
                appFillStatus: '',
                customer: temp_customer,
                actionLoading: false,
            }






        case GET_CUSTOMER_REQUEST:
            return {
                ...state,
                customer: temp_customer,
                isCustomerFound: false,
                searchCustomerApiInitiate: true,
                actionLoading: true,
            }
        case GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload,
                isCustomerFound: action.payload ? true : false,
                searchCustomerApiInitiate: true,
                actionLoading: false,
                isCustomerSubmitted: false,
                emailValidate: true
            }
        case GET_CUSTOMER_FAILED:
            return {
                ...state,
                customer: temp_customer,
                isCustomerFound: false,
                searchCustomerApiInitiate: true,
                actionLoading: true,
            }





        case GET_DEALER_TEMPLATE_REQUEST:
            return {
                ...state,
                templates: null,
            }
        case GET_DEALER_TEMPLATE_SUCCESS:
            return {
                ...state,
                templates: action.payload.data
            }
        case GET_DEALER_TEMPLATE_FAILED:
            return {
                ...state,
                templates: null,
            }





        case TAX_REQUEST:
            return {
                ...state,
                actionLoading: true,
            }
        case TAX_SUCCESS:
            return {
                ...state,
                actionLoading: false,
                tax: action.payload.data.data.sales_tax
            }
        case TAX_FAILED:
            return {
                ...state,
                actionLoading: true,
            }




        
        case VALIDATE_EMAIL_REQUEST:
            return {
                ...state,
                emailValidate: true,
                actionLoading: true,
            }
        case VALIDATE_EMAIL_SUCCESS:
            return {
                ...state,                
                emailValidate: true,
                actionLoading: false,
            }
        case VALIDATE_EMAIL_FAILED:
            return {
                ...state,                
                emailValidate: true,
                actionLoading: false,
            }






        case UPDATE_CUSTOMER_SEARCH_REQUEST:
            console.log('action',action)
            return {
                ...state,
                customer: action.payload,
                customer_search_result: []
            }






        case UPDATE_SALES_SILPS_REQUEST:
            return {
                ...state,
                initialProduct : action.payload,
            }






        case SUBMIT_CREDIT_APP_REQUEST:
            return {
                ...state,
                actionLoading: true,
            }
        case SUBMIT_CREDIT_APP_SUCCESS:
            
            let temp_customer12123 = null

            if(action.payload && action.payload.data)
            {
                temp_customer12123 = {
                    ...state.customer,
                    id: action.payload.data.customer_id || 0,
                    main_app: {
                        ...state.customer.main_app,
                        id: action.payload.data.customer_id,
                    },
                    co_app: {
                        ...state.customer.co_app,
                        id: action.payload.data.co_customer_id,
                    },
                    credit_application_id : action.payload.data.credit_application_id
                }
            }
        
            return {
                ...state,
                actionLoading: false,
                customer: temp_customer12123 ? temp_customer12123 : {...state.customer},
            }
        case SUBMIT_CREDIT_APP_FAILED:
            return {
                ...state,
                actionLoading: false,
            }



        
        case SUBMIT_SALES_APP_REQUEST:
            return {
                ...state,
                actionLoading: true,
            }
        case SUBMIT_SALES_APP_SUCCESS:

        console.log(state.customer)

            let sales_app_id = null
            let credit_agr_file = false

            if(action.payload && action.payload.data)
            {
                sales_app_id = action.payload.data.sales_application_id
                credit_agr_file = action.payload.data.credit_agreement_on_file
            }
            
            let temp_sales_slip = [];
            
            if(state.customer.products && state.customer.products.length > 0) {
                state.customer.products.forEach(sale_slip => {
                    temp_sales_slip.push({
                        ...sale_slip,
                        saved: true
                    })
                });
            }
        
            return {
                ...state,
                customer: {...state.customer, 
                    products: temp_sales_slip, 
                    credit_agreement_saved: true, 
                    sales_application_id: sales_app_id, 
                    credit_agreement_on_file: credit_agr_file  },  
                actionLoading: false,
            }


        case SUBMIT_SALES_APP_FAILED:
            return {
                ...state,
                actionLoading: false,
            }

          
            case SAVEINPROGRESS_SALES_APP:
                return {
                    ...state,
                    actionLoading: true,
                }
            case SAVEINPROGRESS_SALES_APP_SUCCESS:
    
            console.log(state.customer)
    
                let save_sales_app_id = null
                let save_in_progress_credit_agr_file = false
    
                if(action.payload && action.payload.data)
                {
                    save_sales_app_id = action.payload.data.sales_application_id
                    save_in_progress_credit_agr_file = action.payload.data.credit_agreement_on_file
                }
                
                let save_in_progress_temp_sales_slip = [];
                
                if(state.customer.products && state.customer.products.length > 0) {
                    state.customer.products.forEach(sale_slip => {
                        save_in_progress_temp_sales_slip.push({
                            ...sale_slip,
                            saved: true
                        })
                    });
                }
            
                return {
                    ...state,
                    customer: {...state.customer, 
                        products: save_in_progress_temp_sales_slip, 
                        credit_agreement_saved: true, 
                        sales_application_id: save_sales_app_id, 
                        credit_agreement_on_file: save_in_progress_credit_agr_file  },  
                    actionLoading: false,
                }
            case SAVEINPROGRESS_SALES_APP_FAILED:
                return {
                    ...state,
                    actionLoading: false,
                }    

        
            case UPDATE_SALESDATA_OF_PRODUCT:
                console.log('salesaction',action)
                if(!action.payload.remove) {
    if(action.payload.data.length == 0) {
                    return {
                        ...state,
                        salesDataOfProduct: [],
                    }
                }
                const i = state.salesDataOfProduct.findIndex((item) => item.type === action.payload.data.type)
                if(i > -1) {
                    state.salesDataOfProduct[i] = action.payload.data
                } else {
                    state.salesDataOfProduct.push(action.payload.data)
                }
                console.log(state.salesDataOfProduct)
                return {
                    ...state,
                    salesDataOfProduct: [...state.salesDataOfProduct]
            }
                } else {
                    const i = state.salesDataOfProduct.findIndex((item) => item.type === action.payload.data.type)
                    console.log(i)
                    if(i > -1) {
                        state.salesDataOfProduct.splice(i, 1)
                    }
                    console.log(state.salesDataOfProduct)
                    return {
                        ...state,
                        salesDataOfProduct: [...state.salesDataOfProduct]
                }
                }
            

        
        case SUBMIT_SALES_DOC_REQUEST:
            return {
                ...state,
                actionLoading: true,
            }
        case SUBMIT_SALES_DOC_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            }
        case SUBMIT_SALES_DOC_FAILED:
            return {
                ...state,
                actionLoading: false,
            }
        
        

        
        case SUBMIT_CA_DOC_REQUEST:
            return {
                ...state,
                actionLoading: true,
            }
        case SUBMIT_CA_DOC_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            }
        case SUBMIT_CA_DOC_FAILED:
            return {
                ...state,
                actionLoading: false,
            }
        
        case SUBMIT_APPLICATION_BASIC_DETAILS_REQUEST:
            return {
                ...state,
                actionLoading: true,
            }    
        case SUBMIT_APPLICATION_BASIC_DETAILS_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            }            
        case SUBMIT_APPLICATION_BASIC_DETAILS_FAILED:
            return {
                ...state,
                actionLoading: false,
            }    

            
        case SUBMIT_CUSTOMER_REPONSE_REQUEST:
            return {
                ...state,
                actionLoading: true,
            }
        case SUBMIT_CUSTOMER_REPONSE_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            }
        case SUBMIT_CUSTOMER_REPONSE_FAILED:
            return {
                ...state,
                actionLoading: false,
            }   
            
            



        case SEND_APP_LINK_REQUEST:
            return {
                ...state,
                actionLoading: true,
            }
        case SEND_APP_LINK_SUCCESS:
            return {
                ...state,
                actionLoading: false,
                customer : {...state.customer,
                     credit_application_id : action?.payload?.credit_application_id, 
                     credit_application_status : action?.payload?.credit_application_status,
                     onlink_aggrement_status : action.payload.credit_application_status}
            }
        case SEND_APP_LINK_FAILED:
            return {
                ...state,
                actionLoading: false,
            }        
        case SUBMIT_CUSTOMER_REPONSE_TO_THANKYOU:
            return {
                ...state,
                actionLoading: false,
                isCustomerSubmitted: true,
            }






            case GET_SALES_HISTORY_REQUEST:
                return {
                    ...state,
                    salesHistory: {
                        loading: true,
                        data: []
                    },
                }
            case GET_SALES_HISTORY_REQUEST_WITH_LOADER:
                return {
                    ...state,
                    salesHistory: {
                        loading: true,
                        data: state.salesHistory.data
                    },
                }
            case GET_SALES_HISTORY_SUCCESS:
                console.log('actionsaleshistory',action)
                return {
                    ...state,
                    salesHistory: {
                        loading: false,
                        data: action.payload.data
                    },
                }
            case GET_SALES_HISTORY_FAILED:
                return {
                    ...state,
                    salesHistory: {
                        loading: false,
                        data: []
                    },
                }


                case GET_SALES_HISTORY_SAVE_PROGRESS_REQUEST:
                    return {
                        ...state,
                        salesHistory: {
                            loading: true,
                            data :  state.salesHistory.data
                        },
                        salesSaveProgressHistory: {
                            data : []
                        }
                    }
                // case GET_SALES_HISTORY_REQUEST_WITH_LOADER:
                //     return {
                //         ...state,
                //         salesHistory: {
                //             loading: true,
                //             data: state.salesHistory.data
                //         },
                //     }
                case GET_SALES_HISTORY_SAVE_PROGRESS_SUCCESS:
                    console.log('actionsaveprogress',action)
                    console.log(state)
                    return {
                        ...state,
                        salesHistory: {
                            loading: false,
                            data: state.salesHistory.data
                        },
                        salesSaveProgressHistory: {
                            data : action.payload.data
                        }
                    }
                case GET_SALES_HISTORY_SAVE_PROGRESS_FAILED:
                    return {
                        ...state,
                        salesHistory: {
                            loading: false,
                            data: state.salesHistory.data
                        },
                        salesSaveProgressHistory: {
                            data : []
                        }
                    }    






            case GET_SALES_ARCHIVE_REQUEST:
                return {
                    ...state,
                    salesArchive: {
                        loading: true,
                        data: []
                    },
                }
            case GET_SALES_ARCHIVE_SUCCESS:
                return {
                    ...state,
                    salesArchive: {
                        loading: false,
                        data: action.payload.data
                    },
                }
            case GET_SALES_ARCHIVE_FAILED:
                return {
                    ...state,
                    salesArchive: {
                        loading: false,
                        data: []
                    },
                }



                case GET_FIELD_DATA_REQUEST:
                    return {
                        ...state,
                        saleData: {
                            loading: true,
                            data: []
                        },
                    }
                case GET_FIELD_DATA_SUCCESS:
                    return {
                        ...state,
                        saleData: {
                            loading: false,
                            data: action.payload.data
                        },
                    }
                case GET_FIELD_DATA_FAILED:
                    return {
                        ...state,
                        saleData: {
                            loading: false,
                            data: []
                        },
                    }
























        default:
            return state;
    }
}
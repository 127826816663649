import API, { baseUrl } from '../../api';
import axios from 'axios';
import { addEvent } from '../../firebase/firebase';
import { pushNotification } from 'utils/notification';
import { message } from 'shared/constant';
import { UPDATE_SALESDATA_OF_PRODUCT } from './actionTypes';
export const SALES_LIST = 'SALES_LIST';
export const SALES_LIST_SUCCESS = 'SALES_LIST_SUCCESS';
export const SALES_LIST_ERROR = 'SALES_LIST_ERROR';
export const GET_APP_DETAIL = 'GET_APP_DETAIL';
export const GET_APP_DETAIL_SUCCESS = 'GET_APP_DETAIL_SUCCESS';
export const GET_APP_DETAIL_ERROR = 'GET_APP_DETAIL_ERROR';
export const RESEND_APPLICATIONMAIL = 'RESEND_APPLICATIONMAIL';

export const CANCEL_TYPE_COUNT_REQUEST = 'CANCEL_TYPE_COUNT_REQUEST';
export const CANCEL_TYPE_COUNT_SUCCESS = 'CANCEL_TYPE_COUNT_SUCCESS';
export const CANCEL_TYPE_COUNT_ERROR = 'CANCEL_TYPE_COUNT_ERROR';

export const PENDING_TYPE_COUNT_REQUEST = 'PENDING_TYPE_COUNT_REQUEST';
export const PENDING_TYPE_COUNT_SUCCESS = 'PENDING_TYPE_COUNT_SUCCESS';
export const PENDING_TYPE_COUNT_ERROR = 'PENDING_TYPE_COUNT_ERROR';

export const GET_SALES_BY_STATUS_REQUEST = 'GET_SALES_BY_STATUS_REQUEST';
export const GET_SALES_BY_STATUS_SUCCESS = 'GET_SALES_BY_STATUS_SUCCESS';
export const GET_SALES_BY_STATUS_ERROR = 'GET_SALES_BY_STATUS_ERROR';

export const DELETE_SPECIFIC_REQUEST = 'DELETE_SPECIFIC_REQUEST';
export const DELETE_SPECIFIC_SUCCESS = 'DELETE_SPECIFIC_SUCCESS';
export const DELETE_SPECIFIC_ERROR = 'DELETE_SPECIFIC_ERROR';

export const SET_INCOMPLETE_REMINDER_REQUEST =
    'SET_INCOMPLETE_REMINDER_REQUEST';
export const SET_INCOMPLETE_REMINDER_SUCCESS =
    'SET_INCOMPLETE_REMINDER_SUCCESS';
export const SET_INCOMPLETE_REMINDER_ERROR = 'SET_INCOMPLETE_REMINDER_ERROR';

export const SET_SEARCH_CUSTOMER_SEARCH_REQUEST =
    'SET_SEARCH_CUSTOMER_SEARCH_REQUEST';
export const RESET_SEARCH_CUSTOMER_FORM_REQUEST =
    'RESET_SEARCH_CUSTOMER_FORM_REQUEST';
export const REFRESH_CHECK_PAGE_LOAD = 'REFRESH_CHECK_PAGE_LOAD';

export const SET_CUSTOMER_SEARCH_REQUEST = 'SET_CUSTOMER_SEARCH_REQUEST';

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILED = 'GET_CUSTOMER_FAILED';

export const GET_SEARCH_CUSTOMER_REQUEST = 'GET_SEARCH_CUSTOMER_REQUEST';
export const GET_SEARCH_CUSTOMER_SUCCESS = 'GET_SEARCH_CUSTOMER_SUCCESS';
export const GET_SEARCH_CUSTOMER_FAILED = 'GET_SEARCH_CUSTOMER_FAILED';

export const GET_GENERATE_ORDER_CUSTOMER_REQUEST =
    'GET_GENERATE_ORDER_CUSTOMER_REQUEST';
export const GET_GENERATE_ORDER_CUSTOMER_SUCCESS =
    'GET_GENERATE_ORDER_CUSTOMER_SUCCESS';
export const GET_GENERATE_ORDER_CUSTOMER_FAILED =
    'GET_GENERATE_ORDER_CUSTOMER_FAILED';

export const UPDATE_CUSTOMER_SEARCH_REQUEST = 'UPDATE_CUSTOMER_SEARCH_REQUEST';

export const UPDATE_SALES_SILPS_REQUEST = 'UPDATE_SALES_SILPS_REQUEST';

export const SUBMIT_CREDIT_APP_REQUEST = 'SUBMIT_CREDIT_APP_REQUEST';
export const SUBMIT_CREDIT_APP_SUCCESS = 'SUBMIT_CREDIT_APP_SUCCESS';
export const SUBMIT_CREDIT_APP_FAILED = 'SUBMIT_CREDIT_APP_FAILED';

export const SUBMIT_SALES_APP_REQUEST = 'SUBMIT_SALES_APP_REQUEST';
export const SUBMIT_SALES_APP_SUCCESS = 'SUBMIT_SALES_APP_SUCCESS';
export const SUBMIT_SALES_APP_FAILED = 'SUBMIT_SALES_APP_FAILED';

export const SAVEINPROGRESS_SALES_APP = 'SAVEINPROGRESS_SALES_APP';
export const SAVEINPROGRESS_SALES_APP_SUCCESS = 'SAVEINPROGRESS_SALES_APP_SUCCESS';
export const SAVEINPROGRESS_SALES_APP_FAILED = 'SAVEINPROGRESS_SALES_APP_FAILED';

export const SUBMIT_SALES_DOC_REQUEST = 'SUBMIT_SALES_DOC_REQUEST';
export const SUBMIT_SALES_DOC_SUCCESS = 'SUBMIT_SALES_DOC_SUCCESS';
export const SUBMIT_SALES_DOC_FAILED = 'SUBMIT_SALES_DOC_FAILED';

export const SUBMIT_CA_DOC_REQUEST = 'SUBMIT_CA_DOC_REQUEST';
export const SUBMIT_CA_DOC_SUCCESS = 'SUBMIT_CA_DOC_SUCCESS';
export const SUBMIT_CA_DOC_FAILED = 'SUBMIT_CA_DOC_FAILED';

export const SUBMIT_APPLICATION_BASIC_DETAILS_REQUEST = 'SUBMIT_APPLICATION_BASIC_DETAILS_REQUEST';
export const SUBMIT_APPLICATION_BASIC_DETAILS_SUCCESS = 'SUBMIT_APPLICATION_BASIC_DETAILS_SUCCESS';
export const SUBMIT_APPLICATION_BASIC_DETAILS_FAILED = 'SUBMIT_APPLICATION_BASIC_DETAILS_FAILED';

export const SET_APP_FILLED_STATUS = 'SET_APP_FILLED_STATUS';

export const SUBMIT_CUSTOMER_REPONSE_REQUEST =
    'SUBMIT_CUSTOMER_REPONSE_REQUEST';
export const SUBMIT_CUSTOMER_REPONSE_SUCCESS =
    'SUBMIT_CUSTOMER_REPONSE_SUCCESS';
export const SUBMIT_CUSTOMER_REPONSE_FAILED = 'SUBMIT_CUSTOMER_REPONSE_FAILED';

export const SEND_APP_LINK_REQUEST = 'SEND_APP_LINK_REQUEST';
export const SEND_APP_LINK_SUCCESS = 'SEND_APP_LINK_SUCCESS';
export const SEND_APP_LINK_FAILED = 'SEND_APP_LINK_FAILED';

export const VALIDATE_EMAIL_REQUEST = 'VALIDATE_EMAIL_REQUEST';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILED = 'VALIDATE_EMAIL_FAILED';

export const SALES_TEMPLATE_REQUEST = 'SALES_TEMPLATE_REQUEST';
export const SALES_TEMPLATE_SUCCESS = 'SALES_TEMPLATE_SUCCESS';
export const SALES_TEMPLATE_FAILED = 'SALES_TEMPLATE_FAILED';

export const GET_DEALER_TEMPLATE_REQUEST = 'GET_DEALER_TEMPLATE_REQUEST';
export const GET_DEALER_TEMPLATE_SUCCESS = 'GET_DEALER_TEMPLATE_SUCCESS';
export const GET_DEALER_TEMPLATE_FAILED = 'GET_DEALER_TEMPLATE_FAILED';

export const TAX_REQUEST = 'TAX_REQUEST';
export const TAX_SUCCESS = 'TAX_SUCCESS';
export const TAX_FAILED = 'TAX_FAILED';

export const SUBMIT_CUSTOMER_REPONSE_TO_THANKYOU =
    'SUBMIT_CUSTOMER_REPONSE_TO_THANKYOU';

export const GET_SALES_HISTORY_REQUEST_WITH_LOADER =
    'GET_SALES_HISTORY_REQUEST_WITH_LOADER';
export const GET_SALES_HISTORY_REQUEST = 'GET_SALES_HISTORY_REQUEST';
export const GET_SALES_HISTORY_SUCCESS = 'GET_SALES_HISTORY_SUCCESS';
export const GET_SALES_HISTORY_FAILED = 'GET_SALES_HISTORY_FAILED';

export const GET_SALES_HISTORY_SAVE_PROGRESS_REQUEST = 'GET_SALES_HISTORY_SAVE_PROGRESS_REQUEST';
export const GET_SALES_HISTORY_SAVE_PROGRESS_SUCCESS = 'GET_SALES_HISTORY_SAVE_PROGRESS_SUCCESS';
export const GET_SALES_HISTORY_SAVE_PROGRESS_FAILED = 'GET_SALES_HISTORY_SAVE_PROGRESS_FAILED';

export const GET_SALES_ARCHIVE_REQUEST = 'GET_SALES_ARCHIVE_REQUEST';
export const GET_SALES_ARCHIVE_SUCCESS = 'GET_SALES_ARCHIVE_SUCCESS';
export const GET_SALES_ARCHIVE_FAILED = 'GET_SALES_ARCHIVE_FAILED';


export const GET_FIELD_DATA_REQUEST = 'GET_FIELD_DATA_REQUEST';
export const GET_FIELD_DATA_SUCCESS = 'GET_FIELD_DATA_SUCCESS';
export const GET_FIELD_DATA_FAILED = 'GET_FIELD_DATA_FAILED';




export function getSalesList(type, email) {
    return async function (dispatch) {
        try {
            dispatch({
                type: SALES_LIST,
            });
            let response;
            if (type) {
                const body = {
                    status: type,
                };
                if (type === 'all') {
                    const data = {
                        email: email,
                    };
                    response = await API.post('/sales/apps-nohello', data);
                } else {
                    response = await API.post('/sales/apps-list', body);
                }
            } else {
                response = await API.get('/sales/apps');
            }
            dispatch({
                type: SALES_LIST_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: SALES_LIST_ERROR,
            });
        }
    };
}

export function getAppDetailById(appId, setDate, setEmailId) {
    return async function (dispatch) {
        dispatch({
            type: GET_APP_DETAIL,
        });
        try {
            const response = await API.get(`/sales/app/${appId}`);
            dispatch({
                type: GET_APP_DETAIL_SUCCESS,
                payload: response.data,
            });
            setDate &&
                response.data.funding &&
                response.data.funding[0] &&
                response.data.funding[0].delivery_date &&
                setDate(response.data.funding[0].delivery_date);
            if (setEmailId) {
                const dataSet = response.data;
                setEmailId && setEmailId(dataSet);
            }
        } catch (error) {
            dispatch({
                type: GET_APP_DETAIL_ERROR,
            });
        }
    };
}

export function resendApplicationMail(
    app_id,
    applicant_email,
    co_applicant_email,
    order_type,
    products,
    cif_number
) {
    return async function (dispatch) {
        await API.put(`/sales/resendemail`, {
            app_id,
            applicant_email,
            co_applicant_email,
            order_type,
            products,
            cif_number,
        });
    };
}

export function getCancelTypeCount() {
    return async function (dispatch) {
        try {
            dispatch({
                type: CANCEL_TYPE_COUNT_REQUEST,
            });
            const response = await API.get('/sales/counts-app');
            const responseData = response.data.counts;
            dispatch({
                type: CANCEL_TYPE_COUNT_SUCCESS,
                payload: {
                    declineCount: responseData.declined,
                    approvedCount: responseData.approved,
                    fundedCount: responseData.funded,
                    cancelCount: responseData.cancelled,
                },
            });
        } catch (error) {
            dispatch({
                type: CANCEL_TYPE_COUNT_ERROR,
            });
        }
    };
}

export function getPendingTypeCount() {
    return async function (dispatch) {
        try {
            dispatch({
                type: PENDING_TYPE_COUNT_REQUEST,
            });
            const response = await API.get('/sales/counts-incomplete');
            const responseData = response.data.counts;
            dispatch({
                type: PENDING_TYPE_COUNT_SUCCESS,
                payload: {
                    pendingCount: responseData.pending,
                    incompleteCount: responseData.incomplete,
                },
            });
        } catch (error) {
            dispatch({
                type: PENDING_TYPE_COUNT_ERROR,
            });
        }
    };
}

export function changeApplicationStatus(action) {
    return async function (dispatch) {
        dispatch({
            type: DELETE_SPECIFIC_REQUEST,
        });
        try {
            let data = {
                id: action.id,
            };
            let apiResponse = '';
            if (action.type && action.type === 'pre-approval') {
                apiResponse = await API.put(`/sales/preapproval-delete`, data);
            } else {
                data['status'] = action.status;
                if (action.page) {
                    data['page'] = action.page;
                }
                apiResponse = await API.put(`/sales/appstatuschange`, data);
            }

            if (apiResponse) {
                dispatch({
                    type: DELETE_SPECIFIC_SUCCESS,
                });
            }
            action.getSalesList &&
                action.getSalesList(action.pageStatus, action.email);
            action.getPreApprovalCustomers && action.getPreApprovalCustomers();
            pushNotification(
                message.DELETE_SUCCESSFULLY,
                'success',
                'TOP_RIGHT',
                3000
            );
        } catch (error) {
            error.response &&
                pushNotification(
                    error.response.data,
                    'error',
                    'TOP_RIGHT',
                    3000
                );
            dispatch({
                type: DELETE_SPECIFIC_ERROR,
            });
        }
    };
}

export function reminderIncompleteRequest(action) {
    return async function (dispatch) {
        dispatch({
            type: SET_INCOMPLETE_REMINDER_REQUEST,
        });
        try {
            const data = {
                email: action.email,
            };
            await API.post(`/sales/hellosign-reminder/${action.id}`, data);
            dispatch({
                type: SET_INCOMPLETE_REMINDER_SUCCESS,
            });
            pushNotification(
                `Reminder has been sent to ${action.email}.`,
                'success',
                'TOP_RIGHT',
                3000
            );
        } catch (error) {
            error.response &&
                pushNotification(
                    error.response.data.detail,
                    'error',
                    'TOP_RIGHT',
                    3000
                );
            dispatch({
                type: SET_INCOMPLETE_REMINDER_ERROR,
            });
        }
    };
}

export function searchCustomer(data) {
    return async function (dispatch) {
        dispatch({
            type: GET_SEARCH_CUSTOMER_REQUEST,
        });
        try {
            const response = await API.post(`/sales/search-customer`, {
                ...data,
            });
            dispatch({
                type: GET_SEARCH_CUSTOMER_SUCCESS,
                payload: response.data,
            });
            if (response.data.data.length == 0) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            dispatch({
                type: GET_SEARCH_CUSTOMER_FAILED,
            });
            return false;
        }
    };
}

export function resetSearchCustomerSearchApiInitiate() {
    return async function (dispatch) {
        dispatch({
            type: SET_SEARCH_CUSTOMER_SEARCH_REQUEST,
        });
    };
}

export function resetSearchCustomerForm() {
    return async function (dispatch) {
        dispatch({
            type: RESET_SEARCH_CUSTOMER_FORM_REQUEST,
        });
    };
}

export function refreshSearchCustomerForm() {
    return async function (dispatch) {
        dispatch({
            type: REFRESH_CHECK_PAGE_LOAD,
        });
    };
}

export function selectCustomer(data) {
    return async function (dispatch) {
        dispatch({
            type: GET_CUSTOMER_REQUEST,
        });
        try {
            dispatch({
                type: GET_CUSTOMER_SUCCESS,
                payload: data,
            });
            return true;
        } catch (error) {
            dispatch({
                type: GET_CUSTOMER_FAILED,
            });
            return false;
        }
    };
}

export function validateEmailAddress(email) {
    let temp_data = { email: email };

    return async function (dispatch) {
        dispatch({
            type: VALIDATE_EMAIL_REQUEST,
        });
        try {
            await API.post(`/accounts/validate-email`, temp_data);
            dispatch({
                type: VALIDATE_EMAIL_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: VALIDATE_EMAIL_FAILED,
            });
            pushNotification(
                'Invalid email address',
                'error',
                'TOP_RIGHT',
                3000
            );
        }
    };
}

export function resetCustomerSearchApiInitiate() {
    return async function (dispatch) {
        dispatch({
            type: SET_CUSTOMER_SEARCH_REQUEST,
        });
    };
}

export function getSalesTax(data) {
    return async function (dispatch) {
        dispatch({
            type: TAX_REQUEST,
        });
        try {
            const response = await API.post('sales/sales-tax', data);
            dispatch({
                type: TAX_SUCCESS,
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: TAX_FAILED,
            });
        }
    };
}

export function updateApplicationFilledStatus(data, history, path) {
    return async function (dispatch) {
        dispatch({
            type: SET_APP_FILLED_STATUS,
            payload: data,
        });
        history && path && history.push(path);
    };
}

export function updateCustomer(history, path, data) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_CUSTOMER_SEARCH_REQUEST,
            payload: data,
        });
        history && path && history.push(path);
    };
}

export function updateSalesDataOfProduct(data, remove, history, path) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_SALESDATA_OF_PRODUCT,
            payload: {data, remove},
        });
        // if(path) {
        //     history && history.push(path);
        // }
    }
}

export function updateSalesSlip(data) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_SALES_SILPS_REQUEST,
            payload: data,
        });
    };
}

export function getSalesTemplateData() {
    return async function (dispatch) {
        dispatch({
            type: GET_DEALER_TEMPLATE_REQUEST,
        });
        try {
            const response = await API.post(`sales/sales-templates`);
            dispatch({
                type: GET_DEALER_TEMPLATE_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_DEALER_TEMPLATE_FAILED,
            });
        }
    };
}

export function submiCreditApplication(history, path, data) {
    let temp_data = {
        contact: {
            reorder: false,
            co_complete: false,
            co_separate: false,
            co_enabled: data.co_enabled,
            id: data.main_app.id || 0,
            ...data,
            main_app: {
                ...data.main_app,
                landlord_mortgage_holder: null,
                home_phone: null,
            },
            co_app: {
                ...data.co_app,
                landlord_mortgage_holder: null,
                home_phone: null,
            },
        },
    };

    return async function (dispatch) {
        dispatch({
            type: SUBMIT_CREDIT_APP_REQUEST,
        });
        try {
            const response = await API.post(`/sales/creditapp-details`, {
                ...temp_data,
            });
            dispatch({
                type: SUBMIT_CREDIT_APP_SUCCESS,
                payload: response.data,
            });
            addEvent(
                'credit_application_completed',
                'credit-application-completed-success',
                { credit_app_id: temp_data.id }
            );
            if(response.data.status == "error") {
                pushNotification(
                    response.data.message,
                    'error',
                    'TOP_RIGHT',
                    3000
                );
                history && history.push('/applyApplicationBasicDetails');
            } else {
            pushNotification(
                'Application Updated Successfully',
                'success',
                'TOP_RIGHT',
                3000
            );
            history && history.push(path);
            }
            
        } catch (error) {
            // pushNotification(error.response.data.message, 'error', 'TOP_RIGHT', 3000);
            dispatch({
                type: SUBMIT_CREDIT_APP_FAILED,
            });
            addEvent(
                'credit_application_completed',
                'credit-application-completed-failed',
                { credit_app_id: temp_data.id }
            );
        }
    };
}

export function submiSalesApplication(data, dealerToken) {
    console.log('abccc', data)
    let temp_data = {
        contact: {
            reorder: false,
            co_complete: false,
            co_separate: false,
            co_enabled: data.co_enabled ? data.co_enabled : false,
            order_type: data.id == '' ? true : false,
            sent: false,
            preapproval_id: 0,
            sales_application_id: data.app_id,
            ...data,
            main_app: {
                ...data.main_app,
                landlord_mortgage_holder: null,
                home_phone: null,
            },
            co_app: {
                ...data.co_app,
                landlord_mortgage_holder: null,
                home_phone: null,
            },
            saved_id : localStorage.getItem('salesInProcessSavedId') && localStorage.getItem('salesInProcessSavedId') > 0 ? localStorage.getItem('salesInProcessSavedId') : null 
        },
        products: data.products,
        credit_agrment: data.credit_agrment,
        credit_agreement_status: data.credit_agreement_status
            ? data.credit_agreement_status
            : false,
    };

    delete temp_data.contact.products;
    delete temp_data.contact.credit_agrment;
    delete temp_data.contact.credit_agrment_url;
    delete temp_data.contact.credit_agrment_name;
    delete temp_data.contact.credit_agreement_status;

    let roles = '';
    if (localStorage.getItem('role').indexOf(',') > -1) {
        roles = localStorage.getItem('role').split(',');
    }
    console.log(roles);

    return async function (dispatch) {
        dispatch({
            type: SUBMIT_SALES_APP_REQUEST,
        });
        try {
            if (roles.includes('admin')) {
                const response = await axios.post(
                    `${baseUrl}sales/sales-app`,
                    { ...temp_data },
                    {
                        headers: {
                            Authorization: `Token ${dealerToken}`,
                        },
                    }
                );
                dispatch({
                    type: SUBMIT_SALES_APP_SUCCESS,
                    payload: response.data,
                });
            } else {
                const response = await API.post(`sales/sales-app`, {
                    ...temp_data,
                });
                dispatch({
                    type: SUBMIT_SALES_APP_SUCCESS,
                    payload: response.data,
                });
            }
            addEvent('sales_slip_completed', 'sales-slip-completed-success', {
                credit_app_id: temp_data.id,
            });
            pushNotification(
                'Sales Slip Updated Successfully',
                'success',
                'TOP_RIGHT',
                3000
            );
        } catch (error) {
            // pushNotification(error.response.data.message, 'error', 'TOP_RIGHT', 3000);
            pushNotification(
                'Please complete your credit application',
                'error',
                'TOP_RIGHT',
                3000
            );
            dispatch({
                type: SUBMIT_SALES_APP_FAILED,
            });
            addEvent('sales_slip_completed', 'sales-slip-completed-success', {
                credit_app_id: temp_data.id,
            });
        }
    };
}

export function saveInProgressSalesApplication(data, dealerToken) {
    console.log('saveInProgressSalesApplicationdata -> ', data)
    let temp_data = {
        contact: {
            reorder: false,
            co_complete: false,
            co_separate: false,
            co_enabled: data.co_enabled ? data.co_enabled : false,
            order_type: data.id == '' ? true : false,
            sent: false,
            preapproval_id: 0,
            sales_application_id: data.app_id,
            ...data,
            main_app: {
                ...data.main_app,
                landlord_mortgage_holder: null,
                home_phone: null,
            },
            co_app: {
                ...data.co_app,
                landlord_mortgage_holder: null,
                home_phone: null,
            },
            saved_id : localStorage.getItem('salesInProcessSavedId') && localStorage.getItem('salesInProcessSavedId') > 0 ? localStorage.getItem('salesInProcessSavedId') : null
        },
        products: data.products,
        credit_agrment: data.credit_agrment,
        credit_agreement_status: data.credit_agreement_status
            ? data.credit_agreement_status
            : false,
    };

    delete temp_data.contact.products;
    delete temp_data.contact.credit_agrment;
    delete temp_data.contact.credit_agrment_url;
    delete temp_data.contact.credit_agrment_name;
    delete temp_data.contact.credit_agreement_status;

    let roles = '';
    if (localStorage.getItem('role').indexOf(',') > -1) {
        roles = localStorage.getItem('role').split(',');
    }
    console.log(roles);
    console.log(temp_data)
    console.log({...temp_data})

    return async function (dispatch) {
        dispatch({
            type: SAVEINPROGRESS_SALES_APP,
        });
        try {
            if (roles.includes('admin')) {
                const response = await axios.post(
                    `${baseUrl}sales/sales-save-progress`,
                    { ...temp_data },
                    {
                        headers: {
                            Authorization: `Token ${dealerToken}`,
                        },
                    }
                );
                dispatch({
                    type: SAVEINPROGRESS_SALES_APP_SUCCESS,
                    payload: response.data,
                });
            } else {
                const response = await API.post(`sales/sales-save-progress`, {
                    ...temp_data,
                });
                dispatch({
                    type: SAVEINPROGRESS_SALES_APP_SUCCESS,
                    payload: response.data,
                });
            }
            addEvent('data_in_progress_saved', 'data-in-progress-success', {
                credit_app_id: temp_data.id,
            });
            pushNotification(
                'Data Saved in Progress Successfully',
                'success',
                'TOP_RIGHT',
                3000
            );
        } catch (error) {
            pushNotification(error.response.data.message, 'error', 'TOP_RIGHT', 3000);
            // pushNotification(
            //     'Please complete your credit application',
            //     'error',
            //     'TOP_RIGHT',
            //     3000
            // );
            dispatch({
                type: SAVEINPROGRESS_SALES_APP_FAILED,
            });
            addEvent('data_in_progress_saved', 'data-in-progress-success', {
                credit_app_id: temp_data.id,
            });
        }
    };
}

export function submiSalesApplicationWithoutApplicant(data) {
    console.log('data',data)
    let temp_data = {
        contact: {
            id: data.main_app.id,
            order_type: true,
            sent: false,
            preapproval_id: 0,
            sales_application_id: data.sales_application_id,
            main_app: {
                name: data.main_app.first_name + " " +data.main_app.last_name,
                email: data.main_app.email,
                cell_phone: data.main_app.cell_phone,
                first_name: data.main_app.first_name,
                last_name: data.main_app.last_name,
            },
            saved_id : localStorage.getItem('salesInProcessSavedId') && localStorage.getItem('salesInProcessSavedId') > 0 ? localStorage.getItem('salesInProcessSavedId') : null
        },
        products: data.products,
        credit_agrment: data.credit_agrment,
        credit_agreement_status: data.credit_agreement_status
            ? data.credit_agreement_status
            : false,
    };

    return async function (dispatch) {
        dispatch({
            type: SUBMIT_SALES_APP_REQUEST,
        });
        try {
            const response = await API.post(`/sales/sales-customer`, {
                ...temp_data,
            });
            dispatch({
                type: SUBMIT_SALES_APP_SUCCESS,
                payload: response.data,
            });
            addEvent('sales_slip_completed', 'sales-slip-completed-success', {
                credit_app_id: temp_data.id,
            });
            pushNotification(
                'Sales Slip Updated Successfully',
                'success',
                'TOP_RIGHT',
                3000
            );
        } catch (error) {
            // pushNotification(error.response.data.message, 'error', 'TOP_RIGHT', 3000);
            pushNotification(
                'Please complete your credit application',
                'error',
                'TOP_RIGHT',
                3000
            );
            dispatch({
                type: SUBMIT_SALES_APP_FAILED,
            });
            addEvent('sales_slip_completed', 'sales-slip-completed-success', {
                credit_app_id: temp_data.id,
            });
        }
    };
}

export function submiCreditApplicationByMail(history, data) {
    let temp_data = {
        nortridge_cif_number: data.main_app.cif_number
            ? data.main_app.cif_number
            : null,
        customer_id: data.main_app.id ? data.main_app.id : 0,
        customer_email: data.main_app.email,
        customer_phone: data.main_app.cell_phone,
        first_name: data.main_app.first_name,
        last_name: data.main_app.last_name,
        name: data.main_app.first_name + ' ' + data.main_app.last_name,
        action: 'onlink',
    };

    return async function (dispatch) {
        dispatch({
            type: SEND_APP_LINK_REQUEST,
        });

        try {
             const response =  await API.post(`/sales/creditapp`, { ...temp_data });
             console.log('response',response)
            dispatch({
                type: SEND_APP_LINK_SUCCESS,
                payload : response.data.data
            });
            
            addEvent(
                'credit_application_mail_sent',
                'credit-application-mail-sent-success',
                { customer_id: temp_data.id }
            );
            pushNotification(
                'Application Send Successfully',
                'success',
                'TOP_RIGHT',
                3000
            );
            history && history.push('/applyApplicationSummary');
        } catch (error) {
            dispatch({
                type: SEND_APP_LINK_FAILED,
            });
            pushNotification(
                'Error while sending mail',
                'error',
                'TOP_RIGHT',
                3000
            );
            addEvent(
                'credit_application_mail_sent',
                'credit-application-mail-sent-failed',
                { customer_id: temp_data.id }
            );
        }
    };
}

export function getCustomerApiInitiate(data) {
    return async function (dispatch) {
        dispatch({
            type: GET_CUSTOMER_REQUEST,
        });
        try {
            const response = await API.post(`/sales/search-customer-id`, {
                id: data,
                token: localStorage.getItem('customerToken'),
            });
            dispatch({
                type: GET_CUSTOMER_SUCCESS,
                payload:
                    response.data &&
                    response.data.data &&
                    response.data.data.main_app &&
                    response.data.data.main_app.id !== ''
                        ? response.data.data
                        : null,
            });
            addEvent(
                'credit_application_mail_open',
                'credit-application-mail-open-success',
                { customer_id: data.id }
            );
        } catch (error) {
            dispatch({
                type: GET_CUSTOMER_FAILED,
            });
            addEvent(
                'credit_application_mail_open',
                'credit-application-mail-open-failed',
                { customer_id: data.id }
            );
        }
    };
}

export function getCustomerApiInitiateForGenerateOrder(data, history, path) {
    console.log(data, history, path);
    return async function (dispatch) {
        dispatch({
            type: GET_GENERATE_ORDER_CUSTOMER_REQUEST,
        });
        try {
            const response = await API.post(
                `/sales/get-customer-details`,
                data
            );
            console.log(response.data.data)
          
            dispatch({
                type: GET_GENERATE_ORDER_CUSTOMER_SUCCESS,
                payload:
                    response.data &&
                    response.data.data &&
                    response.data.data.main_app &&
                    response.data.data.main_app.id !== ''
                        ? response.data.data
                        : null,
            });
            if(path) {
            history && history.push(path);
            }
            if (response.data) {
                localStorage.setItem('aditinal_info', JSON.stringify([{ sales_menu: response.data.data }]))
            }
        } catch (error) {
            dispatch({
                type: GET_GENERATE_ORDER_CUSTOMER_FAILED,
            });
            pushNotification(
                'Error while fetching customer information',
                'error',
                'TOP_RIGHT',
                3000
            );
        }
    };
}

export function customerResponseSubmit(history, data) {
    let temp_data = {
        contact: {
            token: localStorage.getItem('customerToken'),
            id: localStorage.getItem('customerId'),
            salesperson_email: localStorage.getItem('salesperson_email'),
            reorder: false,
            co_complete: false,
            co_separate: false,
            ...data,
            main_app: {
                ...data.main_app,
                landlord_mortgage_holder: null,
                home_phone: null,
            },
            co_app: {
                ...data.co_app,
                landlord_mortgage_holder: null,
                home_phone: null,
            },
        },
    };

    return async function (dispatch) {
        dispatch({
            type: SUBMIT_CUSTOMER_REPONSE_REQUEST,
        });
        try {
            const response = await API.post(`/sales/creditapp-details-onlink`, {
                ...temp_data,
            });
            dispatch({
                type: SUBMIT_CUSTOMER_REPONSE_SUCCESS,
                payload: '',
            });
            addEvent(
                'credit_application_completed_by_customer',
                'credit-application-completed-by-customer-success',
                { credit_app_id: temp_data.id }
            );
            if(response.data.status == "error") {
                pushNotification(
                    response.data.message,
                    'error',
                    'TOP_RIGHT',
                    3000
                );
                history && history.push('/basic');
            } else {
            pushNotification(
                'Application Updated Successfully',
                'success',
                'TOP_RIGHT',
                3000
            );
            history && history.push('/thankyou');
            }
        } catch (error) {
            pushNotification(
                error.response.data.message,
                'error',
                'TOP_RIGHT',
                3000
            );
            dispatch({
                type: SUBMIT_CUSTOMER_REPONSE_FAILED,
            });
            addEvent(
                'credit_application_completed_by_customer',
                'credit-application-completed-by-customer-failed',
                { credit_app_id: temp_data.id }
            );
            history && history.push('/basic');
        }
    };
}

export function getCustomerToThankYouPage() {
    return async function (dispatch) {
        dispatch({
            type: SUBMIT_CUSTOMER_REPONSE_TO_THANKYOU,
        });
    };
}

export function uploadDocumentFile(files) {
    var formdata = new FormData();

    for (let i = 0; i < files.length; i++) {
        formdata.append(`product-doc${i}`, files[i], files[i].name);
    }

    return async function (dispatch) {
        dispatch({
            type: SUBMIT_SALES_DOC_REQUEST,
        });
        try {
            const response = await API.post(`sales/product-doc`, formdata);
            dispatch({
                type: SUBMIT_SALES_DOC_SUCCESS,
            });
            console.log(response);
            return response.data;
        } catch (error) {
            dispatch({
                type: SUBMIT_SALES_DOC_FAILED,
            });
            console.log(error);
            return null;
        }
    };
}

export function uploadCADocumentFile(files) {
    var formdata = new FormData();

    for (let i = 0; i < files.length; i++) {
        formdata.append(`product-doc${i}`, files[i], files[i].name);
    }

    return async function (dispatch) {
        dispatch({
            type: SUBMIT_CA_DOC_REQUEST,
        });
        try {
            const response = await API.post(`sales/credit-agreement`, formdata);
            dispatch({
                type: SUBMIT_CA_DOC_SUCCESS,
            });
            console.log(response);
            return response.data;
        } catch (error) {
            dispatch({
                type: SUBMIT_CA_DOC_FAILED,
            });
            console.log(error);
            return null;
        }
    };
}

export function submiCustomerDataToNorthbridge(history, data) {
    let temp_data = {
        customer_email: data.main_app.email,
        customer_phone: data.main_app.cell_phone,
        first_name: data.main_app.first_name,
        last_name: data.main_app.last_name,
        name: data.main_app.first_name + ' ' + data.main_app.last_name,
        action: 'save&exit',
    };

    return async function (dispatch) {
        dispatch({
            type: SUBMIT_CA_DOC_REQUEST,
        });

        try {
            await API.post(`/sales/creditapp`, { ...temp_data });
            dispatch({
                type: SUBMIT_CA_DOC_SUCCESS,
            });
            history && history.push('/applyCustomerSearch');
        } catch (error) {
            dispatch({
                type: SUBMIT_CA_DOC_FAILED,
            });
            history && history.push('/applyCustomerSearch');
        }
    };
}

export function submitCustomreBasicDetails(history, data) {
    return async function (dispatch) {
        dispatch({
            type: SUBMIT_APPLICATION_BASIC_DETAILS_REQUEST,
        });
        console.log(history)
        console.log(data)
        try {
            const response = await API.post(`/sales/creditapp`, data);
            if(response.data.status == "error") {
                pushNotification(
                    response.data.message,
                    'error',
                    'TOP_RIGHT',
                    3000
                );
                dispatch({
                    type: SUBMIT_APPLICATION_BASIC_DETAILS_FAILED,
                });
            } else {
            dispatch({
                type: SUBMIT_APPLICATION_BASIC_DETAILS_SUCCESS,
            });
            history && history.push('/applyCustomerSearch');
        }
        } catch (error) {
            pushNotification(error.response.data.message, 'error', 'TOP_RIGHT', 3000);
            dispatch({
                type: SUBMIT_APPLICATION_BASIC_DETAILS_FAILED,
            });
        }
    };
}

export function getSalesHistory(temp) {
    return async function (dispatch) {
        dispatch({
            type: GET_SALES_HISTORY_REQUEST,
        });
        try {
            const response = await API.post(`/sales/sales-history`, temp);
            console.log('response',response)
            dispatch({
                type: GET_SALES_HISTORY_SUCCESS,
                payload: response.data,
            });
            console.log('responsedispatch',response)
            dispatch({
                type: GET_SALES_HISTORY_SAVE_PROGRESS_REQUEST,
            });
            try {
                const getSaveProgressDataResponse = await API.post(`/sales/get-save-progress`, {});
                dispatch({
                            type: GET_SALES_HISTORY_SAVE_PROGRESS_SUCCESS,
                            payload: getSaveProgressDataResponse.data,
                        });
            } catch(error) {
                dispatch({
                            type: GET_SALES_HISTORY_SAVE_PROGRESS_FAILED,
                        });
            }
            
        } catch (error) {
            dispatch({
                type: GET_SALES_HISTORY_FAILED,
            });
        }
    };
}

// export function getSalesSaveProgressHistory(temp) {
//     return async function (dispatch) {
//         dispatch({
//             type: GET_SALES_HISTORY_SAVE_PROGRESS_REQUEST,
//         });
//         // try {
//         //     const response = await API.post(`/sales/get-save-progress`, temp);
//         //     dispatch({
//         //         type: GET_SALES_HISTORY_SAVE_PROGRESS_SUCCESS,
//         //         payload: response.data,
//         //     });
//         // } catch (error) {
//         //     dispatch({
//         //         type: GET_SALES_HISTORY_SAVE_PROGRESS_FAILED,
//         //     });
//         // }
//     };
// }

export function getSalesArchive() {
    let temp = {
        archive: true,
        filter_status: false,
        orderby: 'newtoold',
    };

    return async function (dispatch) {
        dispatch({
            type: GET_SALES_ARCHIVE_REQUEST,
        });
        try {
            const response = await API.post(`/sales/sales-history`, temp);
            dispatch({
                type: GET_SALES_ARCHIVE_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_SALES_ARCHIVE_FAILED,
            });
        }
    };
}

export function archiveSalesHistory(data) {
    console.log(data);

    let temp1 = {
        credit_app_id: data && data.credit_app ? data.credit_app_id : null,
        app_id: data ? data.app_id : null,
    };

    let temp = {
        archive: false,
        filter_status: false,
        orderby: 'newtoold',
    };

    return async function (dispatch) {
        dispatch({
            type: GET_SALES_HISTORY_REQUEST_WITH_LOADER,
        });
        try {
            await API.post(`/sales/sales-move-archive`, temp1);
            try {
                const response = await API.post(`/sales/sales-history`, temp);
                dispatch({
                    type: GET_SALES_HISTORY_SUCCESS,
                    payload: response.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_SALES_HISTORY_FAILED,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_SALES_HISTORY_FAILED,
            });
        }
    };
}


export function getFieldData(temp) {
    return async function (dispatch) {
        dispatch({
            type: GET_FIELD_DATA_REQUEST,
        });
        try {
            const response = await API.post(`/sales/sales-fields`, {product_type: temp});
            dispatch({
                type: GET_FIELD_DATA_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_FIELD_DATA_FAILED,
            });
        }
    };
}

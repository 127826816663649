import axios from 'axios';
import { pushNotification } from 'utils/notification';
export const baseUrl = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
    baseURL: baseUrl,
});

api.interceptors.request.use(
    async function (config) {
        const token = localStorage.getItem('token');
        const impersonatedDealerToken = sessionStorage.getItem('impersonatedDealerToken');
        if(impersonatedDealerToken) {
            config.headers['Authorization']  = `Token ${impersonatedDealerToken}`;
        } else if (token) {
            config.headers['Authorization'] = `Token ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => {
        if (response && response.message) {
            return Promise.reject(response);
        }
        return response;
    },
    (error) => {
        if (!error) {
            return Promise.reject(error);
        }
        if (error.status === 440 || error.status === 401) {
            pushNotification(
                'Your session has expired please login again.',
                'error',
                'TOP_RIGHT',
                3000
            );
        }
        return Promise.reject(error);
    }
);

export default api;

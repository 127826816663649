import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Form, PageItem } from 'react-bootstrap';
import { pushNotification } from 'utils/notification';
import { getParamsValue } from '../../../utils/utility';
import Header from '../../../components/Sales/Header';
import { Modal } from 'react-bootstrap';
import {
    TCPLogo,
    IconListWhite,
    IconArrowLeft,
    IconDeleteNew,
    IconContactAcitve,
} from '../../../assets/images';
import Loader from 'shared/Loader';
import Input from '../../../components/commons/input';
import Dropdown from '../../../components/commons/dropdown';

import { getFromData } from '../../../components/commons/utility';
import {
    getSalesTemplateData,
    updateSalesSlip,
    updateCustomer,
    getSalesTax,
    updateSalesDataOfProduct,
    CANCEL_TYPE_COUNT_REQUEST,
    getFieldData,
} from '../../../redux/actions/sales';
import { getFlooredFixedTwoDigit } from 'utils/helper';
import { action } from '@storybook/addon-actions';

function SalesSlip(props) {
    const {
        history,
        tax,
        getSalesTemplateData,
        getSalesTax,
        customer,
        updateCustomer,
        templates,
        appFillStatus,
        actionLoading,
        initialProduct,
        updateSalesSlip,
        updateSalesDataOfProduct,
        salesDataOfProduct,
        getFieldData,
        saleData,
    } = props;

    console.log(templates);
    console.log(props);

    const data = useSelector((state) => console.log(state));

    const [isSaveVisible, setSaveVisible] = useState(false);
    const [editTax, setEditTax] = useState(tax);
    const [validationResult, setValidationResult] = useState(null);
    const [openTemplateName, setOpenTemplateName] = useState(false);
    const [netPrice, setNetPrice] = useState(0);
    const [unPaidBalance, setUnPaidBalance] = useState(0);
    const [monthlyMinimum, setMonthlyMinimum] = useState(0);
    const [financePeriod, setFinancePeriod] = useState([]);
    const [isTaxEdit, setTaxEdit] = useState(false);
    const [totalObj, setTotalObj] = useState({});
    const [dynamicTotalObj, setDynamicTotalObj] = useState({});
    const [formulaArr, setFormulaArr] = useState([]);
    const [dynamicValueArr, setDynamicValueArr] = useState([]);
    const [filterSalesDataProduct, setFilterSalesDataProduct] = useState([]);
    const [salesValue, setSalesValue] = useState();

    const getValue = (e, i) => {
        // console.log(index)
        setSalesValue(e.target.value);
        console.log(e.target.value, i);
    };
    // console.log(salesValue)

    const [salesData, setSalesData] = useState([]);
    const [initialSalesData, setInitialSalesData] = useState([])

    console.log('salesData:', saleData);

    useEffect(() => {
        console.log('salesData =>', salesData);
    }, []);

    useEffect(() => {
        if (saleData && saleData.data && saleData.data.length > 0) {
            setSalesData(saleData.data);
            setInitialSalesData(saleData.data)
        }
    }, [saleData]);

    useEffect(() => {
        console.log('propsonload', getParamsValue);
        const queryString = window.location.search.replace('?', '');
        let arr = queryString.split('type=');
        let value = null;
        if (arr[1]) {
            value = arr[1].split('&')[0].replaceAll('%20', ' ');
        }
        console.log(value);
        if (props.initialProduct.price == 0 || !props.initialProduct.price) {
            getFieldData(value);
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem('role') === 'sales,dealer,admin' || localStorage.getItem('role') === 'sales' || localStorage.getItem('role') === 'sales,dealer') {
            if (
                props.initialProduct &&
                props.initialProduct.price &&
                props.initialProduct.price > 0 &&
                props.salesDataOfProduct &&
                props.salesDataOfProduct.length > 0
            ) {
                let filterArr = props.salesDataOfProduct.filter(
                    (item) => item.type === props.initialProduct.product_type
                );
                console.log(filterArr);
                setFilterSalesDataProduct(filterArr);
            }
            if (
                props.initialProduct.price &&
                props.initialProduct.price > 0 &&
                props.customer.sales_product &&
                props.customer.sales_product.length > 0
            ) {
                let filterArr = props.customer.sales_product.filter(
                    (item) => item.type === props.initialProduct.product_type
                );
                console.log(filterArr);
                setFilterSalesDataProduct(filterArr);
            }
        } else {
            if (
                props.initialProduct &&
                props.initialProduct.price &&
                props.initialProduct.price > 0 &&
                props.salesDataOfProduct &&
                props.salesDataOfProduct.length > 0
            ) {
                // let fillterTotalObj = totalObj
                let filterArr = props.salesDataOfProduct.filter(
                    (item) => item.type === props.initialProduct.product_type
                );
                console.log(filterArr);
                setFilterSalesDataProduct(filterArr);
                // setSalesData([...filterArr[0].data])
                // filterArr[0].data.map((item, idx) => {
                //     item.field_keys.map((item2, index) => {
                //         if(item2.value_source_table == 'formula') {
                //             Object.assign(fillterTotalObj, { [item2.field_key]: item2.default_value });
                //         }
                //     })
                // })
                // console.log('fillterTotalObj',fillterTotalObj)
                // setTotalObj(fillterTotalObj)
            }
        }
    }, [props.initialProduct]);

    useEffect(() => {
        let fillterTotalObj = totalObj;
        if (filterSalesDataProduct && filterSalesDataProduct.length > 0) {
            console.log(filterSalesDataProduct);
            setSalesData(filterSalesDataProduct[0].data);
            filterSalesDataProduct[0].data.map((item, idx) => {
                item.field_keys.map((item2, index) => {
                    if (item2.field_label_prefix === '%') {
                        Object.assign(fillterTotalObj, {
                            [item2.field_key]:
                                (item2.default_value || +item2.value_source) /
                                100,
                        });
                    } else {
                        Object.assign(fillterTotalObj, {
                            [item2.field_key]: item2.default_value,
                        });
                    }
                });
            });
        }
        setTotalObj(fillterTotalObj);
    }, [filterSalesDataProduct]);

    //   useEffect(() => {
    //     if(props.customer && props.customer.salesData) {
    //         setSalesData(props.customer.salesData);
    //     }
    //   },[props.customer])

    const itemModalChangeHandler = (e, salesItem, field) => {
        let arr = [...salesItem.field_keys];
        let tempData = arr.find((data) => data.id === field.id);
        let key = tempData['field_key'];
        setTotalObj({ ...totalObj, [key]: e });
        Object.assign(tempData, { [key]: e });
        tempData.default_value = e;
    };

    const changeHandler = (e, salesItem, item) => {
        // console.log(e.target.value);
        // console.log(item);
        // console.log(text);
        // console.log(data[text]);
        // let userData = { ...data };
        // console.log(userData);
        // let arr = [];
        // Object.entries(userData).map(([key, value]) => (arr = [...data[text]]));
        // let tempData = arr.find((data) => data.id === item.id);
        // console.log(tempData);
        // tempData.value = +e.target.value;
        // console.log(item);
        // console.log(arr);
        // setData(userData);
        // console.log(userData);

        // Object.entries(data).map(([key, value]) => {
        //   if (key === text) {
        //     value.map((data) => {
        //       if (data.id === item.id) {
        //         data.value = +e.target.value;
        //       }
        //     });
        //   }
        // });
        // setData({ ...data });
        console.log(salesItem);
        console.log(item);
        console.log(e);

        let arr = [...salesItem.field_keys];
        let tempData = arr.find((data) => data.id === item.id);
        console.log(tempData);

        if (tempData.ref1 && tempData.ref2) {
            tempData.default_value = +e;
            let taxAmountKey = tempData['field_key'];
            Object.assign(tempData, { [taxAmountKey]: +e });
            setTotalObj({ ...totalObj, [taxAmountKey]: +e });

            let netPriceObj = arr.find(
                (data) => data.field_key == tempData.ref2
            );

            let myTempData = arr.find(
                (data) => data.field_key === tempData.ref1
            );
            let key = myTempData['field_key'];
            Object.assign(myTempData, {
                default_value: getFlooredFixedTwoDigit(
                    (+e / +netPriceObj.default_value) * 100,
                    2
                ) ,
                value_source: getFlooredFixedTwoDigit(
                    (+e / +netPriceObj.default_value) * 100,
                    2
                ) ,
            });
            setTotalObj({
                ...totalObj,
                [key]: getFlooredFixedTwoDigit(
                    (+e / +netPriceObj.default_value) * 100,
                    2
                ) ,
            });
        } else if (
            tempData.field_label_prefix == '%' &&
            tempData.value_source_table !== 'formula'
        ) {
            // let myArr = [...salesItem.field_keys];
            // let tempData = myArr.find((data) => data.field_key === 'tax');
            tempData.default_value = +e;
            tempData.value_source = +e;
            let taxAmountKey = tempData['field_key'];
            Object.assign(tempData, { [taxAmountKey]: +e });
            setTotalObj({ ...totalObj, [taxAmountKey]: +e });
        } else {
            console.log('not percentage');
            let key = tempData['field_key'];
            console.log(key, e);
            setTotalObj({ ...totalObj, [key]: +e });
            Object.assign(tempData, { [key]: +e });
            tempData.default_value = +e;
        }

        //     if(tempData.field_key == "tax_amount") {
        //         let myArr = [...salesItem.field_keys];
        //         let tempData = myArr.find((data) => data.field_key === 'tax_amount');
        //         tempData.default_value = +e;
        //         let taxAmountKey = tempData['field_key'];
        //         Object.assign(tempData, { [taxAmountKey]: +e});
        //         setTotalObj({ ...totalObj, [taxAmountKey]: +e});

        //         let netPriceObj = myArr.find((data) => data.field_key == 'net_price');

        //         let myTempData = myArr.find((data) => data.field_key === 'tax');
        //         let key = myTempData['field_key'];
        //         Object.assign(myTempData, {
        //             default_value: getFlooredFixedTwoDigit(((+e / +netPriceObj.default_value) * 100),2),
        //             value_source: getFlooredFixedTwoDigit(((+e / +netPriceObj.default_value) * 100),2),
        //         });
        //         setTotalObj({ ...totalObj, [key]: getFlooredFixedTwoDigit(((+e / +netPriceObj.default_value) * 100),2) });
        //     } else if (tempData.field_key == "tax")  {
        //         let myArr = [...salesItem.field_keys];
        //         let tempData = myArr.find((data) => data.field_key === 'tax');
        //         tempData.default_value = +e;
        //         tempData.value_source = +e;
        //         let taxAmountKey = tempData['field_key'];
        //         Object.assign(tempData, { [taxAmountKey]: +e});
        //         setTotalObj({ ...totalObj, [taxAmountKey]: +e});
        //     } else {
        //         console.log('not percentage');
        //         let key = tempData['field_key'];
        //         console.log(key, e);
        //         setTotalObj({ ...totalObj, [key]: +e });
        //         Object.assign(tempData, { [key]: +e });
        //         tempData.default_value = +e;

        // }

        // let parentTotal = 0

        // let obj = {
        //   ...salesItem,
        //   [key]: e,
        // };
        // console.log(obj);
        // Object.assign(tempData, { [key]: +e });

        console.log(tempData);
        console.log(arr);
        console.log(salesData);
        // let total = 0
        // for (let i = 0; i < salesItem.field_keys.length; i++) {
        // total = salesItem.field_keys[i].
        // }
        // console.log(salesItem);
        let dynamicValueArr = salesItem.field_keys.filter(
            (data) =>
                data.value_source_table && data.value_source_table != 'formula'
        );
        setDynamicValueArr(dynamicValueArr);
        // for (let i = 0; i < dynamicValueArr.length; i++) {
        //     dynamicValue += dynamicValueArr[i].value_source;
        //     setTotalObj({ ...totalObj, [dynamicValueArr[i].field_key]: +dynamicValueArr[i].value_source });
        // }
        // console.log(dynamicValue);
        // let total = dynamicValue;
        // console.log(total);
        // console.log(dynamicValueArr);
        let formulaArr = salesItem.field_keys.filter(
            (item) => item.value_source_table == 'formula'
        );
        console.log(formulaArr);

        setFormulaArr(formulaArr);

        // if (formulaArr && formulaArr.length > 0) {
        //   formulaArr.map((item, index) => {
        //     let totalStr = "";
        //     let tempData = formulaArr[index];
        //     console.log(tempData)
        //     tempData.value_source.formula = tempData.value_source.formula.replace(
        //           /[\[\]']+/g,
        //           ""
        //         );
        //         let arithmetic = tempData.value_source.formula.split(/(?=[-+*\/])/);
        //         console.log(arithmetic);

        //         arithmetic.map((item) => {
        //           console.log(item.charAt(0));
        //           if (
        //             item.charAt(0) == "+" ||
        //             item.charAt(0) == "-" ||
        //             item.charAt(0) == "*" ||
        //             item.charAt(0) == "/"
        //           ) {
        //             totalStr += [item.slice(0, 1), "totalObj.", item.slice(1)].join("");
        //           } else {
        //             totalStr += `totalObj.${item}`;
        //           }
        //         });
        //         console.log(totalStr);
        //         console.log(eval(totalStr))
        //         tempData.default_value = eval(totalStr);
        //         console.log(tempData.default_value);
        // console.log(typeof tempData.value_source);
        // if (typeof tempData.value_source === "string") {
        //   tempData.value_source = tempData.value_source.replace(
        //     /[\[\]']+/g,
        //     ""
        //   );
        //   let arithmetic = tempData.value_source.formula.split(/(?=[-+*\/])/);
        //   console.log(arithmetic);

        //   arithmetic.map((item) => {
        //     console.log(item.charAt(0));
        //     if (
        //       item.charAt(0) == "+" ||
        //       item.charAt(0) == "-" ||
        //       item.charAt(0) == "*" ||
        //       item.charAt(0) == "/"
        //     ) {
        //       totalStr += [item.slice(0, 1), "item.", item.slice(1)].join("");
        //     } else {
        //       totalStr += `item.${item}`;
        //     }
        //   });
        //   console.log(totalStr);
        // }
        // console.log(tempData.value_source);
        // console.log(item.value_source.keys);
        // let filterArr = salesItem.field_keys.filter(
        //   (data) =>
        //     item.value_source.keys &&
        //     item.value_source.keys.includes(data.field_key)
        // );
        // console.log(filterArr);
        // let total = 0;
        // for (let i = 0; i < filterArr.length; i++) {
        //   // total = filterArr[i].list_price + filterArr[i].add_discount;
        //   console.log(filterArr[i].list_price);
        // }
        // // filterArr.map((data) => {
        // //   console.log(data);
        // //   total += +data.default_value;
        // // });
        // console.log(total);
        // tempData.default_value = total;
        // console.log(tempData);
        // tempData.default_value = total;
        // });
        // }
        // console.log(formulaArr)
        // setFormulaArr(formulaArr);
        console.log(salesData);
        // setSalesData([...salesData]);
        setSalesData(salesData);
    };

    useEffect(() => {
        if (props.initialProduct.price == 0 || !props.initialProduct.price) {
            let myArr = [];
            salesData.map((item) => {
                item.field_keys.map((data) => {
                    if (
                        data.field_key == 'tax' ||
                        data.field_key == 'tax_amount'
                    ) {
                        myArr.push(data);
                    }
                });
            });
            console.log('myArr', myArr);

            let myObj =
                myArr && myArr[0] && myArr[0].toggle_disabled
                    ? totalObj
                    : { ...totalObj };
            // let myObj = totalObj
            if (dynamicValueArr && dynamicValueArr.length > 0) {
                dynamicValueArr.map((item, index) => {
                    let tempData = dynamicValueArr[index];
                    console.log(tempData);
                    Object.assign(myObj, {
                        [tempData.field_key]: +tempData.default_value,
                    });
                    // if(tempData.toggle_disabled) {
                    //     Object.assign(myObj, { [tempData.field_key]: tempData.default_value });
                    // }
                    // Object.assign(myObj, {
                    //     [tempData.field_key]: +tempData.value_source,
                    // });
                });
                console.log(myObj);
                setDynamicTotalObj(myObj);
                // setTotalObj(myObj);
            }
        } else {
            let myObj = { ...dynamicTotalObj };
            if (dynamicValueArr && dynamicValueArr.length > 0) {
                dynamicValueArr.map((item, index) => {
                    let tempData = dynamicValueArr[index];
                    console.log(tempData);
                    Object.assign(myObj, {
                        [tempData.field_key]:
                            +tempData.value_source || +tempData.default_value,
                    });
                    // Object.assign(myObj, {
                    //     [tempData.field_key]: +tempData.value_source / 100,
                    // });
                });
                console.log(myObj);
                setDynamicTotalObj(myObj);
                // setTotalObj(myObj);
            }
        }
    }, [dynamicValueArr]);

    useEffect(() => {
        setTotalObj({ ...totalObj, ...dynamicTotalObj });
    }, [dynamicTotalObj]);

    useEffect(() => {
        console.log(totalObj);
        let parentTotal = Object.values(totalObj).reduce((a, b) => a + b, 0);
        console.log(parentTotal);
        let newArr = [];
        salesData.map((item, index) => {
            item.field_keys.map((data, index) => {
                if (
                    data.value_source_table &&
                    data.value_source_table == 'formula'
                ) {
                    newArr.push(data);
                }
            });
        });
        console.log(newArr);
        setFormulaArr(newArr);
    }, [totalObj]);

    useEffect(() => {
        console.log(formulaArr);
        // let newOBj = {...totalObj}
        let newOBj = totalObj;
        if (formulaArr && formulaArr.length > 0) {
            formulaArr.map((item, index) => {
                let totalStr = '';
                let tempData = formulaArr[index];
                console.log(tempData);

                // if(!tempData.toggle_disabled){
                tempData.value_source.formula =
                    tempData.value_source.formula.replace(/[\[\]']+/g, '');
                let arithmetic =
                    tempData.value_source.formula.split(/(?=[-+*\/])/);
                console.log(arithmetic);

                arithmetic.map((item) => {
                    console.log(item.charAt(0));
                    console.log(typeof item);
                    console.log(isNaN(item));
                    console.log(item);
                    if (item.charAt(0) == '(') {
                        totalStr += [
                            item.slice(0, 1),
                            'totalObj.',
                            item.slice(1),
                        ].join('');
                    } else if (
                        (item.charAt(0) == '+' ||
                            item.charAt(0) == '-' ||
                            item.charAt(0) == '*' ||
                            item.charAt(0) == '/') &&
                        item.charAt(1) == ' '
                    ) {
                        totalStr += [item.slice(0, 1), '', item.slice(1)].join(
                            ''
                        );
                    } else if (
                        (item.charAt(0) == '+' && item.charAt(1) == '(') ||
                        (item.charAt(0) == '-' && item.charAt(1) == '(') ||
                        (item.charAt(0) == '*' && item.charAt(1) == '(') ||
                        (item.charAt(0) == '/' && item.charAt(1) == '(')
                    ) {
                        totalStr += [
                            item.slice(0, 2),
                            'totalObj.',
                            item.slice(2),
                        ].join('');
                    } else if (
                        item.charAt(0) == '+' ||
                        item.charAt(0) == '-' ||
                        item.charAt(0) == '*' ||
                        item.charAt(0) == '/'
                    ) {
                        totalStr += [
                            item.slice(0, 1),
                            'totalObj.',
                            item.slice(1),
                        ].join('');
                    } else {
                        totalStr += `totalObj.${item}`;
                    }

                    // if (
                    //   item.charAt(0) == "+" ||
                    //   item.charAt(0) == "-" ||
                    //   item.charAt(0) == "*" ||
                    //   item.charAt(0) == "/"
                    // ) {
                    //   totalStr += [item.slice(0, 1), "totalObj.", item.slice(1)].join("");
                    // } else {
                    //   totalStr += `totalObj.${item}`;
                    // }
                });
                console.log('totalStr',totalStr);
                console.log(totalObj);
                console.log(typeof totalObj.list_price)
                console.log(eval(totalStr), eval(totalStr) == 'NaN', typeof eval(totalStr), isNaN(eval(totalStr)));
                console.log({ [tempData.field_key]: isNaN(eval(totalStr)) ? 0 : eval(totalStr) });

                tempData.default_value = isNaN(eval(totalStr)) ? 0 : eval(totalStr);
                // tempData.default_value = eval(totalStr);
                console.log('tempdaata',tempData.default_value)
                // setTotalObj({...totalObj, [tempData.field_key]: tempData.default_value});
                console.log(tempData.field_key, tempData.default_value);
                Object.assign(newOBj, {
                    [tempData.field_key]: tempData.default_value,
                });
                console.log(newOBj);
                setTotalObj(newOBj);
                // setTotalObj({...totalObj, [tempData.field_key]: tempData.default_value});
                // console.log(totalObj);

                // }
            });
            console.log(newOBj);
            //   setTotalObj(newOBj);
        }
        // console.log(formulaArr)
        // setFormulaArr(formulaArr);
        console.log(salesData);
        setSalesData([...salesData]);
    }, [formulaArr, totalObj]);

    useEffect(() => {
        getSalesTemplateData();
        getSalesTax({
            state: customer.main_app.state ? customer.main_app.state : 'NA',
        }); //customer.main_app.state
    }, []);

    useEffect(() => {
        setEditTax(tax);
        if (initialProduct.price == 0) {
            changeState({ tax_per: tax });
        }
    }, [tax]);

    useEffect(() => {
        if (initialProduct.price !== 0) {
            setSaveVisible(true);

            let n_price =
                initialProduct.price -
                initialProduct.add_discount -
                initialProduct.coupon;
            let p_type = getParamsValue('type')
                ? getParamsValue('type').replaceAll('%20', ' ')
                : initialProduct.menuname;

            updateSalesSlip({
                ...initialProduct,
                total_discount:
                    Number(initialProduct.add_discount) +
                    Number(initialProduct.coupon),
                signature_request: initialProduct.signature_request
                    ? initialProduct.signature_request
                    : false,
                product_type: p_type,
                tax: (n_price * initialProduct.tax_per) / 100,
                template_name:
                    initialProduct.template_id === 0
                        ? `${p_type}-template-${n_price}`
                        : initialProduct.template_name,
            });
            setNetPrice(n_price);

            let unpaid_balance =
                Number(n_price) +
                Number(initialProduct.tax) -
                Number(initialProduct.cash_credit) -
                Number(initialProduct.check) -
                Number(initialProduct.down_payment);
            setUnPaidBalance(parseInt(unpaid_balance).toFixed(2));

            if (initialProduct.finance_period != 0) {
                let monthly_min =
                    (Number(n_price) +
                        Number(initialProduct.tax) -
                        Number(initialProduct.cash_credit) -
                        Number(initialProduct.check) -
                        Number(initialProduct.down_payment)) /
                    Number(initialProduct.finance_period);
                setMonthlyMinimum(parseInt(monthly_min).toFixed(2));
            }
        } else {
            setNetPrice(0);
            setUnPaidBalance(0);
            setMonthlyMinimum(0);
        }

        getDropdownData();
    }, [JSON.stringify(initialProduct), totalObj]);

    const getDropdownData = () => {
        let period_arr = [];
        let check = getParamsValue('type')
            ? getParamsValue('type')
            : initialProduct.menuname;
        console.log('check', check);
        if (check === 'Food/Mac') {
            period_arr = [
                { label: '2 months', value: '2' },
                { label: '3 months', value: '3' },
                { label: '4 months', value: '4' },
                { label: '5 months', value: '5' },
                { label: '6 months', value: '6' },
                { label: '7 months', value: '7' },
                { label: '8 months', value: '8' },
                { label: '9 months', value: '9' },
                { label: '10 months', value: '10' },
                { label: '11 months', value: '11' },
                { label: '12 months', value: '12' },
                // { label: '18 months', value: "18" },
                // { label: '24 months', value: "24" },
                // { label: '30 months', value: "30" },
                // { label: '36 months', value: "36" },
                // { label: '48 months', value: "48" },
                // { label: '60 months', value: "60" },
                // { label: '72 months', value: "72" }
            ];
        } else {
            period_arr = [
                // { label: '2 months', value: "2" },
                // { label: '3 months', value: "3" },
                // { label: '4 months', value: "4" },
                // { label: '5 months', value: "5" },
                // { label: '6 months', value: "6" },
                // { label: '7 months', value: "7" },
                // { label: '8 months', value: "8" },
                // { label: '9 months', value: "9" },
                // { label: '10 months', value: "10" },
                // { label: '11 months', value: "11" },
                { label: '12 months', value: '12' },
                { label: '18 months', value: '18' },
                { label: '24 months', value: '24' },
                { label: '30 months', value: '30' },
                { label: '36 months', value: '36' },
                { label: '48 months', value: '48' },
                { label: '60 months', value: '60' },
                { label: '72 months', value: '72' },
            ];
        }
        setFinancePeriod(period_arr);
    };

    const clearsaleslip = (tab) => {
        updateSalesSlip({
            product_type: null,
            makemodel: null,
            price: 0,
            add_discount: 0,
            coupon: 0,
            tax: 0,
            cash_credit: 0,
            check: 0,
            saved: false,
            down_payment: 0,
            finance_period: 0,
            product_doc_id: [],
            product_doc_url: [],
            product_doc_name: [],
            template_id: 0,
            template_name: '',
            signature_request: false,
        });
    };

    const removeSalesSlip = () => {
        window.location.reload()
        // console.log(props)
        // console.log(initialSalesData)
        // if(props.initialProduct.price == 0 || !props.initialProduct.price) {
        //     history.push('/applyApplicationSummary');
        // } else {
        //     let tempArr = props.salesDataOfProduct.filter((item) => item.type == props.initialProduct.product_type);
        //     console.log(tempArr)
        //     updateSalesDataOfProduct(tempArr[0], true);
        //     console.log(customer)
        //     let productArr = customer.product_info.filter((item) => item.product_type !== props.initialProduct.product_type);
        //     console.log(productArr)
        //     let temp_customer = {
        //         ...customer,
        //         product_info: productArr,
        //     };
        //     console.log(temp_customer);

        //     updateCustomer(history, '/applyApplicationSummary', temp_customer);
        //     clearsaleslip();
        

        // }
    };

    const handleScreenTabChange = (tab) => {
        console.log('tabchange');
        console.log(props.customer.salesData);
        // setSalesData(props.customer.salesData)
        updateSalesSlip({
            product_type: null,
            makemodel: null,
            price: 0,
            add_discount: 0,
            coupon: 0,
            tax: 0,
            cash_credit: 0,
            check: 0,
            saved: false,
            down_payment: 0,
            finance_period: 0,
            product_doc_id: [],
            product_doc_url: [],
            product_doc_name: [],
            template_id: 0,
            template_name: '',
            signature_request: false,
        });
        let temp_products = [];

        if (getParamsValue('salesslip')) {
            temp_products = customer.product_info;
            temp_products[getParamsValue('salesslip')] = initialProduct;
        } else {
            temp_products =
                customer.product_info !== undefined
                    ? [...customer.product_info, initialProduct]
                    : [initialProduct];
        }

        let temp_customer = {
            ...customer,
            product_info:
                temp_products &&
                temp_products.length > 0 &&
                temp_products.filter((item) => item.product_type != null) && temp_products.filter((item) => item.price > 0),
        };
        let data = temp_products.filter((item) => item.price > 0)
        console.table(data)
        console.log(temp_products.filter((item) => item.product_type != null) && temp_products.filter((item) => item.price > 0))
        console.log(temp_customer);
        console.log(temp_products);
        updateCustomer(history, '/applyApplicationSummary', temp_customer);
        clearsaleslip();
        history.push({
            pathname: '/applyApplicationSummary',
            search: '?tab=' + tab,
        });
    };

    const getDropdownOption = () => {
        let temp = [];
        let type = getParamsValue('type')
            ? getParamsValue('type').replaceAll('%20', ' ')
            : 'FOOD';
        if (type === 'Food/Mac') {
            let data =
                templates &&
                templates.filter((item) => item.product_type === 'FOOD');
            console.log(data);
            data &&
                data.map((item) => {
                    temp.push({
                        label: item.name,
                        value: item.id,
                    });
                });
        } else if (type === 'Full Service Program') {
            let data =
                templates &&
                templates.filter((item) => item.product_type === 'FSP');
            data &&
                data.map((item) => {
                    temp.push({
                        label: item.name,
                        value: item.id,
                    });
                });
        } else {
            let data =
                templates &&
                templates.filter((item) => item.product_type === 'OA');
            data &&
                data.map((item) => {
                    temp.push({
                        label: item.name,
                        value: item.id,
                    });
                });
        }
        return temp;
    };

    const changeState = (data) => {
        setSaveVisible(true);
        updateSalesSlip({
            ...initialProduct,
            ...data,
            saved: false,
            product_type: getParamsValue('type')
                ? getParamsValue('type').replaceAll('%20', ' ')
                : initialProduct.product_type,
        });
        console.log(data);
    };

    const handleSelectedSalesSlipScreen = (index, type) => {
        console.log(salesData);
        console.log(props.customer);
        updateSalesSlip(customer.product_info[index]);
        history.push({
            pathname: '/createSalesSlip',
            search: '?salesslip=' + index + '&type=' + type,
        });
    };

    const selectedDefaultTemplate = (id) => {
        let p_type = getParamsValue('type')
            ? getParamsValue('type').replaceAll('%20', ' ')
            : initialProduct.product_type;

        let temp_template = templates.filter((t) => t.id == id);

        if (temp_template.length > 0) {
            updateSalesSlip({
                ...initialProduct,
                ...temp_template[0],
                template_name:
                    temp_template[0].name && temp_template[0].name !== ''
                        ? temp_template[0].name
                        : `${p_type}-template-${temp_template[0].id}`,
                template_id: temp_template[0].id,
            });
        } else {
            updateSalesSlip({
                ...initialProduct,
                ...temp_template[0],
                template_name: '',
                template_id: 0,
            });
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        console.clear();
        const formData = getFromData(evt);
        console.log(salesData);
        let salesDataObj = {
            type: props.initialProduct.product_type,
            data: salesData,
        };
        console.log(salesDataObj);
        let totalArr = []
        console.log(totalObj)
        console.log(totalObj.length)
        for(var key in totalObj) {
            totalArr.push(totalObj[key])
        }
        console.log(totalArr.some((val) => val < 0))
        console.log(totalArr)
        console.log(totalArr.length)
        let doesContainNegativeValue = totalArr.some((val) => val < 0)
        console.log(totalArr.every((val) => val != 0))
        if(!doesContainNegativeValue) {
        updateSalesDataOfProduct(salesDataObj, false);

        setValidationResult(formData.validationResult);
        console.log(formData);
        console.log(formData.validationResult);

        // const res = {}
        // for (const key in formData) {
        //   res[key] = {};
        //   for (const prop in formData[key]) {
        //     const parsed = parseInt(formData[key][prop], 10);
        //     res[key][prop] = isNaN(parsed) ? formData[key][prop] : parsed;
        //   }
        // }
        // console.log(res)

        if (!formData.validationResult) {
            let temp_products = [];
            console.log('temp_proooo', temp_products);
            let hellosign = [];
            salesData.map((item, index) => {
                item.field_keys.map((data, idx) => {
                    console.log(data);
                    let text = data.hellosign_key;
                    console.log(text);
                    if (data.hellosign_key) {
                        hellosign.push({
                            [data.hellosign_key]:
                                data.value_source_table &&
                                data.value_source_table !== 'formula'
                                    ? getFlooredFixedTwoDigit(
                                          totalObj[data.hellosign_key],
                                          2
                                      )
                                    : data.hellosign_key &&
                                      typeof text == 'string' &&
                                      text.startsWith('quantity')
                                    ? (totalObj[data.hellosign_key] || "")
                                    : getFlooredFixedTwoDigit(
                                          data.default_value,
                                          2
                                      ),
                        });
                    }
                });
            });
            console.log(hellosign);
            let initialProduct = {
                balance: totalObj.balance,
                unpaid_balance: totalObj.unpaid_balance,
                product_type: props.initialProduct.product_type,
                makemodel: totalObj.quantity,
                price: totalObj.list_price,
                add_discount: totalObj.add_discount,
                coupon: totalObj.coupon,
                tax: totalObj.tax,
                cash_credit: totalObj.cash_credit,
                check: totalObj.check,
                saved: false,
                down_payment: totalObj.down_payment,
                finance_period: totalObj.finance_period,
                product_doc_id: props.initialProduct.product_doc_id,
                product_doc_url: props.initialProduct.product_doc_url,
                product_doc_name: props.initialProduct.product_doc_name,
                template_id: props.initialProduct.template_id,
                template_name: props.initialProduct.template_name,
                signature_request: props.initialProduct.signature_request,
                tax_per: totalObj.tax,
                template_id: props.initialProduct.template_id,
                template_name: props.initialProduct.template_name,
                hellosign: hellosign,
            };

            if (getParamsValue('salesslip')) {
                console.log('getParamsValue', getParamsValue('salesslip'));
                temp_products = customer.product_info;
                temp_products[getParamsValue('salesslip')] = initialProduct;
            } else {
                temp_products =
                    customer.product_info !== undefined
                        ? [...customer.product_info, initialProduct]
                        : [initialProduct];
            }

            console.log('hellosign:', hellosign);

            let temp_customer = {
                ...customer,
                product_info: temp_products,
                hellosign: hellosign,
                // salesData : salesData,
            };
            console.log(temp_customer);
            console.log(temp_products);
            console.log(totalObj);

            updateCustomer(history, '/applyApplicationSummary', temp_customer);
            clearsaleslip();
        } else {
            pushNotification(
                'The fields marked in Red need to be filled with appropriate data.',
                'error',
                'TOP_RIGHT',
                3000
            );
        }
    } else {
        pushNotification(
            'Please fill the correct values.',
            'error',
            'TOP_RIGHT',
            3000
        );
    }
    };

    const editableHandler = (salesItem, item) => {
        let arr = [...salesItem.field_keys];
        let netPriceObj = arr.find((data) => data.field_key == 'net_price');
        let tempData = arr.find((data) => data.id === item.id);
        tempData.toggle_disabled = !tempData.toggle_disabled;
        tempData.field_type = 'hidden';
        let tempData2 = arr.find(
            (data) => data.field_key == item.show_other_field
        );
        console.log(tempData2);
        tempData2.default_value = getFlooredFixedTwoDigit(
            (+tempData.value_source * +netPriceObj.default_value) / 100,
            2
        );
        console.log(tempData2[tempData['show_other_field']]);
        tempData2[tempData['show_other_field']] = tempData2.default_value;
        console.log(tempData2[tempData['show_other_field']]);
        tempData2.field_type = 'text';
        tempData2.toggle_disabled = true;
        console.log(salesData);
        setSalesData([...salesData]);
    };

    const doneEditableHandler = (salesItem, item) => {
        let arr = [...salesItem.field_keys];
        let tempData = arr.find((data) => data.id === item.id);
        tempData.toggle_disabled = true;
        tempData.field_type = 'hidden';
        let tempData2 = arr.find((data) => data.field_key == 'tax');
        console.log(tempData2);
        tempData2.field_type = 'text';
        tempData2.toggle_disabled = false;
        console.log(salesData);
        setSalesData([...salesData]);
    };

    let taxAmount = initialProduct.balance_amount - netPrice;
    let balanceAmount =
        initialProduct.price -
        initialProduct.total_discount +
        initialProduct.tax;

    return (
        <div className="sales sales-slip-container">
            {actionLoading && <Loader />}

            <Header isHome={true} history={history}>
                <img
                    src={IconArrowLeft}
                    alt="home"
                    className="icon-back"
                    onClick={() => handleScreenTabChange('summary_list')}
                />
                <img src={TCPLogo} alt="logo" className="main-logo" />
            </Header>

            <Modal
                show={openTemplateName}
                onHide={() => setOpenTemplateName(false)}
                autoFocus={false}
            >
                <Modal.Header closeButton> Template Name</Modal.Header>
                <Modal.Body className="fixed-height">
                    <Form.Group className="mb-18">
                        <Input
                            name="Template Name"
                            type="text"
                            label=""
                            defaultText="Template Name"
                            required={true}
                            error={{
                                empty: ' ',
                            }}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        class="btn secondary"
                        onClick={() => setOpenTemplateName(false)}
                    >
                        Save
                    </button>
                    <button
                        class="btn secondary"
                        onClick={() => setOpenTemplateName(false)}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            <div className="sub-header">
                <button
                    className={`btn`}
                    onClick={() => handleScreenTabChange('profile')}
                >
                    <img src={IconContactAcitve} alt="" />
                </button>

                {customer.product_info === undefined ||
                customer.product_info.length === 0 ? (
                    <button style={{ minWidth: 238 }} className="btn active">
                        {getParamsValue('type') ? (
                            <span>
                                {getParamsValue('type')
                                    ? `${getParamsValue('type').replaceAll(
                                          '%20',
                                          ' '
                                      )} $${netPrice}`
                                    : 'Product/Service'}
                            </span>
                        ) : (
                            <span>{`${initialProduct.product_type} $${initialProduct.price}`}</span>
                        )}
                        <span className="arrow-down"></span>
                    </button>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            minWidth: 238,
                            overflow: 'auto',
                        }}
                        className="multiple-serviceslider-btn"
                    >
                        {customer.product_info.length > 0 &&
                            customer.product_info.map((p, index) => {
                                console.log('p=', p, index);
                                // let total =
                                //     p.price +
                                //     p.tax -
                                //     (p.add_discount +
                                //         p.coupon +
                                //         p.cash_credit +
                                //         p.check +
                                //         p.down_payment);
                                let total = p.unpaid_balance;

                                return (
                                    <button
                                        className={`btn ${
                                            index == getParamsValue('salesslip')
                                                ? 'active'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            handleSelectedSalesSlipScreen(
                                                index,
                                                p.product_type
                                            )
                                        }
                                    >
                                        {/* <span>{`${p.product_type} $${
                                            p.balance_amount == 0 ||
                                            p.balance_amount == null
                                                ? 0
                                                : p.balance_amount.toFixed(2)
                                        }`}</span> */}
                                        <span>{`${
                                            p.product_type
                                        } $${getFlooredFixedTwoDigit(
                                            p.unpaid_balance || p.balance_amount || total,
                                            2
                                        )}`}</span>
                                        {
                                             index == getParamsValue('salesslip') &&  customer.product_info.length == 1 && <span className="arrow-down"></span>
                                        }
                                       
                                    </button>
                                );
                            })}
                    </div>
                )}

                {(appFillStatus === 'in_app' ||
                    appFillStatus === 'send_link' ||
                    appFillStatus === 'in_app_with_salesslip') && (
                    <button
                        className={`btn`}
                        onClick={() => handleScreenTabChange('credit_details')}
                        style={{  marginLeft :  customer?.product_info?.length > 0 ? '2.3px' : 0}}
                        // style={{marginRight : activeTab === 'credit_details' && '2.3px', marginLeft : activeTab !== 'credit_details' && '2.3px'}}
                    >
                        <img src={IconListWhite} alt="" />
                    </button>
                )}
            </div>
            <form
                action="javascript:void(0)"
                onSubmit={(e) => handleSubmit(e)}
                noValidate
            >
                <div
                    className="container summary-container"
                    style={{ marginBottom: 0 }}
                >
                    <div className="styled-form">
                        {/* <div className="styled-row">
                            <Form.Group
                                className="styled-column mb-18"
                                style={{ width: '100%' }}
                            >
                                <Dropdown
                                    name="template"
                                    type="dropdown"
                                    label="Select template"
                                    defaultText="Select template"
                                    defaultValue={initialProduct.template_id}
                                    options={getDropdownOption()}
                                    handleChange={(e) =>
                                        selectedDefaultTemplate(e)
                                    }
                                />
                            </Form.Group>
                        </div> */}
                        <div
                            className="styled-row xs-sm-styled-row"
                            style={{ flexWrap: 'wrap' }}
                        >
                            <>
                                {
                                    // (!props.initialProduct.price || props.initialProduct.price == 0) && salesData.length > 0 &&
                                    salesData.map((salesItem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <h1
                                                    style={{
                                                        width: '100%',
                                                        color: '#ffffff',
                                                        fontSize: 18,
                                                        marginTop: 20,
                                                    }}
                                                >
                                                    {salesItem.group_label ||
                                                        ''}
                                                </h1>
                                                {salesItem.group_name ==
                                                    'Quantity Tool' && (
                                                    <div
                                                        className="quantity-master-div"
                                                        style={{
                                                            width: '100%',
                                                            height: 230,
                                                        }}
                                                    >
                                                        {salesItem.group_name ==
                                                            'Quantity Tool' && (
                                                            <div
                                                                className="quantity-master-div-header-label"
                                                                style={{
                                                                    marginTop: 65,
                                                                }}
                                                            >
                                                                {salesItem.field_keys.map(
                                                                    (
                                                                        field,
                                                                        i
                                                                    ) => {
                                                                        let bottom = 3;
                                                                        return (
                                                                            <Form.Group
                                                                                className={`quantity-inputs-100`}
                                                                                key={
                                                                                    field.id
                                                                                }
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                    bottom:
                                                                                        i >
                                                                                        1
                                                                                            ? bottom *
                                                                                              i
                                                                                            : 3,
                                                                                    marginBottom: 0,
                                                                                }}
                                                                            >
                                                                                {/* <h1>{field.id}</h1> */}
                                                                                <input
                                                                                    // ref={ref}

                                                                                    name={
                                                                                        field.field_key
                                                                                    }
                                                                                    key={
                                                                                        field.id
                                                                                    }
                                                                                    style={{
                                                                                        height: 16,
                                                                                        borderRadius:
                                                                                            '0 !important',
                                                                                        // border : '1.5px solid #6793b7',
                                                                                        background:
                                                                                            'transparent',
                                                                                        border: 'none',
                                                                                        maxWidth: 360,
                                                                                        float: 'right',
                                                                                        marginBottom:
                                                                                            i >=
                                                                                            3
                                                                                                ? 15
                                                                                                : 10,
                                                                                        color: '#bbbbbb',
                                                                                    }}
                                                                                    type={
                                                                                        field.field_type ==
                                                                                        'hidden'
                                                                                            ? 'hidden'
                                                                                            : 'text'
                                                                                    }
                                                                                    value={
                                                                                        field.default_value
                                                                                    }
                                                                                    label={
                                                                                        field.label
                                                                                    }
                                                                                    isAmount={
                                                                                        field.field_type ==
                                                                                        'hidden'
                                                                                            ? false
                                                                                            : field.field_label_prefix ==
                                                                                              '$'
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        itemModalChangeHandler(
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                            salesItem,
                                                                                            field
                                                                                        )
                                                                                    }
                                                                                    // defaultText={
                                                                                    //     field.value_source ||
                                                                                    //     field.default_value ||
                                                                                    //     0
                                                                                    // }
                                                                                    // isPercent={
                                                                                    //     item.field_label_prefix ==
                                                                                    //     '%'
                                                                                    //         ? true
                                                                                    //         : false
                                                                                    // }
                                                                                    // disabled={item.value_source_table ? true : false}
                                                                                    // value={filterSalesDataProduct && item.default_value}
                                                                                    // disabled={
                                                                                    //     item.toggle_disabled
                                                                                    // }
                                                                                    // required={
                                                                                    //     validateValue &&
                                                                                    //     validateValue.required
                                                                                    // }
                                                                                    // regex={
                                                                                    //     item.field_key ==
                                                                                    //     'coupon'
                                                                                    //         ? validateValue &&
                                                                                    //           validateValue.regex
                                                                                    //         : null
                                                                                    // }
                                                                                    // validationResult={
                                                                                    //     validationResult
                                                                                    // }
                                                                                    // error={{
                                                                                    //     empty: ' ',
                                                                                    //     invalid:
                                                                                    //         ' ',
                                                                                    // }}
                                                                                    // onChange={(e, i) =>

                                                                                    //     var temp = salesValue;
                                                                                    //     salesValue[i].

                                                                                    //     getValue(e, i)}
                                                                                />
                                                                            </Form.Group>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {salesItem.group_name !==
                                                    'Quantity Tool' && (
                                                    <React.Fragment>
                                                        {salesItem.field_keys.map(
                                                            (item, index) => {
                                                                if (
                                                                    item.field_type ==
                                                                    'list'
                                                                ) {
                                                                    console.log(
                                                                        'item',
                                                                        item
                                                                    );
                                                                    let data =
                                                                        JSON.parse(
                                                                            item.data
                                                                        );
                                                                    console.log(
                                                                        'data',
                                                                        data
                                                                    );
                                                                    let financeData =
                                                                        [];
                                                                    console.log(
                                                                        Object.keys(
                                                                            data
                                                                        ).map(
                                                                            (
                                                                                item,
                                                                                index
                                                                            ) => {
                                                                                console.log(
                                                                                    item
                                                                                );
                                                                                if (
                                                                                    item ==
                                                                                        'Food' &&
                                                                                    props
                                                                                        .initialProduct
                                                                                        .product_type ==
                                                                                        'Food/Mac'
                                                                                ) {
                                                                                    console.log(
                                                                                        'item',
                                                                                        data[
                                                                                            item
                                                                                        ]
                                                                                    );
                                                                                    financeData.push(
                                                                                        data[
                                                                                            item
                                                                                        ]
                                                                                    );
                                                                                }
                                                                                if (
                                                                                    item ==
                                                                                        'other' &&
                                                                                    props
                                                                                        .initialProduct
                                                                                        .product_type !==
                                                                                        'Food/Mac'
                                                                                ) {
                                                                                    console.log(
                                                                                        'item',
                                                                                        data[
                                                                                            item
                                                                                        ]
                                                                                    );
                                                                                    financeData.push(
                                                                                        data[
                                                                                            item
                                                                                        ]
                                                                                    );
                                                                                }
                                                                                // financeData.push({
                                                                                //     value: item,
                                                                                //     label: data[item]
                                                                                // })
                                                                            }
                                                                        )
                                                                    );
                                                                    return (
                                                                        <div
                                                                            id="month-dropdown"
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <label>
                                                                                {
                                                                                    item.field_label
                                                                                }
                                                                            </label>

                                                                            <Form.Group className="styled-column mb-18">
                                                                                <Dropdown
                                                                                    name={
                                                                                        item.field_key
                                                                                    }
                                                                                    type="number"
                                                                                    label={
                                                                                        item.field_label
                                                                                    }
                                                                                    defaultText="State"
                                                                                    defaultValue={
                                                                                        item.default_value
                                                                                    }
                                                                                    value={
                                                                                        item.default_value
                                                                                    }
                                                                                    options={
                                                                                        financeData[0]
                                                                                    }
                                                                                    handleChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        changeHandler(
                                                                                            e,
                                                                                            salesItem,
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                    required={
                                                                                        item
                                                                                            .field_validation
                                                                                            .required
                                                                                    }
                                                                                    validationResult={
                                                                                        validationResult
                                                                                    }
                                                                                    error={{
                                                                                        empty: ' ',
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                        </div>
                                                                    );
                                                                }
                                                                if (
                                                                    item.value_source_table !=
                                                                    'formula'
                                                                ) {
                                                                    let validateValue =
                                                                        item.field_validation;

                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            style={{
                                                                                width:
                                                                                    item.field_label ==
                                                                                        'quantity' &&
                                                                                    '100%',
                                                                            }}
                                                                            className={`${
                                                                                item.field_label ==
                                                                                'Tax'
                                                                                    ? 'p-relative left-10'
                                                                                    : ''
                                                                            }`}
                                                                        >
                                                                            {item.field_label !=
                                                                                'quantity' &&
                                                                                item.field_type !=
                                                                                    'hidden' && (
                                                                                    <label
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            flexWrap:
                                                                                                'wrap',
                                                                                            justifyContent:
                                                                                                'space-between',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item.field_label
                                                                                        }
                                                                                        <span
                                                                                            onClick={() =>
                                                                                                editableHandler(
                                                                                                    salesItem,
                                                                                                    item
                                                                                                )
                                                                                            }
                                                                                            style={{
                                                                                                color: '#fff',
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        >
                                                                                            {item.allow_edit &&
                                                                                                'Edit'}
                                                                                        </span>
                                                                                    </label>
                                                                                )}
                                                                            {/* <br /> */}
                                                                            {item.field_label ==
                                                                                'quantity' && (
                                                                                <Form.Group className="mb-18 sales-slip-textarea good-or-services-2">
                                                                                    <textarea
                                                                                        name={
                                                                                            item.field_key
                                                                                        }
                                                                                        defaultText={
                                                                                            item.value_source ||
                                                                                            item.default_value ||
                                                                                            0
                                                                                        }
                                                                                        disabled={
                                                                                            item.value_source_table
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            changeHandler(
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                                salesItem,
                                                                                                item
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                            {item.field_label !=
                                                                                'quantity' &&
                                                                                item.field_key ==
                                                                                    'tax' && (
                                                                                    <Form.Group className="styled-column mb-18">
                                                                                        <Input
                                                                                            name={
                                                                                                item.field_key
                                                                                            }
                                                                                            key={
                                                                                                item.id
                                                                                            }
                                                                                            type={
                                                                                                item.field_type ==
                                                                                                'hidden'
                                                                                                    ? 'hidden'
                                                                                                    : 'text'
                                                                                            }
                                                                                            label={
                                                                                                item.label
                                                                                            }
                                                                                            isAmount={
                                                                                                item.field_type ==
                                                                                                'hidden'
                                                                                                    ? false
                                                                                                    : item.field_label_prefix ==
                                                                                                      '$'
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            value={
                                                                                                item.value_source ||
                                                                                                item.default_value ||
                                                                                                0
                                                                                            }
                                                                                            // defaultText={
                                                                                            //     item.default_value ||
                                                                                            //     item.value_source ||
                                                                                            //     0
                                                                                            // }
                                                                                            isPercent={
                                                                                                item.field_label_prefix ==
                                                                                                '%'
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            // disabled={item.value_source_table ? true : false}
                                                                                            // value={filterSalesDataProduct && item.default_value}
                                                                                            disabled={
                                                                                                item.toggle_disabled
                                                                                            }
                                                                                            required={
                                                                                                validateValue &&
                                                                                                validateValue.required
                                                                                            }
                                                                                            // regex={
                                                                                            //     item.field_key ==
                                                                                            //     'coupon'
                                                                                            //         ? validateValue &&
                                                                                            //           validateValue.regex
                                                                                            //         : null
                                                                                            // }
                                                                                            // error={{
                                                                                            //     empty: ' ',
                                                                                            //     invalid:
                                                                                            //         ' ',
                                                                                            // }}
                                                                                            // error={validateValue && validateValue.regex &&  {invalid : ''}}
                                                                                            // validationResult={
                                                                                            //     validationResult
                                                                                            // }
                                                                                            // value={item.type !== 'formula' ?  item.value : totalSome}
                                                                                            handleChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                changeHandler(
                                                                                                    +e
                                                                                                        .target
                                                                                                        .value,
                                                                                                    salesItem,
                                                                                                    item
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </Form.Group>
                                                                                )}
                                                                            {item.field_label !=
                                                                                'quantity' &&
                                                                                item.field_key !=
                                                                                    'tax' &&
                                                                                (!props
                                                                                    .initialProduct
                                                                                    .price ||
                                                                                    props
                                                                                        .initialProduct
                                                                                        .price ==
                                                                                        0) && (
                                                                                    <Form.Group
                                                                                        className={`styled-column mb-18`}
                                                                                    >
                                                                                        <Input
                                                                                            name={
                                                                                                item.field_key
                                                                                            }
                                                                                            key={
                                                                                                item.id
                                                                                            }
                                                                                            type={
                                                                                                item.field_type ==
                                                                                                'hidden'
                                                                                                    ? 'hidden'
                                                                                                    : 'text'
                                                                                            }
                                                                                            label={
                                                                                                item.label
                                                                                            }
                                                                                            isAmount={
                                                                                                item.field_type ==
                                                                                                'hidden'
                                                                                                    ? false
                                                                                                    : item.field_label_prefix ==
                                                                                                      '$'
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            defaultText={
                                                                                                item.value_source ||
                                                                                                item.default_value ||
                                                                                                0
                                                                                            }
                                                                                            isPercent={
                                                                                                item.field_label_prefix ==
                                                                                                '%'
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            // disabled={item.value_source_table ? true : false}
                                                                                            // value={filterSalesDataProduct && item.default_value}
                                                                                            disabled={
                                                                                                item.toggle_disabled
                                                                                            }
                                                                                            required={
                                                                                                validateValue &&
                                                                                                validateValue.required
                                                                                            }
                                                                                            regex={
                                                                                                item.field_key ==
                                                                                                'coupon'
                                                                                                    ? validateValue &&
                                                                                                      validateValue.regex
                                                                                                    : null
                                                                                            }
                                                                                            validationResult={
                                                                                                validationResult
                                                                                            }
                                                                                            error={{
                                                                                                empty: ' ',
                                                                                                invalid:
                                                                                                    ' ',
                                                                                            }}
                                                                                            handleChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                changeHandler(
                                                                                                    +e
                                                                                                        .target
                                                                                                        .value,
                                                                                                    salesItem,
                                                                                                    item
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </Form.Group>
                                                                                )}
                                                                            {(props
                                                                                .initialProduct
                                                                                .price ||
                                                                                props
                                                                                    .initialProduct
                                                                                    .price >
                                                                                    0) &&
                                                                                item.field_label !=
                                                                                    'quantity' &&
                                                                                item.field_key !=
                                                                                    'tax' && (
                                                                                    <Form.Group className="styled-column mb-18">
                                                                                        <Input
                                                                                            name={
                                                                                                item.field_key
                                                                                            }
                                                                                            key={
                                                                                                item.id
                                                                                            }
                                                                                            type={
                                                                                                item.field_type ==
                                                                                                'hidden'
                                                                                                    ? 'hidden'
                                                                                                    : 'text'
                                                                                            }
                                                                                            label={
                                                                                                item.label
                                                                                            }
                                                                                            isAmount={
                                                                                                item.field_type ==
                                                                                                'hidden'
                                                                                                    ? false
                                                                                                    : item.field_label_prefix ==
                                                                                                      '$'
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            value={
                                                                                                item.value_source ||
                                                                                                item.default_value ||
                                                                                                0
                                                                                            }
                                                                                            isPercent={
                                                                                                item.field_label_prefix ==
                                                                                                '%'
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            disabled={
                                                                                                item.toggle_disabled
                                                                                            }
                                                                                            required={
                                                                                                validateValue &&
                                                                                                validateValue.required
                                                                                            }
                                                                                            regex={
                                                                                                item.field_key ==
                                                                                                'coupon'
                                                                                                    ? validateValue &&
                                                                                                      validateValue.regex
                                                                                                    : null
                                                                                            }
                                                                                            validationResult={
                                                                                                validationResult
                                                                                            }
                                                                                            error={{
                                                                                                empty: ' ',
                                                                                                invalid:
                                                                                                    ' ',
                                                                                            }}
                                                                                            // error={validateValue && validateValue.regex &&  {invalid : ''}}
                                                                                            // validationResult={
                                                                                            //     validationResult
                                                                                            // }
                                                                                            // value={item.type !== 'formula' ?  item.value : totalSome}
                                                                                            handleChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                changeHandler(
                                                                                                    +e
                                                                                                        .target
                                                                                                        .value,
                                                                                                    salesItem,
                                                                                                    item
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </Form.Group>
                                                                                )}
                                                                        </div>
                                                                        // <div key={index}>
                                                                        //   <label>{item.field_label}</label>
                                                                        //   <input
                                                                        //     key={item.id}
                                                                        //     type="text"
                                                                        //     defaultValue={item.default_value || 0}
                                                                        //     onChange={(e) => changeHandler(e.target.value, salesItem, item)}
                                                                        //   />
                                                                        // </div>
                                                                    );
                                                                }

                                                                if (
                                                                    item.value_source_table ==
                                                                    'formula'
                                                                ) {
                                                                    let ref1 =
                                                                        item.ref1;
                                                                    let ref2 =
                                                                        item.ref2;
                                                                    let cssObj =
                                                                        item.css &&
                                                                        JSON.parse(
                                                                            item.css
                                                                        );
                                                                        console.log('cssObj',cssObj)
                                                                        console.log('itemcss',item)
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className={`${
                                                                                item.field_label ==
                                                                                'Balance'
                                                                                    ? 'p-relative'
                                                                                    : ''
                                                                            } ${
                                                                                item.field_key ==
                                                                                'unpaid_balance'
                                                                                    ? 'w-100'
                                                                                    : ''
                                                                            } ${item.field_key == 'monthly_minimum' ? 'monthl_minimum_container' : ''}`}
                                                                        >
                                                                            {item.field_type !==
                                                                                'hidden' && (
                                                                                <label
                                                                                    style={{
                                                                                        display:
                                                                                            'flex',
                                                                                        flexWrap:
                                                                                            'wrap',
                                                                                        justifyContent:
                                                                                            cssObj
                                                                                                ? cssObj.justifyContent
                                                                                                : 'space-between',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item.field_label
                                                                                    }
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            doneEditableHandler(
                                                                                                salesItem,
                                                                                                item
                                                                                            )
                                                                                        }
                                                                                        style={{
                                                                                            color: '#fff',
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                    >
                                                                                        {item.allow_edit &&
                                                                                            item.value_source_table ===
                                                                                                'formula' &&
                                                                                            'Done'}
                                                                                    </span>
                                                                                </label>
                                                                            )}

                                                                            <Form.Group
                                                                                className="styled-column mb-18"
                                                                                style={
                                                                                    cssObj && {
                                                                                        width: cssObj?.width,
                                                                                        justifyContent:
                                                                                            cssObj?.justifyContent,
                                                                                        display:
                                                                                            cssObj?.display,
                                                                                        flexWrap:
                                                                                            cssObj?.flexWrap,
                                                                                    }
                                                                                }
                                                                            >
                                                                                <Input
                                                                                    name={
                                                                                        item.name
                                                                                    }
                                                                                    key={
                                                                                        item.id
                                                                                    }
                                                                                    type={
                                                                                        item.field_type ==
                                                                                        'hidden'
                                                                                            ? 'hidden'
                                                                                            : 'text'
                                                                                    }
                                                                                    label={
                                                                                        item.label
                                                                                    }
                                                                                    isAmount={
                                                                                        item.field_type ==
                                                                                        'hidden'
                                                                                            ? false
                                                                                            : true
                                                                                    }
                                                                                    disabled={
                                                                                        !item.toggle_disabled
                                                                                    }
                                                                                    defaultText={
                                                                                        getFlooredFixedTwoDigit(
                                                                                            item.default_value,
                                                                                            2
                                                                                        )
                                                                                    }
                                                                                    // value={item.type !== 'formula' ?  item.value : totalSome}
                                                                                    // regex="^[0-9][\w\.\d]{0,20}$"
                                                                                    handleChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        changeHandler(
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                            salesItem,
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                    ref1={
                                                                                        ref1
                                                                                    }
                                                                                    ref2={
                                                                                        ref2
                                                                                    }
                                                                                />
                                                                            </Form.Group>
                                                                        </div>

                                                                        // <div key={item.field_key}>
                                                                        //   <input  key={item.id} type="text" defaultValue={item.default_value}  onChange={(e) => changeHandler(e, salesItem, item)} />
                                                                        // </div>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </>
                            {/* {manualData.FormGroup_1.map((item, index) => {
                                console.log(item);
                            //     console.log(Object.keys(item))
                            //     console.log(item['listPrice' + item.id])
                               let totalSome = 0
                                if(item.type == 'formula') {
                                    let some = item.formula && item.formula.split(" + ")
                                    console.log(some[0])
                                    console.log(some, some.includes(some[index]))
                                    let temp = manualData.FormGroup_1.filter((item, index) =>item.name == some[index])
                                    console.log(temp)
                                    totalSome = temp.reduce(function(acc,curr){ return acc + curr.value}, 0)
                                    // for(let i=0;i<temp.length;i++) {
                                    //     totalSome += temp[i][some[i]] 
                                    // }
                                    console.log(totalSome)
                                }
                                return (
                                    <Form.Group
                                        className="styled-column mb-18"
                                        key={item.id}
                                    >
                                        <Input
                                            name={item.name}
                                            type="number"
                                            label={item.label}
                                            isAmount={true}
                                            defaultText="0"
                                            disabled = {item.name === 'total'}
                                            value={item.type !== 'formula' ?  item.value : totalSome}
                                            regex="^[0-9][\w\.\d]{0,20}$"
                                            handleChange={(e) =>
                                                customChangeHandler(e,item)
                                            }
                                        />
                                    </Form.Group>
                                );
                            })} */}
                        </div>
                    </div>
                </div>
                <div className="footer-container">
                    {getParamsValue('type') &&
                    getParamsValue('salesslip') === undefined ? (
                        (console.log(
                            getParamsValue('type'),
                            getParamsValue('salesslip')
                        ),
                        (
                            <>
                                <button
                                    onClick={() => {
                                        removeSalesSlip();
                                        clearsaleslip();
                                    }}
                                    className="btn secondary clear-btn-sales"
                                    type="button"
                                >
                                    Clear
                                </button>
                                <button className="btn secondary clear-btn-sales" type="submit">
                                    Add to Order
                                </button>
                            </>
                        ))
                    ) : isSaveVisible ? (
                        <>
                            {' '}
                            <button
                                onClick={() => {
                                    removeSalesSlip();
                                    clearsaleslip();
                                }}
                                className="btn secondary clear-btn-sales"
                                type="button"
                            >
                                Clear
                            </button>{' '}
                            <button className="btn secondary clear-btn-sales" type="submit">
                                Add to Order
                            </button>
                            {/* <button className="btn secondary" type="submit">
                                Add to Order
                            </button> */}
                        </>
                    ) : (
                        <button
                            disabled
                            className="btn secondary next-btn-sales"
                            type="button"
                        >
                            Add to Order
                        </button>
                    )}
                </div>
                <div className="slip-summary d-flex flex-wrap justify-content-between align-items-end">
                    <div>
                    Total<br></br>
                            Price:{' '}
                            {totalObj.net_price
                                ? getFlooredFixedTwoDigit(totalObj.net_price, 2)
                                : 0}
                    </div>
                    <div>
                    Unpaid:{' '}
                            {totalObj.unpaid_balance
                                ? getFlooredFixedTwoDigit(
                                      totalObj.unpaid_balance,
                                      2
                                  )
                                : 0}
                    </div>
                    <div>
                    Montly:{' '}
                            {totalObj.monthly_minimum
                                ? getFlooredFixedTwoDigit(
                                      totalObj.monthly_minimum,
                                      2
                                  )
                                : 0}
                    </div>
                    {/* <div className="row" style={{ alignItems: 'flex-end' }}>
                        <div className="col-4">
                            Total<br></br>
                            Price:{' '}
                            {totalObj.net_price
                                ? getFlooredFixedTwoDigit(totalObj.net_price, 2)
                                : 0}
                        </div>
                        <div className="col-4">
                            <span>
                            Unpaid:{' '}
                            {totalObj.unpaid_balance
                                ? getFlooredFixedTwoDigit(
                                      totalObj.unpaid_balance,
                                      2
                                  )
                                : 0}
                                </span>
                        </div>
                        <div className="col-4">
                            <span>
                            Montly:{' '}
                            {totalObj.monthly_minimum
                                ? getFlooredFixedTwoDigit(
                                      totalObj.monthly_minimum,
                                      2
                                  )
                                : 0}
                                </span>
                        </div>
                    </div> */}
                </div>
            </form>

            {/* <form
                action="javascript:void(0)"
                onSubmit={(e) => handleSubmit(e)}
                noValidate
            >
                <div
                    className="container summary-container"
                    style={{ marginBottom: 0 }}>
                    <div className="styled-form">
                        <div className="styled-row"> */}
            {/* <button className="custom-btn red">
                                <img src={IconDeleteNew} alt="view"></img>
                            </button> */}
            {/* <Form.Group
                                className="styled-column mb-18"
                                style={{ width: '100%' }}
                            >
                                <Dropdown
                                    name="template"
                                    type="dropdown"
                                    label="Select template"
                                    defaultText="Select template"
                                    defaultValue={initialProduct.template_id}
                                    options={getDropdownOption()}
                                    handleChange={(e) =>
                                        selectedDefaultTemplate(e)
                                    }
                                />
                            </Form.Group> */}
            {/* <button className="custom-btn add-btn" onClick={() => setOpenTemplateName(true)}>+</button> */}
            {/* </div>
                        <Form.Group className="mb-18 sales-slip-textarea good-or-services-2">
                            <textarea
                                name="makemodel"
                                value={
                                    initialProduct.makemodel
                                        ? initialProduct.makemodel
                                        : ''
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if ((value.match(/\n/g) || []).length < 6) {
                                        changeState({ makemodel: value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <div className="styled-row">
                            <Form.Group className="styled-column mb-18">
                                <Input
                                    name="price"
                                    type="number"
                                    label="List Price:"
                                    isAmount={true}
                                    defaultText="0"
                                    value={initialProduct.price}
                                    regex="^[0-9][\w\.\d]{0,20}$"
                                    required={true}
                                    error={{
                                        empty: ' ',
                                        invalid: ' ',
                                    }}
                                    optionalParams={{ style: { width: 98 } }}
                                    validationResult={validationResult}
                                    handleChange={(e) =>
                                        changeState({
                                            price:
                                                e.target.value !== ''
                                                    ? Number(e.target.value)
                                                    : 0,
                                        })
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="styled-column mb-18">
                                <Input
                                    name="add_discount"
                                    type="text"
                                    label="Add. discount"
                                    isAmount={true}
                                    defaultText="0"
                                    value={initialProduct.add_discount}
                                    regex="^[0-9][\w\.\d]{0,20}$"
                                    required={true}
                                    error={{
                                        empty: ' ',
                                        invalid: ' ',
                                    }}
                                    optionalParams={{ style: { width: 98 } }}
                                    validationResult={validationResult}
                                    handleChange={(e) =>
                                        changeState({
                                            add_discount:
                                                e.target.value != ''
                                                    ? Number(e.target.value)
                                                    : 0,
                                        })
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="styled-column mb-18">
                            <Input
                                name="coupon"
                                type="text"
                                defaultValue={Number(initialProduct.coupon)}
                                label="Coupon"
                                defaultText="0"
                                regex="^([0-9]|[1-9][0-9]|[1-4][0-9]{2}|500)$"s
                                className="small-input"
                                required={true}
                                optionalParams={{ style: { width: 98 } }}
                                error={{
                                    'empty': " ",
                                    'invalid': " "
                                }}
                                validationResult={validationResult}
                             handleChange={(e) =>
                                        changeState({
                                            coupon:
                                                e.target.value != ''
                                                    ? Number(e.target.value)
                                                    : 0,
                                        })
                                    }
                            />
                            </Form.Group>
                        </div>
                        <div className="styled-row">
                            <Form.Group className="styled-column mb-18">
                                <Input
                                    name="netPrice"
                                    type="number"
                                    label="Net Price"
                                    isAmount={true}
                                    disabled={true}
                                    required={true}
                                    defaultText="0"
                                    value={parseInt(netPrice).toFixed(2)}
                                    optionalParams={{ style: { width: 98 } }}
                                />
                            </Form.Group>
                            <Form.Group className="styled-column mb-18">
                                {!isTaxEdit ? (
                                    <Input
                                        name="tax"
                                        type="number"
                                        label={`Tax: ${
                                            initialProduct.tax_per
                                                ? initialProduct.tax_per
                                                : tax
                                        }%`}
                                        isAmount={true}
                                        disabled={true}
                                        isEditButtonText="Edit"
                                        isEditButton={true}
                                        isEditAction={() =>
                                            setTaxEdit(!isTaxEdit)
                                        }
                                        defaultText={taxAmount}
                                        value={initialProduct.tax}
                                        required={true}
                                        optionalParams={{
                                            style: { width: 98 },
                                        }}
                                    />
                                ) : (
                                    <Input
                                        name="tax"
                                        type="number"
                                        label="Tax"
                                        isPercent={true}
                                        isEditButtonText="Save"
                                        isEditButton={true}
                                        isEditAction={() =>
                                            setTaxEdit(!isTaxEdit)
                                        }
                                        defaultText="0"
                                        value={
                                            initialProduct.tax_per
                                                ? initialProduct.tax_per
                                                : editTax
                                        }
                                        required={true}
                                        optionalParams={{
                                            style: { width: 98 },
                                        }}
                                        handleChange={(e) => {
                                            setEditTax(e.target.value);
                                            changeState({
                                                tax_per: e.target.value,
                                            });
                                        }}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className="styled-column mb-18">
                                <Input
                                    name="balance"
                                    type="number"
                                    label="Balance"
                                    isAmount={true}
                                    defaultText={balanceAmount}
                                    disabled={true}
                                    value={balanceAmount}
                                    required={true}
                                    optionalParams={{ style: { width: 98 } }}
                                />
                            </Form.Group>
                        </div>
                        <label className="title-text" style={{ marginTop: 15 }}>
                            DEPOSIT AMOUNTS
                        </label>
                        <div className="styled-row">
                            <Form.Group className="styled-column mb-18">
                                <Input
                                    name="cash_credit"
                                    type="number"
                                    label="Cash/Credit Card:"
                                    isAmount={true}
                                    value={initialProduct.cash_credit}
                                    defaultText="0"
                 defaultValue={initialProduct.cash_credit}
                                    regex="^[0-9][\w\.\d]{0,20}$"
                                    required={true}
                                    error={{
                                        empty: ' ',
                                        invalid: ' ',
                                    }}
                                    optionalParams={{ style: { width: 98 } }}
                                    validationResult={validationResult}
                                    handleChange={(e) =>
                                        changeState({
                                            cash_credit:
                                                e.target.value != ''
                                                    ? Number(e.target.value)
                                                    : 0,
                                        })
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="styled-column mb-18">
                                <Input
                                    name="check"
                                    type="number"
                                    label="Check"
                                    isAmount={true}
                                    value={initialProduct.check}
                                    defaultText="0"
                                    defaultValue={initialProduct.cash_credit}
                                    regex="^[0-9][\w\.\d]{0,20}$"
                                    required={true}
                                    error={{
                                        empty: ' ',
                                        invalid: ' ',
                                    }}
                                    optionalParams={{ style: { width: 98 } }}
                                    validationResult={validationResult}
                                    handleChange={(e) =>
                                        changeState({
                                            check:
                                                e.target.value != ''
                                                    ? Number(e.target.value)
                                                    : 0,
                                        })
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="styled-column mb-18">
                                <Input
                                    name="down_payment"
                                    type="number"
                                    label="Down Payment"
                                    isAmount={true}
                                    value={initialProduct.down_payment}
                                    defaultText="0"
                                    defaultValue={initialProduct.down_payment}
                                    regex="^[0-9][\w\.\d]{0,20}$"
                                    required={true}
                                    error={{
                                        empty: ' ',
                                        invalid: ' ',
                                    }}
                                    optionalParams={{ style: { width: 98 } }}
                                    validationResult={validationResult}
                                    handleChange={(e) =>
                                        changeState({
                                            down_payment:
                                                e.target.value != ''
                                                    ? Number(e.target.value)
                                                    : 0,
                                        })
                                    }
                                />
                            </Form.Group>
                        </div>
                        <div
                            className="styled-row single-item"
                            style={{ marginTop: 25, marginBottom: 15 }}
                        >
                            <label style={{ fontSize: 12 }}>
                                Unpaid Balance
                            </label>
                            <Form.Group className="styled-column mb-18">
                                <Input
                                    name="unpaid_balance"
                                    type="number"
                                    label=""
                                    isAmount={true}
                                    disabled={true}
                                    value={unPaidBalance}
                                    defaultText="0"
                                    required={true}
                                    optionalParams={{ style: { width: 98 } }}
                                />
                            </Form.Group>
                        </div>
                        <div id="month-dropdown" className="styled-row">
                            <Form.Group className="styled-column mb-18">
                                <Dropdown
                                    name="finance_period"
                                    type="number"
                                    label="Finance Period"
                                    required={true}
                                    defaultText="State"
                                    defaultValue={initialProduct.finance_period}
                                    value={initialProduct.finance_period}
                                    options={financePeriod}
                                    handleChange={(e) =>
                                        changeState({
                                            finance_period: Number(e),
                                        })
                                    }
                                    validationResult={validationResult}
                                    error={{
                                        empty: ' ',
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="styled-column mb-18">
                                <Input
                                    name="monthly_minimum"
                                    type="number"
                                    label="Monthly Minimum"
                                    isAmount={true}
                                    defaultText="0"
                                    disabled={true}
                                    value={monthlyMinimum}
                                    regex="^[0-9][\w\.\d]{0,20}$"
                                    required={true}
                                    error={{
                                        empty: ' ',
                                        invalid: ' ',
                                    }}
                                    validationResult={validationResult}
                                    optionalParams={{ style: { width: 112 } }}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
                
                <div className="footer-container">
                    {getParamsValue('type') &&
                    getParamsValue('salesslip') === undefined ? (
                        console.log(getParamsValue('type'), getParamsValue('salesslip')),
                        <>
                            <button
                                onClick={() => clearsaleslip()}
                                className="btn secondary"
                                type="button"
                            >
                                Clear
                            </button>
                            <button className="btn secondary" type="submit">
                                Add to Order
                            </button>
                        </>
                    ) : isSaveVisible ? (
                        <>
                            {' '}
                            <button
                                onClick={() => clearsaleslip()}
                                className="btn secondary"
                                type="button"
                            >
                                Clear
                            </button>{' '}
                            <button className="btn secondary" type="submit">
                            Add to Order
                            </button> */}
            {/* <button className="btn secondary" type="submit">
                                Add to Order
            //                 </button> */}
            {/* </>
                    ) : (
                        <button
                            disabled
                            className="btn secondary"
                            type="button"
                        >
                            Add to Order
                        </button>
                    )}
                </div>
            </form> */}
        </div>
    );
}

const mapStateToProps = (state) => ({
    appFillStatus: state.sales.appFillStatus,
    customer: state.sales.customer,
    actionLoading: state.sales.actionLoading,
    templates: state.sales.templates,
    initialProduct: state.sales.initialProduct,
    salesDataOfProduct: state.sales.salesDataOfProduct,
    tax: state.sales.tax,
    saleData: state.sales.saleData,
});

const mapDispatchToProps = (dispatch) => ({
    getSalesTemplateData: () => dispatch(getSalesTemplateData()),
    updateSalesSlip: (data) => dispatch(updateSalesSlip(data)),
    updateCustomer: (history, path, data) =>
        dispatch(updateCustomer(history, path, data)),
    updateSalesDataOfProduct: (data, remove, history, path) =>
        dispatch(updateSalesDataOfProduct(data, remove, history, path)),
    getSalesTax: (data) => dispatch(getSalesTax(data)),
    getFieldData: (data) => dispatch(getFieldData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesSlip);

import React from 'react';
import PropTypes from 'prop-types';
import {
    IconDoubleCheckIcon,
    IconSingleCheckIcon,
} from '../../../../../assets/images';
import moment from 'moment';
export default function MessageBox(props) {

    const { position,
        type,
        text,
        data,
        date,
        dateString,
        status,
        notch,
        userName } = props;

    console.log('msgboxprops', props)

    const formatDate = (date) => {
        return moment(date).format('h:mm A');
    };

    return (
        <>
            <div className={`sender ${position} ${notch ? 'notched' : ''}`}>
                {userName}
            </div>
            <div className={`message-box ${position} ${notch ? 'notched' : ''}`}>
                <div className="message-box--text">{text}</div>
                <div className="message-box--date">
                    {formatDate(date)} &nbsp;
                    <span className="ml-1">
                        {status === 'sent' && <img src={IconSingleCheckIcon} width={8}/>}
                        {status === 'read' && <img src={IconDoubleCheckIcon} />}
                    </span>
                </div>
            </div></>
    );
}
MessageBox.propTypes = {
    position: PropTypes.string.isRequired,
    type: PropTypes.string,
    text: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    // dateString: PropTypes.object.isRequired,
    data: PropTypes.object,
    status: PropTypes.string,
    notch: PropTypes.bool,
};

MessageBox.defaultProps = {
    type: 'text',
    // status: 'sent',
    data: null,
    notch: true,
};


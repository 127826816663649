import React, { Fragment, useState, useEffect } from 'react';
import Header, {
    HeaderCenter,
    HeaderLeft,
    HeaderRight,
} from '../../../../components/Dealer/Header';
import API from "../../../../../src/api";
import {
    IconHome,
    IconSearchAdmin,
    IconFilterInActive,
    IconDoorClosed,
    IconFixIssueAwesomeSendRed,
    greenIcon,
    redIcon,
    yellowIcon,
    ArchiveIconSvg,
    CheckMarkSalesHistoryTick,
    CheckMarkSalesHistoryTickWhite,
    ArchiveIconOriginalSvg,
    greenTickCircle,
    IconCalenderRight,
    IconCalendarRightWhite,
    IconStatusComplete,
    IconStatusSent,
    IconStatusSend,
    IconStatusSentSales,
    redCircleSalesScg,
    yellowCirleNoBg,
} from '../../../../assets/images';
import {ArchiveIcon} from '../../../../assets/images/ArchiveIcon.svg'
import { Row, Col, Form, Modal } from 'react-bootstrap';
import { getFormattedDateTime } from '../../../../components/commons/utility';
import { ExpandIcon } from '../common/ExpandIcon';
import { connect } from 'react-redux';
import { getSalesHistory, archiveSalesHistory, getCustomerApiInitiateForGenerateOrder, getSalesSaveProgressHistory, updateSalesDataOfProduct } from 'redux/actions/sales';
import Loader from "shared/Loader";
import Filter from './filter';
import { CircledImageButton, CirculedImageButtonSalesFilter } from '../../style';
import moment from 'moment';
import { getFlooredFixedTwoDigit } from 'utils/helper';
// import 'bootstrap/dist/css/bootstrap.css';
import { Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



export function SalesHistory(props) {

    const {
        history,
        salesHistory,
        salesSaveProgressHistory,
        getSalesHistory,
        archiveSalesHistory,
        getSalesSaveProgressHistory,
        updateSalesDataOfProduct,
        getCustomerApiInitiateForGenerateOrder
    } = props;

    const [key, setKey] = useState('home');

    console.log(salesHistory)
    console.log(salesSaveProgressHistory)

    const IconAwesomePaperPlane = <svg xmlns="http://www.w3.org/2000/svg" width="17.982" height="18.273" viewBox="0 0 17.982 18.273">
        <path d="M8.956 6.775v2.43c.931.211 1.759.557 2.626.8V7.567c-.928-.207-1.763-.553-2.626-.792zm7.969-4.389a10.42 10.42 0 0 1-4.175 1.138c-1.909 0-3.49-1.242-5.9-1.242a6.938 6.938 0 0 0-2.427.428 2 2 0 1 0-3.008.924v13.779a.854.854 0 0 0 .856.856h.571a.854.854 0 0 0 .856-.856v-3.369a9.95 9.95 0 0 1 4.082-.789c1.913 0 3.49 1.242 5.9 1.242a7.457 7.457 0 0 0 4.371-1.46 1.138 1.138 0 0 0 .492-.942V3.421a1.14 1.14 0 0 0-1.619-1.035zm-10.6 9.228a11.242 11.242 0 0 0-2.626.592V9.691a10.209 10.209 0 0 1 2.63-.621zm10.506-4.8a11.383 11.383 0 0 1-2.626.853V10.2a6.633 6.633 0 0 0 2.626-.928v2.516a5.766 5.766 0 0 1-2.626.967V10.2a6.036 6.036 0 0 1-2.626-.2v2.41a20.821 20.821 0 0 0-2.626-.76V9.205a7.932 7.932 0 0 0-2.624-.135v-2.5a12.593 12.593 0 0 0-2.629.748V4.8a10.219 10.219 0 0 1 2.626-.785v2.557a6.07 6.07 0 0 1 2.626.2V4.37a20.315 20.315 0 0 0 2.626.76v2.441a6.8 6.8 0 0 0 2.626.1V5.1a12.626 12.626 0 0 0 2.626-.8z" transform="translate(-.563 .003)" />
    </svg>

    const IconEnvelopeOpen = <svg xmlns="http://www.w3.org/2000/svg" width="17.266" height="17.266" viewBox="0 0 17.266 17.266">
        <path d="M8.633 0L0 4.316v12.95h17.266V4.316zm0 2.439l6.475 3.237v4.058l-6.475 3.237-6.475-3.237V5.676zM4.316 6.5v2.155l4.316 2.158 4.316-2.158V6.5z" />
    </svg>

    const IconAwesomePenFancy = <svg xmlns="http://www.w3.org/2000/svg" width="17.266" height="17.266" viewBox="0 0 17.266 17.266">
        <g>
            <path d="M2.67 9.541a1.079 1.079 0 0 0-.683.683L0 16.187l.158.158 3.132-3.132a1.038 1.038 0 0 1-.053-.263 1.079 1.079 0 1 1 1.079 1.079 1.038 1.038 0 0 1-.263-.053L.921 17.108l.158.158 5.963-1.988a1.079 1.079 0 0 0 .683-.683l1.115-2.834-3.335-3.335zM12.452.955L6.277 7.682l3.3 3.3L16.3 4.807c2.868-2.531-1.336-6.693-3.848-3.852z" />
        </g>
    </svg>

    const IconSend = <svg xmlns="http://www.w3.org/2000/svg" width="18.494" height="18.499" viewBox="0 0 18.494 18.499">
        <path d="M17.2.115L.452 9.774a.868.868 0 0 0 .079 1.561l3.84 1.611L14.75 3.8a.217.217 0 0 1 .311.3l-8.7 10.6v2.908a.867.867 0 0 0 1.535.571l2.294-2.792 4.5 1.886a.869.869 0 0 0 1.192-.657l2.6-15.606A.867.867 0 0 0 17.2.115z" transform="translate(-.001 .002)" />
    </svg>

    const IconEnvelopeClosed = <svg xmlns="http://www.w3.org/2000/svg" width="17.331" height="12.998" viewBox="0 0 17.331 12.998">
        <path d="M0 0v2.166L8.665 6.5l8.665-4.333V0zm0 4.333V13h17.331V4.333L8.665 8.665z" />
    </svg>

    const IconAwesomePenFancyRight = <svg xmlns="http://www.w3.org/2000/svg" width="17.875" height="19.445" viewBox="0 0 17.875 19.445">
        <g>
            <path d="M2.527 9.029a1.021 1.021 0 0 0-.646.646L0 15.318l.15.15L3.114 12.5a.982.982 0 0 1-.05-.249 1.021 1.021 0 1 1 1.021 1.021.982.982 0 0 1-.249-.05L.872 16.19l.149.149 5.643-1.881a1.021 1.021 0 0 0 .646-.646l1.055-2.682L5.21 7.974zM11.784.9L5.94 7.27l3.123 3.123 6.366-5.844c2.71-2.395-1.268-6.334-3.645-3.649z" transform="translate(1.5) rotate(-90 8.188 8.151)" />
            <path stroke="#c8e0ed" stroke-linecap="round" stroke-width="3px" d="M0 0h14.769" transform="translate(1.5) translate(0 17.945)" />
        </g>
    </svg>

    const HistoryArchive = <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 89.5 73.8" style="enable-background:new 0 0 89.5 73.8;" space="preserve">

<g>
   <path class="st0" d="M78.4,9.4H11c-1.7,0-3,1.4-3,3v7.8h73.4v-7.8C81.4,10.8,80.1,9.4,78.4,9.4z"/>
   <path class="st0" d="M14.3,25v35.7c0,1.9,1.5,3.4,3.4,3.4h54.1c1.9,0,3.4-1.5,3.4-3.4V25H14.3z M54.2,40.9H35.3
       c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9h18.9c2.1,0,3.9,1.7,3.9,3.9C58,39.2,56.3,40.9,54.2,40.9z"/>
</g>
</svg>


const IconSmallArrowRight = (props) => {
    return (
        <svg className={`arrow-icon ${props.expanded && "arrow-icon-active"}`} enableBackground="new 0 0 12 12" height="12px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="32px">
            <path d="M24.291,14.276L14.705,4.69c-0.878-0.878-2.317-0.878-3.195,0l-0.8,0.8c-0.878,0.877-0.878,2.316,0,3.194  L18.024,16l-7.315,7.315c-0.878,0.878-0.878,2.317,0,3.194l0.8,0.8c0.878,0.879,2.317,0.879,3.195,0l9.586-9.587  c0.472-0.471,0.682-1.103,0.647-1.723C24.973,15.38,24.763,14.748,24.291,14.276z" />
        </svg>
    )
}

    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
    const [search, setSearch] = useState('');
    const [filterHistory, setFilterHistory] = useState([]);
    const [expanded, setExpanded] = useState(null);
    const [showWarning, setShowWarning] = useState(false);
    const [archiveRow, setArchiveRow] = useState(null);
    const [showFilter, setShowFilter] = useState(false);
    const [showSavedProgressData, setShowSavedProgressData] = useState([])

    useEffect(() => {
        let temp = {
            "archive": false,
            "filter_status": false,
            "orderby": "newtoold"
        }
        getSalesHistory(temp)
        updateSalesDataOfProduct([], false);
        localStorage.removeItem('salesInProcessSavedId')
        let data ={}
        // getSalesSaveProgressHistory(data)
        // getProgressData()
    }, [])

    // const getProgressData = async () => {
    //     let data ={}
    //     const result = await API.post(`/sales/get-save-progress`, { ...data });
    //     console.log('getProgressDataresult ==',result) 
    // }

    useEffect(() => {
        console.log('effectsaleshistory',salesHistory)
        if (search != null && search !== '') {
            let temp = salesHistory.data.filter((d) =>
                JSON.stringify(d.customer_data).toLowerCase().includes(search.toLowerCase())
            );
            setFilterHistory(temp);
        } else {
            setFilterHistory(salesHistory.data);
        }
    }, [search, salesHistory]);

    useEffect(() => {
        console.log('salesSaveProgressHistory',salesSaveProgressHistory)
        if(salesSaveProgressHistory && salesSaveProgressHistory.data.length > 0) {
            setShowSavedProgressData(salesSaveProgressHistory?.data)
        }
    },[salesSaveProgressHistory])

    const renderPurchasedItems = (items) => {
        return items.map((item) => {
            return (
                <div className="customer-purchase-item" key={`product_${item.product_id}`}>
                    {item.product_type}
                </div>
            );
        });
    };

    const renderPurchasedItemsInExpand = (items) => {
        return items.products && items.products.map((item) => {
            return (
                <Row>
                    <Col>
                        <div>
                            <div className='customer-purchase-item f-12'>{item.product_type} <span className='mr-2 ml-2'>-</span> <span className='text-white'>${getFlooredFixedTwoDigit(item.balance_amount,2)}</span></div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        {item.signature_status && item.hellosign_signature_data && item.hellosign_signature_data.length > 0 &&
                            <div className="d-flex hello-sign-status" style={{ height: 25, justifyContent: 'end' }}>
                                {
                                    Array.isArray(item.hellosign_signature_data) && item.hellosign_signature_data.length === 0 ?
                                        <button className="active">
                                            <img src={IconFixIssueAwesomeSendRed} alt="" />
                                        </button>
                                        :
                                        item.hellosign_signature_data &&
                                            item.hellosign_signature_data[0] &&
                                            (item.hellosign_signature_data[0].last_viewed_at !== null && item.hellosign_signature_data[0].status_code !== "delivery_in_queue" && (item.hellosign_signature_data[0].status_code === "declined" || item.hellosign_signature_data[0].status_code === "error" || item.hellosign_signature_data[0].status_code === "not_delivered")) ? (
                                            <button className="active-mark">
                                                {IconSend}
                                            </button>
                                        ) : (
                                            <button className="active">
                                                {IconAwesomePaperPlane}
                                            </button>
                                        )}
                                {
                                    item.hellosign_signature_data &&
                                        item.hellosign_signature_data[0] &&
                                        item.hellosign_signature_data[0].last_viewed_at !== null &&
                                        item.hellosign_signature_data[0].status_code !== "awaiting_signature" ? (
                                        <button className="active">
                                            {IconEnvelopeOpen}
                                        </button>
                                    ) : (
                                        <button>
                                            {IconEnvelopeClosed}
                                        </button>
                                    )}
                                {
                                    item.hellosign_signature_data &&
                                        item.hellosign_signature_data[0] &&
                                        item.hellosign_signature_data[0].last_viewed_at !== null &&
                                        item.hellosign_signature_data[0].status_code !== "awaiting_signature" &&
                                        item.hellosign_signature_data[0].status_code == "signed" ? (
                                        <button className="active">
                                            {IconAwesomePenFancyRight}
                                        </button>
                                    ) : (
                                        <button>
                                            {IconAwesomePenFancy}
                                        </button>
                                    )}
                            </div>
                        }
                    </Col>
                </Row>
            );
        });
    };

    const getStatusIcon = (item) => {
        console.log('item',item)
        if (item.sales_app) {

            let filter_data = item.products ? item.products.filter(hsd => hsd.signature_status && hsd.hellosign_signature_data.length > 0 && hsd.hellosign_signature_data[0].status_code !== 'signed') : []
            console.log('filter_Data',filter_data)
            // filter_data.map((item) => {
            //     console.log(item.hellosign_signature_data[0].status_code)
            //     // console.log(Math.abs(new Date() - new Date(item.sales_app_date)) / 36e5 >= 48)
            //     // console.log(moment(item.sales_app_date).format("YYYY-MM-DD"))
            //     console.log(moment("2015-08-11T13:00:00.000000Z").format("YYYY-MM-DD"))
            // })
            // console.log(moment(item.sales_app_date).format("YYYY-MM-DD"))
            console.log(Math.abs(new Date() - new Date(item.sales_app_date)) / 36e5 <= 48)
            if(filter_data.length == 0) {
                if(Math.abs(new Date() - new Date(item.sales_app_date)) / 36e5 <= 48) {
                    return <img src={yellowCirleNoBg} alt="" style={{ width: 30,  padding : 2 }} />
                } else {
                    return <img src={redCircleSalesScg} alt="" style={{ width: 30, padding : 2 }} />
                }
            } else {
            if(filter_data.every((item) => item.hellosign_signature_data[0].status_code == 'signed')) {
                return <img src={CheckMarkSalesHistoryTick} alt="" style={{ width: 30 }} />
            } else if(filter_data.some((item) => item.hellosign_signature_data[0].status_code == 'signed')) {
                if(Math.abs(new Date() - new Date(item.sales_app_date)) / 36e5 <= 48) {
                    return <img src={yellowCirleNoBg} alt="" style={{ width: 30,  padding : 2 }} />
                } else {
                    return <img src={redCircleSalesScg} alt="" style={{ width: 30, padding : 2 }} />
                }
            } else {
                return <img src={redCircleSalesScg} alt="" style={{ width: 30, padding : 2 }} />
            }
        }
            // if (filter_data && filter_data.length > 0 && filter_data.length !== item.products.length) {
            //     return <img src={yellowCirleNoBg} alt="" style={{ width: 30,  padding : 2 }} />
            // }
            // else if (filter_data && filter_data.length > 0 && filter_data.length === item.products.length) {
            //     return <img src={redCircleSalesScg} alt="" style={{ width: 30 }} />
            // }
            // else {
            //     return <img src={CheckMarkSalesHistoryTick} alt="" style={{ width: 30 }} />
            // }
        } else {
         if (item.credit_app) {
            if (item.credit_application_status === "completed") {
                return <img src={CheckMarkSalesHistoryTick} alt="" style={{ width: 30 }} />
            }
            else {
                return <img src={redCircleSalesScg} alt="" style={{ width: 30, padding : 2 }} />
            }
        }
    }
        // else if (item.credit_app) {
        //     return <img src={redCircleSalesScg} alt="" style={{ width: 30 }} />
        // }
    }

    const setExpandedAction = (item) => {
        let id = item.credit_app_id ? item.credit_app_id : '' + item.app_id ? item.app_id : '';
        setExpanded(id)
    }

    const idRowSelected = (item) => {
        let id = item.credit_app_id ? item.credit_app_id : '' + item.app_id ? item.app_id : '';

        if (id === expanded) {
            return true
        }
        else {
            return false
        }
    }





    const redirectionToSummary = (data) => {
        console.log('data:', data);

        let temp = {
            "app_id": data.app_id ? data.app_id : null,
            "credit_app_id": data.credit_app_id ? data.credit_app_id : null,
            "include_products": true,
        }
        localStorage.setItem('redirectionPath', window.location.pathname + window.location.search)
        getCustomerApiInitiateForGenerateOrder(temp, history, '/applyApplicationSummary')
    }

 
    const redirectionToSummaryFromSaveProgress = (data) => {
        console.log('data:', data);
        localStorage.setItem('salesInProcessSavedId',data.saved_id)
        let temp = {
            "app_id": data.app_id ? data.app_id : null,
            "credit_app_id": data.credit_app_id ? data.credit_app_id : null,
            "include_products": true,
            "include_saved_products":true,
            "saved_id": data.saved_id
        }
        console.log(temp)
        localStorage.setItem('redirectionPath', window.location.pathname + window.location.search)
        getCustomerApiInitiateForGenerateOrder(temp, history, '/applyApplicationSummary')
    }   

    const filterHandler = () => {
        setShowFilter(!showFilter)
    }





    return (
        <div className="admin">

            {salesHistory && salesHistory.loading && <Loader />}

            <Header>
                <HeaderLeft>
                    <div className='d-flex flex-wrap justify-content-center align-items-center'>
                    <img
                        src={IconHome}
                        alt=""
                        onClick={() => history && history.push('/')}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img
                        src={ArchiveIconOriginalSvg}
                        alt=""
                        width={30}
                        style={{position : 'relative', top : 1}}
                        onClick={() => history && history.push('/archive')}
                    />
                    </div>
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">HISTORY</div>
                </HeaderCenter>
                <HeaderRight>
                <div className='d-flex flex-wrap justify-content-center align-items-center'>
                {/* {!showFilter && */}
                        <>
                           
                            <img
                                src={IconSearchAdmin}
                                alt=""
                                onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}
                                style={{visibility : showFilter ? 'hidden' : 'visible'}}
                            />
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   
                        </>
                    {/* } */}
                    <CirculedImageButtonSalesFilter active={showFilter}>
                        <i class="fa fa-filter" aria-hidden="true"
                            onClick={filterHandler} style={{ color: showFilter ? '#fff' : '#B2D8F7', fontSize: !showFilter && 20 }} ></i>
                    </CirculedImageButtonSalesFilter>
                    {/* <img
                        src={IconFilterInActive}
                        alt=""
                        width={16}
                        onClick={() => setShowFilter(!showFilter)}
                    /> */}
                   
                    </div>
                </HeaderRight>
            </Header>

            <Modal show={showWarning} onHide={() => setShowWarning(false)} autoFocus={false}>
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body className="fixed-height">
                    Moving this document to the archive will turn off signature reminders to the customer. Would you like to move this document(s) to the archive?
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn secondary" onClick={() => {
                        setShowWarning(false)
                        archiveSalesHistory(archiveRow)
                    }}>
                        Yes
                    </button>
                    <button class="btn secondary" onClick={() => setShowWarning(false)}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>

            {isSearchBarVisible && (
                <div className="search-header">
                    <form action="javascript:void(0)">
                        <Form.Group>
                            <Form.Control
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            )}


            {showFilter ?

                <Filter setShowFilter={setShowFilter} />

                :

                <div className="main">
                    <div className="list fill-screen" style={{overflowY : 'auto'}}>
                        <Fragment>
                          
                            <Tabs
      id="my-tabs"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3 justify-content-around history-custom-tabs"
    >
      <Tab eventKey="home" title="Submitted">
         {salesHistory && salesHistory.data && filterHistory.map((item) => {
                                return (
                                    <>
                                    <div className={`${idRowSelected(item) ? 'expanded-div' : ''}`}>
                                        <Row className={`single-row ${idRowSelected(item) ? 'expanded' : ''}`} style={{ cursor: 'default' }}>
                                            <div className="customer-row sales-customer-row" style={{ marginLeft: 5 }}>
                                                <Col xs={1}>
                                                    {getStatusIcon(item)}
                                                </Col>
                                                <Col>
                                                    <div style={{ marginLeft: 10 }} className='tab-display-flex-sales'>
                                                        <div className='customer-name-no-wrpapper tab-inline-overflow '>{item.customer_data.customer_name}</div>
                                                        <div style={{position : 'relative', bottom : 5}} className={`tab-margin-left-55 ${item.customer_data.customer_name.length > 17 && 'tab-display-flex-customer-name'}`}>
                                                            {item.credit_app ?
                                                                <span style={{ fontSize: 12, color: 'rgb(110 153 191)' }}>{getFormattedDateTime(item.credit_app_date)}</span>
                                                                :
                                                                <span style={{ fontSize: 12, color: 'rgb(110 153 191)' }}>{getFormattedDateTime(item.sales_app_date)}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={4} className="content-col text-left" style={{ padding: 5 }}>
                                                    {item.credit_app &&
                                                        <div className="customer-purchase-item">
                                                            Credit Application
                                                        </div>
                                                    }
                                                    {item.products && renderPurchasedItems(item.products)}
                                                </Col>
                                                <Col xs={1} className="action-col text-right" style={{ display: 'flex', alignItems: "center", justifyContent: "end" }}>
                                                    <span onClick={() => setExpandedAction(item)} style={{paddingRight : idRowSelected(item) ? 2 : 10}}>
                                                        {
                                                            idRowSelected(item) ?  
                                                            <div style={{background : '#009d86', padding : 5, width : 23, height : 23, borderRadius : '50%'}} className="d-flex flex-wrap justify-content-center align-items-center"><img src={IconCalendarRightWhite} style={{transform : 'rotate(90deg)'}} /></div> : 
                                                            <img src={IconCalendarRightWhite} />
                                                        }
                                                        </span>
                                                </Col>
                                            </div>
                                        </Row>
                                        </div>
                                        {idRowSelected(item) &&
                                            <>
                                                <Row className={`single-row`} style={{position : 'relative'}}>
                                                    <div className="customer-row sales-customer-row" style={{ alignItems: 'flex-start', padding: "10px 0", margin: 12, marginTop: 0, marginBottom: 0, }}>
                                                        
                                                        <Col xs={7}>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <div className='customer-purchase-item f-12 mt-2 row' style={{marginBottom : 10}}>
                                                                    <Col xs={3}>
                                                                    <span style={{color : "#6792b6"}}>Email:</span>
                                                                    </Col>
                                                                    <Col xs={9}>
                                                                     <span className='text-white'>{item.customer_data.email}</span>
                                                                     </Col>
                                                                     </div>
                                                                <div className='customer-purchase-item f-12 mb-2 row'>
                                                                    <Col xs={3}>
                                                                    <span style={{color : "#6792b6"}}>Phone:</span>
                                                                    </Col>
                                                                    <Col xs={9}>
                                                                     <span className='text-white'>{item.customer_data.phone}</span>
                                                                     </Col>
                                                                     </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={5} className="text-left">
                                                           
                                                            {item.customer_data.co_customer && <div className="customer-purchase-item f-12">Co-App: {item.customer_data.co_customer} </div>}
                                                        </Col>
                                                        <span className="reload"
                                                                onClick={() => {
                                                                    setShowWarning(true)
                                                                    setArchiveRow(item)
                                                                }}
                                                                style={{ marginTop: 0, marginRight: -12, padding : 4, borderRadius : '10px 0 0 10px', position: 'absolute', right: 12, top: 22 }}
                                                            >
                                                                <img src={ArchiveIconSvg} alt="archive" style={{position : 'relative', bottom : 1}} />
                                                            </span>
                                                    </div>
                                                </Row>
                                                <Row className={`single-row expand-product-div width-95-mobile`} onClick={() => redirectionToSummary(item)} style={{background  :"#335169", maxWidth : 600, margin : 'auto', borderRadius : 3}}>
                                                    <div className="customer-row sales-customer-row" style={{ margin: 0, padding: "5px 10px", display: "block" }}>
                                                        {
                                                            console.log('itemhere',item)
                                                        }
                                                        {item.credit_app &&
                                                            <Row>
                                                                <Col>
                                                                    <div>
                                                                        <div className='customer-purchase-item f-12' style={{color : "#b2d8f7"}}>Credit Application</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <div className="customer-purchase-item f-12" style={{ textAlign: 'right' }}>
                                                                        {
                                                                            item.credit_application_status == 'sent' ? 
                                                                            <div className='d-flex flex-wrap justify-content-end'>
                                                                            <span style={{ marginRight: 5, background : "#009d86", borderRadius : '50%', padding : 5, display : 'flex', flexWrap : 'wrap', justifyContent : 'center', alignItems : 'center' }}>
                                                                                <img src={IconStatusSentSales} style={{position : 'relative', right : 1}}/>
                                                                            </span> 
                                                                            {item.credit_application_status}
                                                                            </div>
                                                                            :
                                                                            <>
                                                                            <img src={item.credit_application_status == 'open' ? IconStatusSend : IconStatusComplete} alt="" style={{ width: 16, marginRight: 5, height: 16, background : "#009d86", borderRadius : '50%', padding : 3 }} />
                                                                            {item.credit_application_status}
                                                                            </>
                                                                        }
                                                                       </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {item.products && item.products.length > 0 && renderPurchasedItemsInExpand(item)}
                                                    </div>
                                                </Row>
                                            </>
                                        }
                                   </>
                                )
                            })}
      </Tab>
      <Tab eventKey="profile" title="In Process">
      <div>
      {salesSaveProgressHistory && salesSaveProgressHistory.data && showSavedProgressData.map((item) => {
                                return (
                                    <>
                                    {/* <div className={`${idRowSelected(item) ? 'expanded-div' : ''}`}> */}
                                        {/* <Row className={`single-row ${idRowSelected(item) ? 'expanded' : ''}`} style={{ cursor: 'default' }}> */}
                                        <div style={{padding : "20px 5px"}} className='savedProgressDataHover' onClick={() => redirectionToSummaryFromSaveProgress(item)}>
                                        <Row className={`single-row`}>
                                            <div className="customer-row sales-customer-row justify-content-between" style={{ marginLeft: 5 }}>
                                                <div className="tab-inline-overflow-save-progress" style={{width : 150}}>
                                                {item.customer_data.customer_name}
                                                <br />
                                                <>
                                                {item.credit_app ?
                                                                <span style={{ fontSize: 12, color: 'rgb(110 153 191)' }} className="saveprogressTime">{getFormattedDateTime(item.credit_app_date)}</span>
                                                                :
                                                                <span style={{ fontSize: 12, color: 'rgb(110 153 191)' }} className="saveprogressTime">{getFormattedDateTime(item.sales_app_date)}</span>
                                                            }
                                                </>
                                                </div>
                                                <div className="saveprogressTimeDisplayTab" style={{width : 150}}>
                                                {item.credit_app ?
                                                                <span style={{ fontSize: 12, color: 'rgb(110 153 191)' }}>{getFormattedDateTime(item.credit_app_date)}</span>
                                                                :
                                                                <span style={{ fontSize: 12, color: 'rgb(110 153 191)' }}>{getFormattedDateTime(item.sales_app_date)}</span>
                                                            }
                                                </div>
                                                <div className="customer-purchase-item text-center customerEmailPhoneSaveProgress" style={{width : 150}}>
                                                {item.credit_app && <>
                                                {'Credit Application'}<br />
                                                </> }
                                                {
                                                    item.saved_product_types && item.saved_product_types.length > 0 && item.saved_product_types.map((subItem, idx) => {
                                                        return (
                                                            <>
                                                            {subItem}<br />
                                                            </>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <div className="customer-purchase-item text-right customerEmailPhoneSaveProgress" style={{width : 150}}>
                                                {item.customer_data.email}<br />
                                                {item.customer_data.phone}
                                                </div>
                                                {/* <Col xs={1}>
                                                    {getStatusIcon(item)}
                                                </Col> */}
                                                {/* <Col xs={10}>
                                                    <div style={{ marginLeft: 10 }} className='tab-display-flex-sales'>
                                                        <div className='customer-name-no-wrpapper tab-inline-overflow-save-progress '>{item.customer_data.customer_name}</div>
                                                        <div style={{position : 'relative', bottom : 5}} className={`tab-margin-left-55 ${item.customer_data.customer_name.length > 17 && 'tab-display-flex-customer-name'}`}>
                                                            {item.credit_app ?
                                                                <span style={{ fontSize: 12, color: 'rgb(110 153 191)' }}>{getFormattedDateTime(item.credit_app_date)}</span>
                                                                :
                                                                <span style={{ fontSize: 12, color: 'rgb(110 153 191)' }}>{getFormattedDateTime(item.sales_app_date)}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={2} className="content-col text-right marginRightSaveProgressMobile" style={{ padding: 5 }}>
                                                    <div>
                                                        <div className="customer-purchase-item">
                                                            {item.customer_data.email}
                                                        </div>
                                                    
                                                      <div className="customer-purchase-item">
                                                            {item.customer_data.phone}
                                                       </div>
                                                       </div>
                                                </Col> */}
                                                {/* <Col xs={1} className="action-col text-right" style={{ display: 'flex', alignItems: "center", justifyContent: "end" }}>
                                                    <span onClick={() => setExpandedAction(item)} style={{paddingRight : idRowSelected(item) ? 2 : 10}}>
                                                        {
                                                            // idRowSelected(item) ?  
                                                            // <div style={{background : '#009d86', padding : 5, width : 23, height : 23, borderRadius : '50%'}} className="d-flex flex-wrap justify-content-center align-items-center"><img src={IconCalendarRightWhite} style={{transform : 'rotate(90deg)'}} /></div> : 
                                                            <img src={IconCalendarRightWhite} />
                                                        }
                                                        </span>
                                                </Col> */}
                                            </div>
                                        </Row>
                                        </div>
                                        {/* {idRowSelected(item) &&
                                            <>
                                                <Row className={`single-row`} style={{position : 'relative'}}>
                                                    <div className="customer-row sales-customer-row" style={{ alignItems: 'flex-start', padding: "10px 0", margin: 12, marginTop: 0, marginBottom: 0, }}>
                                                        
                                                        <Col xs={7}>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <div className='customer-purchase-item f-12 mt-2 row' style={{marginBottom : 10}}>
                                                                    <Col xs={3}>
                                                                    <span style={{color : "#6792b6"}}>Email:</span>
                                                                    </Col>
                                                                    <Col xs={9}>
                                                                     <span className='text-white'>{item.customer_data.email}</span>
                                                                     </Col>
                                                                     </div>
                                                                <div className='customer-purchase-item f-12 mb-2 row'>
                                                                    <Col xs={3}>
                                                                    <span style={{color : "#6792b6"}}>Phone:</span>
                                                                    </Col>
                                                                    <Col xs={9}>
                                                                     <span className='text-white'>{item.customer_data.phone}</span>
                                                                     </Col>
                                                                     </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={5} className="text-left">
                                                           
                                                            {item.customer_data.co_customer && <div className="customer-purchase-item f-12">Co-App: {item.customer_data.co_customer} </div>}
                                                        </Col>
                                                        <span className="reload"
                                                                onClick={() => {
                                                                    setShowWarning(true)
                                                                    setArchiveRow(item)
                                                                }}
                                                                style={{ marginTop: 0, marginRight: -12, padding : 4, borderRadius : '10px 0 0 10px', position: 'absolute', right: 12, top: 22 }}
                                                            >
                                                                <img src={ArchiveIconSvg} alt="archive" style={{position : 'relative', bottom : 1}} />
                                                            </span>
                                                    </div>
                                                </Row>
                                                <Row className={`single-row expand-product-div width-95-mobile`} onClick={() => redirectionToSummary(item)} style={{background  :"#335169", maxWidth : 600, margin : 'auto', borderRadius : 3}}>
                                                    <div className="customer-row sales-customer-row" style={{ margin: 0, padding: "5px 10px", display: "block" }}>
                                                        {
                                                            console.log('itemhere',item)
                                                        }
                                                        {item.credit_app &&
                                                            <Row>
                                                                <Col>
                                                                    <div>
                                                                        <div className='customer-purchase-item f-12' style={{color : "#b2d8f7"}}>Credit Application</div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <div className="customer-purchase-item f-12" style={{ textAlign: 'right' }}>
                                                                        {
                                                                            item.credit_application_status == 'sent' ? 
                                                                            <div className='d-flex flex-wrap justify-content-end'>
                                                                            <span style={{ marginRight: 5, background : "#009d86", borderRadius : '50%', padding : 5, display : 'flex', flexWrap : 'wrap', justifyContent : 'center', alignItems : 'center' }}>
                                                                                <img src={IconStatusSentSales} style={{position : 'relative', right : 1}}/>
                                                                            </span> 
                                                                            {item.credit_application_status}
                                                                            </div>
                                                                            :
                                                                            <>
                                                                            <img src={item.credit_application_status == 'open' ? IconStatusSend : IconStatusComplete} alt="" style={{ width: 16, marginRight: 5, height: 16, background : "#009d86", borderRadius : '50%', padding : 3 }} />
                                                                            {item.credit_application_status}
                                                                            </>
                                                                        }
                                                                       </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {item.products && item.products.length > 0 && renderPurchasedItemsInExpand(item)}
                                                    </div>
                                                </Row>
                                            </>
                                        } */}
                                   </>
                                )
                            })}
        </div>
      </Tab>
     
    </Tabs>
                        </Fragment>
                    </div>
                </div>
            }

        </div>
    );
}




const mapStateToProps = (state) => (console.log('historystate',state),{
    salesHistory: state.sales.salesHistory,
    salesSaveProgressHistory : state.sales.salesSaveProgressHistory
});

const mapDispatchToProps = (dispatch) => ({
    getSalesHistory: (data) => dispatch(getSalesHistory(data)),
    // getSalesSaveProgressHistory: (data) => dispatch(getSalesSaveProgressHistory(data)),
    archiveSalesHistory: (data) => dispatch(archiveSalesHistory(data)),
    getCustomerApiInitiateForGenerateOrder: (data, history, path) => dispatch(getCustomerApiInitiateForGenerateOrder(data, history, path)),
    updateSalesDataOfProduct: (data, remove, history, path) =>
    dispatch(updateSalesDataOfProduct(data, remove, history, path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesHistory);


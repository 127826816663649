import { SliderContainer, SliderItem } from '../style';
import PropTypes from 'prop-types';
import React from 'react';

function DealerFooter({ activeTab, onClickTab, sales, funding}) {
    return (
        <div className="footer-container p-3">
            <SliderContainer>
                <SliderItem
                    className="col-6"
                    active={activeTab === 0}
                    onClick={() => onClickTab(0)}
                >
                    <div className='badge-icon leftc'>{sales}</div> 
                    <span>Sales</span> 
                </SliderItem>
                <SliderItem
                    className="col-6"
                    active={activeTab === 1}
                    onClick={() => onClickTab(1)}
                >
                    <span>Funding</span> 
                    <div className='badge-icon rightc'>{funding}</div>
                </SliderItem>
            </SliderContainer>
        </div>
    );
}

DealerFooter.propTypes = {
    activeTab: PropTypes.number,
    onClickTab: PropTypes.func,
};

export default DealerFooter;

// TCP 2.0
import API from "../../api";
import { addEvent } from "../../firebase/firebase";
import { pushNotification } from "utils/notification";
import moment from 'moment';
import { message as notificationMsg } from "shared/constant";
import { REMOVE_GLOBAL_ITEM_ADJUSTMENT, REMOVE_GLOBAL_ITEM_ADJUSTMENT_FAILED, REMOVE_GLOBAL_ITEM_ADJUSTMENT_SUCCESS, UPDATE_GLOBAL_ITEM_ADJUSTMENT, UPDATE_GLOBAL_ITEM_ADJUSTMENT_FAILED, UPDATE_GLOBAL_ITEM_ADJUSTMENT_SUCCESS } from "./actionTypes";

export const GET_STAFFS_REQUESTS = "GET_STAFFS_REQUESTS";
export const GET_STAFFS_REQUESTS_SUCCESS = "GET_STAFFS_REQUESTS_SUCCESS";
export const GET_STAFFS_REQUESTS_FAILED = "GET_STAFFS_REQUESTS_FAILED";

export const SET_STAFFS_REQUEST = "SET_STAFFS_REQUEST";

export const ADD_STAFFS_REQUEST = "ADD_STAFFS_REQUEST";
export const ADD_STAFFS_REQUEST_SUCCESS = "ADD_STAFFS_REQUEST_SUCCESS";
export const ADD_STAFFS_REQUEST_FAILED = "ADD_STAFFS_REQUEST_FAILED";

export const UPDATE_STAFFS_REQUEST = "UPDATE_STAFFS_REQUEST";
export const UPDATE_STAFFS_REQUEST_SUCCESS = "UPDATE_STAFFS_REQUEST_SUCCESS";
export const UPDATE_STAFFS_REQUEST_FAILED = "UPDATE_STAFFS_REQUEST_FAILED";

export const DELETE_STAFFS_REQUEST = "DELETE_STAFFS_REQUEST";
export const DELETE_STAFFS_REQUEST_SUCCESS = "DELETE_STAFFS_REQUEST_SUCCESS";
export const DELETE_STAFFS_REQUEST_FAILED = "DELETE_STAFFS_REQUEST_FAILED";

export const TOGGLE_SHOW_DATA = "TOGGLE_SHOW_DATA";
export const TOGGLE_SHOW_DATA_SUCCESS = "TOGGLE_SHOW_DATA_SUCCESS";
export const TOGGLE_SHOW_DATA_FAILED = "TOGGLE_SHOW_DATA_FAILED";

export const PREAPPROVAL_SALES_REQUEST = "PREAPPROVAL_SALES_REQUEST";
export const PREAPPROVAL_SALES_SUCCESS = "PREAPPROVAL_SALES_SUCCESS";
export const PREAPPROVAL_SALES_FAILED = "PREAPPROVAL_SALES_FAILED";

export const FUNDED_SCHEDULED_REQUEST = "FUNDED_SCHEDULED_REQUEST";
export const FUNDED_SCHEDULED_SUCCESS = "FUNDED_SCHEDULED_SUCCESS";
export const FUNDED_SCHEDULED_FAILED = "FUNDED_SCHEDULED_FAILED";

export const GET_PAPERWORK_REQUEST = "GET_PAPERWORK_REQUEST";
export const GET_PAPERWORK_SUCCESS = "GET_PAPERWORK_SUCCESS";
export const GET_PAPERWORK_FAILED = "GET_PAPERWORK_FAILED";

export const GET_HISTORY_REQUEST = "GET_HISTORY_REQUEST";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const GET_HISTORY_FAILED = "GET_HISTORY_FAILED";

export const GET_CREDIT_REQUEST = "GET_CREDIT_REQUEST";
export const GET_CREDIT_SUCCESS = "GET_CREDIT_SUCCESS";
export const GET_CREDIT_FAILED = "GET_CREDIT_FAILED";

export const GET_CUSTOMER_SEARCH_REQUEST = "GET_CUSTOMER_SEARCH_REQUEST";
export const GET_CUSTOMER_SEARCH_SUCCESS = "GET_CUSTOMER_SEARCH_SUCCESS";
export const GET_CUSTOMER_SEARCH_FAILED = "GET_CUSTOMER_SEARCH_FAILED";

export const GET_CUSTOMER_PROFILE_REQUEST = "GET_CUSTOMER_PROFILE_REQUEST";
export const GET_CUSTOMER_PROFILE_SUCCESS = "GET_CUSTOMER_PROFILE_SUCCESS";
export const GET_CUSTOMER_PROFILE_FAILED = "GET_CUSTOMER_PROFILE_FAILED";

export const GENERATE_PREAPPROVAL_REQUEST = "GENERATE_PREAPPROVAL_REQUEST";
export const GENERATE_PREAPPROVAL_SUCCESS = "GENERATE_PREAPPROVAL_SUCCESS";
export const GENERATE_PREAPPROVAL_FAILED = "GENERATE_PREAPPROVAL_FAILED";

export const UPDATE_SELECTED_CUSTOMER_SUCCESS = "UPDATE_SELECTED_CUSTOMER_SUCCESS";

export const REQUEST_FUNDING_REQUEST = "REQUEST_FUNDING_REQUEST";
export const REQUEST_FUNDING_SUCCESS = "REQUEST_FUNDING_SUCCESS";
export const REQUEST_FUNDING_FAILED = "REQUEST_FUNDING_FAILED";

export const REQUEST_ADJUST_FUNDING_REQUEST = "REQUEST_ADJUST_FUNDING_REQUEST";
export const REQUEST_ADJUST_FUNDING_SUCCESS = "REQUEST_ADJUST_FUNDING_SUCCESS";
export const REQUEST_ADJUST_FUNDING_FAILED = "REQUEST_ADJUST_FUNDING_FAILED";

export const GET_FUNDING_REQUESTS_SUCCESS = 'GET_FUNDING_REQUESTS_SUCCESS';
export const GET_FUNDING_REQUESTS_FAILED = 'GET_FUNDING_REQUESTS_FAILED';

export const SET_SELECTED_DEALER_REQUEST = 'SET_SELECTED_DEALER_REQUEST';
export const SET_SELECTED_DEALER_SUCCESS = 'SET_SELECTED_DEALER_SUCCESS';
export const SET_SELECTED_DEALER_FAILED = 'SET_SELECTED_DEALER_FAILED';

export const GET_CUSTOMER_UNIQUE_LAST_NAME_REQUEST = 'GET_CUSTOMER_UNIQUE_LAST_NAME_REQUEST';
export const GET_CUSTOMER_UNIQUE_LAST_NAME_SUCCESS = 'GET_CUSTOMER_UNIQUE_LAST_NAME_SUCCESS';
export const GET_CUSTOMER_UNIQUE_LAST_NAME_FAILED = 'GET_CUSTOMER_UNIQUE_LAST_NAME_FAILED';

export const GET_HELLO_SIGN_STATUS_REQUEST = 'GET_HELLO_SIGN_STATUS_REQUEST';
export const GET_HELLO_SIGN_STATUS_SUCCESS = 'GET_HELLO_SIGN_STATUS_SUCCESS';
export const GET_HELLO_SIGN_STATUS_FAILED = 'GET_HELLO_SIGN_STATUS_FAILED';

export const D_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST = "D_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST";
export const D_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS = "D_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS";
export const D_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED = "D_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED";

export const D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST = "D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST";
export const D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS = "D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS";
export const D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED = "D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED";


export const GET_HISTORY_NORTHRIDGE_DATA_REQUEST = "GET_HISTORY_NORTHRIDGE_DATA_REQUEST ";
export const GET_HISTORY_NORTHRIDGE_DATA_SUCCESS = "GET_HISTORY_NORTHRIDGE_DATA_SUCCESS ";
export const GET_HISTORY_NORTHRIDGE_DATA_FAILED = "GET_HISTORY_NORTHRIDGE_DATA_FAILED ";

/*
export const GENERATE_ORDER_REQUEST = "GENERATE_ORDER_REQUEST";
export const GENERATE_ORDER_REQUEST_SUCCESS = "GENERATE_ORDER_REQUEST_SUCCESS";
export const GENERATE_ORDER_REQUEST_FAILED = "GENERATE_ORDER_REQUEST_FAILED";

export const SELECT_CUSTOMER_SUCCESS = "SELECT_CUSTOMER_SUCCESS";

export const SEARCH_DEALER_REQUEST = "SEARCH_DEALER_REQUEST";
export const SEARCH_DEALER_SUCCESS = "SEARCH_DEALER_SUCCESS";
export const SEARCH_DEALER_FAILED = "SEARCH_DEALER_FAILED";
*/


export function getStaffs() {
    return async function (dispatch) {
        dispatch({
            type: GET_STAFFS_REQUESTS,
        });
        try {
            const response = await API.get(`/accounts/users`);
            dispatch({
                type: GET_STAFFS_REQUESTS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_STAFFS_REQUESTS_FAILED,
            });
        }
    };
}

export function setStaff(item) {
    return async function (dispatch) {
        dispatch({
            type: SET_STAFFS_REQUEST,
            payload: item,
        });
    };
}

export function updateStaff(history, data) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_STAFFS_REQUEST,
        });
        try {
            await API.post(`/accounts/update-user`, { ...data });
            dispatch({
                type: UPDATE_STAFFS_REQUEST_SUCCESS,
                payload: "",
            });
            addEvent("staff_updated", "staff-data-updated-success", {
                staff_id: data.id,
            });
            pushNotification(
                notificationMsg.REQUEST_SUCCESS,
                "success",
                "TOP_RIGHT",
                3000
            );
            history && history.push("/dealer/staff");
        } catch (error) {
            pushNotification(error.response.data.message, "error", "TOP_RIGHT", 3000);
            dispatch({
                type: UPDATE_STAFFS_REQUEST_FAILED,
            });
            addEvent("staff_updated", "staff-data-updated-failed", {
                staff_id: data.id,
            });
        }
    };
}

export function addStaff(history, data) {
    return async function (dispatch) {
        dispatch({
            type: ADD_STAFFS_REQUEST,
        });
        try {
            await API.post(`/accounts/invite`, { ...data });
            dispatch({
                type: ADD_STAFFS_REQUEST_SUCCESS,
            });
            addEvent("staff_added", "staff-data-added-success", {
                staff_role: data.role,
                staff_email: data.email,
            });
            pushNotification(
                notificationMsg.REQUEST_SUCCESS,
                "success",
                "TOP_RIGHT",
                3000
            );
            history && history.push("/dealer/staff");
        } catch (error) {
            pushNotification(error.response.data.message, "error", "TOP_RIGHT", 3000);
            dispatch({
                type: ADD_STAFFS_REQUEST_FAILED,
            });
            addEvent("staff_added", "staff-data-added-failed", {
                staff_role: data.role,
                staff_email: data.email,
            });
        }
    };
}

export function deleteStaff(id) {
    return async function (dispatch) {
        dispatch({
            type: DELETE_STAFFS_REQUEST,
        });
        try {
            await API.put(`/accounts/user-delete/${id}`);
            dispatch({
                type: DELETE_STAFFS_REQUEST_SUCCESS,
                payload: id,
            });
            addEvent("staff_updated", "staff-data-updated-success", { staff_id: id });
            pushNotification(
                notificationMsg.REQUEST_SUCCESS,
                "success",
                "TOP_RIGHT",
                3000
            );
        } catch (error) {
            pushNotification(error.response.data.message, "error", "TOP_RIGHT", 3000);
            dispatch({
                type: DELETE_STAFFS_REQUEST_FAILED,
            });
            addEvent("staff_updated", "staff-data-updated-failed", { staff_id: id });
        }
    };
}

export function showSingleRowdata(app_id) {
    return async function (dispatch) {
        dispatch({
            type: TOGGLE_SHOW_DATA_SUCCESS,
            payload: app_id,
        });
        // console.log(app_id)
    };
}

export function getCustomerSearchData(data) {
    return async function (dispatch) {
        dispatch({
            type: GET_CUSTOMER_SEARCH_REQUEST,
        });
        try {
            const response = await API.post(`/sales/dealer-search-customer`, data);
            console.log('response',response)
            let temp_data = response.data.data
            // let temp_data = response && response.data && response.data.data ? response.data.data.filter(c => c.app_id !== null) : null
            console.log('tempdata', temp_data)
            if (temp_data && temp_data.length > 0) {
                pushNotification(response.data.message, "success", "TOP_RIGHT", 3000);
            } else {
                pushNotification("No results found", "error", "TOP_RIGHT", 3000);
            }
            dispatch({
                type: GET_CUSTOMER_SEARCH_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            pushNotification(error.response.data.message, "error", "TOP_RIGHT", 3000);
            dispatch({
                type: GET_CUSTOMER_SEARCH_FAILED,
            });
        }
    };
}

export function clearCustomerSearchArr() {
    return async function (dispatch) {
        dispatch({
            type: GET_CUSTOMER_SEARCH_FAILED
        });
    };
}

export function getPreApprovals(makeBlank = true) {
    return async function (dispatch) {
        if (makeBlank) {
            dispatch({
                type: PREAPPROVAL_SALES_REQUEST,
            });
        }
        try {
            const response = await API.get(`/sales/sales-preapproval-list`);
            dispatch({
                type: PREAPPROVAL_SALES_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_STAFFS_REQUESTS_FAILED,
            });
        }
    };
}

export function getFundedSchedules(startDate, endDate, makeBlank = true) {

    let temp = {
        start_date: startDate ? moment(new Date(startDate)).format("YYYY-MM-DD") : startDate,
        end_date: endDate ? moment(new Date(endDate)).format("YYYY-MM-DD") : endDate
    }

    return async function (dispatch) {
        if (makeBlank) {
            dispatch({
                type: FUNDED_SCHEDULED_REQUEST,
            });
        }
        try {
            const response = await API.post(`/sales/scheduled-funded-list`, temp);
            dispatch({
                type: FUNDED_SCHEDULED_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: FUNDED_SCHEDULED_FAILED,
            });
        }
    };
}

export function getCustomerProfile(history, id, customer_CIF, profileType = 'dealer', makeEmpty = true) {

    let temp_data = {
        app_id: id,
        cif_number: customer_CIF ? customer_CIF : null
    };

    return async function (dispatch) {
        if (makeEmpty) {
            dispatch({
                type: GET_CUSTOMER_PROFILE_REQUEST,
            });
        }

        try {
            const response = await API.post(`/sales/customer-profile`, temp_data);
            console.log(response)
            history.push('/dealer/profile')
            dispatch({  
                type: GET_CUSTOMER_PROFILE_SUCCESS,
                payload: response.data,
            });
            localStorage.setItem('activeCustomer', id)
            if (makeEmpty) {
                console.log(history)
                history && history.push("/dealer/profile");
            }

        } catch (error) {
            dispatch({
                type: GET_CUSTOMER_PROFILE_FAILED,
            });
        }
    };
}

export function getCustomerPaperWork(id) {
    console.log(id)

    let temp_data = {
        app_id: id,
    };
    console.log(temp_data)
    return async function (dispatch) {
        dispatch({
            type: GET_PAPERWORK_REQUEST,
        });
        try {
            const response = await API.post(`/sales/customer-paperwork`, temp_data);
            dispatch({
                type: GET_PAPERWORK_SUCCESS,
                payload: response.data,
            });
            return true
        } catch (error) {
            dispatch({
                type: GET_PAPERWORK_FAILED,
            });
        }
    };
}

export function getCustomerHistory(id, cif_number) {
    console.log(id)

    let temp_data = {
        app_id: id,
        cif_number: cif_number,
    };

    return async function (dispatch) {
        dispatch({
            type: GET_HISTORY_REQUEST,
        });
        try {
            const response = await API.post(`/sales/customer-history`, temp_data);
            console.log(response)
            if(response.data.status == "error") {
                pushNotification(
                    response.data.message,
                    'error',
                    'TOP_RIGHT',
                    3000
                );
                dispatch({
                    type: GET_HISTORY_FAILED,
                });
            } else {
            dispatch({
                type: GET_HISTORY_SUCCESS,
                payload: response.data,
            });
        }
           
        } catch (error) {
            console.log(error,error.response)
            pushNotification(
                error.response.data.message,
                'error',
                'TOP_RIGHT',
                3000
            );
            dispatch({
                type: GET_HISTORY_FAILED,
            });
        }
    };
}

export function getCustomerCredit(id) {

    let temp_data = {
        app_id: id,
    };

    return async function (dispatch) {
        dispatch({
            type: GET_CREDIT_REQUEST,
        });
        try {
            const response = await API.post(`/sales/customer-credit`, temp_data);
            dispatch({
                type: GET_CREDIT_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_CREDIT_FAILED,
            });
        }
    };
}



export function requestPreApproval(data) {

    // let temp_data = {
    //     action: "request_preapproval",
    //     app_id: id,
    // };

    return async function (dispatch) {
        dispatch({
            type: GENERATE_PREAPPROVAL_REQUEST,
        });
        try {
            const response = await API.post(`/sales/request-preapproval`, data);
            dispatch({
                type: GENERATE_PREAPPROVAL_SUCCESS,
            });
            pushNotification("Updated Successfully", 'success', 'TOP_RIGHT', 3000);
        } catch (error) {
            dispatch({
                type: GENERATE_PREAPPROVAL_FAILED,
            });
        }
    };
}


export function requestFunding(id) {
    return async function (dispatch) {
        dispatch({
            type: REQUEST_FUNDING_REQUEST,
        });
        try {
           const res =  await API.post(`/sales/request-funding`, { app_id: id });
            try {
                const response = await API.get(`/sales/sales-preapproval-list`);
                dispatch({
                    type: PREAPPROVAL_SALES_SUCCESS,
                    payload: response.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_STAFFS_REQUESTS_FAILED,
                });
            }
            dispatch({
                type: REQUEST_FUNDING_SUCCESS,
            });
            pushNotification(res.data.message, res.data.status, 'TOP_RIGHT', 3000);
        } catch (error) {
            dispatch({
                type: REQUEST_FUNDING_FAILED,
            });
        }
    };
}


export function itemValueAdjustment(data, isDealerScreen) {
    return async function (dispatch) {
        dispatch({
            type: REQUEST_ADJUST_FUNDING_REQUEST,
        });
        try {
            const response = await API.post(`/sales/item-value-adjustment`, data);
            if (isDealerScreen == 1) {
                let id = localStorage.getItem('activeDealer');
                try {
                    const response = await API.post(`/sales/dealer-customers`, { dealer_id: id });
                    dispatch({
                        type: SET_SELECTED_DEALER_SUCCESS,
                        payload: response.data,
                    })
                } catch (error) {
                    dispatch({
                        type: SET_SELECTED_DEALER_FAILED,
                    });
                }
            }
            else if (isDealerScreen == 2) {
                try {
                    const response = await API.post(`/sales/scheduled-funded-list`);
                    dispatch({
                        type: FUNDED_SCHEDULED_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: FUNDED_SCHEDULED_FAILED,
                    });
                }
            }
            else {
                try {
                    const response = await API.post(`/sales/scheduled-funding`);
                    dispatch({
                        type: GET_FUNDING_REQUESTS_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: GET_FUNDING_REQUESTS_FAILED,
                    });
                }
            }
            dispatch({
                type: REQUEST_ADJUST_FUNDING_SUCCESS,
            });
            pushNotification("Successful", 'success', 'TOP_RIGHT', 3000);
            console.log(response)
        } catch (error) {
            dispatch({
                type: REQUEST_ADJUST_FUNDING_FAILED,
            });
        }
    };
}


export function removeItemAdjustment(data, isDealerScreen) {
    return async function (dispatch) {
        dispatch({
            type: REQUEST_ADJUST_FUNDING_REQUEST,
        });
        try {
            const response = await API.post(`/sales/adjustment-remove`, data);
            if (isDealerScreen == 1) {
                let id = localStorage.getItem('activeDealer');
                try {
                    const response = await API.post(`/sales/dealer-customers`, { dealer_id: id });
                    dispatch({
                        type: SET_SELECTED_DEALER_SUCCESS,
                        payload: response.data,
                    })
                } catch (error) {
                    dispatch({
                        type: SET_SELECTED_DEALER_FAILED,
                    });
                }
            }
            else if (isDealerScreen == 2) {
                try {
                    const response = await API.post(`/sales/scheduled-funded-list`);
                    dispatch({
                        type: FUNDED_SCHEDULED_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: FUNDED_SCHEDULED_FAILED,
                    });
                }
            }
            else {
                try {
                    const response = await API.post(`/sales/scheduled-funding`);
                    dispatch({
                        type: GET_FUNDING_REQUESTS_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: GET_FUNDING_REQUESTS_FAILED,
                    });
                }
            }
            dispatch({
                type: REQUEST_ADJUST_FUNDING_SUCCESS,
            });
            pushNotification(response.data.message, response.data.status, 'TOP_RIGHT', 3000);
        } catch (error) {
            dispatch({
                type: REQUEST_ADJUST_FUNDING_FAILED,
            });
        }
    };
}

export function updateGlobalItemAdjustment(data, isDealerScreen) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_GLOBAL_ITEM_ADJUSTMENT,
        });
        try {
            const response = await API.post(`/sales/total-value-adjustment`, data);
           
                if (isDealerScreen == 1) {
                    let id = localStorage.getItem('activeDealer');
                    try {
                        const response = await API.post(`/sales/dealer-customers`, { dealer_id: id });
                        dispatch({
                            type: SET_SELECTED_DEALER_SUCCESS,
                            payload: response.data,
                        })
                    } catch (error) {
                        dispatch({
                            type: SET_SELECTED_DEALER_FAILED,
                        });
                    }
                }  else if (isDealerScreen == 2) {
                    try {
                        const response = await API.post(`/sales/scheduled-funded-list`);
                        dispatch({
                            type: FUNDED_SCHEDULED_SUCCESS,
                            payload: response.data,
                        });
                    } catch (error) {
                        dispatch({
                            type: FUNDED_SCHEDULED_FAILED,
                        });
                    }
                }else {
                    try {
                        const response = await API.post(`/sales/scheduled-funding`);
                        dispatch({
                            type: GET_FUNDING_REQUESTS_SUCCESS,
                            payload: response.data,
                        });
                    } catch (error) {
                        dispatch({
                            type: GET_FUNDING_REQUESTS_FAILED,
                        });
                    }
                }
                dispatch({
                    type: UPDATE_GLOBAL_ITEM_ADJUSTMENT_SUCCESS,
                });
            pushNotification(response.data.message, response.data.status, 'TOP_RIGHT', 3000);
        } catch (error) {
            console.log(error)
            console.log(error.response)
            dispatch({
                type: UPDATE_GLOBAL_ITEM_ADJUSTMENT_FAILED,
            });
            pushNotification(error.message, 'error', 'TOP_RIGHT', 3000);
        }
    };
}

export function removeGlobalItemAdjustment(data, isDealerScreen) {
    return async function (dispatch) {
        dispatch({
            type: REMOVE_GLOBAL_ITEM_ADJUSTMENT,
        });
        try {
            const response = await API.post(`/sales/total-adjustment-remove`, data);
            if (isDealerScreen == 1) {
                let id = localStorage.getItem('activeDealer');
                try {
                    const response = await API.post(`/sales/dealer-customers`, { dealer_id: id });
                    dispatch({
                        type: SET_SELECTED_DEALER_SUCCESS,
                        payload: response.data,
                    })
                } catch (error) {
                    dispatch({
                        type: SET_SELECTED_DEALER_FAILED,
                    });
                }
            }  else if (isDealerScreen == 2) {
                try {
                    const response = await API.post(`/sales/scheduled-funded-list`);
                    dispatch({
                        type: FUNDED_SCHEDULED_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: FUNDED_SCHEDULED_FAILED,
                    });
                }
            }else {
                try {
                    const response = await API.post(`/sales/scheduled-funding`);
                    dispatch({
                        type: GET_FUNDING_REQUESTS_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: GET_FUNDING_REQUESTS_FAILED,
                    });
                }
            }
            dispatch({
                type: REMOVE_GLOBAL_ITEM_ADJUSTMENT_SUCCESS,
            });
            pushNotification(response.data.message, response.data.status, 'TOP_RIGHT', 3000);
        } catch (error) {
            dispatch({
                type: REMOVE_GLOBAL_ITEM_ADJUSTMENT_FAILED,
            });
            pushNotification(error.message, 'error', 'TOP_RIGHT', 3000);
        }
    };
}


export function cancelPreapprovalItem(data) {
    return async function (dispatch) {
        dispatch({
            type: REQUEST_ADJUST_FUNDING_REQUEST,
        });
        try {
            const response = await API.post(`/sales/update-preapproval`, data);
            try {
                const response = await API.get(`/sales/sales-preapproval-list`);
                dispatch({
                    type: PREAPPROVAL_SALES_SUCCESS,
                    payload: response.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_STAFFS_REQUESTS_FAILED,
                });
            }
            dispatch({
                type: REQUEST_ADJUST_FUNDING_SUCCESS,
            });
            pushNotification("Successfull", 'success', 'TOP_RIGHT', 3000);
            console.log(response)
        } catch (error) {
            dispatch({
                type: REQUEST_ADJUST_FUNDING_FAILED,
            });
        }
    };
}


export function getCustomerLastNameAction() {
    return async function (dispatch) {
        dispatch({
            type: GET_CUSTOMER_UNIQUE_LAST_NAME_REQUEST,
        });
        try {
            const response = await API.get(`/sales/customer-lastname`);
            dispatch({
                type: GET_CUSTOMER_UNIQUE_LAST_NAME_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_CUSTOMER_UNIQUE_LAST_NAME_FAILED,
            });
        }
    };
}


export function getHelloSignStatus(product_id) {
    return async function (dispatch) {
        dispatch({
            type: GET_HELLO_SIGN_STATUS_REQUEST,
        });
        try {
            const response = await API.post(`sales/hellosign-status`, { product_id: 291 });
            console.log(response);
            dispatch({
                type: GET_HELLO_SIGN_STATUS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_HELLO_SIGN_STATUS_FAILED,
            });
        }
    };
}


export function getProductSignatureDetailsDealer(data) {
    return async function (dispatch) {
        dispatch({
            type: D_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST,
        });
        try {
            const response = await API.post(`/sales/hellosign-status`, data);
            // console.log(response);
            dispatch({
                type: D_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS,
                payload: { ...data, hello_sign: response.data && response.data.data ? response.data.data : [] },
            });
        } catch (error) {
            dispatch({
                type: D_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED,
            });
            console.log("error");
        }
    }
}


export function getPaperWorkProductSignatureDetails(data) {
    return async function (dispatch) {
        dispatch({
            type: D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST,
        });
        try {
            const response = await API.post(`/sales/hellosign-status`, data);
            // console.log(response);
            dispatch({
                type: D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS,
                payload: { ...data, hello_sign: response.data && response.data.data ? response.data.data : [] },
            });
        } catch (error) {
            dispatch({
                type: D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED,
            });
            console.log("error");
        }
    }
}



export function getHistoryNorthridgeData(data) {
    return async function (dispatch) {
        dispatch({
            type: GET_HISTORY_NORTHRIDGE_DATA_REQUEST,
        });
        try {
            const response = await API.get(`sales/nortridge-loanpayment/${data}`);
            dispatch({
                type: GET_HISTORY_NORTHRIDGE_DATA_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_HISTORY_NORTHRIDGE_DATA_FAILED,
            });
        }
    };
}
import React, { Fragment, useState, useEffect } from 'react';
import Header, {
    HeaderCenter,
    HeaderLeft,
    HeaderRight,
} from '../../../../components/Dealer/Header';
import {
    IconHome,
    IconSearchAdmin,
    IconDashboardSalesApp
} from '../../../../assets/images';
import { Row, Col, Form } from 'react-bootstrap';
import { getFormattedDateTime } from '../../../../components/commons/utility';
import { connect } from 'react-redux';
import { getCustomerApiInitiateForGenerateOrder, getSalesArchive } from 'redux/actions/sales';
import Loader from "shared/Loader";

export function SalesArchive(props) {

    const {
        history,
        salesArchive,
        getSalesArchive,
        getCustomerApiInitiateForGenerateOrder,
        salesHistory
    } = props;

    console.log('props',props)

    console.log(salesHistory.data[0].app_id)

    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
    const [search, setSearch] = useState('');
    const [filterHistory, setFilterHistory] = useState([]);

    useEffect(() => {
        getSalesArchive();
    }, [])

    useEffect(() => {
        if (search != null && search !== '') {
            let temp = salesArchive.data.filter((d) =>
                JSON.stringify(d.customer_data).toLowerCase().includes(search.toLowerCase())
            );
            setFilterHistory(temp);
        } else {
            setFilterHistory(salesArchive ? salesArchive.data : []);
        }
    }, [search, salesArchive]);

    const renderPurchasedItems = (items) => {
        return items.map((item) => {
            return (
                <div className="customer-purchase-item" key={`product_${item.product_id}`}>
                    {item.product_type}
                </div>
            );
        });
    };

    const goToSummary = (item) => {
        console.log('data:', item);
        let data = {
            customer_id: item.customer_data.customer_id || null,
            co_customer_id:item.customer_data.co_customer_id || null,
            include_products: true,
        };
        console.log(data)
        getCustomerApiInitiateForGenerateOrder(data, history, '/applyApplicationSummary')
        // history && history.push('/applyApplicationSummary');
    }

    return (
        <div className="admin">

            {salesArchive && salesArchive.loading && <Loader />}

            <div style={{position : 'relative'}}>
            <Header>
               
                <HeaderLeft>
                    <div className='d-flex flex-wrap justify-content-center align-items-center'>
                    <img
                        src={IconHome}
                        alt=""
                        onClick={() => history && history.push('/')}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i class="fa fa-history" aria-hidden="true" style={{ transform: 'none', fontSize: '22px', color: '#B2D8F7' }} onClick={() => history && history.push('/salesHistory')}></i>
                    </div>
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main" style={{position : 'absolute', top : '50%', left : '50%', transform : 'translate(-50%, -50%'}}>ARCHIVE</div>
                </HeaderCenter>
                <HeaderRight>
                <div className='d-flex flex-wrap justify-content-center align-items-center'>
                    <img
                        src={IconSearchAdmin}
                        alt=""
                        onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}
                    />
                    </div>
                </HeaderRight>
            </Header>
            </div>

            {isSearchBarVisible && (
                <div className="search-header">
                    <form action="javascript:void(0)">
                        <Form.Group>
                            <Form.Control
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            )}

            <div className="main">
                <div className="list fill-screen">
                    <Fragment>
                    {salesArchive && salesArchive.data && filterHistory.map((item) => {
                        console.log(item)
                        return (
                            <Row className={`single-row`} style={{ margin: '12px 0' }}>
                                <div className="customer-row">
                                    <Col>
                                        <div onClick={() => goToSummary(item)}>
                                            <div className='customer-name-no-wrpapper'><span style={{color : '#c9e1ed'}}>{item.customer_data.customer_name}</span><br/>
                                            <span style={{ fontSize: 12, color: '#b2d8f7' }}>{item.customer_data.email}</span></div>
                                            <div>
                                                {item.credit_app ?
                                                <span style={{ fontSize: 12, color: 'rgb(110 153 191)' }}>{getFormattedDateTime(item.credit_app_date)}</span>
                                                :
                                                <span style={{ fontSize: 12, color: 'rgb(110 153 191)' }}>{getFormattedDateTime(item.sales_app_date)}</span>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="content-col text-left" style={{ padding: 5    }}>
                                        {item.credit_app &&
                                            <div className="customer-purchase-item" style={{color : '#c9e1ed'}}>
                                                Credit Application
                                            </div>
                                        }
                                        {item.products && renderPurchasedItems(item.products)}
                                    </Col>
                                </div>
                            </Row>
                        )
                    })}
                    </Fragment>
                </div>
            </div>
        </div>
    );
}




const mapStateToProps = (state) => ({
    salesArchive: state.sales.salesArchive,
    salesHistory: state.sales.salesHistory,

});

const mapDispatchToProps = (dispatch) => ({
    getSalesArchive: (data) => dispatch(getSalesArchive(data)),
    getCustomerApiInitiateForGenerateOrder: (data, history, path) => dispatch(getCustomerApiInitiateForGenerateOrder(data, history, path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesArchive);
import React, { useEffect, useLayoutEffect, useReducer, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import Header, {
    HeaderLeft,
    HeaderCenter,
    HeaderRight,
} from '../../../../components/Dealer/Header';
import { TCPLogo, IconArrowLeft } from '../../../../assets/images';
import { ExpandIcon } from '../Home/Review/components/ExpandIcon';
import { ToggleButton } from '../Home/Review/components/ToggleButton/ToggleButton';
import {
    sendDealerSettingData,
    viewDealerSettingData,
} from '../../../../redux/actions/admin';
import { setLoanProductName } from 'utils/helper';
import Input from 'components/commons/input';
import Loader from 'shared/Loader';
// import { getFromData } from '../../../components/commons/utility';
import justChevron from '../../../../assets/images/justChevron.svg'
import CircularActive from '../../../../assets/images/CircuralActive.svg'
import CircuralInactiveLight from '../../../../assets/images/CircuralInactiveLight.svg'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

export function DealerSettings(props) {
    const {
        history,
        dealer,
        loading,
        addDealer,
        updateDealer,
        actionLoading,
        activeTab = 0,
        sendDealerSettingData,
        viewDealerSettingData,
        dealerSetting,
    } = props;

    console.log('dealersetting', props);
    const data = useSelector((state) => console.log(state));

    const [expand, setExpand] = useState('');
    const [fundingFee, setFundingFee] = useState('');
    const [fundingDocument, setFundingDocument] = useState('');
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    // useEffect(() => {
    //     viewDealerSettingData({ 'dealer_id': dealer.id });
    // }, [])

    // useEffect(() => {
    //     dealerSetting && dealerSetting.data ?
    //         setFundingFee(dealerSetting.data.funding_fee)
    //         :
    //         setFundingFee('0')
    // }, [dealerSetting])

    const [tierUpdate, setTierUpdate] = useState([
        { discount: '', monthlyRate: '', fee: '' },
        { discount: '', monthlyRate: '', fee: '' },
        { discount: '', monthlyRate: '', fee: '' },
        { discount: '', monthlyRate: '', fee: '' },
        { discount: '', monthlyRate: '', fee: '' },
        { discount: '', monthlyRate: '', fee: '' },
        { discount: '', monthlyRate: '', fee: '' },
        { discount: '', monthlyRate: '', fee: '' },
        { discount: '', monthlyRate: '', fee: '' },
        { discount: '', monthlyRate: '', fee: '' },
    ]);

    const [frequency, setFrequency] = useState([
        {
            value: 1,
            label: 'M',
            checked:
                dealerSetting &&
                    dealerSetting.data &&
                    dealerSetting.data.funding_frequency &&
                    dealerSetting.data.funding_frequency.includes(1)
                    ? true
                    : false,
        },
        {
            value: 2,
            label: 'T',
            checked:
                dealerSetting &&
                    dealerSetting.data &&
                    dealerSetting.data.funding_frequency &&
                    dealerSetting.data.funding_frequency.includes(2)
                    ? true
                    : false,
        },
        {
            value: 3,
            label: 'W',
            checked:
                dealerSetting &&
                    dealerSetting.data &&
                    dealerSetting.data.funding_frequency &&
                    dealerSetting.data.funding_frequency.includes(3)
                    ? true
                    : false,
        },
        {
            value: 4,
            label: 'T',
            checked:
                dealerSetting &&
                    dealerSetting.data &&
                    dealerSetting.data.funding_frequency &&
                    dealerSetting.data.funding_frequency.includes(4)
                    ? true
                    : false,
        },
        {
            value: 5,
            label: 'F',
            checked:
                dealerSetting &&
                    dealerSetting.data &&
                    dealerSetting.data.funding_frequency &&
                    dealerSetting.data.funding_frequency.includes(5)
                    ? true
                    : false,
        },
    ]);

    const handleArrowBack = () => {
        history.push('/admin/dealer');
    };

    const handleExpand = (idx) => {
        if (expand == idx) {
            setExpand('');
        } else {
            setExpand(idx);
        }
    };

    const changeItemActive = (dealer, isActive) => {
        console.log(isActive);
        if (isActive == true) {
            setFundingDocument(false);
        } else {
            setFundingDocument(true);
        }
    };

    const handleSubmit = async (e, idx) => {
        console.log(idx);
        let productIdx = idx;
        e.preventDefault();
        let temp = {
            dealer_id: dealer.id,
            funding_fees:
                parseInt(fundingFee) || dealerSetting.data.funding_fee,
            funding_frequency: frequency
                .filter((item) => item.checked)
                .map((item) => item.value),
            funding_document:
                fundingDocument || dealerSetting.data.documents_required,
            product_tiers_config: [
                {
                    product_type:
                        (idx == 0 && 'Food/Mac') ||
                        (idx == 1 && 'Full Service Program') ||
                        (idx == 2 && 'Other Appliance'),
                    product_tier_details: tierUpdate.map((tier, idx) => {
                        console.log(tier)
                        return {
                            dealer_id: dealer.id,
                            tier: idx + 1,
                            total_discount:
                                tier.discount == '' ? 0 : tier.discount ||
                                    (dealerSetting.data.product_tiers_config &&
                                        dealerSetting.data.product_tiers_config[
                                        productIdx
                                        ] &&
                                        dealerSetting.data.product_tiers_config[
                                            productIdx
                                        ].product_tier_details[idx].discount),
                            monthly_rate:
                                tier.monthlyRate == '' ? 0 : tier.monthlyRate ||
                                    (dealerSetting.data.product_tiers_config &&
                                        dealerSetting.data.product_tiers_config[
                                        productIdx
                                        ] &&
                                        dealerSetting.data.product_tiers_config[
                                            productIdx
                                        ].product_tier_details[idx].monthly_rate),
                            total_fees:
                                tier.fee == '' ? 0 : tier.fee ||
                                    (dealerSetting.data.product_tiers_config &&
                                        dealerSetting.data.product_tiers_config[
                                        productIdx
                                        ] &&
                                        dealerSetting.data.product_tiers_config[
                                            productIdx
                                        ].product_tier_details[idx].fee),
                        };
                    }),
                },
            ],
        };
        // console.log(temp)
        sendDealerSettingData(temp);
        setFundingFee('');
        setFundingDocument(false);
        setTierUpdate([
            { discount: '', monthlyRate: '', fee: '' },
            { discount: '', monthlyRate: '', fee: '' },
            { discount: '', monthlyRate: '', fee: '' },
            { discount: '', monthlyRate: '', fee: '' },
            { discount: '', monthlyRate: '', fee: '' },
            { discount: '', monthlyRate: '', fee: '' },
            { discount: '', monthlyRate: '', fee: '' },
            { discount: '', monthlyRate: '', fee: '' },
            { discount: '', monthlyRate: '', fee: '' },
            { discount: '', monthlyRate: '', fee: '' },
        ]);
        setFrequency([
            { value: 1, label: 'M', checked: false },
            { value: 2, label: 'T', checked: false },
            { value: 3, label: 'W', checked: false },
            { value: 4, label: 'T', checked: false },
            { value: 5, label: 'F', checked: false },
        ]);
    };

    const handleChange = (e, idx) => {
        console.log(idx);
        const { value, checked } = e.target;
        console.log(`${value} is ${checked} `);
        var temp = frequency;
        temp[idx].checked = checked;
        console.log(temp, [...temp]);
        setFrequency([...temp]);
    };

    // const data = dealerSetting && dealerSetting.data && dealerSetting.data[0].map((ds) => {
    //     return (
    //        <p> {ds.document_requied}</p>
    //     )
    // })

    // useEffect(() => {
    //     viewDealerSettingData({ dealer_id: dealer.id });
    //     // window.location.reload()
    // }, []);

    // useEffect(() => {
    //     if(!props.loading) {
    //     forceUpdate()
    //     console.log('some')
    //     }
    // },[props.loading])

    useLayoutEffect(() => {
        viewDealerSettingData({ dealer_id: dealer.id });
    }, [])

    return (
        <div className="admin mb-5 pb-5">
            {loading && <Loader />}
            {props.dealerSetting && (
                <React.Fragment>
                    <div style={{position : 'sticky', top : 0, zIndex : 2}}>
                    <Header>
                        <HeaderLeft>
                            <img
                                src={IconArrowLeft}
                                onClick={() => handleArrowBack()}
                                alt=""
                            />
                        </HeaderLeft>
                        <HeaderCenter>
                            <div className="header-main">
                                {dealer.company_name}
                            </div>
                        </HeaderCenter>
                        <HeaderRight></HeaderRight>
                    </Header>
                    </div>
                    <div className="dealer-setting-accordian">
                        <div className='top-parent-header' style={{position : 'sticky', top : 48, zIndex : 2}}>
                            <p>PRODUCT/SERVICE</p>
                        </div>
                    <Accordion allowZeroExpanded>
                        {dealer &&
                            dealer.dealer_product &&
                            dealer.dealer_product.map((item, idx) => {
                                let productCategoryId = idx;
                                console.log('delaerinsiderrender', item);
                                console.log(dealer);
                                console.log(dealerSetting);
                                console.log(props.dealerSetting);









                                return (
                                    <AccordionItem key={idx} onClick={() => {console.log('some'); console.log(idx)}}>
                                        <div className="dealerSetting-product mt-2" style={{padding : 0}}>
                                            <AccordionItemHeading style={{padding : '7px 26px', position:'relative'}}>
                                                <AccordionItemButton>
                                                    <div>
                                                    <Row className="service">
                                                        <Col md="9" xs="9">
                                                            <p>
                                                                {' '}
                                                                <span className="text ml-2" style={{color : "#b2d8f7", fontSize : 14}}>
                                                                    {(idx == 0 && 'Food/MAC') ||
                                                                        (idx == 1 &&
                                                                            'Full Service Program') ||
                                                                        (idx == 2 &&
                                                                            'Other Appliance')}
                                                                </span>
                                                            </p>
                                                        </Col>
                                                        <AccordionItemState>
                    {({ expanded }) => (expanded ? <> 
                    
                    <Col
                                                            md="3"
                                                            xs="3"
                                                            className="text-right setting-expand-icon"
                                                            onClick={() => {
                                                                handleExpand(idx);
                                                            }}
                                                        >   
                                                        <div>
                                                           {/* < <ExpandIcon className="expand" 
                                                            />> */}
                                                            <img src={CircularActive} alt="CircularActive"/>
                                                            </div>

                                                            
                                                        </Col> </>:  <Col
                                                            md="3"
                                                            xs="3"
                                                            className="text-right"
                                                            onClick={() => {
                                                                handleExpand(idx);
                                                            }}
                                                        >
                                                            {/* <ExpandIcon className="expand" /> */}
                                                            <img src={CircuralInactiveLight} alt="CircuralInactiveLight"/>

                                                            
                                                        </Col>)}
                </AccordionItemState>

                <AccordionItemState>
                    {({ expanded }) => (expanded ? <div style={{border : 'solid 1.2px #009d86', width : '100%', position : 'absolute', bottom : 7, right : 0, background : "#009d86"}}/> : null)}
                </AccordionItemState>
                                                       
                                                    </Row>
                                                    </div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel style={{padding : 0}}>

                                                {idx == expand && (
                                                    <form
                                                        onSubmit={(e) =>
                                                            handleSubmit(e, idx)
                                                        }
                                                        style={{position : 'relative'}}
                                                    >
                                                        <div className="text-center" style={{padding : '0 26px'}}>
                                                            {/* {
                                        dealerSetting && dealerSetting.data && dealerSetting.data.product_tiers_config && dealerSetting.data.product_tiers_config.map((tier, idx) => {
                                            console.log('tier',tier)
                                            tier.product_tier_details.map((tierDetail, idx) => {
                                                console.log('tierDetail',tierDetail)
                                                return (
                                                    <div className='tier mt-2'>
                                                       <h1>data</h1>
                                                    </div>
                                                )
                                            })
                                    })
                                } */}
                                                            {tierUpdate &&
                                                                tierUpdate.map(
                                                                    (item, idx) => {
                                                                        // console.log('teiritem', item);
                                                                        return (
                                                                            <Form.Group className="mb-18 mt-3 d-flex align-items-center justify-content-center">
                                                                                <span className="mr-3 tier dealer-setting-tier" style={{marginTop : idx == 0 ? 23 : 0}}>
                                                                                    Tier{' '}
                                                                                    {idx +
                                                                                        1}
                                                                                </span>
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            'grid',
                                                                                    }}
                                                                                >
                                                                                    {idx ==
                                                                                        0 && (
                                                                                            <label
                                                                                                htmlFor={`discount${idx}`}
                                                                                                className="title discount_title"
                                                                                            >
                                                                                                Discount
                                                                                            </label>
                                                                                        )}
                                                                                        <div style={{position : 'relative'}}>
                                                                                    <Input
                                                                                        type="text"
                                                                                        className="setting-field change-setting-field discount-field"
                                                                                        defaultText="0"
                                                                                        value={
                                                                                            dealerSetting &&
                                                                                            dealerSetting.data &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                            productCategoryId
                                                                                            ] &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                                productCategoryId
                                                                                            ]
                                                                                                .product_tier_details &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                                productCategoryId
                                                                                            ]
                                                                                                .product_tier_details[
                                                                                            idx
                                                                                            ] &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                                productCategoryId
                                                                                            ]
                                                                                                .product_tier_details[
                                                                                                idx
                                                                                            ]
                                                                                                .discount
                                                                                        }
                                                                                        name={`discount${idx}`}
                                                                                        handleChange={(e) => {
                                                                                            var temp = tierUpdate;
                                                                                            temp[idx].discount =
                                                                                                e.target.value;
                                                                                            setTierUpdate(
                                                                                                [
                                                                                                    ...temp,
                                                                                                ]
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <span className="setting-input-icon-right"><i className="fa fa-percent" aria-hidden="true"></i></span>
                                                                                    </div>
                                                                                    {/* <input
                                                                name={`discount${idx}`}
                                                                type="text"
                                                                className="setting-field"
                                                                value={
                                                                    item.discount ||
                                                                    (dealerSetting &&
                                                                        dealerSetting.data &&
                                                                        dealerSetting
                                                                            .data
                                                                            .product_tiers_config &&
                                                                        dealerSetting
                                                                            .data
                                                                            .product_tiers_config[0] &&
                                                                        dealerSetting
                                                                            .data
                                                                            .product_tiers_config[0]
                                                                            .product_tier_details &&
                                                                        dealerSetting
                                                                            .data
                                                                            .product_tiers_config[0]
                                                                            .product_tier_details[
                                                                            idx
                                                                        ] &&
                                                                        dealerSetting
                                                                            .data
                                                                            .product_tiers_config[0]
                                                                            .product_tier_details[
                                                                            idx
                                                                        ]
                                                                            .total_discount)
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    var temp =
                                                                        tierUpdate;
                                                                    temp[
                                                                        idx
                                                                    ].discount =
                                                                        e.target.value;
                                                                    setTierUpdate(
                                                                        [
                                                                            ...temp,
                                                                        ]
                                                                    );
                                                                }}
                                                            /> */}
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            'grid',
                                                                                    }}
                                                                                >
                                                                                    {idx ==
                                                                                        0 && (
                                                                                            <label
                                                                                                htmlFor={`monthlyRate${idx}`}
                                                                                                className="title discount_title"
                                                                                            >
                                                                                                Monthly
                                                                                                Rate
                                                                                            </label>
                                                                                        )}
                                                                                        <div style={{position : 'relative'}}>
                                                                                    <Input
                                                                                        type="text"
                                                                                        className="setting-field change-setting-field monthly-field"
                                                                                        value={
                                                                                            dealerSetting &&
                                                                                            dealerSetting.data &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                            productCategoryId
                                                                                            ] &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                                productCategoryId
                                                                                            ]
                                                                                                .product_tier_details &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                                productCategoryId
                                                                                            ]
                                                                                                .product_tier_details[
                                                                                            idx
                                                                                            ] &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                                productCategoryId
                                                                                            ]
                                                                                                .product_tier_details[
                                                                                                idx
                                                                                            ]
                                                                                                .monthly_rate
                                                                                        }
                                                                                        name={`monthlyRate${idx}`}
                                                                                        defaultText="0"
                                                                                        handleChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            var temp =
                                                                                                tierUpdate;
                                                                                            temp[
                                                                                                idx
                                                                                            ].monthlyRate =
                                                                                                e.target.value;
                                                                                            setTierUpdate(
                                                                                                [
                                                                                                    ...temp,
                                                                                                ]
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <span className="setting-input-icon-right"><i className="fa fa-percent" aria-hidden="true"></i></span>
                                                                                    </div>
                                                                                    {/* <input
                                                                name={`monthlyRate${idx}`}
                                                                type="text"
                                                                className="setting-field"
                                                                defaultText="%"
                                                                value={
                                                                    item.monthlyRate
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    var temp =
                                                                        tierUpdate;
                                                                    temp[
                                                                        idx
                                                                    ].monthlyRate =
                                                                        e.target.value;
                                                                    setTierUpdate(
                                                                        [
                                                                            ...temp,
                                                                        ]
                                                                    );
                                                                }}
                                                            /> */}
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            'grid',
                                                                                    }}
                                                                                >
                                                                                    {idx ==
                                                                                        0 && (
                                                                                            <label
                                                                                                htmlFor={`fee${idx}`}
                                                                                                className="title discount_title"
                                                                                            >
                                                                                                Fee
                                                                                            </label>
                                                                                        )}
                                                                                    <Input
                                                                                        type="text"
                                                                                        className="setting-field fee-field"
                                                                                        value={
                                                                                            dealerSetting &&
                                                                                            dealerSetting.data &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                            productCategoryId
                                                                                            ] &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                                productCategoryId
                                                                                            ]
                                                                                                .product_tier_details &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                                productCategoryId
                                                                                            ]
                                                                                                .product_tier_details[
                                                                                            idx
                                                                                            ] &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .product_tiers_config[
                                                                                                productCategoryId
                                                                                            ]
                                                                                                .product_tier_details[
                                                                                                idx
                                                                                            ]
                                                                                                .fee
                                                                                        }
                                                                                        name={`fee${idx}`}
                                                                                        defaultText="0"
                                                                                        handleChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            var temp =
                                                                                                tierUpdate;
                                                                                            temp[
                                                                                                idx
                                                                                            ].fee =
                                                                                                e.target.value;
                                                                                            setTierUpdate(
                                                                                                [
                                                                                                    ...temp,
                                                                                                ]
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    {/* <input
                                                                name={`fee${idx}`}
                                                                type="text"
                                                                className="setting-field"
                                                                defaultText=""
                                                                value={item.fee}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    var temp =
                                                                        tierUpdate;
                                                                    temp[
                                                                        idx
                                                                    ].fee =
                                                                        e.target.value;
                                                                    setTierUpdate(
                                                                        [
                                                                            ...temp,
                                                                        ]
                                                                    );
                                                                }}
                                                            /> */}
                                                                                </div>
                                                                            </Form.Group>
                                                                        );
                                                                    }
                                                                )}
                                                        </div>
                                                        <Row className="mt-5" style={{alignItems : 'center', backgroundColor  :"#22323e", padding : '18px 20px'}}>
                                                            <Col xs="6">
                                                                <p style={{paddingLeft : 26, marginBottom : 10}}>
                                                                    Funding Fee
                                                                </p>
                                                                <Input
                                                                    type="number"
                                                                    className="setting-field funding-fee-field"
                                                                    value={
                                                                        props.dealerSetting
                                                                            .data
                                                                            .funding_fee
                                                                    }
                                                                    // value={fundingFee}
                                                                    name="fundingFee"
                                                                    defaultText="0"
                                                                    handleChange={(e) =>
                                                                        setFundingFee(
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                xs="6"
                                                                className="text-center dealerSettingtoggleSwitch"
                                                            >
                                                                <p className="text-center">
                                                                    All the documents have
                                                                    to be submitted before
                                                                    the funding request.
                                                                </p>
                                                                <ToggleButton
                                                                    yesText={'Yes'}
                                                                    noText="No"
                                                                    defaultChecked={
                                                                        dealerSetting.data
                                                                            .documents_required
                                                                    }
                                                                    onChange={(
                                                                        isActive
                                                                    ) => {
                                                                        changeItemActive(
                                                                            dealer,
                                                                            isActive
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <div className="mt-3" style={{padding : '7px 26px'}}>
                                                            <p>Funding Frequency</p>
                                                            <div className="d-flex dealer-setting-funding-frequency">
                                                                {frequency &&
                                                                    frequency.map(
                                                                        (fre, idx) => {
                                                                            return (
                                                                                <div className="container-check">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={
                                                                                            fre.value
                                                                                        }
                                                                                        name={`fre${fre.value}`}
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            console.log(
                                                                                                'idx'
                                                                                            );
                                                                                            handleChange(
                                                                                                e,
                                                                                                idx
                                                                                            );
                                                                                        }}
                                                                                        // checked={
                                                                                        //     fre.checked
                                                                                        // }
                                                                                        defaultChecked={
                                                                                            dealerSetting &&
                                                                                            dealerSetting.data &&
                                                                                            dealerSetting
                                                                                                .data
                                                                                                .funding_frequency &&
                                                                                            dealerSetting.data.funding_frequency.includes(
                                                                                                fre.value
                                                                                            )
                                                                                        }
                                                                                    />

                                                                                    <label
                                                                                        htmlFor={`fre${fre.value}`}
                                                                                        className="checkmark"
                                                                                    >
                                                                                        {
                                                                                            fre.label
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <div className='dealerSettingSbmtContainer'>
                                                        <button
                                                            type="submit"
                                                            className="setting-save"
                                                        >
                                                            Save
                                                        </button>
                                                        </div>
                                                    </form>

                                                )}
                                            </AccordionItemPanel>
                                        </div>

                                    </AccordionItem>
                                );
                            })}
                    </Accordion>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    dealer: state.admin.selectedDealer,
    dealerSetting: state.admin.dealerSetting,
    loading: state.admin.dealerSetting.loading,
});

const mapDispatchToProps = (dispatch) => ({
    sendDealerSettingData: (data) => dispatch(sendDealerSettingData(data)),
    viewDealerSettingData: (data) => dispatch(viewDealerSettingData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerSettings);

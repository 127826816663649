import React from 'react';
import { connect } from 'react-redux';
import Header from '../../../components/Sales/Header';
import Loader from 'shared/Loader';
import { IconList, IconSend, IconArrowLeft, TCPLogo } from '../../../assets/images';

import { submiCreditApplication } from '../../../redux/actions/sales';

function PreSummary(props) {

    const {
        history,
        customer,
        actionLoading,
        submiCreditApplication
    } = props;

    const handleArrowBack = () => {
        history.replace('/applyApplicationEmployement');        
    }   

    const handleCompleteSalesSlipClick = () => {
        // history.push({
        //     pathname: '/applyApplicationSummary',
        //     search: '?tab=summary_list'
        // })
        submiCreditApplication(history, '/applyApplicationSummary?tab=summary_list', customer); 
    }

    const handleNoadditionalDocuemntRequired = () => {
        submiCreditApplication(history, '/applyApplicationSummary', customer);  
    }

    return (
        <div className="sales">

            { actionLoading && <Loader />}

            <Header isHome={true} history={history} >
                <img src={IconArrowLeft} alt="home" className="icon-back" onClick={() => handleArrowBack()} />
                <img src={TCPLogo} alt="logo" className="main-logo"/>
            </Header>

            <div className="apply-application">

                <button autoFocus={true} className="btn button" onClick={() => handleCompleteSalesSlipClick()}>
                    <div className="icon">
                        <img className="icon-new-customer" src={IconList} alt="new" />
                    </div>
                    <div className="label">Complete Sales slip(s)</div>
                </button>


                <button className="btn button" onClick={() => handleNoadditionalDocuemntRequired()}>
                    <div className="icon">
                        {/* <img className="icon-reorder-customer" src={IconSend} alt="reorder" /> */}
                        <i class="fa fa-ban icon-reorder-customer" aria-hidden="true" style={{transform : 'none'}}></i>
                    </div>
                    <div className="label">No Additional Documents are Being Generated at This Time</div>
                </button>

            </div>

        </div>
    )
}

const mapStateToProps = state => ({    
    customer: state.sales.customer,
    actionLoading: state.sales.actionLoading
});

const mapDispatchToProps = dispatch => ({
    submiCreditApplication: (history, path, data) => dispatch(submiCreditApplication(history, path, data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreSummary);
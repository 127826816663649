import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { toast } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configStore from './redux/store';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const { store, persistor } = configStore();

const AppContainer: React.FC = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    );
};

ReactDOM.render(<AppContainer />, document.getElementById('root'));
serviceWorker.unregister();

import React, { useState, useEffect } from 'react';
import { pushNotification } from 'utils/notification';
import { connect } from 'react-redux';
import Header from '../../../components/Sales/Header';
import { Form } from 'react-bootstrap';
import Input from '../../../components/commons/input';
import {
    IconHome,
    IconArrowLeft,
    TCPLogo,
    IconRight,
} from '../../../assets/images';

import { getFromData } from '../../../components/commons/utility';
import {
    updateApplicationFilledStatus,
    searchCustomer,
    updateCustomer,
    refreshSearchCustomerForm,
    validateEmailAddress,
    resetSearchCustomerForm,
    selectCustomer,
    resetSearchCustomerSearchApiInitiate,
} from '../../../redux/actions/sales';

function HomeScreen(props) {
    const {
        history,
        avatar,
        customer_search_result,
        customer,
        refreshCheck,
        isCustomerFound,
        actionLoading,
        searchCustomer,
        selectCustomer,
        updateCustomer,
        validateEmailAddress,
        searchCustomerApiInitiate,
        updateApplicationFilledStatus,
        resetSearchCustomerForm,
        refreshSearchCustomerForm,
        resetSearchCustomerSearchApiInitiate,
    } = props;

    const [validationResult, setValidationResult] = useState(null);
    const [applicantFirstName, setApplicantFirstName] = useState(null);
    const [applicantLastName, setApplicantLastName] = useState(null);
    const [applicantEmail, setApplicantEmail] = useState(null);
    const [applicantPhone, setApplicantPhone] = useState(null);
    const [clearCheck, setClearCheck] = useState(false);
    const [isMobileDevice, SetIsMobileDevice] = useState(false)

    // console.log(clearCheck)

    useEffect(() => {
        if (window.performance) {
            if (performance.navigation.type == 1 && refreshCheck) {
                resetSearchCustomerSearchApiInitiate();
            } else {
                refreshSearchCustomerForm(true);
            }
        }
    }, []);
    let details = navigator.userAgent;
    console.log(details)
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevices = regexp.test(details);

    useEffect(() => {
        SetIsMobileDevice(isMobileDevices)
    },[isMobileDevices])



    useEffect(() => {
        if(customer && customer.main_app && customer.main_app.first_name) {
            setApplicantEmail(customer.main_app.email)
            setApplicantFirstName(customer.main_app.first_name)
            setApplicantLastName(customer.main_app.last_name)
            setApplicantPhone(customer.main_app.cell_phone)
        }
    },[customer])

    useEffect(() => {
        let temp_phone = null;
        let temp_email = null;

        if (
            applicantPhone &&
            applicantPhone != '' &&
            applicantPhone.indexOf('_') == -1
        ) {
            temp_phone = applicantPhone;
        }

        if (applicantEmail && applicantEmail != '') {
            temp_email = applicantEmail;
        }

        if (
            !searchCustomerApiInitiate &&
            (applicantEmail ||
                (applicantPhone && applicantPhone.indexOf('_') == -1))
        ) {
            searchCustomer({ email: temp_email, phone: temp_phone }).then(
                (res) => {
                    if (!res && customer_search_result.length == 0) {
                        pushNotification(
                            'No match found',
                            'error',
                            'TOP_RIGHT',
                            3000
                        );
                    }
                }
            );
        }
    }, [applicantEmail, applicantPhone]);

    const handleHomeClick = () => {
        history.replace('/');
    };

    const triggerNextPage = (customer_search) => {
        let temp_customer = {
            ...customer_search,
            id: customer_search.main_app.id ? customer_search.main_app.id : 0,
        };
        if (
            customer_search.invite_status == 'COMPLETED' ||
            customer_search.invite_status == 'SENT'
        ) {
            temp_customer = {
                ...temp_customer,
                main_app: {
                    ...temp_customer.main_app,
                    additional_income_status:
                        temp_customer.main_app.additional_income &&
                        temp_customer.main_app.additional_income != '0'
                            ? 'yes'
                            : 'no',
                },
                co_app: {
                    ...temp_customer.co_app,
                    additional_income_status:
                        temp_customer.co_enabled &&
                        temp_customer.co_app.additional_income &&
                        temp_customer.co_app.additional_income != '0'
                            ? 'yes'
                            : 'no',
                },
            };

            if (customer_search.invite_status == 'COMPLETED') {
                updateApplicationFilledStatus('in_app', null, null);
            } else {
                updateApplicationFilledStatus('send_link', null, null);
            }

            updateCustomer(history, '/applyApplicationSummary', temp_customer);
        } else {
            updateCustomer(history, '/applyApplication', temp_customer);
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();

        const formData = getFromData(evt);

        setValidationResult(formData.validationResult);

        if (!searchCustomerApiInitiate && !formData.validationResult) {
            if (
                applicantEmail ||
                (applicantPhone && applicantPhone.indexOf('_') == -1)
            ) {
                searchCustomer({
                    email: applicantEmail,
                    phone:
                        applicantPhone && applicantPhone.indexOf('_') !== -1
                            ? null
                            : applicantPhone,
                });
            }
        } else if (
            customer_search_result.length > 0 &&
            customer.main_app.id == undefined &&
            !formData.validationResult
        ) {
            console.log(applicantEmail, applicantPhone, customer_search_result);

            pushNotification(
                `The ${customer_search_result[0].main_app.email}/${customer_search_result[0].main_app.cell_phone} entered match an existing entry in TCP's database, please select the matching contact from below or modify the ${customer_search_result[0].main_app.email}/${customer_search_result[0].main_app.cell_phone} to proceed with this entry`,
                'error',
                'TOP_RIGHT',
                8000
            );
        } else {
            if (!formData.validationResult) {
                let data = formData.formData;

                let temp_customer = {
                    ...customer,
                    id: 0,
                    main_app: {
                        ...customer.main_app,
                        name: data.first_name + ' ' + data.last_name,
                        email: data.email,
                        cell_phone: data.cell_phone,
                        first_name: data.first_name,
                        last_name: data.last_name,
                    },
                    co_app: {
                        ...customer.co_app,
                    },
                    invite_status: 'SENT',
                };

                validateEmailAddress(applicantEmail);

                updateCustomer(history, '/applyApplication', temp_customer);
            }
        }
    };

    const refreshPhoneApiCheck = (e) => {
        if (e.target.value && e.target.value.indexOf('_') == -1) {
            resetSearchCustomerSearchApiInitiate();
        }
    };

    const refreshEmailApiCheck = (e) => {
        if (e.target.value) {
            resetSearchCustomerSearchApiInitiate();
        }
    };

    return (
        <div className="sales">
            <div style={{position : 'sticky', top : 0}}>
            <Header isHome={true} history={history} avatar={avatar}>
                <img
                    src={IconHome}
                    alt="home"
                    className="icon-logo"
                    onClick={() => handleHomeClick()}
                />
                <img src={TCPLogo} alt="logo" className="main-logo" />
            </Header>
            </div>
            <form onSubmit={(e) => handleSubmit(e)} noValidate>
                <div className="main-box">
                    <p className="title">ENTER CUSTOMER INFORMATION</p>
                    <p className="sub-title">
                        Please begin by entering your customer's email address
                        and/or phone number. If they are already in TCP's
                        database, the customer will appear at the bottom of the
                        screen for you to select. Otherwise, please enter an
                        email address and/or phone number, first name and last
                        name to proceed.
                    </p>
                </div>

                <div className={`container`} style={{height : isMobileDevice ? 'calc(100vh - 150px)' : '100%'}}>
                    <div className="styled-form home-form">
                        <div className="box color-box">
                            <Form.Group className="home-input h-main mb-18">
                                <Input
                                    name="email"
                                    type="email"
                                    regex="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
                                    label="Applicant Email"
                                    defaultText="Applicant Email"
                                    // {...(isCustomerFound && {
                                    //     value: customer.main_app.email
                                    // })}
                                    value={
                                        customer ? customer.main_app.email : ''
                                    }
                                    {...(clearCheck && {
                                        value: '',
                                    })}
                                    required={true}
                                    error={{
                                        invalid:
                                            'Please enter valid Email address',
                                        empty: 'Please enter Applicant Email',
                                    }}
                                    optionalParams={{
                                        autoFocus: true,
                                    }}
                                    validationResult={validationResult}
                                    handleChange={(e) => {
                                        refreshEmailApiCheck(e);
                                        setClearCheck(false);
                                    }}
                                    onBlur={(e) => {
                                        setApplicantEmail(e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="home-input h-main mb-18">
                                <Input
                                    name="cell_phone"
                                    type="hidden"
                                    label="Phone"
                                    defaultText="(123) 456-7890"
                                    // {...(isCustomerFound && {
                                    //     value: customer.main_app.cell_phone
                                    // })}
                                    value={
                                        customer
                                            ? customer.main_app.cell_phone
                                            : ''
                                    }
                                    {...(clearCheck && {
                                        value: '',
                                    })}
                                    regex="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                                    mask="(999) 999-9999"
                                    required={true}
                                    error={{
                                        invalid: 'Please enter 10 digit number',
                                        empty: 'Please enter Phone number',
                                    }}
                                    validationResult={validationResult}
                                    handleChange={(e) => {
                                        refreshPhoneApiCheck(e);
                                        setClearCheck(false);
                                    }}
                                    onBlur={(e) => {
                                        setApplicantPhone(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <Form.Group className="home-input mb-18">
                            <Input
                                name="first_name"
                                type="text"
                                label="Applicant First Name"
                                defaultText="Applicant First Name"
                                // {...(isCustomerFound && {
                                //     value: customer.main_app.first_name
                                // })}
                                value={
                                    customer ? customer.main_app.first_name : ''
                                }
                                {...(clearCheck && {
                                    value: '',
                                })}
                                required={true}
                                error={{
                                    empty: ' ',
                                }}
                                handleChange={(e) => {
                                    setClearCheck(false);
                                    setApplicantFirstName(e.target.value);
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                        <Form.Group className="home-input mb-18">
                            <Input
                                name="last_name"
                                type="text"
                                label="Applicant Last Name"
                                defaultText="Applicant Last Name"
                                // {...(isCustomerFound && {
                                //     value: customer.main_app.last_name
                                // })}
                                value={
                                    customer ? customer.main_app.last_name : ''
                                }
                                {...(clearCheck && {
                                    value: '',
                                })}
                                required={true}
                                error={{
                                    empty: ' ',
                                }}
                                handleChange={(e) => {
                                    setClearCheck(false);
                                    setApplicantLastName(e.target.value);
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className="match-found-result">
                    {customer_search_result &&
                        customer_search_result.map((customer_search) => (
                            <div className="match-found-container">
                                <div className="title">
                                    Match Found{' '}
                                    <img
                                        src={IconRight}
                                        style={{ marginLeft: 10 }}
                                    />
                                </div>
                                <div
                                    className="details"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        selectCustomer(customer_search);
                                        triggerNextPage(customer_search);
                                    }}
                                >
                                    <p className="name-details">
                                        {customer_search.main_app.name}{' '}
                                        {customer_search.co_enabled
                                            ? ` & ${customer_search.co_app.name}`
                                            : null}
                                    </p>
                                    <div className="row other-details">
                                        <div className="col">
                                            <span>
                                                {
                                                    customer_search.main_app
                                                        .street
                                                }
                                            </span>
                                            <br></br>
                                            <span>
                                                {customer_search.main_app.city}{' '}
                                                {customer_search.main_app.state}{' '}
                                                {customer_search.main_app.zip}.
                                            </span>
                                        </div>
                                        <div className="col">
                                            <span>
                                                {customer_search.main_app.email}
                                            </span>
                                            <br></br>
                                            <span>
                                                {
                                                    customer_search.main_app
                                                        .cell_phone
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                <div className="footer-container">
                    <button
                        className="secondary clear-btn-sales clear-btn-focus"
                        type="button"
                        onClick={() => {
                            resetSearchCustomerForm();
                            setClearCheck(true);
                        }}
                        id="clearBtn"
                        onMouseDown={() => {
                            document.getElementById('clearBtn').style.backgroundColor='#009e87'
                        }}
                        onMouseUp={() => {
                            document.getElementById('clearBtn').style.backgroundColor='#6793b7'
                        }}
                    >
                        Clear
                    </button>
                    {actionLoading ? (
                        <button
                            className="btn secondary"
                            type="button"
                            disabled
                        >
                            Searching...
                        </button>
                    ) : (
                        <button
                            className={`btn secondary ${(!applicantEmail || !applicantFirstName || !applicantLastName || !applicantPhone) ? 'next-btn-sales' : 'clear-btn-sales'}`}
                            type="submit"
                            disabled={
                                !applicantEmail ||
                                !applicantFirstName ||
                                !applicantLastName ||
                                !applicantPhone
                                    ? true
                                    : false
                            }
                            style={{cursor :  !applicantEmail ||
                                !applicantFirstName ||
                                !applicantLastName ||
                                !applicantPhone ? 'not-allowed' : 'pointer' }}
                        >
                            Next
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

const mapStateToProps = (state) => ({
    avatar: state.auth.avatar,
    refreshCheck: state.sales.refreshCheck,
    customer_search_result: state.sales.customer_search_result,
    customer: state.sales.customer,
    isCustomerFound: state.sales.isCustomerFound,
    actionLoading: state.sales.actionLoading,
    searchCustomerApiInitiate: state.sales.searchCustomerApiInitiate,
});

const mapDispatchToProps = (dispatch) => ({
    searchCustomer: (data) => dispatch(searchCustomer(data)),
    selectCustomer: (data) => dispatch(selectCustomer(data)),
    validateEmailAddress: (data) => dispatch(validateEmailAddress(data)),
    resetSearchCustomerSearchApiInitiate: (data) =>
        dispatch(resetSearchCustomerSearchApiInitiate(data)),
    resetSearchCustomerForm: () => dispatch(resetSearchCustomerForm()),
    refreshSearchCustomerForm: () => dispatch(refreshSearchCustomerForm()),
    updateCustomer: (history, path, data) =>
        dispatch(updateCustomer(history, path, data)),
    updateApplicationFilledStatus: (data, history, path) =>
        dispatch(updateApplicationFilledStatus(data, history, path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);

const getHashValue = (attribute) => {

    const urlHash = window.location.hash.replace("#", "");
    if (urlHash.indexOf(attribute) === -1) {
        return null;
    }
    const splitArray = urlHash.split(`${attribute}=`)

    let value = null;
    if (splitArray[1]) {
        value = splitArray[1].split('&')[0];
    }
    return value;
}


const getParamsValue = (attribute) => {

    const urlSearch = window.location.search.replace("?", "");
    if (urlSearch.indexOf(attribute) === -1) {
        return null;
    }
    const splitArray = urlSearch.split(`${attribute}=`)

    let value = null;
    if (splitArray[1]) {
        value = splitArray[1].split('&')[0];
    }
    return value;
}

const setLocalStorageItem = (localStorageArr) => {
    return Promise.resolve().then(function () {
        localStorageArr.length > 0 && localStorageArr.map((item) => {
            localStorage.setItem(item.key, item.value);
            return true
        })
    });
}

export {
    getHashValue,
    getParamsValue,
    setLocalStorageItem
}
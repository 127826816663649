import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from 'shared/Loader';

import {
    IconSmallArrowRight,
    IconBackArrow,
    IconDoorClosed,
} from 'assets/images';
import {
    getCustomerHistory,
    getHistoryNorthridgeData,
} from '../../../../redux/actions/dealer';
import { getFormattedDate } from '../../../../components/commons/utility';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import moment from 'moment';

function History(props) {
    const {
        history,
        actionLoading,
        getCustomerHistory,
        getHistoryNorthridgeData,
        selectedCustomer,
        northridgeHistory,
        nortridgeLoading,
        cProfile: { loading, data: app },
    } = props;

    console.log(props);

    useEffect(() => {
        if ((app && app.app_id) || (app && app.cif_number)) {
            getCustomerHistory(app.app_id, app.cif_number);
        }
    }, []);

    return (
        <>
            {actionLoading && <Loader />}

            <div className="main custom_history_accordian">
                {/* <table style={{ width: '100%' }}>
                    { selectedCustomer && selectedCustomer.history && selectedCustomer.history.map((item) => {

                        return (
                            <tr className="history-tab">
                                <td className="cursor-pointer" style={{ paddingRight: 0 }}>
                                    <img src={IconDoorClosed} alt="door-closed" />
                                </td>
                                <td>
                                    <h6 className="item">{item.product_type}</h6>
                                    <h6 className="item-desc">{getFormattedDate(item.order_placed_date)}</h6>
                                </td>
                                <td>
                                    <h6 className=" item-desc">Loan No.{item.Loan_no}</h6>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                    <img src={IconSmallArrowRight} alt="expand" />
                                </td>
                            </tr>
                        );
                    })}
                </table> */}
                <Accordion>
                    {selectedCustomer &&
                        selectedCustomer.history &&
                        selectedCustomer.history.map((item) => {
                            return (
                                <AccordionItem
                                    key={item?.Acctrefno}
                                    onClick={() =>
                                        getHistoryNorthridgeData(
                                            item?.Acctrefno
                                        )
                                    }
                                >
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <table style={{ width: '100%' }}>
                                                <tr className="history-tab">
                                                    <td
                                                        className="cursor-pointer history-first-tab-img"
                                                        style={{
                                                            paddingRight: 0,
                                                            color : "#3a5c77"
                                                        }}
                                                    >
                                                        
                                                        <img
                                                            src={IconDoorClosed}
                                                            alt="door-closed"
                                                        />
                                                        <span className="parent-span">
                                                        <span className="customer-history-product-type">{item.product_type}</span>
                                                        <p className="customer-history-product-type-date-firstrow">{getFormattedDate(
                                                                item.Open_Date
                                                            )}
                                                            </p>
                                                         </span>  
                                                    </td>
                                                    {/* <td>
                                                        <h6 className="item">
                                                            {item.product_type}
                                                        </h6>
                                                        <h6 className="item-desc">
                                                            {getFormattedDate(
                                                                item.Open_Date
                                                            )}
                                                        </h6>
                                                    </td> */}
                                                    <td style={{float : 'right'}}>
                                                        
                                                        <h6 className=" item-desc">
                                                        <span className="customer-history-product-type-date">{getFormattedDate(
                                                                item.Open_Date
                                                            )}
                                                            </span>
                                                            Loan No.
                                                            {item.Loan_Number}
                                                            {" "}
                                                            <img
                                                            src={
                                                                IconSmallArrowRight
                                                            }
                                                            alt="expand"
                                                        />
                                                        </h6>
                                                        
                                                    </td>
                                                </tr>
                                            </table>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className="total_container">
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Total Balance
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Total_Balance}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Maturity Date
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Maturity_Date}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Total Curr Date
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Total_Curr_Due}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Total Past Date
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Total_Past_Due}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Days Past Due
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Days_Past_Due}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Next Principal
                                                            Billing and Due Date
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Last_Payment_Date}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <table>
                                        <tr>
                                            <td>
                                                <h6 className=" item-desc">Total Balance</h6>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <h6>{item && item.Details && item.Details.Total_Balance}</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6 className=" item-desc">Maturity Date</h6>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <h6>{item && item.Details && item.Details.Maturity_Date}</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6 className=" item-desc">Total Curr Date</h6>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <h6>{item && item.Details && item.Details.Total_Curr_Due}</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6 className=" item-desc">Total Past Date</h6>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <h6>{item && item.Details && item.Details.Total_Past_Due}</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6 className=" item-desc">Days Past Due</h6>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <h6>{item && item.Details && item.Details.Days_Past_Due}</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6 className=" item-desc">Next Principal Billing and Due Date</h6>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <h6>{item && item.Details && item.Details.Last_Payment_Date}</h6>
                                            </td>
                                        </tr>
                                    </table> */}
                                        <table
                                            style={{ width: '100%' }}
                                            className="customer_history_payment_history_table"
                                        >
                                            <thead>
                                                <th
                                                    colSpan={5}
                                                    className="text-center"
                                                    style={{
                                                        background: '#c8e0ed',
                                                        padding:
                                                            '5px 15px 15px 15px',
                                                        color: '#515b77',
                                                    }}
                                                >
                                                    Payment History
                                                </th>
                                            </thead>
                                            <thead className="payment_next_head">
                                                <th>Due Date</th>
                                                <th>Payment Effective Date</th>
                                                <th>Description</th>
                                                <th>Payment Method</th>
                                                <th>Amount Paid</th>
                                            </thead>
                                            <tbody>
                                                {console.log(
                                                    northridgeHistory.data
                                                )}
                                                {nortridgeLoading ? (
                                                    <React.Fragment>
                                                        <tr><td colSpan={5} className='text-center'><div class="lds-ring"><div></div><div></div><div></div><div></div></div></td></tr>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {northridgeHistory && northridgeHistory.data && northridgeHistory.data.map((data) => {
                                                            return (
                                                                <tr>
                                                                    <td>{data.Date_Due ? moment(data.Date_Due).format("DD-MMM-YYYY") : '-'}</td>
                                                                    <td>{data.Payment_Effective_Date ? moment(data.Payment_Effective_Date).format("DD-MMM-YYYY") : '-'}</td>
                                                                    <td>{data.Description}</td>
                                                                    <td>{data.Payment_Method}</td>
                                                                    <td>${data.Amount_Paid}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                )}
                                            </tbody>
                                        </table>
                                    </AccordionItemPanel>
                                </AccordionItem >
                            );
                        })}
                </Accordion >
            </div >
        </>
    );
}

const mapStateToProps = (state) => ({
    actionLoading: state.dealer.actionLoading,
    selectedCustomer: state.dealer.selectedCustomer,
    cProfile: state.admin.cProfile,
    northridgeHistory: state.dealer.northridgeHistory,
    nortridgeLoading: state.dealer.northridgeHistory.loading
});
const mapDispatchToProps = (dispatch) => ({
    getCustomerHistory: (id, cif_number) => dispatch(getCustomerHistory(id, cif_number)),
    getHistoryNorthridgeData: (id) => dispatch(getHistoryNorthridgeData(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(History);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    deleteDealer,
    getDealers,
    setDealer,
    setExpandedDealers,
    setSelectedDealer,
} from '../../../../../redux/actions/admin';
import { Container, Form } from 'react-bootstrap';
import { HomeTabBar } from '../components/HomeTabBar';
import { DealerRow } from './components/DealerRow';
import CustomerRow from './components/CustomerRow';
import Header, {
    HeaderCenter,
    HeaderLeft,
    HeaderRight,
} from '../../../../../components/Dealer/Header';
import { SideMenu } from './components/SideMenu';
import { IconSearchAdmin, TCPLogo } from '../../../../../assets/images';
import { CircledImageButton } from '../../../style';
import Loader from '../../../../../shared/Loader';
import { renderCustomerRows } from './components/DealerRow';
import dealer from 'redux/reducers/dealer';
// import DateSelect from '../../../../../../src/components/commons/DateSelect';

export function PureReview({
    history,
    dealers,
    getDealers,
    setDealer,
    deleteDealer,
    actionLoading,
    setExpandHandler,
    setExpandedDealers,
    expandedDealers,
    setSelectedDealer,
}) {
    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
    const [search, setSearch] = useState('');
    const [filterDealer, setFilterDealer] = useState([]);
    const [containerOpen, setContainerOpen] = useState(false)

    console.log(filterDealer);

    useEffect(() => {
        getDealers();

        const intervalId = setInterval(function () {
            if (window.location.pathname === '/') {
                getDealers(false);
            }
        }, 120000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (search != null && search !== '') {
            let temp_dealers = [];

            dealers.data.forEach((dealer) => {
                console.log(dealer);

                let temp_sales_customer_data = [];
                let temp_preapproval_data = [];

                if (dealer.sales_customer_data.length > 0) {
                    temp_sales_customer_data =
                        dealer.sales_customer_data.filter((d) =>
                            d.customer_name
                                .toLowerCase()
                                .includes(search.toLowerCase())
                        );
                }

                if (dealer.preapproval_data.length > 0) {
                    temp_preapproval_data = dealer.preapproval_data.filter(
                        (d) =>
                            d.customer_name
                                .toLowerCase()
                                .includes(search.toLowerCase())
                    );
                }

                if (
                    temp_sales_customer_data.length > 0 ||
                    temp_preapproval_data.length > 0
                ) {
                    temp_dealers =
                        temp_dealers.length > 0
                            ? [
                                  ...temp_dealers,
                                  {
                                      ...dealer,
                                      sales_customer_data:
                                          temp_sales_customer_data,
                                      preapproval_data: temp_preapproval_data,
                                  },
                              ]
                            : [
                                  {
                                      ...dealer,
                                      sales_customer_data:
                                          temp_sales_customer_data,
                                      preapproval_data: temp_preapproval_data,
                                  },
                              ];
                }
            });
            setFilterDealer(temp_dealers);
        } else {
            setFilterDealer(dealers.data);
        }
    }, [search]);

    useEffect(() => {
        setFilterDealer(dealers.data);
    }, [dealers]);

    useEffect(() => {
        setExpandHandler && setExpandHandler(handleExpand);
        return () => {
            setExpandHandler && setExpandHandler(() => {});
        };
    });

    const handleExpand = () => {
        const activeIndex = filterDealer.findIndex(
            (dealer) => expandedDealers[toOpenIndex(dealer.id)]
        );
        if (activeIndex > -1) {
            filterDealer.forEach((dealer) => {
                expandedDealers[toOpenIndex(dealer.id)] = false;
            });
        } else {
            filterDealer.forEach((dealer) => {
                if (dealer.num_customers === 0) return;
                expandedDealers[toOpenIndex(dealer.id)] = true;
            });
        }
        setExpandedDealers({ ...expandedDealers });
    };

    const handleOpenDealerAction = (dealer) => {
        setContainerOpen(!containerOpen)
        if (dealer.num_customers > 0 || 1 === 1) {
            const key = toOpenIndex(dealer.id);
            const currentValue = expandedDealers[key];
            setExpandedDealers({
                ...expandedDealers,
                [key]: !currentValue,
            });
        }
    };

    const handleClickSearch = () => {
        setIsSearchBarVisible(!isSearchBarVisible);
    };

    const renderDealers = (dealers) => {
        return dealers.map((item) => (
            <DealerRow
                history={history}
                key={item.id}
                dealer={item}
                expanded={expandedDealers[toOpenIndex(item.id)]}
                selectDealer={setSelectedDealer}
                onClick={handleOpenDealerAction}
                type="review"
                search={search}
            />
        ));
    };

    const toOpenIndex = (id) => {
        return 'map' + id;
    };

    return (
        <>
            {(dealers.loading || actionLoading) && <Loader />}
            <Header>
                <HeaderLeft>
                    <SideMenu
                        history={history}
                        activeTab={0}
                        dealers={dealers}
                    />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">
                        <img className="main-logo" src={TCPLogo} alt="" />
                    </div>
                </HeaderCenter>
                <HeaderRight>
                    <CircledImageButton active={isSearchBarVisible}>
                        <img
                            src={IconSearchAdmin}
                            alt=""
                            onClick={handleClickSearch}
                        />
                    </CircledImageButton>
                </HeaderRight>
            </Header>

            {isSearchBarVisible && (
                <div className="search-header">
                    <form action="javascript:void(0)">
                        <Form.Group>
                            <Form.Control
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            )}

            <div className="main">
                <div className={`list ${isSearchBarVisible ? 'expand' : ''}`}>
                    {filterDealer &&  (renderDealers(filterDealer))}
                </div>
            </div>
        </>
    );
}

PureReview.propTypes = {
    history: PropTypes.object,
    dealers: PropTypes.object,
    getDealers: PropTypes.func.isRequired,
    setDealer: PropTypes.func.isRequired,
    deleteDealer: PropTypes.func.isRequired,
    setExpandHandler: PropTypes.func,
    actionLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    dealers: state.admin.dealers,
    expandedDealers: state.admin.expandedDealers,
    actionLoading: state.admin.actionLoading,
});

const mapDispatchToProps = (dispatch) => ({
    getDealers: (action = true) => dispatch(getDealers(action)),
    setDealer: (item) => dispatch(setDealer(item)),
    deleteDealer: (id) => dispatch(deleteDealer(id)),
    setExpandedDealers: (data) => dispatch(setExpandedDealers(data)),
    setSelectedDealer: (data, history) =>
        dispatch(setSelectedDealer(data, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PureReview);

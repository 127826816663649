import API from '../../api';
import { addEvent } from '../../firebase/firebase';
import { pushNotification } from 'utils/notification';
import { message as notificationMsg } from 'shared/constant';
import moment from 'moment';

export const GET_PREAPPROVALS = 'GET_PREAPPROVALS';
export const GET_PREAPPROVALS_SUCCESS = 'GET_PREAPPROVALS_SUCCESS';
export const GET_PREAPPROVALS_FAILED = 'GET_PREAPPROVALS_FAILED';

export const SET_SELECTED_PREAPPROVAL = 'SET_SELECTED_PREAPPROVAL';
export const CHANGE_SELECTED_PREAPPROVAL_STATUS =
    'CHANGE_SELECTED_PREAPPROVAL_STATUS';

export const SET_SELECTED_PENDING_APPLICATION =
    'SET_SELECTED_PENDING_APPLICATION';
export const CHANGE_SELECTED_PENDING_APPLICATION_STATUS =
    'CHANGE_SELECTED_PENDING_APPLICATION_STATUS';

export const GET_FUNDING_REQUESTS = 'GET_FUNDING_REQUESTS';
export const GET_FUNDING_REQUESTS_SUCCESS = 'GET_FUNDING_REQUESTS_SUCCESS';
export const GET_FUNDING_REQUESTS_FAILED = 'GET_FUNDING_REQUESTS_FAILED';
export const SET_SELECTED_FUNDING_REQUEST = 'SET_SELECTED_FUNDING_REQUEST';
export const CHANGE_SELECTED_FUNDING_REQUEST_REQUEST =
    'CHANGE_SELECTED_FUNDING_REQUEST_REQUEST';
export const CHANGE_SELECTED_FUNDING_REQUEST_STATUS =
    'CHANGE_SELECTED_FUNDING_REQUEST_STATUS';
export const CHANGE_SELECTED_FUNDING_REQUEST_FAILED =
    'CHANGE_SELECTED_FUNDING_REQUEST_FAILED';

export const CHANGE_SELECTED_PREAPPROVAL_REQUEST =
    'CHANGE_SELECTED_PREAPPROVAL_REQUEST';
export const CHANGE_SELECTED_PREAPPROVAL_FAILED =
    'CHANGE_SELECTED_PREAPPROVAL_FAILED';

// TCP 2.0

export const GET_DEALERS_REQUESTS = 'GET_DEALERS_REQUESTS';
export const GET_DEALERS_REQUESTS_SUCCESS = 'GET_DEALERS_REQUESTS_SUCCESS';
export const GET_DEALERS_REQUESTS_FAILED = 'GET_DEALERS_REQUESTS_FAILED';
export const RESET_DEALER_TOKEN = 'RESET_DEALER_TOKEN';
export const SET_DEALER_REQUEST = 'SET_DEALER_REQUEST';
export const ADD_DEALER_REQUEST = 'ADD_DEALER_REQUEST';
export const ADD_DEALER_REQUEST_SUCCESS = 'ADD_DEALER_REQUEST_SUCCESS';
export const ADD_DEALER_REQUEST_FAILED = 'ADD_DEALER_REQUEST_FAILED';
export const UPDATE_DEALER_REQUEST = 'UPDATE_DEALER_REQUEST';
export const UPDATE_DEALER_REQUEST_SUCCESS = 'UPDATE_DEALER_REQUEST_SUCCESS';
export const UPDATE_DEALER_REQUEST_FAILED = 'UPDATE_DEALER_REQUEST_FAILED';
export const UPDATE_DEALER_DATA_REQUEST = 'UPDATE_DEALER_DATA_REQUEST';
export const UPDATE_DEALER_DATA_REQUEST_SUCCESS =
    'UPDATE_DEALER_DATA_REQUEST_SUCCESS';
export const UPDATE_DEALER_DATA_REQUEST_FAILED =
    'UPDATE_DEALER_DATA_REQUEST_FAILED';
export const DELETE_DEALER_REQUEST = 'DELETE_DEALER_REQUEST';
export const DELETE_DEALER_REQUEST_SUCCESS = 'DELETE_DEALER_REQUEST_SUCCESS';
export const DELETE_DEALER_REQUEST_FAILED = 'DELETE_DEALER_REQUEST_FAILED';
export const DEALER_CUSTOMER_DATA_REQUEST = 'DEALER_CUSTOMER_DATA_REQUEST';
export const DEALER_CUSTOMER_DATA_SUCCESS = 'DEALER_CUSTOMER_DATA_SUCCESS';
export const DEALER_CUSTOMER_DATA_FAILED = 'DEALER_CUSTOMER_DATA_FAILED';

export const GET_DEALERS_HISTORY_REQUEST = 'GET_DEALERS_HISTORY_REQUEST';
export const GET_DEALERS_HISTORY_SUCCESS = 'GET_DEALERS_HISTORY_SUCCESS';
export const GET_DEALERS_HISTORY_FAILED = 'GET_DEALERS_HISTORY_FAILED';

export const GET_DEALERS_PAPERWORK_REQUEST = 'GET_DEALERS_PAPERWORK_REQUEST';
export const GET_DEALERS_PAPERWORK_SUCCESS = 'GET_DEALERS_PAPERWORK_SUCCESS';
export const GET_DEALERS_PAPERWORK_FAILED = 'GET_DEALERS_PAPERWORK_FAILED';

export const GET_ADMIN_CUSTOMER_PROFILE_REQUEST = 'GET_ADMIN_CUSTOMER_PROFILE_REQUEST';
export const GET_ADMIN_CUSTOMER_PROFILE_SUCCESS = 'GET_ADMIN_CUSTOMER_PROFILE_SUCCESS';
export const GET_ADMIN_CUSTOMER_PROFILE_FAILED = 'GET_ADMIN_CUSTOMER_PROFILE_FAILED';

export const GET_UPDATED_PRODUCT_TIER_SUCCESS = 'GET_UPDATED_PRODUCT_TIER_SUCCESS';
export const GET_UPDATED_PRODUCT_TIER_FAILED = 'GET_UPDATED_PRODUCT_TIER_FAILED';

export const GET_UPDATED_DATE_SUCCESS = 'GET_UPDATED_DATE_SUCCESS';
export const GET_UPDATED_DATE_FAILED = 'GET_UPDATED_DATE_FAILED';

export const GET_PREAPPROVAL_STATUS_REQUEST = 'GET_PREAPPROVAL_STATUS_REQUEST';
export const GET_PREAPPROVAL_STATUS_SUCCESS = 'GET_PREAPPROVAL_STATUS_SUCCESS';
export const GET_PREAPPROVAL_STATUS_FAILED = 'GET_PREAPPROVAL_STATUS_FAILED';

export const CANCEL_SCHEDULE_STATUS_REQUEST = 'CANCEL_SCHEDULE_STATUS_REQUEST';
export const CANCEL_SCHEDULE_STATUS_SUCCESS = 'CANCEL_SCHEDULE_STATUS_SUCCESS';
export const CANCEL_SCHEDULE_STATUS_FAILED = 'CANCEL_SCHEDULE_STATUS_FAILED';

export const REVERSE_SCHEDULE_STATUS_REQUEST = 'REVERSE_SCHEDULE_STATUS_REQUEST';
export const REVERSE_SCHEDULE_STATUS_SUCCESS = 'REVERSE_SCHEDULE_STATUS_SUCCESS';
export const REVERSE_SCHEDULE_STATUS_FAILED = 'REVERSE_SCHEDULE_STATUS_FAILED';

export const UPDATE_SCHEDULE_DATE_REQUEST = 'UPDATE_SCHEDULE_DATE_REQUEST';
export const UPDATE_SCHEDULE_DATE_SUCCESS = 'UPDATE_SCHEDULE_DATE_SUCCESS';
export const UPDATE_SCHEDULE_DATE_FAILED = 'UPDATE_SCHEDULE_DATE_FAILED';

export const SET_EXPANDED_DEALER_SUCCESS = 'SET_EXPANDED_DEALER_SUCCESS';

export const SET_SELECTED_DEALER_REQUEST = 'SET_SELECTED_DEALER_REQUEST';
export const SET_SELECTED_DEALER_SUCCESS = 'SET_SELECTED_DEALER_SUCCESS';
export const SET_SELECTED_DEALER_FAILED = 'SET_SELECTED_DEALER_FAILED';

export const GET_DEALER_SEARCH_REQUEST = 'GET_DEALER_SEARCH_REQUEST';
export const GET_DEALER_SEARCH_SUCCESS = 'GET_DEALER_SEARCH_SUCCESS';
export const GET_DEALER_SEARCH_FAILED = 'GET_DEALER_SEARCH_FAILED';

export const FUNDED_SCHEDULED_REQUEST = "FUNDED_SCHEDULED_REQUEST";
export const FUNDED_SCHEDULED_SUCCESS = "FUNDED_SCHEDULED_SUCCESS";
export const FUNDED_SCHEDULED_FAILED = "FUNDED_SCHEDULED_FAILED";

export const PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST = "PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST";
export const PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS = "PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS";
export const PRODUCT_HELLO_SIGN_SIGNATURE_FAILED = "PRODUCT_HELLO_SIGN_SIGNATURE_FAILED";

export const PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST = "PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST";
export const PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS = "PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS";
export const PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED = "PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED";

export const GET_DEALERS_CUSTOMER_HISTORY_REQUEST = "GET_DEALERS_CUSTOMER_HISTORY_REQUEST";
export const GET_DEALERS_CUSTOMER_HISTORY_SUCCESS = "GET_DEALERS_CUSTOMER_HISTORY_SUCCESS";
export const GET_DEALERS_CUSTOMER_HISTORY_FAILED = "GET_DEALERS_CUSTOMER_HISTORY_FAILED";

export const GET_SENT_COMMENT_REQUEST = "GET_SENT_COMMENT_REQUEST";
export const GET_SENT_COMMENT_SUCCESS = "GET_SENT_COMMENT_SUCCESS";
export const GET_SENT_COMMENT_FAILED = "GET_SENT_COMMENT_FAILED";

export const GET_VIEW_COMMENT_REQUEST = "GET_VIEW_COMMENT_REQUEST";
export const GET_VIEW_COMMENT_SUCCESS = "GET_VIEW_COMMENT_SUCCESS";
export const GET_VIEW_COMMENT_FAILED = "GET_VIEW_COMMENT_FAILED";

export const COMMENT_RESET = "COMMENT_RESET";

export const PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_REQUEST = "PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_REQUEST";
export const PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_SUCCESS = "PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_SUCCESS";
export const PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_FAILED = "PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_FAILED";



export const GET_CREDIT_APP_REQUEST = "GET_CREDIT_APP_REQUEST";
export const GET_CREDIT_APP_SUCCESS = "GET_CREDIT_APP_SUCCESS";
export const GET_CREDIT_APP_FAILED = "GET_CREDIT_APP_FAILED";


export const GET_DEALER_STATUS_REQUEST = "GET_DEALER_STATUS_REQUEST";
export const GET_DEALER_STATUS_SUCCESS = "GET_DEALER_STATUS_SUCCESS";
export const GET_DEALER_STATUS_FAILED = "GET_DEALER_STATUS_FAILED";


export const UPDATE_EARLIEST_DATE_DELIVERY_REQUEST = "UPDATE_EARLIEST_DATE_DELIVERY_REQUEST";
export const UPDATE_EARLIEST_DATE_DELIVERY_SUCCESS = "UPDATE_EARLIEST_DATE_DELIVERY_SUCCESS";
export const UPDATE_EARLIEST_DATE_DELIVERY_FAILED = "UPDATE_EARLIEST_DATE_DELIVERY_FAILED";


export const GET_ACCOUNT_DEALER_REQUEST = "GET_ACCOUNT_DEALER_REQUEST";
export const GET_ACCOUNT_DEALER_SUCCESS = "GET_ACCOUNT_DEALER_SUCCESS";
export const GET_ACCOUNT_DEALER_FAILED = "GET_ACCOUNT_DEALER_FAILED";


export const SEND_DEALER_SETTING_REQUEST = "SEND_DEALER_SETTING_REQUEST";
export const SEND_DEALER_SETTING_SUCCESS = "SEND_DEALER_SETTING_SUCCESS";
export const SEND_DEALER_SETTING_FAILED = "GET_ACCOUNT_DEALER_FAILED";


export const VIEW_DEALER_SETTING_DATA = "VIEW_DEALER_SETTING_DATA";
export const VIEW_DEALER_SETTING_SUCCESS = "VIEW_DEALER_SETTING_SUCCESS";
export const VIEW_DEALER_SETTING_FAILED = "VIEW_DEALER_SETTING_FAILED ";


export const GET_DEALER_ACCESS_REQUEST = "GET_DEALER_ACCESS_REQUEST ";
export const GET_DEALER_ACCESS_SUCCESS = "GET_DEALER_ACCESS_SUCCESS ";
export const GET_DEALER_ACCESS_FAILED = "GET_DEALER_ACCESS_FAILED ";




export function getPreapprovals() {
    return async function (dispatch) {
        dispatch({
            type: GET_PREAPPROVALS,
        });
        try {
            const response = await API.get(`/sales/preapprovals`);
            dispatch({
                type: GET_PREAPPROVALS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_PREAPPROVALS_FAILED,
            });
        }
    };
}

export function setSelectedPreApproval(item) {
    return async function (dispatch) {
        dispatch({
            type: SET_SELECTED_PREAPPROVAL,
            payload: item,
        });
    };
}

export function changeSelectedPreApprovalStatus(
    id,
    status,
    message,
    history,
    url,
    approveItem
) {
    return async function (dispatch) {
        dispatch({
            type: CHANGE_SELECTED_PREAPPROVAL_REQUEST,
        });
        try {
            await API.put(`/sales/preapproval/${id}`, {
                status,
                message,
                appliance: approveItem.appliance,
                earliest_delivery_date: approveItem.earliest_delivery_date,
                product_type: approveItem.product_type,
            });
            dispatch({
                type: CHANGE_SELECTED_PREAPPROVAL_STATUS,
                payload: status,
            });
            pushNotification(
                notificationMsg.DISPOSITION_SUCCESS,
                'success',
                'TOP_RIGHT',
                3000
            );
            history && url && history.push(url);
        } catch (error) {
            pushNotification(
                'Some Thing Went Wrong',
                'error',
                'TOP_RIGHT',
                3000
            );
            dispatch({
                type: CHANGE_SELECTED_PREAPPROVAL_FAILED,
            });
        }
    };
}

export function setSelectedPendingApplication(item) {
    return async function (dispatch) {
        dispatch({
            type: SET_SELECTED_PENDING_APPLICATION,
            payload: item,
        });
    };
}

export function changeSelectedPendingApplicationStatus(
    id,
    status,
    history,
    path,
    rating,
    msg
) {
    return async function (dispatch) {
        dispatch({
            type: CHANGE_SELECTED_PREAPPROVAL_REQUEST,
        });
        try {
            const message = msg || '';
            await API.put(`/sales/appstatuschange`, {
                id,
                status,
                rating,
                message,
            });
            dispatch({
                type: CHANGE_SELECTED_PREAPPROVAL_STATUS,
                payload: status,
            });
            pushNotification(
                notificationMsg.REQUEST_SUCCESS,
                'success',
                'TOP_RIGHT',
                3000
            );
            history && history.push(path);
        } catch (error) {
            pushNotification(
                'Some Thing Went Wrong',
                'error',
                'TOP_RIGHT',
                3000
            );
            dispatch({
                type: CHANGE_SELECTED_PREAPPROVAL_FAILED,
            });
        }
    };
}

export function requestFunding(appId, deliveryDate, modalOpen, mountFunction) {
    return async function (dispatch) {
        const response = await API.post(`/sales/fundingrequests`, {
            app_id: appId,
            delivery_date: deliveryDate,
        });
        if (response) {
            modalOpen && modalOpen();
            mountFunction && mountFunction();
        }
        return true;
    };
}

export function requestCancel(appId) {
    return async function (dispatch) {
        await API.put(`/sales/cancelapp`, {
            id: appId,
        });
        return true;
    };
}

export function getFundingRequests(startDate, endDate, makeEmpty = true) {

    let temp = {
        start_date: startDate ? moment(new Date(startDate)).format("YYYY-MM-DD") : startDate,
        end_date: endDate ? moment(new Date(endDate)).format("YYYY-MM-DD") : endDate
    }

    return async function (dispatch) {
        if (makeEmpty) {
            dispatch({
                type: GET_FUNDING_REQUESTS,
            });
        }
        try {
            const response = await API.post(`/sales/scheduled-funding`, temp);
            dispatch({
                type: GET_FUNDING_REQUESTS_SUCCESS,
                payload: response.data,
            });

        } catch (error) {
            dispatch({
                type: GET_FUNDING_REQUESTS_FAILED,
            });
        }
    };
}

export function setSelectedFundingRequest(item) {
    return async function (dispatch) {
        dispatch({
            type: SET_SELECTED_FUNDING_REQUEST,
            payload: item,
        });
    };
}

export function changeSelectedFundingRequestStatus(id, status) {
    return async function (dispatch) {
        dispatch({
            type: CHANGE_SELECTED_FUNDING_REQUEST_REQUEST,
        });
        try {
            await API.put(`/sales/fundingrequest/${id}`, { status });
            dispatch({
                type: CHANGE_SELECTED_FUNDING_REQUEST_STATUS,
                payload: status,
            });
        } catch (error) {
            dispatch({
                type: CHANGE_SELECTED_FUNDING_REQUEST_FAILED,
            });
        }
    };
}

// TCP 2.0

export function getDealers(makeEmpty = true) {
    return async function (dispatch) {
        if (makeEmpty) {
            dispatch({
                type: GET_DEALERS_REQUESTS,
            });
        }
        try {
            const response = await API.get(`/sales/list-dealer-customer`);
            dispatch({
                type: GET_DEALERS_REQUESTS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_DEALERS_REQUESTS_FAILED,
            });
        }
    };
}

export function resetDealerToken() {
    return async function (dispatch) {
        dispatch({
            type: RESET_DEALER_TOKEN,
        });
    };
}


export function setDealer(item) {
    return async function (dispatch) {
        dispatch({
            type: SET_DEALER_REQUEST,
            payload: item,
        });
    };
}

export function updateOneDealer(data) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_DEALER_DATA_REQUEST,
        });
        try {
            await API.post(`/accounts/update-dealer`, data);
            dispatch({
                type: UPDATE_DEALER_DATA_REQUEST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_DEALER_DATA_REQUEST_FAILED,
            });
        }
    };
}

export function updateDealer(history, data) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_DEALER_REQUEST,
        });
        try {
            await API.post(`/accounts/update-dealer`, { ...data });
            dispatch({
                type: UPDATE_DEALER_REQUEST_SUCCESS,
                payload: '',
            });
            addEvent('dealer_updated', 'dealer-data-updated-success', {
                dealer_id: data.id,
            });
            pushNotification(
                notificationMsg.REQUEST_SUCCESS,
                'success',
                'TOP_RIGHT',
                3000
            );
            history && history.push('/admin/dealers');
        } catch (error) {
            pushNotification(
                error.response.data.message,
                'error',
                'TOP_RIGHT',
                3000
            );
            dispatch({
                type: UPDATE_DEALER_REQUEST_FAILED,
            });
            addEvent('dealer_updated', 'dealer-data-updated-failed', {
                dealer_id: data.id,
            });
        }
    };
}

export function addDealer(history, data) {
    return async function (dispatch) {
        dispatch({
            type: ADD_DEALER_REQUEST,
        });
        try {
            await API.post(`/accounts/add-dealer`, { ...data });
            dispatch({
                type: ADD_DEALER_REQUEST_SUCCESS,
            });
            addEvent('dealer_added', 'dealer-data-added-success', {
                dealer_phone: data.phone,
                dealer_email: data.email,
            });
            pushNotification(
                notificationMsg.REQUEST_SUCCESS,
                'success',
                'TOP_RIGHT',
                3000
            );
            history && history.push('/admin/dealers');
        } catch (error) {
            pushNotification(
                error.response.data.message,
                'error',
                'TOP_RIGHT',
                3000
            );
            dispatch({
                type: ADD_DEALER_REQUEST_FAILED,
            });
            addEvent('dealer_added', 'dealer-data-added-failed', {
                dealer_phone: data.phone,
                dealer_email: data.email,
            });
        }
    };
}

export function deleteDealer(id) {
    return async function (dispatch) {
        dispatch({
            type: DELETE_DEALER_REQUEST,
        });
        try {
            await API.put(`/accounts/user-delete/${id}`);
            dispatch({
                type: DELETE_DEALER_REQUEST_SUCCESS,
                payload: id,
            });
            addEvent('dealer_deleted', 'dealer-data-deleted-success', {
                dealer_id: id,
            });
            pushNotification(
                notificationMsg.REQUEST_SUCCESS,
                'success',
                'TOP_RIGHT',
                3000
            );
        } catch (error) {
            pushNotification(
                error.response.data.message,
                'error',
                'TOP_RIGHT',
                3000
            );
            dispatch({
                type: DELETE_DEALER_REQUEST_FAILED,
            });
            addEvent('dealer_deleted', 'dealer-data-deleted-failed', {
                dealer_id: id,
            });
        }
    };
}

// export function getDealersHistory() {
//     return async function (dispatch) {
//         dispatch({
//             type: GET_DEALERS_HISTORY_REQUEST,
//         });
//         try {
//             const response = await API.get(`/sales/history-logs`);
//             dispatch({
//                 type: GET_DEALERS_HISTORY_SUCCESS,
//                 payload: response.data,
//             });
//         } catch (error) {
//             dispatch({
//                 type: GET_DEALERS_HISTORY_FAILED,
//             });
//         }
//     };
// }


export function getDealersPaperwork(history, app_id) {
    // console.log(data)
    return async function (dispatch) {
        dispatch({
            type: GET_DEALERS_PAPERWORK_REQUEST,
        });
        try {
            const response = await API.post(`/sales/customer-paperwork`, { app_id });
            dispatch({
                type: GET_DEALERS_PAPERWORK_SUCCESS,
                payload: response.data.data,
            });
            // history && history.push('/sales/customer-paperwork')
        } catch (error) {
            dispatch({
                type: GET_DEALERS_PAPERWORK_FAILED,
            });
        }
    }
}


export function getCustomerProfile(app_id, cif_number, history, profileType = 'admin', makeEmpty = true, path) {
    return async function (dispatch) {
        if (makeEmpty) {
            dispatch({
                type: GET_ADMIN_CUSTOMER_PROFILE_REQUEST,
            });
        }
        try {
            const response = await API.post(`/sales/customer-profile`, { app_id: app_id, cif_number: cif_number == undefined ? null : cif_number });
            console.log(response)
            console.log(makeEmpty, profileType, path)
            dispatch({
                type: GET_ADMIN_CUSTOMER_PROFILE_SUCCESS,
                payload: response.data,
            });
            localStorage.setItem('activeCustomer', app_id)
            if(path) {
                history && history.push(path)
            }
            if (makeEmpty) {
                history.push('/' + profileType + '/profile')
            }
        } catch (error) {
            dispatch({
                type: GET_ADMIN_CUSTOMER_PROFILE_FAILED,
            });
        }
    }
}


export function updateProductTier(data, isDealerScreen) {
    return async function (dispatch) {
        try {
            await API.post(`/sales/update-producttier`, data);
            if (isDealerScreen == 1) {
                let id = localStorage.getItem('activeDealer');
                try {
                    const response = await API.post(`/sales/dealer-customers`, { dealer_id: id });
                    dispatch({
                        type: SET_SELECTED_DEALER_SUCCESS,
                        payload: response.data,
                    })
                } catch (error) {
                    dispatch({
                        type: SET_SELECTED_DEALER_FAILED,
                    });
                }
            }
            else if (isDealerScreen == 2) {
                try {
                    const response = await API.post(`/sales/scheduled-funded-list`);
                    dispatch({
                        type: FUNDED_SCHEDULED_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: FUNDED_SCHEDULED_FAILED,
                    });
                }
            }
            else {
                try {
                    const response = await API.get(`/sales/list-dealer-customer`);
                    dispatch({
                        type: GET_DEALERS_REQUESTS_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: GET_DEALERS_REQUESTS_FAILED,
                    });
                }
            }
        } catch (error) {
            dispatch({
                type: GET_UPDATED_PRODUCT_TIER_FAILED,
            });
        }
    };
}


export function updateDate(data, isDealerScreen) {
    return async function (dispatch) {
        try {
            await API.post(`/sales/update-earliest-delivery-date`, data);
            if (isDealerScreen == 1) {
                let id = localStorage.getItem('activeDealer');
                try {
                    const response = await API.post(`/sales/dealer-customers`, { dealer_id: id });
                    dispatch({
                        type: SET_SELECTED_DEALER_SUCCESS,
                        payload: response.data,
                    })
                } catch (error) {
                    dispatch({
                        type: SET_SELECTED_DEALER_FAILED,
                    });
                }
            }
            else if (isDealerScreen == 2) {
                try {
                    const response = await API.post(`/sales/scheduled-funded-list`);
                    dispatch({
                        type: FUNDED_SCHEDULED_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: FUNDED_SCHEDULED_FAILED,
                    });
                }
            }
            else {
                try {
                    const response = await API.get(`/sales/list-dealer-customer`);
                    dispatch({
                        type: GET_DEALERS_REQUESTS_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: GET_DEALERS_REQUESTS_FAILED,
                    });
                }
            }
        } catch (error) {
            dispatch({
                type: GET_UPDATED_DATE_FAILED,
            });
        }
    };
}


export function updatePreApprovalStatus(data, isDealerScreen) {
    return async function (dispatch) {
        dispatch({
            type: GET_PREAPPROVAL_STATUS_REQUEST,
        });
        try {
            var resp_data = await API.post(`/sales/update-preapproval`, data);
            pushNotification(
                resp_data.data.message,
                resp_data.data.status,
                'TOP_RIGHT',
                3000
            );
            if (isDealerScreen == 1) {
                let id = localStorage.getItem('activeDealer');
                try {
                    const response = await API.post(`/sales/dealer-customers`, { dealer_id: id });
                    dispatch({
                        type: SET_SELECTED_DEALER_SUCCESS,
                        payload: response.data,
                    })
                } catch (error) {
                    dispatch({
                        type: SET_SELECTED_DEALER_FAILED,
                    });
                }
            }
            else if (isDealerScreen == 2) {
                try {
                    const response = await API.post(`/sales/scheduled-funded-list`);
                    dispatch({
                        type: FUNDED_SCHEDULED_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: FUNDED_SCHEDULED_FAILED,
                    });
                }
            }
            else {
                try {
                    const response = await API.get(`/sales/list-dealer-customer`);
                    dispatch({
                        type: GET_DEALERS_REQUESTS_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: GET_DEALERS_REQUESTS_FAILED,
                    });
                }
            }
            dispatch({
                type: GET_PREAPPROVAL_STATUS_SUCCESS
            });
        } 
        catch (error) {
            dispatch({
                type: GET_PREAPPROVAL_STATUS_FAILED,
            });
        }
    }
}


export function cancelScheduling(data, isDealerScreen) {
    return async function (dispatch) {
        dispatch({
            type: CANCEL_SCHEDULE_STATUS_REQUEST,
        });
        try {
            await API.post(`/sales/fundingReq-declined`, data);
            if (isDealerScreen == 1) {
                let id = localStorage.getItem('activeDealer');
                try {
                    const response = await API.post(`/sales/dealer-customers`, { dealer_id: id });
                    dispatch({
                        type: SET_SELECTED_DEALER_SUCCESS,
                        payload: response.data,
                    })
                } catch (error) {
                    dispatch({
                        type: SET_SELECTED_DEALER_FAILED,
                    });
                }
            }
            else if (isDealerScreen == 2) {
                try {
                    const response = await API.post(`/sales/scheduled-funded-list`);
                    dispatch({
                        type: FUNDED_SCHEDULED_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: FUNDED_SCHEDULED_FAILED,
                    });
                }
            }
            else {
                try {
                    const response = await API.post(`/sales/scheduled-funding`);
                    dispatch({
                        type: GET_FUNDING_REQUESTS_SUCCESS,
                        payload: response.data,
                    });

                } catch (error) {
                    dispatch({
                        type: GET_FUNDING_REQUESTS_FAILED,
                    });
                }
            }
            dispatch({
                type: CANCEL_SCHEDULE_STATUS_SUCCESS
            });
        } catch (error) {
            dispatch({
                type: CANCEL_SCHEDULE_STATUS_FAILED,
            });
        }
    }
}


export function reverseScheduling(data, isDealerScreen) {
    console.log(data)
    return async function (dispatch) {
        dispatch({
            type: REVERSE_SCHEDULE_STATUS_REQUEST,
        });
        try {
            await API.post(`/sales/reverse-funded-item`, data);
            if (isDealerScreen == 1) {
                let id = localStorage.getItem('activeDealer');
                try {
                    const response = await API.post(`/sales/dealer-customers`, { dealer_id: id });
                    dispatch({
                        type: SET_SELECTED_DEALER_SUCCESS,
                        payload: response.data,
                    })
                } catch (error) {
                    dispatch({
                        type: SET_SELECTED_DEALER_FAILED,
                    });
                }
            }
            else if (isDealerScreen == 2) {
                try {
                    const response = await API.post(`/sales/scheduled-funded-list`);
                    dispatch({
                        type: FUNDED_SCHEDULED_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: FUNDED_SCHEDULED_FAILED,
                    });
                }
            }
            else {
                try {
                    const response = await API.post(`/sales/scheduled-funding`);
                    dispatch({
                        type: GET_FUNDING_REQUESTS_SUCCESS,
                        payload: response.data,
                    });

                } catch (error) {
                    dispatch({
                        type: GET_FUNDING_REQUESTS_FAILED,
                    });
                }
            }
            dispatch({
                type: REVERSE_SCHEDULE_STATUS_SUCCESS
            });
        } catch (error) {
            dispatch({
                type: REVERSE_SCHEDULE_STATUS_FAILED,
            });
        }
    }
}


export function updateFundDate(data, isDealerScreen) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_SCHEDULE_DATE_REQUEST,
        });
        try {
            await API.post(`/sales/future_funding_scheduled`, data);
            if (isDealerScreen == 1) {
                let id = localStorage.getItem('activeDealer');
                try {
                    const response = await API.post(`/sales/dealer-customers`, { dealer_id: id });
                    dispatch({
                        type: SET_SELECTED_DEALER_SUCCESS,
                        payload: response.data,
                    })
                } catch (error) {
                    dispatch({
                        type: SET_SELECTED_DEALER_FAILED,
                    });
                }
            }
            else if (isDealerScreen == 2) {
                try {
                    const response = await API.post(`/sales/scheduled-funded-list`);
                    dispatch({
                        type: FUNDED_SCHEDULED_SUCCESS,
                        payload: response.data,
                    });
                } catch (error) {
                    dispatch({
                        type: FUNDED_SCHEDULED_FAILED,
                    });
                }
            }
            else {
                try {
                    const response = await API.post(`/sales/scheduled-funding`);
                    dispatch({
                        type: GET_FUNDING_REQUESTS_SUCCESS,
                        payload: response.data,
                    });

                } catch (error) {
                    dispatch({
                        type: GET_FUNDING_REQUESTS_FAILED,
                    });
                }
            }
            dispatch({
                type: UPDATE_SCHEDULE_DATE_SUCCESS
            });
        } catch (error) {
            dispatch({
                type: UPDATE_SCHEDULE_DATE_FAILED,
            });
        }
    }
}


export function setExpandedDealers(data) {
    return async function (dispatch) {
        dispatch({
            type: SET_EXPANDED_DEALER_SUCCESS,
            payload: data,
        });
    }
}


export function setSelectedDealer(dealer_id, history, makeEmpty = true) {
    console.log(dealer_id)
    return async function (dispatch) {
        if (makeEmpty) {
            dispatch({
                type: SET_SELECTED_DEALER_REQUEST,
            });
        }
        try {
            const response = await API.post(`/sales/dealer-customers`, { dealer_id: dealer_id });
            dispatch({
                type: SET_SELECTED_DEALER_SUCCESS,
                payload: response.data,
            })

            localStorage.setItem('activeDealer', dealer_id)
            localStorage.setItem('redirectionPath', window.location.pathname + window.location.search)

            if (makeEmpty) {
                history && history.push({
                    pathname: '/admin/dealer',
                    search: '?tab=0'
                })
            }
        } catch (error) {
            dispatch({
                type: SET_SELECTED_DEALER_FAILED,
            });
        }
    }
}


export function getDealerAdditionalInfo(id) {
    return async function (dispatch) {
        try {
            const response = await API.post(`/sales/dealer-sales-info`, { dealer_id: id });
            console.log(response.data.data)
            if (response.data) {
                localStorage.setItem('aditinal_info', JSON.stringify([{ sales_menu: response.data.data }]))
            }
        } catch (error) {
            console.log('error')
        }
    }
}

export function getDealerCustomers(data) {
    return async function (dispatch) {
        dispatch({
            type: GET_DEALER_SEARCH_REQUEST,
        });
        try {
            const response = await API.post(`/sales/search-customer-local`, data);
            console.log(response)
            dispatch({
                type: GET_DEALER_SEARCH_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_DEALER_SEARCH_FAILED,
            });
        }
    }
}

export function getProductSignatureDetails(data) {
    return async function (dispatch) {
        dispatch({
            type: PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST,
        });
        try {
            const response = await API.post(`/sales/hellosign-status`, data);
            // console.log(response);
            dispatch({
                type: PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS,
                payload: { ...data, hello_sign: response.data && response.data.data ? response.data.data : [] },
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_HELLO_SIGN_SIGNATURE_FAILED,
            });
            console.log("error");
        }
    }
}

export function getPaperWorkProductSignatureDetails(data) {
    return async function (dispatch) {
        dispatch({
            type: PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST,
        });
        try {
            const response = await API.post(`/sales/hellosign-status`, data);
            // console.log(response);
            dispatch({
                type: PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS,
                payload: { ...data, hello_sign: response.data && response.data.data ? response.data.data : [] },
            });
        } catch (error) {
            dispatch({
                type: PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED,
            });
            console.log("error");
        }
    }
}

export function getProductSignatureDetailDirectForAdminReview(data) {
    return async function (dispatch) {
        dispatch({
            type: PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_REQUEST,
        });
        try {
            const response = await API.post(`/sales/hellosign-status`, data);
            dispatch({
                type: PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_SUCCESS,
                payload: response.data && response.data.data ? { ...data, ...response.data.data[0] } : { ...data },
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_FAILED,
            });
            console.log("error");
        }
    }
}


export function getCustomerHistory() {
    return async function (dispatch) {
        dispatch({
            type: GET_DEALERS_CUSTOMER_HISTORY_REQUEST,
        });
        try {
            const response = await API.get(`/sales/history-logs`);
            dispatch({
                type: GET_DEALERS_CUSTOMER_HISTORY_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_DEALERS_CUSTOMER_HISTORY_FAILED,
            });
        }
    };
}


export function sentComments(data) {
    return async function (dispatch) {
        dispatch({
            type: GET_SENT_COMMENT_REQUEST,
            payload : data
        });
        try {
            await API.post(`/sales/sent-comment`, data);
            try {
                const response = await API.post(`/sales/view-comment`, data);
                dispatch({
                    type: GET_VIEW_COMMENT_SUCCESS,
                    payload: response.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_VIEW_COMMENT_FAILED,
                });
            }

        } catch (error) {
            dispatch({
                type: GET_SENT_COMMENT_FAILED,
            });
        }
    };
}


export function viewComments(data) {
    return async function (dispatch) {
        dispatch({
            type: GET_VIEW_COMMENT_REQUEST,
            payload : data
        });
        try {
            const response = await API.post(`/sales/view-comment`, data);
            dispatch({
                type: GET_VIEW_COMMENT_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_VIEW_COMMENT_FAILED,
            });
        }
    };
}

export function commentReset() {
    return async function (dispatch) {
        dispatch({
            type : COMMENT_RESET,
            // payload : data
        })
    }
}


export function getCreditApp(data) {
    return async function (dispatch) {
        dispatch({
            type: GET_CREDIT_APP_REQUEST,
        });
        try {
            const response = await API.post(`/sales/credit-app-required`, data);
            dispatch({
                type: GET_CREDIT_APP_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_CREDIT_APP_FAILED,
            });
        }
    };
}

export function getDealerStatus(id, status) {
    return async function (dispatch) {
        dispatch({
            type: GET_DEALER_STATUS_REQUEST,
        });
        try {
            const response = await API.post(`/accounts/update-user-status`, { id, status });
            dispatch({
                type: GET_DEALER_STATUS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_DEALER_STATUS_FAILED,
            });
        }
    };
}





export function getAccountDealers(makeEmpty = true) {
    return async function (dispatch) {
        if (makeEmpty) {
            dispatch({
                type: GET_ACCOUNT_DEALER_REQUEST,
            });
        }
        try {
            const response = await API.get(`accounts/list-dealer-customer`);
            dispatch({
                type: GET_ACCOUNT_DEALER_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: GET_ACCOUNT_DEALER_FAILED,
            });
        }
    };
}



export function sendDealerSettingData(data) {
    return async function (dispatch) {
        dispatch({
            type: SEND_DEALER_SETTING_REQUEST,
        });
        try {
            await API.post(`sales/additional-dealer-settings`, data);
            try {
                const response = await API.post(`sales/get-dealer-tier-details`, data);
                console.log('response',response)
                dispatch({
                    type: VIEW_DEALER_SETTING_SUCCESS,
                    payload: response.data,
                });
                window.location.reload();   
            } catch (error) {
                dispatch({
                    type: VIEW_DEALER_SETTING_FAILED,
                });
            }
        } catch (error) {
            dispatch({
                type: SEND_DEALER_SETTING_FAILED,
            });
        }
    };
}




export function viewDealerSettingData(data) {
    return async function (dispatch) {
        dispatch({
            type: VIEW_DEALER_SETTING_DATA,
        });
        try {
            const response = await API.post(`sales/get-dealer-tier-details`, data);
            dispatch({
                type: VIEW_DEALER_SETTING_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: VIEW_DEALER_SETTING_FAILED,
            });
        }
    };
}



export function getDealerAcessRequest(dealer_id, history) {
    return async function (dispatch) {
        dispatch({
            type: GET_DEALER_ACCESS_REQUEST,
        });
        try {
            const response = await API.post(`accounts/get-dealer-access`, { dealer_id });
            console.log('response',response.data)
            dispatch({
                type: GET_DEALER_ACCESS_SUCCESS,
                payload: response.data,
            });
            sessionStorage.setItem('impersonatedDealerToken', response.data.token);
            sessionStorage.setItem('dealer_name', response.data.data.first_name + " " + response.data.data.last_name);
            pushNotification("Dealer's Access Granted", 'success', 'TOP_RIGHT', 3000);
            window.open('/')
           
        } catch (error) {
            dispatch({
                type: GET_DEALER_ACCESS_FAILED,
            });
        }
    };
}



export function updateEarliestDateDelivery(data) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_EARLIEST_DATE_DELIVERY_REQUEST,
        });
        try {
            const response = await API.post(`sales/update-earliest-delivery-date`, data);
            dispatch({
                type: UPDATE_EARLIEST_DATE_DELIVERY_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_EARLIEST_DATE_DELIVERY_FAILED,
            });
        }
    };
}
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import Input from '../../../../../components/commons/input';
import {
    IconDownArrow,
    IconArrowLeft,
    IconSearchAdmin,
    IconKey,
    IconSetting,
    IconCogsvg,
    keySvg,
} from '../../../../../assets/images';
import IconCog from '../../../../../assets/images/IconCog.png';
import keyIconHeader from '../../../../../assets/images/keyIconHeader.png';
import Header, {
    HeaderCenter,
    HeaderLeft,
    HeaderRight,
} from '../../../../../components/Dealer/Header';
import {
    getDealerCustomers,
    getCustomerProfile,
    getDealerAcessRequest,
} from '../../../../../redux/actions/admin';
import { getFromData } from '../../../../../components/commons/utility';
import { CircledImageButton } from '../../../style';
import Loader from '../../../../../shared/Loader';

function HomeScreen(props) {
    const {
        history,
        dealer,
        getDealerCustomers,
        dealerCustomers,
        getCustomerProfile,
        getDealerAcessRequest,
    } = props;

    console.log(props);

    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
    const [search, setSearch] = useState('');
    const [filterCustomer, setFilterCustomer] = useState(null);
    console.log(filterCustomer);
    const [validationResult, setValidationResult] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (search != null && search !== '') {
            let temp =
                dealer[0].sales_data &&
                dealer[0].sales_data &&
                dealer[0].sales_data.filter((d) =>
                    d.customer_name.toLowerCase().includes(search.toLowerCase())
                );
            let temp1 =
                dealer[0].preapproval_data &&
                dealer[0].preapproval_data &&
                dealer[0].preapproval_data.filter((d) =>
                    d.customer_name.toLowerCase().includes(search.toLowerCase())
                );
            let temp2 =
                dealer[0].funding_data &&
                dealer[0].funding_data &&
                dealer[0].funding_data.filter((d) =>
                    d.customer_name.toLowerCase().includes(search.toLowerCase())
                );

            setFilterCustomer(temp, temp1, temp2);
            console.log(temp);
        } else {
            setFilterCustomer([]);
        }
    }, [search]);

    const showHandler = () => {
        setShow(!show);
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();

        const formData = getFromData(evt);
        setValidationResult(formData.validationResult);

        if (!formData.validationResult) {
            getDealerCustomers(formData.formData);
            setShow(false);
        }
    };

    const handleClickSearch = () => {
        setShow(false);
        setIsSearchBarVisible(!isSearchBarVisible);
    };

    const handleArrowBack = () => {
        // let path = localStorage.getItem('redirectionPath') ? localStorage.getItem('redirectionPath') : '/?tab=1'
        // history.push(path)
        history && history.push('/');
    };

    const handleSetting = () => {
        // history && history.push('/admin/dealer/settings')
        window.location.assign('/admin/dealer/settings');
    };

    const handleAccess = () => {
        getDealerAcessRequest(dealer[0].id);
        // history && history.push('/login')
    };

    return (
        <div className="sales" style={{ marginBottom: 60 }}>
            {dealerCustomers.loading && <Loader />}
            <div style={{ position: 'sticky', top: 0, zIndex: 9 }}>
                <Header>
                    <HeaderLeft>
                        <img
                            src={IconArrowLeft}
                            onClick={() => handleArrowBack()}
                            alt=""
                        />
                        <img
                            src={IconCogsvg}
                            width="22px"
                            className="ml-3"
                            onClick={handleSetting}
                        />
                    </HeaderLeft>
                    <HeaderCenter>
                        <div className="header-main custom_header_main_center">
                            {dealer ? dealer[0].company_name : ''}
                        </div>
                    </HeaderCenter>
                    <HeaderRight>
                        <img
                            src={keySvg}
                            width="27px"
                            className="mr-3"
                            onClick={handleAccess}
                        />
                        <CircledImageButton active={isSearchBarVisible}>
                            <img
                                src={IconSearchAdmin}
                                alt=""
                                onClick={handleClickSearch}
                            />
                        </CircledImageButton>
                    </HeaderRight>
                </Header>
            </div>

            {isSearchBarVisible && (
                <div className="search-header">
                    <form action="javascript:void(0)">
                        <Form.Group>
                            <Form.Control
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            )}

<div style={{ position: 'sticky', top: 48, zIndex: 9 }}>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div
                    className="container dealer-search-container"
                    style={{
                        height: 'auto',
                        borderBottom: '4px solid rgb(0, 157, 134)',
                    }}
                >
                    {show && (
                        <div className="styled-form home-form">
                            <div className={show ? 'search-box' : ''}>
                                <Form.Group className="home-input mb-18">
                                    <Input
                                        name="last_name"
                                        type="text"
                                        label="Last Name"
                                        defaultText="Last Name"
                                    />
                                </Form.Group>
                                <Form.Group className="home-input mb-18">
                                    <Input
                                        name="cif_number"
                                        type="text"
                                        label="TCP Account Number"
                                        defaultText="TCP Account Number"
                                    />
                                </Form.Group>
                                <Form.Group className="home-input mb-18">
                                    <Input
                                        name="email"
                                        type="text"
                                        label="Email"
                                        defaultText="Email"
                                    />
                                </Form.Group>
                                <Form.Group className="home-input mb-18">
                                    <Input
                                        name="phone"
                                        type="hidden"
                                        label="Phone"
                                        defaultText="(123) 456-7890"
                                        regex="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                                        mask="(999) 999-9999"
                                        required={false}
                                        error={{
                                            invalid:
                                                'Please enter 10 digit number',
                                        }}
                                        validationResult={validationResult}
                                    />
                                </Form.Group>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: 20,
                                    }}
                                >
                                    <button
                                        className="search"
                                        type="submit"
                                        style={{
                                            backgroundColor: '#009d86',
                                            height: 35,
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </form>

            <div className="searchDropDown" onClick={showHandler}>
                <img
                    src={IconDownArrow}
                    alt=""
                    width={12}
                    style={{ transform: show && 'rotate(180deg)' }}
                />
            </div>
</div>
            {filterCustomer &&
                filterCustomer.length > 0 &&
                filterCustomer.map((item) => (
                    <Row
                        style={{ maxWidth: 380, margin: 'auto', padding: 15 }}
                        onClick={() =>
                            getCustomerProfile(
                                item.app_id ? item.app_id : null,
                                item.cifnumber,
                                history,
                                'admin'
                            )
                        }
                    >
                        <Col
                            className="search-detail"
                            style={{
                                fontSize: 14,
                                opacity: 1,
                                color: '#9abdd9',
                            }}
                        >
                            <div>{item.customer_name}</div>
                            <div>{item.sales_name}</div>
                        </Col>
                        <Col
                            className="search-detail"
                            style={{
                                fontSize: 14,
                                textAlign: 'right',
                                opacity: 1,
                                color: '#c1d9e6',
                            }}
                        >
                            <div>{item.city}, </div>
                            <div>{item.state}</div>
                        </Col>
                    </Row>
                ))}

            {dealerCustomers.data &&
                dealerCustomers.data.length > 0 &&
                dealerCustomers.data.map((item) => (
                    <Row
                        style={{ maxWidth: 380, margin: 'auto', padding: 15 }}
                        onClick={() =>
                            getCustomerProfile(
                                item.app_id ? item.app_id : null,
                                item.cifnumber,
                                history,
                                'admin'
                            )
                        }
                    >
                        <Col
                            className="search-detail"
                            style={{
                                fontSize: 14,
                                opacity: 1,
                                color: '#9abdd9',
                            }}
                        >
                            <div>{item.name}</div>
                            <div>TCP# {item.cifnumber}</div>
                        </Col>
                        <Col
                            className="search-detail"
                            style={{
                                fontSize: 12,
                                textAlign: 'right',
                                opacity: 1,
                                color: '#c1d9e6',
                            }}
                        >
                            <div>{item.email}</div>
                            <div>{item.phone}</div>
                        </Col>
                    </Row>
                ))}
        </div>
    );
}

const mapStateToProps = (state) => ({
    dealer: state.admin.dealer,
    dealerCustomers: state.admin.dealerCustomers,
});

const mapDispatchToProps = (dispatch) => ({
    getDealerAcessRequest: (data) => dispatch(getDealerAcessRequest(data)),
    getDealerCustomers: (formData) => dispatch(getDealerCustomers(formData)),
    getCustomerProfile: (id, cifnumber, history, profileType) =>
        dispatch(getCustomerProfile(id, cifnumber, history, profileType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Header, {
    HeaderCenter,
    HeaderLeft,
    HeaderRight,
} from '../../../../components/Dealer/Header';
import { IconArrowLeft } from '../../../../assets/images';
import { MessageList } from './Chat/MessageList';
import Loader from 'shared/Loader';
import { connect, useDispatch } from 'react-redux';
import { commentReset, sentComments, viewComments } from '../../../../redux/actions/admin';
import { pushNotification } from 'utils/notification';
import Input from '../../../../components/commons/input';
import { getFromData } from '../../../../components/commons/utility';

function Comments(props) {

    const {
        applicant,
        history,
        sentComments,
        comment,
        viewComments,
        commentLoading,
        selectedCustomer: customer
    } = props;
    // console.log('dealercustomer',props);

    const [chatData, setChatData] = useState([]);
    const [validationResult, setValidationResult] = useState(null);
    const [value, setValue] = useState("");
    const [initialLoad, setInitialLoad] = useState(false)
    const inputRef = useRef(null);

    const dispatch = useDispatch()

    const handleArrowBack = () => {
        history && history.goBack();
        // dispatch(commentReset())
    };

    useEffect(() => {
        // console.log('chatdata',chatData)
        // dispatch(commentReset(true))
        viewComments({
            app_id: customer ? customer?.app_id : localStorage.getItem('customerAppId'),
            co_customer_id: customer && customer.customer_info && customer.customer_info.co_app ? customer.customer_info.co_app.id : null,
            customer_id: customer && customer.customer_info && customer.customer_info.main_app && customer.customer_info.main_app.id,
            loading : true
        })
        setInitialLoad(true)
        const intervalId = setInterval(function () {
            if (window.location.pathname === '/dealer/comments') {
                // dispatch(commentReset(false))
                viewComments({
                    app_id: customer ? customer?.app_id : localStorage.getItem('customerAppId'),
                    co_customer_id: customer && customer.customer_info && customer.customer_info.co_app ? customer.customer_info.co_app.id : null,
                    customer_id: customer && customer.customer_info && customer.customer_info.main_app && customer.customer_info.main_app.id,
                    loading : false
                })
                setInitialLoad(false)
            }
        }, 15000);

        return () => {
            clearInterval(intervalId)
        }

    }, [])

    useEffect(() => {

        let newChatData = [];

        if (comment && comment.data && comment.data.length > 0) {

            comment.data.forEach(c => {
                newChatData.push({
                    text: c.message,
                    date: new Date(c.sent_time),
                    dateString: c.sent_time,
                    status: c.status,
                    user: {
                        id: c.sender_id,
                        name: c.sender,
                        email: c.sender_email
                    },
                })
            })
        }

        setTimeout(function () {
            let objDiv = document.getElementById("chat-box-div");
            objDiv.scrollTop = objDiv.scrollHeight;
        }, 200)

        setChatData(newChatData);

        // return () => {
        //     newChatData = []
        //     setChatData([])
        //     console.log('leavecomment97')
        // }

    }, [JSON.stringify(comment.data)]);


    const handleSubmit = (e) => {

        e.preventDefault();
        const formData = getFromData(e);

        setValidationResult(formData.validationResult);

        if (!formData.validationResult) {

            let data = formData.formData
            sentComments({
                app_id: customer ? customer?.app_id : localStorage.getItem('customerAppId'),
                customer_id: customer && customer.customer_info && customer.customer_info.main_app && customer.customer_info.main_app.id,
                co_customer_id: customer && customer.customer_info && customer.customer_info.co_app ? customer.customer_info.co_app.id : null,
                message: data.message,
                loading : false
            });

            setValue('')
        }
        else {
            pushNotification('The fields marked in Red need to be filled with appropriate data.', 'error', 'TOP_RIGHT', 3000);
        }
    }


    const handleInputKeyDown = (e) => {
        console.log(e.key)
        if (e.key === 'Enter') {
            console.log(value)
            setValue(null)
            // inputRef.current.value = null;
        }
    };

    return (
        <div className="admin pb-5">

            {comment && comment.loading}
        <div style={{position : 'sticky', top : 0, zIndex : 2}}>
            <Header>
                <HeaderLeft>
                    <img src={IconArrowLeft} onClick={handleArrowBack} alt="" />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">
                        {customer
                            && customer.customer_info
                            && customer.customer_info.main_app
                            && customer.customer_info.main_app.full_name}
                    </div>
                </HeaderCenter>
                <HeaderRight />
            </Header>
            </div>
            {commentLoading && <Loader />}
            <MessageList dataSource={chatData} user={applicant} initialLoad={initialLoad}/>
            <form className="footer-container p-3 comment-footer" onSubmit={(e) => handleSubmit(e)}
            >

                <Input
                    // ref={inputRef}
                    name="message"
                    type="text"
                    className="comment-input"
                    defaultText="Type your message here..."
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyPress={(e) => handleInputKeyDown(e)}
                    required={true}
                    error={{
                        'empty': " "
                    }}
                    optionalParams={{
                        autoFocus: true
                    }}
                    validationResult={validationResult}
                />
                <input type='submit' style={{ display: 'none' }} />
            </form>
           
        </div>
    );
}

Comments.propTypes = {
    // applicant: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => (console.log('commentstate',state),{
    selectedCustomer: state.dealer.selectedCustomer,
    comment: state.admin.comment,
    commentLoading : state.admin.comment.loading
});

const mapDispatchToProps = (dispatch) => ({
    sentComments: (data) => dispatch(sentComments(data)),
    viewComments: (data) => dispatch(viewComments(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);

import { Col, Row } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react';
import { ExpandIcon } from '../components/ExpandIcon';
import CustomerRow from '../../Home/Review/components/CustomerRow';
import OrderRequestCard from '../../Home/Review/components/OrderRequestCard';
import PreApprovalRequestCard from '../../Home/Review/components/PreApprovalRequestCard';
import FundingRequestCard from '../../Home/Review/components/FundingRequestCard';

const BadgedExpand = ({ number }) => {
    if (number > 0) {
        return (
            <span className="badged-expand">
                <span className="number-badge">{number}</span>
                <ExpandIcon />
            </span>
        );
    } else {
        return <span className="number-badge">{number}</span>;
    }
};

export function DealerRow({
    history,
    dealer,
    filterDealer,
    statusName = '',
    expanded,
    onClick,
    type = 'review',
    expandAllFunding,
    parentControlReview,
    changeExandAllFundinhHandler,
    closeAllFunding,
}) {
    console.log(
        dealer,
        filterDealer,
        // type,
        statusName
    );
    const [activeCustomer, setActiveCustomer] = useState(null);
    const [customExpand, setCustomExpand] = useState(expanded);

    useEffect(() => {
        if (expanded && parentControlReview) {
            setCustomExpand(false);
        }
    }, [parentControlReview]);

    useEffect(() => {

        if (!expandAllFunding) {
            setActiveCustomer(null)
        }
        console.log(activeCustomer, expandAllFunding)
        if (expandAllFunding && activeCustomer) {
            setActiveCustomer(null)
        }
    }, [expandAllFunding])

    const handleClickCustomer = (item, type) => {
        console.log('typehere', type)
        setActiveCustomer(item.app_id)
        // if(expandAllFunding && activeCustomer) {
        //     setActiveCustomer(null)
        // }
        // if(expandAllFunding) {
        //   setActiveCustomer(item.app_id)
        // } 
        // else {
        // setActiveCustomer(item.app_id)
        // }
        if (item.app_id === activeCustomer) {
            localStorage.setItem('activeCustomer', null);
            setActiveCustomer(null);
        } else {
            localStorage.setItem('activeCustomer', item.app_id);
            setActiveCustomer(item.app_id);
            setCustomExpand(false);
        }
    };

    const renderCustomerRows = (customers, type) => {
        console.log(type, customers);
        return customers.map(
            (item) => (
                console.log(
                    'customeritem',
                    item,
                    expandAllFunding,
                    item.app_id !== activeCustomer,
                    localStorage.getItem('activeCustomer')
                ),
                (
                    <Fragment key={item.app_id}>
                        <CustomerRow
                            history={history}
                            // expanded={
                            //     !expandAllFunding &&
                            //     localStorage.getItem('activeCustomer') == null
                            //         ? false
                            //         : !expandAllFunding &&
                            //           item.app_id != activeCustomer
                            //         ? true
                            //         : !expandAllFunding &&
                            //           item.app_id == activeCustomer
                            //         ? false
                            //         : true
                            // }
                            expanded={item.app_id == activeCustomer}
                            customer={item}
                            requestType={type}
                            closeAllFunding={closeAllFunding}
                            onClick={() => {
                                handleClickCustomer(item, type);
                            }}
                            expandAllFunding={expandAllFunding}
                            dealer={dealer}
                            statusName={statusName}
                            changeExandAllFundinhHandler={
                                changeExandAllFundinhHandler
                            }
                            type={type}
                        />

                        {item.app_id == activeCustomer && type === 'order' && (
                            <OrderRequestCard
                                history={history}
                                customer={item}
                                dealer_id={dealer.id}
                                isDealerScreen={1}
                                type={type}
                            />
                        )}

                        {item.app_id == activeCustomer &&
                            type === 'preapproval' && (
                                <PreApprovalRequestCard
                                    customer={item}
                                    isDealerScreen={1}
                                    type={type}
                                />
                            )}

                        {/* {item.app_id == activeCustomer && type === 'funding' &&
                    <FundingRequestCard
                        customer={item}
                        isDealerScreen={1}
                    />
                } */}
                        {expandAllFunding && type === 'funding' && !activeCustomer && (
                            <FundingRequestCard
                                customer={item}
                                isDealerScreen={1}
                            />
                        )}
                        {
                            expandAllFunding && type === 'funding' && (activeCustomer && item.app_id != activeCustomer) && (
                                <FundingRequestCard
                                    customer={item}
                                    isDealerScreen={1}
                                />
                            )
                        }
                        {!expandAllFunding &&
                            type === 'funding' &&
                            item.app_id == activeCustomer && (
                                <FundingRequestCard
                                    customer={item}
                                    isDealerScreen={1}
                                />
                            )}

                        {/* {expandAllFunding &&
                        type === 'funding' &&
                        item.app_id != activeCustomer ? (
                            <FundingRequestCard
                                customer={item}
                                isDealerScreen={1}
                            />
                        ) : (
                            type === 'funding' &&
                            item.app_id == activeCustomer && (
                                <FundingRequestCard
                                    customer={item}
                                    isDealerScreen={1}
                                />
                            )
                        )} */}
                    </Fragment>
                )
            )
        );
    };

    return (
        <React.Fragment key={dealer.id}>
            {type !== 'funding' && (
                <Row
                    className={`single-row ${expanded ? 'expanded' : ''}`}
                    // onClick={() => onClick && onClick(type + '_' + dealer.id)}
                    onClick={() =>
                        onClick && Array.isArray(dealer)
                            ? onClick(type + '_' + dealer[0].id)
                            : onClick(type + '_' + dealer.id)
                    }
                >
                    <div className="dealer-row" style={{width : '96%'}}>
                        <Col xs={6}>
                            <span className="dealer-name" style={{color : "#B2D8F7"}}>{statusName}</span>
                        </Col>
                        <Col
                            xs={6}
                            className="dealer-action"
                            style={{ textAlign: 'right' }}
                        >
                            {statusName === 'Pre Approvals' &&
                                dealer &&
                                dealer[0] &&
                                dealer[0].preapproval_data && (
                                    <BadgedExpand
                                        number={
                                            dealer[0].preapproval_data
                                                ? dealer[0].preapproval_data
                                                    .length
                                                : 0
                                        }
                                    />
                                )}

                            {statusName === 'Application(s)' &&
                                dealer &&
                                dealer[0] &&
                                dealer[0].sales_data && (
                                    <BadgedExpand
                                        number={
                                            dealer[0].sales_data
                                                ? dealer[0].sales_data.length
                                                : 0
                                        }
                                    />
                                )}

                            {statusName === 'Incomplete' &&
                                dealer && dealer[0] && dealer[0].incomplete &&
                                <span className="number-badge">{dealer[0].incomplete}</span>
                            }
                        </Col>
                    </div>
                    <div className="dealer-row-border" />
                </Row>
            )}

            {type === 'preapproval' &&
                expanded &&
                dealer &&
                dealer[0] &&
                dealer[0].preapproval_data &&
                renderCustomerRows(dealer[0].preapproval_data, 'preapproval')}

            {type === 'order' &&
                expanded &&
                dealer[0] &&
                dealer[0].sales_data &&
                renderCustomerRows(dealer[0].sales_data, 'order')}

            {type === 'funding' && !filterDealer[0].funding_data
                ? renderCustomerRows(filterDealer, 'funding')
                : type === 'funding' &&
                dealer &&
                dealer[0] &&
                dealer[0].funding_data &&
                expanded &&
                renderCustomerRows(dealer[0].funding_data, 'funding')}
        </React.Fragment>
    );
}

import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { ExpandIcon } from './ExpandIcon';
import CustomerRow from './CustomerRow';
import OrderRequestCard from './OrderRequestCard';
import PreApprovalRequestCard from './PreApprovalRequestCard';
import connect from 'react-redux/lib/connect/connect';
import FundingRequestCard from './FundingRequestCard';
import { useDispatch } from 'react-redux';
import { setDealer } from '../../../../../../redux/actions/admin';
import IconFeatherChevronRightpng from '../../../../../../assets/images/IconFeatherChevronRightpng.png';
import ElongedInactive from '../../../../../../assets/images/ElongedInactive.svg'
import ElongedActive from '../../../../../../assets/images/ElongedActive.svg'

const BadgedExpand = ({ number, expanded }) => {
    if (number > 0) {
        return (
            <>
             {/* <span className="badged-expand" style={{ float: 'right' }}>
                 <span className="number-badge">{number}</span>
                 <ExpandIcon expanded={expanded}/>
               
             </span> */}
             <div>
            {
                !expanded ?
                <>
                 <img src={ElongedInactive} alt="chevron-right" style={{position : 'relative'}}/>
            <span style={{position : 'absolute', right : number > 9 ? 34 : '37.5px', fontSize : 12, top : 5}}>{number}</span>
                </> : <>
                <img src={ElongedActive} alt="chevron-right" style={{position : 'relative'}}/>
            <span style={{position : 'absolute', right : number > 9 ? 34 : '37.5px', fontSize : 12, top : 5}}>{number}</span>
                </>
            }
           
            </div>
            </>
        );
    } else {
        return (
            <span className="number-badge" style={{ float: 'right' }}>
                {number}
            </span>
        );
    }
};

export function DealerRow({
    history,
    dealer,
    expanded,
    onClick,
    type = 'review',
    selectDealer,
    search,
    getFundingFrequency,
}) {
    console.log('dealer', dealer);
    const [activeCustomer, setActiveCustomer] = useState(
        localStorage.getItem('activeCustomer')
            ? localStorage.getItem('activeCustomer')
            : null
    );
    const dispatch = useDispatch();
    const handleClickCustomer = (item) => {
        if (item.app_id === activeCustomer) {
            localStorage.setItem('activeCustomer', null);
            setActiveCustomer(null);
        } else {
            localStorage.setItem('activeCustomer', item.app_id);
            setActiveCustomer(item.app_id);
        }
    };

    const renderCustomerRows = (dealer, customers, type) => {
        console.log(customers, type, dealer);

        return customers.map(
            (item) => (
                console.log('customeritem', item),
                (
                    <Fragment key={item.app_id}>
                        <CustomerRow
                            history={history}
                            expanded={item.app_id == activeCustomer}
                            customer={item}
                            requestType={type}
                            onClick={() => {
                                handleClickCustomer(item);
                            }}
                            dealer={dealer}
                        />

                        {item.app_id == activeCustomer && type === 'order' && (
                            <OrderRequestCard
                                history={history}
                                customer={item}
                                dealer_id={dealer.id}
                            />
                        )}

                        {item.earliest_delivery_date == null &&
                            item.app_id == activeCustomer &&
                            type === 'preapproval' && (
                                <PreApprovalRequestCard
                                    customer={item}
                                    funding_frequency={dealer.funding_frequency}
                                />
                            )}

                        {item.earliest_delivery_date != null &&
                            item.app_id == activeCustomer &&
                            type === 'preapproval' && (
                                <PreApprovalRequestCard
                                    customer={item}
                                    funding_frequency={dealer.funding_frequency}
                                />
                            )}

                        {item.funding_date == null &&
                            item.app_id == activeCustomer &&
                            type === 'funding' && (
                                <FundingRequestCard
                                    customer={item}
                                    funding_frequency={dealer.funding_frequency}
                                />
                            )}

                        {item.funding_date != null &&
                            item.app_id == activeCustomer &&
                            type === 'funding' && (
                                <FundingRequestCard
                                    customer={item}
                                    funding_frequency={dealer.funding_frequency}
                                />
                            )}
                    </Fragment>
                )
            )
        );
    };

    return (
        <React.Fragment key={dealer.id}>
            {(dealer &&
                dealer.preapproval_data &&
                dealer.preapproval_data.length > 0) ||
            (dealer.customer_data && dealer.customer_data.length > 0) ||
            (dealer.sales_customer_data &&
                dealer.sales_customer_data.length > 0) ? (
                <Row className={`single-row ${expanded ? 'expanded' : ''} `}>
                    <div
                        className="dealer-row admin-dealer-row-bottom-border"
                        style={{
                            paddingBottom: 10,
                            marginBottom: expanded ? 0 : 15,
                            width : '96%'
                        }}
                    >
                        <Col
                            xs={8} sm={8} lg={6} md={6}
                            onClick={() => {
                                selectDealer(dealer.id, history);
                                dispatch(setDealer(dealer));
                            }}
                        >
                            <span className="dealer-name" style={{height : 23, display : 'inline-block', padding : '0 12px'}}>
                                {dealer.company_name}
                            </span>
                        </Col>
                        <Col
                            xs={4} sm={4} lg={6} md={6}
                            className="dealer-action"
                            style={{ textAlign: 'end', position : 'relative' }}
                            onClick={() => {
                                onClick && onClick(dealer);
                                getFundingFrequency &&
                                    getFundingFrequency(
                                        dealer.funding_frequency
                                    );
                            }}
                        >
                            {dealer.preapproval_data && (
                                <BadgedExpand
                                    number={
                                        dealer.preapproval_data
                                            ? dealer.preapproval_data.length +
                                              dealer.sales_customer_data.length
                                            : 0
                                    }
                                    expanded={expanded}
                                />
                            )}
                            {dealer.customer_data && (
                                <BadgedExpand
                                    number={
                                        dealer.customer_data
                                            ? dealer.customer_data.length
                                            : 0
                                    }
                                    expanded={expanded}
                                />
                            )}
                        </Col>
                    </div>
                    <div className="dealer-row-border" />
                </Row>
            ) : (
                <Row className={`single-row `}>
                    <div
                        className="dealer-row admin-dealer-row-bottom-border"
                        style={{
                            paddingBottom: 10,
                            marginBottom: 15,
                            width : '96%'
                        }}
                    >
                        <Col
                            xs={8} sm={8} lg={6} md={6}
                            onClick={() => {
                                selectDealer(dealer.id, history);
                                dispatch(setDealer(dealer));
                            }}
                        >
                            <span className="dealer-name">
                                {dealer.company_name}
                            </span>
                        </Col>
                        <Col
                            xs={4} sm={4} lg={6} md={6}
                            className="dealer-action"
                            style={{ textAlign: 'end', position : 'relative', top : 1 }}
                            onClick={() => onClick && onClick(dealer)}
                        >
                            {dealer.preapproval_data && (
                                <BadgedExpand
                                    number={
                                        dealer.preapproval_data
                                            ? dealer.preapproval_data.length +
                                              dealer.sales_customer_data.length
                                            : 0
                                    }
                                />
                            )}
                            {dealer.customer_data && (
                                <BadgedExpand
                                    number={
                                        dealer.customer_data
                                            ? dealer.customer_data.length
                                            : 0
                                    }
                                />
                            )}
                        </Col>
                    </div>
                    <div className="dealer-row-border" />
                </Row>
            )}
            {type === 'review' &&
                expanded &&
                renderCustomerRows(dealer, dealer.sales_customer_data, 'order')}
            {type === 'review' &&
                expanded &&
                renderCustomerRows(
                    dealer,
                    dealer.preapproval_data,
                    'preapproval'
                )}
            {type === 'funding' &&
                dealer.customer_data &&
                expanded &&
                renderCustomerRows(dealer, dealer.customer_data, 'funding')}
        </React.Fragment>
    );
}

import { SliderContainer, SliderItem } from '../../style';
import PropTypes from 'prop-types';
import React from 'react';

function CustomerFooter({ activeTab, history }) {

    const onClickTab = (path) => {
        history && history.push(path)
    }

    return (
        <div className="footer-container p-3">
            <SliderContainer>
                <SliderItem
                    className="col-4"
                    active={activeTab === 0}
                    onClick={() => onClickTab('/dealer/profile')}
                >
                    <span>Profile</span> 
                </SliderItem>
                <SliderItem
                    className="col-4"
                    active={activeTab === 1}
                    onClick={() => onClickTab('/dealer/paperwork')}
                >
                    <span>Paperwork</span> 
                </SliderItem>
                <SliderItem
                    className="col-4"
                    active={activeTab === 2}
                    onClick={() => onClickTab('/dealer/history')}
                >
                    <span>History</span> 
                </SliderItem>
            </SliderContainer>
        </div>
    );
}

CustomerFooter.propTypes = {
    activeTab: PropTypes.number,
    onClickTab: PropTypes.func
};

export default CustomerFooter;

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import DealerFooter from "./DealerFooter";
import { getParamsValue } from '../../../utils/utility';
import { SideMenu } from "./SideMenu";
import Input from "components/commons/input";
import { TCPLogo, IconBgDown, IconCancel, IconSmallArrowRight,IconStatusWaiting, HourGlassSvg } from "assets/images";
import { connect, useSelector } from "react-redux";
import { 
    getPreApprovals, 
    getCustomerProfile, 
    getFundedSchedules, 
    getCustomerSearchData, 
    clearCustomerSearchArr,
    getCustomerLastNameAction 
} from "../../../redux/actions/dealer";
import Loader from "shared/Loader";
import Header from '../../../components/Sales/Header';
import SalesTab from './Sales';
import FundingTab from './Funds';
import ChevronCircluar from '../../../assets/images/Chevron_circular.svg'

function Home(props) {

    const {
        getPreApprovals,
        getFundedSchedules,
        getCustomerProfile,
        preaprovals,
        searchCustomers,
        clearCustomerSearchArr,
        sales,
        scheduled, 
        funded,
        actionLoading,
        history,
        getCustomerSearchData,
        customerlastNames,
        getCustomerLastNameAction
    } = props;

    console.log(props);

    const data = useSelector((state) => console.log(state))

    const [activeTab, setActiveTab] = useState(getParamsValue('tab') ? Number(getParamsValue('tab')) : 0);
    const [openLastNameDropdown, setOpenLastNameDropdown] = useState(false);
    const [name, setName] = useState("");
    const [city, setCity] = useState("");

    useEffect(() => {
        getPreApprovals();
        getFundedSchedules(null, null, true);
        setName("");
        setCity("");
        clearCustomerSearchArr();

        const intervalId = setInterval(function () {
            if(window.location.pathname === '/') {
                if(activeTab == 0) {
                    console.log('home')
                    getPreApprovals(false);
                } else {
                    console.log('home')
                    getFundedSchedules(null, null, false);
                }
            }
        }, 120000);

        document.getElementById('last_name').onkeydown = function(e){
            console.log(e.keyCode)
            if(e.keyCode == 13){
                getCustomerSearchData({ last_name: name, city: city })
            }
        };

        document.getElementById('city').onkeydown = function(e){
             if(e.keyCode == 13){
                getCustomerSearchData({ last_name: name, city: city })
             }
        };

        return () => {
            clearInterval(intervalId)
        }

    }, []);

    const handleClickTab = (tab) => {
        if (tab === 0 && activeTab === tab) {
            getPreApprovals();
        }
        else if (tab === 1 && activeTab === tab) {
            getFundedSchedules();
        }
        localStorage.setItem('mainTab', tab)
        setActiveTab(tab);
        if(tab === 0) {
            history.push({
                pathname: '/',
                search: '?tab=0'
            })
        } else {
            history.push({
                pathname: '/',
                search: '?tab=1'
            })
        }
    };

    const searchCustomerByName = () => {

        if (name) {
            getCustomerSearchData({ last_name: name, city: city })
        }
    }

    const searchCustomerByCity = () => {

        if (city) {
            getCustomerSearchData({ last_name: name, city: city })
        }
    }

    const startlastNameSearch = (name) => {
        setName(name);
        setOpenLastNameDropdown(false);
        getCustomerSearchData({ last_name: name, city: city })
    }

    // const customerProfileHandler = (history, app_id, nortridge_cif_number) => {
    //     getCustomerProfile(history, app_id, nortridge_cif_number).then((res) => {
    //         console.log(res)
    //         history.push('/dealer/profile')
    //     }).catch((err) => {
    //         console.log(err)
    //     })
    // }


    return (
        <>
            <div className="sales">
                <Header isHome={true} history={history} avatar={''}>
                    <div className="dealer"><SideMenu /></div>
                    <img src={TCPLogo} alt="logo" className="main-logo"/>
                </Header>
            </div>

            {(actionLoading || preaprovals.loading || sales.loading || scheduled.loading || funded.loading) && <Loader />}
            
            <div className="m-auto main-sales">
                <div className="container" style={{ height: "calc(100vh - 125px)" }}>
                    
                    <div className="styled-form m-auto addStaffForm" style={{ paddingTop: 10 }}>
                        <div style={{ position: "relative" }}>
                            <Form.Group className="mb-18 position-relative">
                                <Input
                                    name="last_name"
                                    type="text"
                                    label="Last Name"
                                    defaultText="Name"
                                    value={name}
                                    required={true}
                                    className="position-relative"
                                    handleChange={(e) => { 
                                        setName(e.target.value ? e.target.value : "")
                                    }}
                                    onBlur ={() => searchCustomerByName()}
                                />
                                <img
                                    src={openLastNameDropdown ? IconCancel : ChevronCircluar}
                                    alt="down-arrow"
                                    className={openLastNameDropdown ? "downArrow custom-close-btn" : "downArrow"}
                                    width="39"
                                    onClick={!openLastNameDropdown ? () => {
                                        getCustomerLastNameAction()
                                        setOpenLastNameDropdown(true)
                                    } : () => {
                                        setOpenLastNameDropdown(false)
                                    }}
                                />
                            </Form.Group>
                            {openLastNameDropdown &&
                                <div className="searchResultBocLastName">
                                    <ul>
                                        {customerlastNames && customerlastNames.map(ln => {
                                            return <li onClick={() => startlastNameSearch(ln)}>{ln}</li>
                                        })}
                                    </ul>
                                </div>
                            }
                        </div>
                        <div>
                            <Form.Group className="mb-18">
                                <Input
                                    name="city"
                                    type="text"
                                    label="City"
                                    defaultText="City"
                                    required={true}
                                    handleChange={(e) => { 
                                        setCity(e.target.value ? e.target.value : "")
                                    }}
                                    onBlur={() => searchCustomerByCity()}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    {activeTab === 0 ?
                        <SalesTab history={history} />
                        :
                        <FundingTab history={history} />
                    }

                </div>
            </div>

            <DealerFooter onClickTab={(tab) => handleClickTab(tab)} activeTab={activeTab} sales={sales && sales.data ? preaprovals && preaprovals.data ? preaprovals.data.length + sales.data.length : sales.data.length : preaprovals && preaprovals.data ? preaprovals.data.length : 0} funding={funded && funded.data ? funded.data.length : 0} />

            {searchCustomers && searchCustomers.length > 0 &&
                <div className="dealer-customer-search custom-dealer-search-customer-height">
                    <div className="header" style={{background : "#009e87"}}>
                        <span className="count">{searchCustomers.length} match found...</span>
                        <button className="btn secondary" type="button" onClick={() => clearCustomerSearchArr()} style={{background : "#DAE8EF", color : "#3A5B77"}}>Cancel</button>
                    </div>
                    <div className="content">
                        <table style={{ width: 'calc(100% - 30px)', margin: '0 15px' }}>
                            {searchCustomers && searchCustomers.map((item) => {
                                return (
                                    <tr className="details">
                                        <td onClick={() => getCustomerProfile(history, item.app_id, item.nortridge_cif_number)} style={{ cursor: "pointer" , color : "#3A5B77"}}>
                                            <label className="name">{item.main_app.name}</label>
                                            <p className="address">{item.main_app.street} {item.city}</p>
                                            <p className="address">{item.main_app.zip} {item.main_app.state}</p>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            {item.sales_preapproval_inprogress && <img src={HourGlassSvg} alt="arrow" width={37}/>}
                                            <img src={IconSmallArrowRight} alt="arrow"/>
                                        </td>
                                    </tr>   
                                )
                            })}
                        </table>
                    </div>
                </div>
            }

        </>
    );
}


const mapStateToProps = (state) => ({
    preaprovals: state.dealer.preaprovals,
    sales: state.dealer.sales,
    scheduled: state.dealer.scheduled,
    funded: state.dealer.funded,
    customerlastNames: state.dealer.customerlastNames,
    searchCustomers: state.dealer.searchCustomers,
    actionLoading: state.dealer.actionLoading,
});

const mapDispatchToProps = (dispatch) => ({
    getPreApprovals: (action) => dispatch(getPreApprovals(action)),
    getFundedSchedules: (start, end, action) => dispatch(getFundedSchedules(start, end, action)),
    getCustomerProfile: (history, data, cif) => dispatch(getCustomerProfile(history, data, cif)),
    getCustomerSearchData: (data) => dispatch(getCustomerSearchData(data)),
    getCustomerLastNameAction: () => dispatch(getCustomerLastNameAction()),
    clearCustomerSearchArr: () => dispatch(clearCustomerSearchArr())
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);

import { REMOVE_GLOBAL_ITEM_ADJUSTMENT, REMOVE_GLOBAL_ITEM_ADJUSTMENT_FAILED, REMOVE_GLOBAL_ITEM_ADJUSTMENT_SUCCESS, UPDATE_GLOBAL_ITEM_ADJUSTMENT, UPDATE_GLOBAL_ITEM_ADJUSTMENT_FAILED, UPDATE_GLOBAL_ITEM_ADJUSTMENT_SUCCESS } from "redux/actions/actionTypes";
import {
    GET_STAFFS_REQUESTS,
    GET_STAFFS_REQUESTS_SUCCESS,
    GET_STAFFS_REQUESTS_FAILED,
    SET_STAFFS_REQUEST,
    ADD_STAFFS_REQUEST,
    ADD_STAFFS_REQUEST_SUCCESS,
    ADD_STAFFS_REQUEST_FAILED,
    UPDATE_STAFFS_REQUEST,
    UPDATE_STAFFS_REQUEST_SUCCESS,
    UPDATE_STAFFS_REQUEST_FAILED,
    DELETE_STAFFS_REQUEST,
    DELETE_STAFFS_REQUEST_SUCCESS,
    DELETE_STAFFS_REQUEST_FAILED,




    TOGGLE_SHOW_DATA,
    TOGGLE_SHOW_DATA_FAILED,
    TOGGLE_SHOW_DATA_SUCCESS,

    PREAPPROVAL_SALES_REQUEST,
    PREAPPROVAL_SALES_SUCCESS,
    PREAPPROVAL_SALES_FAILED,

    FUNDED_SCHEDULED_REQUEST,
    FUNDED_SCHEDULED_SUCCESS,
    FUNDED_SCHEDULED_FAILED,

    GET_PAPERWORK_REQUEST,
    GET_PAPERWORK_SUCCESS,
    GET_PAPERWORK_FAILED,

    GET_HISTORY_REQUEST,
    GET_HISTORY_SUCCESS,
    GET_HISTORY_FAILED,

    GET_CREDIT_REQUEST,
    GET_CREDIT_SUCCESS,
    GET_CREDIT_FAILED,

    GET_CUSTOMER_SEARCH_REQUEST,
    GET_CUSTOMER_SEARCH_SUCCESS,
    GET_CUSTOMER_SEARCH_FAILED,

    GET_CUSTOMER_PROFILE_REQUEST,
    GET_CUSTOMER_PROFILE_SUCCESS,
    GET_CUSTOMER_PROFILE_FAILED,

    GENERATE_PREAPPROVAL_REQUEST,
    GENERATE_PREAPPROVAL_SUCCESS,
    GENERATE_PREAPPROVAL_FAILED,

    REQUEST_FUNDING_REQUEST,
    REQUEST_FUNDING_SUCCESS,
    REQUEST_FUNDING_FAILED,

    REQUEST_ADJUST_FUNDING_REQUEST,
    REQUEST_ADJUST_FUNDING_SUCCESS,
    REQUEST_ADJUST_FUNDING_FAILED,

    GET_CUSTOMER_UNIQUE_LAST_NAME_REQUEST,
    GET_CUSTOMER_UNIQUE_LAST_NAME_SUCCESS,
    GET_CUSTOMER_UNIQUE_LAST_NAME_FAILED,

    GET_HELLO_SIGN_STATUS_REQUEST,
    GET_HELLO_SIGN_STATUS_SUCCESS,
    GET_HELLO_SIGN_STATUS_FAILED,

    D_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST,
    D_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS,
    D_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED,

    D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST,
    D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS,
    D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED,

    
    GET_HISTORY_NORTHRIDGE_DATA_REQUEST,
    GET_HISTORY_NORTHRIDGE_DATA_SUCCESS,
    GET_HISTORY_NORTHRIDGE_DATA_FAILED,

} from "../actions/dealer";

const INIT_STATE = {
    staffs: {
        loading: false,
        data: [],
    },

    selectedStaff: {},
    actionLoading: false,

    preaprovals: {
        loading: false,
        data: null
    },

    sales: {
        loading: false,
        data: null
    },

    funded: {
        loading: false,
        data: null
    },

    scheduled: {
        loading: false,
        data: null
    },

    totalFundAmount: {
        loading: false,
        data: null
    },
    
    totalScheduledAmount: {
        loading: false,
        data: null
    },

    
    northridgeHistory: {
        loading: true,
        data: [],
    },

    customerlastNames: null,

    selectedRow: null,

    generateOrder: [],
    selectedCustomer: {},
    signStatus: {
        data: [],
    },
    searchCustomers: [],
    globalItemAdjustmentLoader : false
};



export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case TOGGLE_SHOW_DATA:
            return {
                ...state,
                selectedRow: null,
            };
        case TOGGLE_SHOW_DATA_SUCCESS:
            return {
                ...state,
                selectedRow: action.payload == state.selectedRow ? null : action.payload,
            };
        case TOGGLE_SHOW_DATA_FAILED:
            return {
                ...state,
                selectedRow: null,
            };



        case GET_STAFFS_REQUESTS:
            return {
                ...state,
                staffs: {
                    loading: true,
                    data: [],
                },
            };
        case GET_STAFFS_REQUESTS_SUCCESS:
            return {
                ...state,
                staffs: {
                    loading: false,
                    data: action.payload,
                },
            };
        case GET_STAFFS_REQUESTS_FAILED:
            return {
                ...state,
                staffs: {
                    loading: false,
                    data: [],
                },
            };



        case SET_STAFFS_REQUEST:
            return {
                ...state,
                selectedStaff: action.payload,
            };



        case UPDATE_STAFFS_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case UPDATE_STAFFS_REQUEST_SUCCESS:
            return {
                ...state,
                selectedStaff: {},
                actionLoading: false,
            };
        case UPDATE_STAFFS_REQUEST_FAILED:
            return {
                ...state,
                actionLoading: false,
            };




        case GET_CUSTOMER_SEARCH_REQUEST:
            return {
                ...state,
                actionLoading: true,
                searchCustomers: []
            };
        case GET_CUSTOMER_SEARCH_SUCCESS:

            // let temp_data = action.payload.data ? action.payload.data.filter(c => c.app_id !== null) : null
            console.log(action.payload)
            return {
                ...state,
                actionLoading: false,
                searchCustomers: action.payload.data
            };
        case GET_CUSTOMER_SEARCH_FAILED:
            return {
                ...state,
                actionLoading: false,
                searchCustomers: []
            };




        case ADD_STAFFS_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case ADD_STAFFS_REQUEST_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case ADD_STAFFS_REQUEST_FAILED:
            return {
                ...state,
                actionLoading: false,
            };



        case DELETE_STAFFS_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case DELETE_STAFFS_REQUEST_SUCCESS:

            const index = state.staffs.data.findIndex((d) => d.id == action.payload);
            state.staffs.data.splice(index, 1);

            return {
                ...state,
                actionLoading: false,
            };
        case DELETE_STAFFS_REQUEST_FAILED:
            return {
                ...state,
                actionLoading: false,
            };



        case PREAPPROVAL_SALES_REQUEST:
            return {
                ...state,
                actionLoading: true,
                preaprovals: {
                    loading: true,
                    data: null
                },
                sales: {
                    loading: true,
                    data: null
                },
            };
        case PREAPPROVAL_SALES_SUCCESS:
            return {
                ...state,
                actionLoading: false,
                preaprovals: {
                    loading: false,
                    data: action.payload.data.preaprovals
                },
                sales: {
                    loading: false,
                    data: action.payload.data.sales
                },
            };
        case PREAPPROVAL_SALES_FAILED:
            return {
                ...state,
                actionLoading: false,
                preaprovals: {
                    loading: false,
                    data: null
                },
                sales: {
                    loading: false,
                    data: null
                },
            };



        case FUNDED_SCHEDULED_REQUEST:
            return {
                ...state,
                actionLoading: true,
                funded: {
                    loading: false,
                    data: null
                },
                scheduled: {
                    loading: false,
                    data: null
                },
            };
        case FUNDED_SCHEDULED_SUCCESS:
            return {
                ...state,
                actionLoading: false,
                funded: {
                    loading: false,
                    data: action.payload.data.funded_info
                },
                scheduled: {
                    loading: false,
                    data: action.payload.data.scheduled_info
                },
                totalFundAmount: {
                    loading: false,
                    data: action.payload.data.total_fund_info
                },
                totalScheduledAmount: {
                    loading: false,
                    data: action.payload.data.total_scheduled_info
                }
            };
        case FUNDED_SCHEDULED_FAILED:
            return {
                ...state,
                actionLoading: false,
                funded: {
                    loading: false,
                    data: null
                },
                scheduled: {
                    loading: false,
                    data: null
                },
            };



        case GET_CUSTOMER_PROFILE_REQUEST:
            return {
                ...state,
                actionLoading: true,
                selectedCustomer: null,
            };
        case GET_CUSTOMER_PROFILE_SUCCESS:
            // let new_d_customer = action.payload.data

            // state.selectedCustomer && state.selectedCustomer.app_inprogress && state.selectedCustomer.app_inprogress[0].data && state.selectedCustomer.app_inprogress[0].data.length > 0 && state.selectedCustomer.app_inprogress[0].data.forEach(pp => {

            //     let p_index = new_d_customer.app_inprogress[0].data.findIndex(p => p.product_id == pp.product_id)

            //     if (p_index !== -1) {
            //         new_d_customer.app_inprogress[0].data[p_index] = { ...new_d_customer.app_inprogress[0].data[p_index], hello_sign: state.cProfile.data.app_inprogress[0].data[p_index].hello_sign }
            //     }
            // });

            // let temp_customer33 = {
            //     ...new_d_customer,
            //     app_inprogress: [{
            //         ...new_d_customer.app_inprogress[0],
            //         data: new_d_customer.app_inprogress[0].data
            //     }]
            // }

            // console.log(temp_customer33)

            return {
                ...state,
                actionLoading: false,
                selectedCustomer: action.payload.data,
                // selectedCustomer: temp_customer33 || new_d_customer,
            };

        case GET_CUSTOMER_PROFILE_FAILED:
            return {
                ...state,
                actionLoading: false,
                selectedCustomer: null,
            };



        case GET_PAPERWORK_REQUEST:
            return {
                ...state,
                actionLoading: true,
                // selectedCustomer: { ...state.selectedCustomer, paperwork: null },
            };
        case GET_PAPERWORK_SUCCESS:
            return {
                ...state,
                actionLoading: false,
                selectedCustomer: { ...state.selectedCustomer, paperwork: action.payload.data ? action.payload.data : null },
            };
        case GET_PAPERWORK_FAILED:
            return {
                ...state,
                actionLoading: false,
                selectedCustomer: { ...state.selectedCustomer, paperwork: null },
            };



        case D_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST:
            return {
                ...state,
                actionLoading: true
            };
        case D_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS:

            let customer = state.selectedCustomer;

            let p_index = customer.app_inprogress[0].data.findIndex(p => p.product_id == action.payload.product_id)
            customer.app_inprogress[0].data[p_index] = { ...customer.app_inprogress[0].data[p_index], hello_sign: action.payload.hello_sign }

            let temp_customer = {
                ...customer,
                app_inprogress: [{
                    ...customer.app_inprogress[0],
                    data: customer.app_inprogress[0].data
                }]
            }

            return {
                ...state,
                actionLoading: false,
                selectedCustomer: temp_customer,
            };
        case D_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED:
            return {
                ...state,
                actionLoading: false,
            };




        case D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST:
            return {
                ...state,
                actionLoading: true
            };
        case D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS:

            let customer2 = state.selectedCustomer;

            let p_index2 = customer2.paperwork.findIndex(p => p.product_id == action.payload.product_id)
            customer2.paperwork[p_index2] = { ...customer2.paperwork[p_index2], hello_sign: action.payload.hello_sign }

            let temp_customer2 = {
                ...customer2,
                app_inprogress: [{
                    ...customer2.app_inprogress[0],
                    data: customer2.paperwork
                }]
            }

            return {
                ...state,
                actionLoading: false,
                selectedCustomer: temp_customer2,
            };
        case D_PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED:
            return {
                ...state,
                actionLoading: false,
            };




        case GET_HISTORY_REQUEST:
            return {
                ...state,
                actionLoading: true,
                // selectedCustomer: { ...state.selectedCustomer, history: null },
            };
        case GET_HISTORY_SUCCESS:
            return {
                ...state,
                actionLoading: false,
                selectedCustomer: {
                    ...state.selectedCustomer, history: action.payload.customer_history
                    //  ? [action.payload.customer_history] : null 
                },
            };
        case GET_HISTORY_FAILED:
            return {
                ...state,
                actionLoading: false,
                selectedCustomer: { ...state.selectedCustomer, history: null },
            };



        case GET_CREDIT_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case GET_CREDIT_SUCCESS:
            return {
                ...state,
                actionLoading: false,
                selectedCustomer: {
                    ...state.selectedCustomer,
                    credit: action.payload.data
                    // ? action.payload.data : null 
                },
            };
        case GET_CREDIT_FAILED:
            return {
                ...state,
                actionLoading: false,
                selectedCustomer: { ...state.selectedCustomer, credit: null },
            };




        case GENERATE_PREAPPROVAL_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case GENERATE_PREAPPROVAL_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case GENERATE_PREAPPROVAL_FAILED:
            return {
                ...state,
                actionLoading: false,
            };



        case REQUEST_FUNDING_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case REQUEST_FUNDING_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case REQUEST_FUNDING_FAILED:
            return {
                ...state,
                actionLoading: false,
            };



        case REQUEST_ADJUST_FUNDING_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case REQUEST_ADJUST_FUNDING_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case REQUEST_ADJUST_FUNDING_FAILED:
            return {
                ...state,
                actionLoading: false,
            };

        case UPDATE_GLOBAL_ITEM_ADJUSTMENT:
            return {
                ...state,
                globalItemAdjustmentLoader : true
            }    
         case UPDATE_GLOBAL_ITEM_ADJUSTMENT_SUCCESS: 
         return {
            ...state,
            globalItemAdjustmentLoader : false
         }   
         case UPDATE_GLOBAL_ITEM_ADJUSTMENT_FAILED:
            return {
                ...state,
                globalItemAdjustmentLoader : false
            }

         case REMOVE_GLOBAL_ITEM_ADJUSTMENT:
            return {
                ...state,
                globalItemAdjustmentLoader : true
            }       
         case REMOVE_GLOBAL_ITEM_ADJUSTMENT_FAILED:
            return {
                ...state,
                globalItemAdjustmentLoader : false
            }   
          case REMOVE_GLOBAL_ITEM_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                globalItemAdjustmentLoader : false
            }  


        case GET_CUSTOMER_UNIQUE_LAST_NAME_REQUEST:
            return {
                ...state,
                actionLoading: true,
                customerlastNames: null
            };
        case GET_CUSTOMER_UNIQUE_LAST_NAME_SUCCESS:
            return {
                ...state,
                actionLoading: false,
                customerlastNames: action.payload.data
            };
        case GET_CUSTOMER_UNIQUE_LAST_NAME_FAILED:
            return {
                ...state,
                actionLoading: false,
                customerlastNames: null
            };





        case GET_HELLO_SIGN_STATUS_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case GET_HELLO_SIGN_STATUS_SUCCESS:
            return {
                ...state,
                signStatus: {
                    actionLoading: false,
                    data: action.payload,
                }
            };
        case GET_HELLO_SIGN_STATUS_FAILED:
            return {
                ...state,
                actionLoading: false,
            };


            case GET_HISTORY_NORTHRIDGE_DATA_REQUEST:
                return {
                    ...state,
                    northridgeHistory: {
                        loading: true,
                        data: [],
                    },
                };
            case GET_HISTORY_NORTHRIDGE_DATA_SUCCESS:
                return {
                    ...state,
                    northridgeHistory: {
                        loading: false,
                        data: action.payload,
                    },
                };
            case GET_HISTORY_NORTHRIDGE_DATA_FAILED:
                return {
                    ...state,
                    northridgeHistory: {
                        loading: false,
                        data: [],
                    },
                };




        default:
            return state;
    }
}

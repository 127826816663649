import { toast } from 'react-toastify'

export function pushNotification(msg, type, position, duration) {
    if (type === 'info') {
        return toast.info(msg, {
            // position: position ? toast.POSITION[ position ] : toast.POSITION.TOP_CENTER,
            // autoClose: duration ? duration : 3000,
            className: 'toast-bottom-position',
            position: 'bottom-right',
            autoClose: 5000,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            newestOnTop: true,
            hideProgressBar: true
        })
    } else if (type === 'success') {
        return toast.success(msg, {
            position: 'bottom-right',
            // autoClose: duration ? duration : 3000,
            className: 'toast-bottom-position',
            autoClose: 5000,
            pauseOnFocusLoss: true,
            pauseOnHover: false,
            newestOnTop: true,
            hideProgressBar: true,
        })
    } else if (type === 'warning') {
        return toast.warn(msg, {
            position: 'bottom-right',
            // autoClose: duration ? duration : 3000,
            className: 'toast-bottom-position',
            autoClose: 5000,
            pauseOnFocusLoss: true,
            pauseOnHover: false,
            newestOnTop: true,
            hideProgressBar: true
        })
    } else if (type === 'error') {
        return toast.error(msg, {
            position: 'bottom-right',
            // autoClose: duration ? duration : 3000,
            className: 'toast-bottom-position',
            autoClose: 5000,
            pauseOnFocusLoss: true,
            pauseOnHover: false,
            newestOnTop: true,
            hideProgressBar: true
        })
    } else {
        return toast.info(msg, {
            position: 'bottom-right',
            // autoClose: duration ? duration : 3000,
            autoClose: 5000,
            pauseOnFocusLoss: true,
            pauseOnHover: false,
            newestOnTop: true,
            hideProgressBar: true
        })
    }
}

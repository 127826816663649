import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { pushNotification } from 'utils/notification';
import Header from '../../../components/Sales/Header';
import { TCPLogo, IconProfileActive, IconLogout, IconArrowLeft, IconRight } from '../../../assets/images';
import Input from '../../../components/commons/input';
import Loader from 'shared/Loader';

import { getFromData } from '../../../components/commons/utility';
import { updateUserRequest } from '../../../redux/actions/userAction';
import { resetCustomerSearchApiInitiate } from '../../../redux/actions/sales';

function ProfileForm(props) {

    const {
        history,
        userData,
        updateUserRequest,
        actionLoading,
        resetCustomerSearchApiInitiate
    } = props;

    console.log(userData)
    console.log(props)

    const [validationResult, setValidationResult] = useState(null);

    const handleArrowBack = () => {
        history.replace('/');
    }

    const handleLogout = () => {
        resetCustomerSearchApiInitiate();
        localStorage.removeItem('token');
        history.replace('/login');
    }

    const handleSubmit = evt => {

        evt.preventDefault();
        const formData = getFromData(evt);

        console.log(formData.validationResult)

        setValidationResult(formData.validationResult);

        if (!formData.validationResult) {

            let data = formData.formData

            let temp_userdata = {
                "first_name": data.first_name,
                "last_name": data.last_name,
                "email": data.email,
                "phone": data.phone,
            }

            console.log(temp_userdata)

            updateUserRequest(temp_userdata)
        }
        else {
            pushNotification('The fields marked in Red need to be filled with appropriate data.', 'error', 'TOP_RIGHT', 3000);
        }
    }

    return (
        <div className="sales">

            { props.user.isUserDataLoading && <Loader />}

            

            <Header isHome={true} history={history} visibleLogout={true}>
                <img src={IconArrowLeft} alt="home" className="icon-back" onClick={() => handleArrowBack()} />
                <img src={TCPLogo} alt="logo" className="main-logo" />
                <img src={IconLogout} alt="logo" className="main-logo" onClick={() => handleLogout()} />
            </Header>

            <div className="sub-header">
                <button className="active">
                    <img src={IconProfileActive} alt="" style={{ width: 12, marginRight: 5, height: "unset" }} />
                    <span>Profile</span>
                </button>
            </div>
           
            <form action="javascript:void(0)" onSubmit={(e) => handleSubmit(e)} noValidate>
                <div className="container">
                    <div className="styled-form">
                        <Form.Group className="mb-18">
                            <Input
                                name="first_name"
                                type="text"
                                // defaultValue={userData.first_name}
                                // defaultValue={props.user.userData.first_name}
                                value={props.user.userData.first_name}
                                label="First Name"
                                defaultText="First Name"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                optionalParams={{
                                    autoFocus: true
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="last_name"
                                type="text"
                                // defaultValue={userData.last_name}
                                value={props.user.userData.last_name}
                                label="Last Name"
                                defaultText="Last Name"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="email"
                                type="email"
                                regex="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
                                // defaultValue={userData.email}
                                value={props.user.userData.email}
                                label="Email"
                                defaultText="Email"
                                required={true}
                                error={{
                                    'invalid': "Please enter valid Email address",
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="phone"
                                type="hidden"
                                // defaultValue={userData.phone}
                                value={props.user.userData.phone}
                                label="Phone"
                                defaultText="(123) 456-7890"
                                regex="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                                mask="(999) 999-9999"
                                required={true}
                                error={{
                                    'invalid': "Please enter 10 digit number",
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className="footer-container">
                    <div className="row">
                        <div className="col">
                            <input className="btn secondary" type="button" value="Change Password" onClick={() => history && history.push('/change-password')} />
                        </div>
                        <div className="col">
                            <input className="btn secondary" type="submit" value="Update" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    userData: state.user.userData,
    actionLoading: state.sales.actionLoading
});

const mapDispatchToProps = dispatch => ({
    updateUserRequest: (data) => dispatch(updateUserRequest(data)),
    resetCustomerSearchApiInitiate: () => dispatch(resetCustomerSearchApiInitiate)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileForm);
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { historyFilterBy, historySortBy } from 'shared/constant';
import Dropdown from '../../../../components/commons/dropdown';
import Loader from 'shared/Loader';

import { getFromData } from '../../../../components/commons/utility';
import { getSalesHistory } from 'redux/actions/sales';

function Filter(props) {

    const {
        getSalesHistory,
        setShowFilter,
        actionLoading
    } = props;

    const [tempFilterBy, setFilterBy] = useState('all');
    const [tempSortBy, setSortBy] = useState('newtoold');
    const [validationResult, setValidationResult] = useState(null);

    const handleSubmit = evt => {
        evt.preventDefault();
        const formData = getFromData(evt);

        console.log(formData.validationResult)

        setValidationResult(formData.validationResult);

        if (!formData.validationResult) {

            let data = formData.formData

            console.log(data) 

            let temp = {
                "archive": false,
                "filter_status": true,
                "range": tempFilterBy,
                "orderby": tempSortBy
            }
            getSalesHistory(temp).then(() => {
                setShowFilter(false)
            });
        }
    }

    const setDefaultFilter = () => {
        setFilterBy('all');
        setSortBy('newtoold');
    }

    return (
        <div className="sales">

            {actionLoading && <Loader />}

            <form action="javascript:void(0)" onSubmit={(e) => handleSubmit(e)} noValidate>
                <div className="container">
                    <div className="styled-form full-dropdown-width full-dropdown-width-filter-history">
                        <br></br>
                        <br></br>
                        <Form.Group className="styled-column mb-18">
                            <Dropdown
                                name="range"
                                type="dropdown"
                                value={tempFilterBy}
                                defaultValue={tempFilterBy}
                                label="Filter By"
                                defaultText="Filter By"
                                required={true}
                                options={historyFilterBy}
                                validationResult={validationResult}
                                error={{
                                    'empty': " "
                                }}
                                handleChange={(e) => {
                                    setFilterBy(e)
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="styled-column mb-18">
                            <Dropdown
                                name="orderby"
                                type="dropdown"
                                value={tempSortBy}
                                defaultValue={tempSortBy}
                                label="Sort By"
                                defaultText="Sort By"
                                required={true}
                                options={historySortBy}
                                validationResult={validationResult}
                                error={{
                                    'empty': " "
                                }}
                                handleChange={(e) => {
                                    setSortBy(e)
                                }}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className="footer-container comment-footer">
                    <input className="btn secondary" type="button" value="Clear" onClick={() => setDefaultFilter()}/>
                    <input className="btn secondary" type="submit" value="Apply" />
                </div>
            </form>

        </div>
    )
}




const mapStateToProps = (state) => ({
    actionLoading: state.sales.actionLoading,
    salesHistory: state.sales.salesHistory,
});

const mapDispatchToProps = (dispatch) => ({
    getSalesHistory: (data) => dispatch(getSalesHistory(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import MessageBox from './MessageBox';
import { DateSeparator } from './DateSeparator';

export function MessageList({ dataSource, className, user, position, initialLoad }) {
    const [messageLength, setMessageLength] = useState(dataSource.length);
    const [finalLength, setFinalLength] = useState(0);
    let today = new Date();
    const GROUPING_SPAN = 300000;

    const isSameDay = (dateA, dateB) => {
        return !(
            dateA.getFullYear() !== dateB.getFullYear() ||
            dateA.getMonth() !== dateB.getMonth() ||
            dateA.getDate() !== dateB.getDate()
        );
    };

    // const isMe = (targetUser) => {
    //     // return targetUser.id === user.id;
    //     1
    // };

    useEffect(() => {
        if (initialLoad) {
            setFinalLength(dataSource.length);
            setMessageLength(dataSource.length);
            window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: 'smooth',
            });
        } else {
            if (dataSource.length > finalLength) {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    }, [dataSource]);
  
    // useEffect(() => {
    //     setFinalLength(dataSource.length)
    // },[dataSource])

    // useEffect(() => {
    //     if(finalLength > messageLength) {
    //          window.scrollTo({
    //         top: document.getElementById("chat-box-div").scrollHeight,
    //         left: 0,
    //         behavior: 'smooth'
    //       });
    //     }
    // },[messageLength, finalLength])

    return (

        <div id="chat-box-div" className="message-list" style={{maxWidth : 768, margin : 'auto'}}>
            {dataSource.map((message, index) => {

                console.log(message)
                const previousMessage = index > 0 ? dataSource[index - 1] : null;

                let userName = <></>;
                let dateSeparator = <></>;
                let notch =  false;
                let msgPosition = message.user.email == localStorage.getItem('email') ? 'right' : 'left'

                if (
                    (previousMessage === null &&
                        !isSameDay(message.date, today)) ||
                    (previousMessage !== null &&
                        !isSameDay(message.date, previousMessage.date))
                ) {
                    dateSeparator = (
                        <DateSeparator
                            date={message.date}
                            dateString={message.dateString}
                        />
                    );
                    notch = true;
                }

                if (
                    (previousMessage === null ||
                        previousMessage.user.id !== message.user.id)
                    //      &&
                    // !isMe(message.user)
                ) {
                    userName = (
                        <div className="message-list--username">
                            {message.user.name}
                        </div>
                    );
                    notch = true;
                }

                // console.log(message)

                if (
                    previousMessage !== null &&
                    message.date - previousMessage.date > GROUPING_SPAN
                ) {
                    userName = (
                        <div className='message-list--username'>
                            {message.user.name}
                        </div>
                    );
                }

                return (
                    <>
                        {dateSeparator}
                        <div className="message-list--item">
                            <MessageBox
                                key={message}
                                {...message}
                                position={msgPosition}
                                notch={notch}
                                userName={userName}
                            />
                        </div>
                    </>
                );
            })}
        </div>
    );
}

MessageList.propTypes = {
    className: PropTypes.string,
    dataSource: PropTypes.array.isRequired,
    // user: PropTypes.object.isRequired,
};

MessageList.defaultProps = {
    className: '',
};

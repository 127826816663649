import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { pushNotification } from 'utils/notification';
import { message } from 'shared/constant';
import { TCPLogo, IconProfile, IconLogout } from '../../assets/images';

import { resetCustomerSearchApiInitiate } from '../../redux/actions/sales';

class Header extends Component {
    menuContent = React.createRef();
    state = {
        menuOpen: false
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.menuContent && this.menuContent.current && !this.menuContent.current.contains(event.target)) {
            this.setState({ menuOpen: false });
        }
    }

    onProfile = () => {
        this.props.history.replace('/profile');
    }

    onLogout = () => {
        this.props.resetCustomerSearchApiInitiate();
        localStorage.removeItem('token');
        localStorage.clear()
        sessionStorage.clear()
        this.props.history.replace('/login');
       
    }

    onOpenMenu = () => {
        this.setState({ menuOpen: true });
    }

    renderAvatar = () => {
        const { isHome } = this.props;
        if (!isHome) {
            return ;
        }
        const { avatar } = this.props;
        if(localStorage.getItem('role') == 'sales') {
        if (!avatar || avatar === "") {
            // return (
            //     <div className="header-avatar" onClick={() => { localStorage.getItem('role') == 'sales,dealer' ?   window.location.href='/profile' : this.onOpenMenu(); }} ></div>
            // )
            return (
                <div className='avatar-container' onClick={() => { localStorage.getItem('role') == 'sales,dealer' ?   window.location.href='/profile' : this.onOpenMenu(); }}>
                    <span>{localStorage.getItem('firstName').split("")[0].toUpperCase() + " " + localStorage.getItem('lastName').split("")[0].toUpperCase()}</span>
                </div>
            )
        }
        return (
            <img
                className="header-avatar"
                alt=""
                src={avatar}
                onClick={this.onOpenMenu}
            />
        )
        }
    }

    render() {

        const { children } = this.props;

        return (
            <div className={`header`}>

                <div className="header-main">
                    {children}
                    {!this.props.visibleLogout && this.renderAvatar()}
                </div>

                {!this.props.visibleLogout &&
                    <div className="header-menu" ref={this.menuContent}>
                        {this.state.menuOpen && (
                            <div className="header-menu-content">
                                <div style={{ height: 3, backgroundColor: '#b2d8f7' }}></div>
                                <Row>
                                    <div className="profile-btn" onClick={this.onProfile}>
                                        <img alt="profile" src={IconProfile} />
                                        <span>Profile</span>
                                    </div>
                                    <div className="logout-btn" onClick={this.onLogout}>
                                        <img alt="profile" src={IconLogout} />
                                        <span>Logout</span>
                                    </div>
                                </Row>
                            </div>
                        )}
                    </div>
                }

            </div>
        )
    }
}

const mapStateToProps = state => (console.log(state),{
});

export default connect(
    mapStateToProps,
    {
        resetCustomerSearchApiInitiate
    }
)(Header);
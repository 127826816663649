import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import MessageBox from './MessageBox';
import { DateSeparator } from './DateSeparator';

export function MessageList({ dataSource, className, user, initialLoad }) {
    const [messageLength, setMessageLength] = useState(dataSource.length);
    const [finalLength, setFinalLength] = useState(0);
    let today = new Date();
    const GROUPING_SPAN = 300000;

    const isSameDay = (dateA, dateB) => {
        return !(
            dateA.getFullYear() !== dateB.getFullYear() ||
            dateA.getMonth() !== dateB.getMonth() ||
            dateA.getDate() !== dateB.getDate()
        );
    };

    // useEffect(() => {
    //     console.log('messageLength', messageLength);
    //     console.log('finalLength', finalLength);
    // }, [messageLength, finalLength]);

    useEffect(() => {
        if (initialLoad) {
            setFinalLength(dataSource.length);
            setMessageLength(dataSource.length);
            window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: 'smooth',
            });
        } else {
            if (dataSource.length > finalLength) {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    }, [dataSource]);

    // useEffect(() => {
    //     console.log('initialLoadfinallength', initialLoad);
    //     if (!initialLoad && finalLength > messageLength) {
    //         window.scrollTo({
    //             top: document.getElementById('chat-box-div').scrollHeight,
    //             left: 0,
    //             behavior: 'smooth',
    //         });
    //     }
    // }, [initialLoad, finalLength]);

    // const isMe = (targetUser) => {
    //     // return targetUser.id === user.id;
    //     1
    // };

    // useEffect(() => {
    //     window.scrollTo({
    //         top: document.getElementById("chat-box-div").scrollHeight,
    //         left: 0,
    //         behavior: 'smooth'
    //       });
    // },[user])

    // useEffect(() => {
    //     setFinalLength(dataSource.length)
    //     // console.log('data2')
    //     if(finalLength > messageLength) {
    //         window.scrollTo({
    //        top: document.getElementById("chat-box-div").scrollHeight + document.getElementById('chat-box-div').style.height,
    //        left: 0,
    //        behavior: 'smooth'
    //      });
    //    }
    // },[dataSource])

    // useEffect(() => {
    //     console.log('data',dataSource)
    //     if(finalLength > messageLength) {
    //          window.scrollTo({
    //         top: document.getElementById("chat-box-div").scrollHeight + document.getElementById('chat-box-div').style.height,
    //         left: 0,
    //         behavior: 'smooth'
    //       });
    //     }
    // },[messageLength, finalLength])

    return (
        <div
            id="chat-box-div"
            className="message-list"
            style={{ maxWidth: 768, margin: 'auto' }}
        >
            {dataSource.map((message, index) => {
                // console.log('sourcemsg',message)
                const previousMessage =
                    index > 0 ? dataSource[index - 1] : null;

                let userName = <></>;
                let dateSeparator = <></>;
                let notch = false;

                if (
                    (previousMessage === null &&
                        !isSameDay(message.date, today)) ||
                    (previousMessage !== null &&
                        !isSameDay(message.date, previousMessage.date))
                ) {
                    dateSeparator = (
                        <DateSeparator
                            date={message.date}
                            dateString={message.dateString}
                        />
                    );
                    notch = true;
                }

                if (
                    previousMessage === null ||
                    previousMessage.user.id !== message.user.id
                    // &&
                    // !isMe(message.user)
                ) {
                    userName = (
                        <div
                            className={`message-list--username ${
                                message.user.email ===
                                localStorage.getItem('email')
                                    ? 'userNameRight'
                                    : ''
                            }`}
                        >
                            {message.user.name}
                        </div>
                    );
                    notch = true;
                }

                if (
                    previousMessage !== null &&
                    message.date - previousMessage.date > GROUPING_SPAN
                ) {
                    notch = true;
                }

                return (
                    <>
                        {dateSeparator}
                        <div
                            className="message-list--item"
                            style={{ maxWidth: 352, margin: 'auto' }}
                        >
                            {userName}
                            <MessageBox
                                key={message}
                                {...message}
                                position={
                                    // message.user.id === user.id
                                    message.user.email ===
                                    localStorage.getItem('email')
                                        ? 'right'
                                        : 'left'
                                }
                                notch={notch}
                                // notch={true}
                            />
                        </div>
                    </>
                );
            })}
        </div>
    );
}

MessageList.propTypes = {
    className: PropTypes.string,
    dataSource: PropTypes.array.isRequired,
    // user: PropTypes.object.isRequired,
};

MessageList.defaultProps = {
    className: '',
};

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { pushNotification } from 'utils/notification';
import Header, { HeaderLeft, HeaderCenter, HeaderRight } from '../../../components/Dealer/Header';
import { TCPLogo, IconProfileActive, IconArrowLeft } from '../../../assets/images';
import Input from '../../../components/commons/input';
import Loader from 'shared/Loader';

import { getFromData } from '../../../components/commons/utility';
import { changePasswordRequest } from '../../../redux/actions/changePassword';

function AddDealer(props) {

    const {
        history,
        changePasswordRequest,
        actionLoading,
    } = props;

    const [validationResult, setValidationResult] = useState(null);
    const [passwordEye, setPasswordEye] = useState(false);

    const handleArrowBack = () => {
        history.replace('/profile');
    }

    const handleSubmit = evt => {

        evt.preventDefault();
        const formData = getFromData(evt);

        console.log(formData.validationResult)

        setValidationResult(formData.validationResult);

        if (!formData.validationResult) {

            let data = formData.formData
            
            if(data.password !== data.confirmPassword) {
                pushNotification('Password and Confrim Password are not matched', 'error', 'TOP_RIGHT', 3000);
            }
            else {
                let temp_userdata = {
                    "currentpassword": data.currentPassword,
                    "newpassword": data.password
                }
                changePasswordRequest(temp_userdata)
            }
        } 
        else 
        {
            pushNotification('The fields marked in Red need to be filled with appropriate data.', 'error', 'TOP_RIGHT', 3000);
        } 
    }

    return (
        <div className="dealer">

            { actionLoading && <Loader />}

            <Header>
                <HeaderLeft>
                    <img src={IconArrowLeft} onClick={() => handleArrowBack()} alt="" />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">
                        <img className="main-logo" src={TCPLogo} alt="" />
                    </div>
                </HeaderCenter>
                <HeaderRight></HeaderRight>
            </Header>

            <div className="sub-header">
                <button className="active">
                    <img src={IconProfileActive} alt="" style={{width: 12, marginRight: 5, height: "unset"}}/> 
                    <span>Change Password</span>
                </button>
            </div>

            <form action="javascript:void(0)" onSubmit={(e) => handleSubmit(e)} noValidate>
                <div className="container">
                    <div className="styled-form">
                        <Form.Group className="mb-18">
                            <Input
                                name="currentPassword"
                                type="password"
                                label="Current Password"
                                defaultText="Current Password"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                optionalParams={{
                                    autoFocus: true
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18 password-field">
                            <Input
                                name="password"
                                type={ passwordEye ? "text" : "password" }
                                label="Password"
                                defaultText="Password"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                            />
                             <span className="password-eye" style={{ top: 22 }}>
                                <img
                                    src={passwordEye ? require('assets/images/eye_show.svg') : require('assets/images/eye_hide.svg')}
                                    alt="logo"
                                    onClick={() => setPasswordEye(!passwordEye) }
                                />
                            </span>
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="confirmPassword"
                                type="password"
                                label="Confirm Password"
                                defaultText="Confirm Password"
                                required={true}
                                error={{
                                    'empty': " ",
                                    'not_match': "Password & confirm password are not equal"
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className="footer-container">
                    <div className="row">
                        <div className="col">
                            <input className="btn secondary" type="button" value="Profile" onClick={() => history && history.push('/profile')}/>
                        </div>
                        <div className="col">
                            <input className="btn secondary" type="submit" value="Update"/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    actionLoading: state.sales.actionLoading
});

const mapDispatchToProps = dispatch => ({
    changePasswordRequest: data => dispatch(changePasswordRequest(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDealer);
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ExpandIcon } from './ExpandIcon';
import StatusIcon from './StatusIcons/StatusIcon';
import { toAbbrName } from '../../../../../../utils/helper';
import { IconYellowP, IconYellowS, IconGreenp, TCPLogo, IconBgDown, IconSmallArrowRight, IconStatusWaiting, IconBorderGreen } from "assets/images";
import { getCustomerProfile } from '../../../../../../redux/actions/dealer';
import {
    CircleStatusIcon,
    HexagonStatusIcon,
    TriangleStatusIcon,
} from './StatusIcons';

function DealerCustomerRow(props) {

    const {
        applicant: applicant,
        co_applicant: coApplicant,
        food_date: foodDate,
        food_status: foodStatus,
        other_status: otherStatus,
        items: items,
        status: status,
        review_mode: reviewMode,
        food_review_mode: foodReviewMode,
        other_review_mode: otherReviewMode,

        history,
        expanded,
        requestType,
        onClick,
        getCustomerProfile,
        dealer,
        profileType = 'admin',
        customer: {
            app_id,
            customer_CIF,
            products,
            customer_name = "",
            city = "",
            state = "",
            street = "",
            sales_date = "",
            earliest_delivery_date = null,
            app_status = "",
            funding_status = "",
            sales_name = "",
            address= ""
        }
    } = props;

    console.log(props);

    const formatDate = (date) => {
        return moment(date).format('DD/MM/YY');
    };

    const renderFood = (mode, status, date) => {
        return (
            <div>
                {getStatusIcon(status)}
                <span className="customer-food-date">{date ? formatDate(date) : 'N/A'}</span>
            </div>
        );
    };

    const renderPurchasedItems = (items) => {
        return items && items.map((item) => {
            return (
                <div className="customer-purchase-item" key={`product_${item.id}`}>
                    {item.product}
                </div>
            );
        });
    };

    const renderPurchasedItemPrice = (items) => {
        return items && items.map((item) => {
            return (
                <div className="customer-purchase-item" key={`product_${item.id}`}>
                    ${item.purchase_price.toFixed(2)}
                </div>
            );
        });
    };


    const getStatusIcon = (status) => {
        if (status === 'in_process') {
            return <TriangleStatusIcon
                symbol={requestType == "order" ? 'S' : 'P'}
                fill={true}
                disabled={false}
            />
        }
        else if (status === "approval") {
            return <CircleStatusIcon
                symbol={requestType == "order" ? 'S' : 'P'}
                fill={true}
                disabled={false}
            />
        }
        else if (status === "rejection") {
            return <HexagonStatusIcon
                symbol={requestType == "order" ? 'S' : 'P'}
                fill={true}
                disabled={false}
            />
        }
    }

    return (
        <Row
            className={`single-row ${expanded ? 'expanded' : ''}`}
            style={{background : expanded ? "rgb(51, 81, 105)" : ""}}
        >
            <div className={`customer-row ${expanded && 'funding-customer-row'}`}>
                <Col>
                    <div>
                        <span
                            className={`customer-name ${requestType === 'funding' && funding_status}`}
                            onClick={() => getCustomerProfile(history, app_id, customer_CIF, 'dealer')}
                            style={{background : expanded ? "rgb(0, 157, 134)" : "#2d4a5f", color : expanded ? "#fff" : "#b2d8f7"}}
                        >
                            {customer_name ? customer_name : "N/A"}
                        </span>
                    </div>
                    {requestType !== "order" &&
                        <div>
                            <span className="customer-location" style={{paddingLeft : 10}}>
                                {city ? city : "N/A"},
                                {state ? state : "N/A"}
                            </span>
                        </div>
                    }
                    {requestType === "order" &&
                        <div className="customer-location">

                            <p className='mb-0'>{sales_name}</p>
                            <p>{sales_date ? formatDate(sales_date) : 'MM/DD/YY'}</p>

                        </div>
                    }
                </Col>
                <Col xs={4} className="content-col text-left">
                    {requestType === 'order' || requestType === 'funding' ?
                        renderPurchasedItems(products)
                        :
                        renderFood(true, app_status, earliest_delivery_date)
                    }
                </Col>
                <Col xs={3} className="action-col text-right" style={{ display: 'flex', alignItems: "center", justifyContent: "end" }}>
                    {requestType === 'funding' ?
                        <div>{renderPurchasedItemPrice(products)}</div>
                        :
                        getStatusIcon(app_status)
                    }
                    <span onClick={onClick}><ExpandIcon /></span>
                </Col>
            </div>
        </Row>
    );
}

DealerCustomerRow.propTypes = {
    customer: PropTypes.object.isRequired,
    expanded: PropTypes.bool,
    onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    getCustomerProfile: ( history, id, customer_CIF, profileType) => dispatch(getCustomerProfile( history, id, customer_CIF, profileType))
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerCustomerRow);
import { SliderContainer, SliderItem } from '../../../style';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

export function DealerTabBar({ activeTab, onClickTab, badges }) {
    const history = useHistory()
    return (
        <div className={`footer-container p-3  ${history.location.pathname == "/admin/dealer" && 'admin-dealer-setting-footer'}`} style={{fontSize : 15}}>
            <SliderContainer>
                <SliderItem
                    className="col-4"
                    active={activeTab === 0}
                    onClick={() => onClickTab(0)}
                >
                    <div className="slider-item--left">
                        <span className="badge-icon">{badges[0]}</span>
                    </div>
                    <span style={{ marginLeft: 20 }}>Review</span>
                </SliderItem>
                <SliderItem
                    className="col-4"
                    active={activeTab === 1}
                    onClick={() => onClickTab(1)}
                >
                    <div className="slider-item--left">
                        <span className="badge-icon">{badges[1]}</span>
                    </div>
                    <span style={{ marginLeft: 20 }}>Funding</span>
                </SliderItem>
                <SliderItem
                    className="col-4"
                    active={activeTab === 2}
                    onClick={() => onClickTab(2)}
                >
                    Search
                </SliderItem>
            </SliderContainer>
        </div>
    );
}

DealerTabBar.propTypes = {
    activeTab: PropTypes.number,
    onClickTab: PropTypes.func,
    badges: PropTypes.array.isRequired,
};

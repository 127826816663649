import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { pushNotification } from 'utils/notification';
import { dropdownList } from 'shared/constant';
import Header, { HeaderLeft, HeaderCenter, HeaderRight } from '../../../../components/Dealer/Header';
import { TCPLogo, IconArrowLeft } from '../../../../assets/images';
import Input from '../../../../components/commons/input';
import Dropdown from '../../../../components/commons/dropdown';
import Loader from 'shared/Loader';
import { getFromData } from '../../../../components/commons/utility';
import { updateDealer, addDealer } from '../../../../redux/actions/admin';
import DealerHomeTab from './components/DealerHomeTab';


export function DealerDetails(props) {
    const {
        history,
        dealer,
        addDealer,
        updateDealer,
        actionLoading,
        activeTab = 0,
        disabled
    } = props;

    console.log('detailsprop',props)

    const [validationResult, setValidationResult] = useState(null);
    const [dealerName, setDealerName] = useState(dealer.id ? dealer.company_name : null);

    const handleArrowBack = () => {
        history.push('/admin/dealers')
    }


    const handleSubmit = evt => {

        evt.preventDefault();
        const formData = getFromData(evt);

        setValidationResult(formData.validationResult);

        if (!formData.validationResult) {

            let data = formData.formData

            if (dealer.id) {

                data = { ...data, updated_email: data.email, updated_phone: data.phone }
                updateDealer(history, data)
            }
            else {
                addDealer(history, data)
            }
        } 
        else 
        {
            pushNotification('The fields marked in Red need to be filled with appropriate data.', 'error', 'TOP_RIGHT', 3000);
        }
    }
    return (
        <div className="admin">
            {/* {dealers.loading && <Loader />} */}

            {/* {actionLoading && <Loader />} */}

            <Header>
                <HeaderLeft>
                    <img
                        src={IconArrowLeft}
                        onClick={() => handleArrowBack()}
                        alt=""
                    />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">
                    {dealer.company_name}
                    </div>
                </HeaderCenter>
                <HeaderRight>
                    {/* <img
                        src={IconAdd}
                        onClick={() => handleAddEditDealer({})}
                        alt=""
                    /> */}
                </HeaderRight>
            </Header>

            <form action="javascript:void(0)" onSubmit={(e) => handleSubmit(e)} noValidate style={{marginBottom : 18}}>
                {dealer.id &&
                    <Input
                        name="id"
                        type="hidden"
                        value={dealer.id}
                    />
                }
                <div className="container dealer_details_container" style={{height : 'calc(100vh - 82.5px)'}}>
                    <div className="styled-form">
                        <Form.Group className="mb-18">
                            <Input
                                name="company_name"
                                type="text"
                                defaultValue={dealer.company_name}
                                label="Dealer Name"
                                defaultText="Dealer Name"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                                handleChange={(e)=>setDealerName(e.target.value)}
                            />
                        </Form.Group>
                        <div className="box">
                            <Form.Group className="mb-18">
                                <Input
                                    name="email"
                                    type="email"
                                    regex="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
                                    defaultValue={dealer.email}
                                    label="Main Dealer User Email"
                                    defaultText="Dealer User Email"
                                    required={true}
                                    error={{
                                        'invalid': "Please enter valid Email address",
                                        'empty': " "
                                    }}
                                    validationResult={validationResult}
                                    disabled ={true}
                                />
                            </Form.Group>
                            <Form.Group className="mb-18">
                                <Input
                                    name="contact_email"
                                    type="email"
                                    regex="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
                                    defaultValue={dealer.contact_email}
                                    label="Dealer Contact Email"
                                    defaultText="Dealer Contact Email"
                                    required={true}
                                    error={{
                                        'invalid': "Please enter valid Email address",
                                        'empty': " "
                                    }}
                                    validationResult={validationResult}
                                />
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-18">
                            <Input
                                name="phone"
                                type="hidden"
                                defaultValue={dealer.phone}
                                label="Phone"
                                defaultText="(123) 456-7890"
                                regex="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                                mask="(999) 999-9999"
                                required={true}
                                error={{
                                    'invalid': "Please enter 10 digit number",
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                        <span className="divider"></span>
                        <span className="title">ADDRESS</span>
                        <Form.Group className="mb-18">
                            <Input
                                name="street"
                                type="text"
                                defaultValue={dealer.street}
                                label="Street"
                                defaultText="Street"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="city"
                                type="text"
                                defaultValue={dealer.city}
                                label="City"
                                defaultText="City"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Dropdown
                                name="state"
                                className="full-dropdown-width custom-dealer-detail-state-dropdown"
                                type="dropdown"
                                defaultValue={dealer.state ? dealer.state : null}
                                label="State"
                                defaultText="State"
                                required={true}
                                options={dropdownList}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="zip"
                                type="text"
                                defaultValue={dealer.zip}
                                regex="^[0-9]{5}$"
                                label="Zip Code"
                                defaultText="Zip Code"
                                required={true}
                                error={{
                                    'invalid': "Please enter 5 digit number",
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                            />
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-center" style={{marginBottom : 45}}>
                    <button className="secondary" onClick={() => handleArrowBack()}>Cancel</button>
                    <button className="secondary" type="submit">Save</button>
                </div>
                </div>
                
            </form>
        </div>
    );
}

const mapStateToProps = (state) => ({
    dealer: state.admin.selectedDealer,
    actionLoading: state.admin.actionLoading
});

const mapDispatchToProps = (dispatch) => ({
    addDealer: (history, data) => dispatch(addDealer(history, data)),
    updateDealer: (history, data) => dispatch(updateDealer(history, data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DealerDetails);
import React, { useState, useEffect, Fragment } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import Header, {
    HeaderLeft,
    HeaderCenter,
    HeaderRight,
} from '../../../../components/Dealer/Header';
import { Form, Row, Col, Container } from 'react-bootstrap';
import {
    TCPLogo,
    IconAdd,
    IconArrowLeft,
    IconDeleteNew,
} from '../../../../assets/images';
import Loader from 'shared/Loader';

import {
    setDealer,
    getDealers,
    deleteDealer,
    updateOneDealer,
    getDealerStatus,
    getAccountDealers
} from '../../../../redux/actions/admin';
import PropTypes from 'prop-types';
import { HomeTabBar } from '../Home/components/HomeTabBar';
import { ToggleButton } from '../Home/Review/components/ToggleButton/ToggleButton';
import CircuralInactive from '../../../../assets/images/CircuralInactive.svg'
import CircuralActive from '../../../../assets/images/CircuralActive.svg'

const IconSmallArrowRight = ({ active }) => {
    return (
        <svg
            className={`arrow-icon ${active && 'arrow-icon-active'}`}
            enableBackground="new 0 0 12 12"
            height="12px"
            id="Layer_1"
            version="1.1"
            viewBox="0 0 32 32"
            width="32px"
        >
            <path d="M24.291,14.276L14.705,4.69c-0.878-0.878-2.317-0.878-3.195,0l-0.8,0.8c-0.878,0.877-0.878,2.316,0,3.194  L18.024,16l-7.315,7.315c-0.878,0.878-0.878,2.317,0,3.194l0.8,0.8c0.878,0.879,2.317,0.879,3.195,0l9.586-9.587  c0.472-0.471,0.682-1.103,0.647-1.723C24.973,15.38,24.763,14.748,24.291,14.276z" />
        </svg>
    );
};

IconSmallArrowRight.propTypes = {
    active: PropTypes.bool.isRequired,
};

export function PureDealerManagement(props) {
    const {
        history,
        dealers,
        getAccountDealers,
        setDealer,
        deleteDealer,
        actionLoading,
        activeTab = 0,
        fundings,
        getDealerStatus
    } = props;

    const [openDealerIndex, setOpenDealerIndex] = useState(null);
    const [search, setSearch] = useState(null);
    const [filterDealer, setFilterDealer] = useState([]);

    // console.log(dealers)

    useEffect(() => {
        getAccountDealers();
    }, []);

    useEffect(() => {
      console.log(openDealerIndex)
      if(openDealerIndex) {
      console.log(openDealerIndex.includes('map'))
      }
    }, [openDealerIndex]);

    useEffect(() => {
        if (search != null && search !== '') {
            let temp = dealers.data.filter((d) =>
                d.company_name.toLowerCase().includes(search.toLowerCase())
            );
            setFilterDealer(temp);
        } else {
            setFilterDealer(dealers.data);
        }
    }, [search]);

    useEffect(() => {
        setFilterDealer(dealers.data);
    }, [dealers]);

    const handleArrowBack = () => {
        history && history.push('/');
    };

    const handleAddEditDealer = (item) => {
        setDealer(item);
        history.push(`/admin/addDealer`);
    };

    // const handleDeleteDealer = (item) => {
    //     Swal.fire({
    //         title: 'Delete ' + item.company_name + '?',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!',
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             deleteDealer(item.id);
    //         }
    //     });
    // };

    const handleOpenDealerAction = (dealer) => {
        if ('map' + dealer.id === openDealerIndex) setOpenDealerIndex(null);
        else setOpenDealerIndex('map' + dealer.id);
    };

    const isOpenItem = (item) => {
        return openDealerIndex === 'map' + item.id;
    };

    const changeItemActive = (item, isActive) => {
        console.log(item)

        updateOneDealer({ ...item, is_active: isActive });

        if (isActive == true) {
            getDealerStatus(item.id, false)
        }
        else {
            getDealerStatus(item.id, true)
        }
    };

    const handleClickItemSettings = (item) => {
        setDealer(item);
        history.push(`/admin/dealer/settings`);
    };

    const getSelectTab = (tab) => {
        if (tab === 0) {
            history.push({
                pathname: '/',
                search: '?tab=0'
            })
        } else {
            history.push({
                pathname: '/',
                search: '?tab=1'
            })
        }
    }

    const getDealerCount = () => {
        let count = 0;
        if (dealers && dealers.data) {
            dealers.data.map((tt) => {
                count += tt.preapproval_data.length + tt.sales_customer_data.length
            })
        }
        return count;
    }

    const getFundingCount = () => {
        let count = 0;
        if (fundings && fundings.data) {
            fundings.data.map((tt) => {
                count += tt.customer_data.length
            })
        }
        return count;
    }

    return (
        <div className="admin">
            {dealers.loading && <Loader />}

            {/* {actionLoading && <Loader />} */}
            <div style={{position : 'sticky', top : 0, zIndex : 9}}>
            <Header>
                <HeaderLeft>
                    <img
                        src={IconArrowLeft}
                        onClick={handleArrowBack}
                        alt=""
                    />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">
                        <img className="main-logo" src={TCPLogo} alt="" />
                    </div>
                </HeaderCenter>
                <HeaderRight>
                    <img
                        src={IconAdd}
                        onClick={() => handleAddEditDealer({})}
                        alt=""
                    />
                </HeaderRight>
            </Header>
            </div>

            <div className="main">
                <div className="list" style={{height : 'auto'}}>
                    <Container style={{padding : openDealerIndex && openDealerIndex.includes('map') ? 0 : '0', height : 'auto'}}>
                    {dealers &&
                        dealers.data.map((item) => (
                            <Fragment key={item.id}>
                                <div style={{padding : '0 15px', position : 'relative'}}>
                                    {isOpenItem(item) && 
                                <div className="dealer-row-border" style={{position : 'absolute', bottom : 0, width : '100%', border: 'solid 1px #009d86', right: 0, background : '#009d86'}}/>
                                    }
                                <Row
                                    className={`single-row ${isOpenItem(item) ? 'expanded' : ''
                                        }`}
                                    onClick={() => handleOpenDealerAction(item)}
                                >
                                    <div className="dealer-row" style={{paddingBottom : 10, marginBottom : isOpenItem(item) ? 0 : 10, borderBottom : '2px solid #2e495e'}}>
                                        <Col xs={8} sm={8} lg={6} md={6}>
                                            <span className="dealer-name">
                                                {item.company_name}
                                            </span>
                                        </Col>
                                        <Col xs={4} sm={4} lg={6} md={6} className="dealer-action">
                                            <span style={{float : 'right'}}>
                                                {/* <IconSmallArrowRight
                                                    active={isOpenItem(item)}
                                                /> */}
                                                {
                                                    isOpenItem(item) ? <>
                                                    <img src={CircuralActive} alt="CircuralActive" />
                                                    </> : <>
                                                    <img src={CircuralInactive} alt="CircuralInactive" />
                                                    </>
                                                }
                                            </span>
                                        </Col>
                                    </div>
                                    
                                </Row>
                                </div>
                                {isOpenItem(item) && (
                                    <div className="dealer-detail-row">
                                        
                                        <div className="dealer-detail-actions">
                                            <ToggleButton
                                                yesText={'Active'}
                                                noText="Inactive"
                                                defaultChecked={item.is_active}
                                                onChange={(isActive) => {
                                                    changeItemActive(
                                                        item,
                                                        isActive
                                                    );
                                                }}
                                            />
                                            <button  className="btn-normal"
                                                onClick={() => {
                                                    handleClickItemSettings(
                                                        item
                                                    );
                                                }}><span style={{position : 'relative', bottom : 2, fontSize : 14}}>Settings</span></button>
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                        </Container>
                </div>
            </div>

            {/* <HomeTabBar
                activeTab={activeTab}
                badges={[dealers && dealers.data ? getDealerCount() : '0', fundings && fundings.data ? getFundingCount() : '0']}
                onClickTab={(e) => getSelectTab(e)}
            /> */}
        </div>
    );
}

// PureDealerManagement.propTypes = {
//     history: PropTypes.object,
//     dealers: PropTypes.shape({
//         loading: PropTypes.bool,
//         data: PropTypes.array,
//     }),
//     actionLoading: PropTypes.bool.isRequired,
//     setDealer: PropTypes.func.isRequired,
//     deleteDealer: PropTypes.func.isRequired,
//     getAccountDealers: PropTypes.func.isRequired,
// };

const mapStateToProps = (state) => ({
    dealers: state.admin.dealers,
    actionLoading: state.admin.actionLoading,
    fundings: state.admin.fundings,
});

const mapDispatchToProps = (dispatch) => ({
    getAccountDealers: () => dispatch(getAccountDealers()),
    setDealer: (data) => dispatch(setDealer(data)),
    deleteDealer: (id) => dispatch(deleteDealer(id)),
    updateOneDealer: (data) => dispatch(updateOneDealer(data)),
    getDealerStatus: (id, status) => dispatch(getDealerStatus(id, status)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PureDealerManagement);
import { SliderContainer, SliderItem } from '../../../style';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

function DealerHomeTab({ activeTab, onClickTab, badges }) {
    const history = useHistory()
    console.log('footerhistory',history)
    return (
        <div className={`footer-container p-3 ${history.location.pathname == "/admin/dealer/settings" && 'admin-dealer-setting-footer'}`}>
            <SliderContainer>
                <SliderItem
                    className="col-6"
                    active={activeTab === 0}
                    onClick={() => onClickTab(0)}
                >
                    Dealer Details
                </SliderItem>
                <SliderItem
                    className="col-6"
                    active={activeTab === 1}
                    onClick={() => onClickTab(1)}
                >
                    Settings
                </SliderItem>
            </SliderContainer>
        </div>
    );
}

DealerHomeTab.propTypes = {
    activeTab: PropTypes.number,
    onClickTab: PropTypes.func,
    badges: PropTypes.array.isRequired,
};

export default DealerHomeTab

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import {
    CalendarContainer,
} from '../../../../style';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { IconCalenderHeader, IconReload, IconCancel } from '../../../../../../assets/images';
import Input from 'components/commons/input';
import { itemValueAdjustment, removeItemAdjustment } from "../../../../../../redux/actions/dealer";
import { cancelScheduling, reverseScheduling, updateFundDate } from "../../../../../../redux/actions/admin";
import Loader from "shared/Loader";
import { IconAwesomePenFancy, IconAwesomePenFancyRight, IconEnvelopeOpen } from '../../../../../../screens/Admin/2.0/Customer/CustomerProfile';
import { Link } from 'react-router-dom';
import { getCustomerApiInitiateForGenerateOrder } from '../../../../../../redux/actions/sales';
import history from 'history';
import { useHistory } from 'react-router-dom';
import Unionone from '../../../../../../assets/images/Union1.svg'



const CustomInput = React.forwardRef((props, ref) => {
    return (
        <span className="calender" onClick={props.onClick} ref={ref}>
            <img src={IconCalenderHeader} alt="reload" width="9" />
        </span>
    )
})

// DateInput.propTypes = {
//     value: PropTypes.object.isRequired,
//     onChange: PropTypes.func,
// };

export function FundingRequestCard(props) {

    const {
        customer,
        actionLoading,
        itemValueAdjustment,
        removeItemAdjustment,
        cancelScheduling,
        reverseScheduling,
        updateFundDate,
        isDealerScreen = 0,
        funding_frequency,
        getCustomerApiInitiateForGenerateOrder,
        funded
    } = props
    console.log('fundingrequestprops', props)

    const history = useHistory();
    console.log('history',history)
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState(null);
    const [cancelWarning, setCancelWarning] = useState(false);
    const [reverseWarning, setReverseWarning] = useState(false);
    const [summaryWarning, setSummaryWarning] = useState(false);
    const [reverseItemId, setReverseItemId] = useState(null);

    const isDisabled = (date) => {
        const day = date.getDay(date);
        return funding_frequency.includes(day);
    }

    function DateInput({ value, onChange }) {
        return (
            <CalendarContainer className={`calendar-approve ${(history.location.search == '?tab=1' || history.location.pathname == '/dealer/scheduled') ? 'funding_modal_calendar' : ''} calendar-position-mobile`} 
            // style={{ position: 'absolute', top: '35%', right: 30 }}
            >
                <DatePicker
                    selected={new Date(value)}
                    onChange={onChange}
                    minDate={new Date()}
                    className="calendar-approve"
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    withPortal
                    customInput={<CustomInput />}
                    filterDate={funding_frequency && funding_frequency.length > 0 ? isDisabled : null}
                />
            </CalendarContainer>
        );
    }

    const submitAdjustment = () => {
        if (description != "" && amount != "") {

            let temp = {
                "funding_id": customer.funding_id,
                "app_id": customer.app_id,
                "amount": amount,
                "item_description": description
            };

            setError(null)

            itemValueAdjustment(temp, isDealerScreen).then(() => {
                setDescription('');
                setAmount('')
                // window.location.reload();
            })
        }
        else {
            setError('Please enter correct data')
        }
    }

    const getotalFAmount = (date) => {
        let amount = 0;
        customer && customer.products && customer.products.forEach(f => {
            amount += f.finance_amount
        });
        return amount
        // .toFixed(2);
    };

    // const getotalPAmount = (date) => {
    //     let amount = 0;
    //     customer.products.forEach(f => {
    //         amount += f.total_purchase_price
    //     });
    //     return amount.toFixed(2);
    // };

    const updateFundDateAction = (date) => {

        let t_date = date ? moment(date).format('DD/MM/YY') : 'N/A';
        updateFundDate({ app_id: customer.app_id, scheduled_date: t_date }, isDealerScreen)
    }


    const reverseItem = (id) => {
        console.log(id)
        setReverseWarning(true)
        setReverseItemId(id)
        // reverseScheduling({
        //     funding_id: customer.funding_id,
        //     product_id: id
        // }, isDealerScreen)
    }


    const IconEnvelopeClosed = <svg xmlns="http://www.w3.org/2000/svg" width="17.331" height="12.998" viewBox="0 0 17.331 12.998">
        <path d="M0 0v2.166L8.665 6.5l8.665-4.333V0zm0 4.333V13h17.331V4.333L8.665 8.665z" />
    </svg>


    const generateOrder = () => {

       

        // let data = {
        // "customer_id": customer.customer_info.main_app.id,
        // "co_customer_id": customer.customer_info.co_app && customer.customer_info.co_app.id ? customer.customer_info.co_app.id : null,
        // "include_products": false
        // }
        // localStorage.setItem('redirectionPath', window.location.pathname + window.location.search)
        // getCustomerApiInitiateForGenerateOrder(data, history, '/admin/applyApplicationSummary')
    }

    return (
        <div className="preapproval-request-card" style={{
            paddingTop : history.location.search == '?tab=1' || history.location.pathname == "/dealer/scheduled" || history.location.pathname == '/dealer/funded' ? 15 : 0
        }}>


            {actionLoading && <Loader />}


            <Modal show={cancelWarning} onHide={() => setCancelWarning(false)} autoFocus={false}>
                <Modal.Body className="fixed-height">
                You are about to cancel the scheduled funding for this customer’s sale, are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn secondary" onClick={() => {
                        setCancelWarning(false)
                        cancelScheduling({ funding_id: customer.funding_id }, isDealerScreen)
                    }}>
                        Yes
                    </button>
                    <button class="btn secondary" onClick={() => setCancelWarning(false)}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>



            <Modal show={summaryWarning} onHide={() => setSummaryWarning(false)} autoFocus={false}>
                <Modal.Body className="fixed-height">
                    You are about to exit this page and go to the Summary screen for this particular order. Are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn secondary" onClick={() => {
                        setSummaryWarning(false)
                        getCustomerApiInitiateForGenerateOrder(
                            {
                            "app_id": customer.app_id,
                            "credit_app_id": null,
                            "include_products": true
                        },
                            history,
                            '/applyApplicationSummary'
                        )
                        localStorage.setItem('redirectionPath', window.location.pathname)

                    }}>

                        Yes
                    </button>
                    <button class="btn secondary" onClick={() => setSummaryWarning(false)}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>


            <Modal show={reverseWarning} onHide={() => setReverseWarning(false)} autoFocus={false}>
                <Modal.Body className="fixed-height">
                    You are about to reverse an item that has already been funded. This will result in reversing the transaction details on the next funding date for this dealer. Are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn secondary" onClick={() => {
                        setReverseWarning(false)
                        reverseScheduling({
                            funding_id: customer.funding_id,
                            product_id: reverseItemId
                        }, isDealerScreen)
                        // window.location.reload();
                    }}
                    >
                        Yes
                    </button>
                    <button class="btn secondary" onClick={() => setReverseWarning(false)}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>

            <div className="request-wrapper mb-3 funding-request-wrapper" style={{ padding: 0, position : 'relative' }}>
                <div style={{position : 'absolute', borderLeft : 'solid 5px #009d86', height : '100%', display : customer.funding_adjustments && customer.funding_adjustments.length > 0 ? 'block' : 'none', bottom : 15}}></div>
                <div className={`product-detail ${history.location.pathname == "/dealer/scheduled" && 'dealer-funding-product-detail'} `} style={{flexWrap : 'wrap', padding  :'0 30px', position : history.location.search == '?tab=1' ? "relative" : "", maxWidth : 600, margin : 'auto'}}>
                    {customer && customer.funding_status == "scheduled" ?
                        (<span className="delete" onClick={() => setCancelWarning(true)}>
                            <img src={IconCancel} alt="delete" width="8" />
                        </span>)
                        : (<div></div>)
                    }
                     {customer && customer.funding_status == "scheduled" &&
                        <DateInput value={customer.funding_date ? customer.funding_date : new Date()} onChange={(e) => updateFundDateAction(e)} />

                    }
                    <div style={{display : 'flex', flexWrap : 'wrap', width : '100%', justifyContent : 'space-around', alignItems : 'center'}} className="fundingrequest-product-table">
                        <p>Product Name</p>
                        <p>Tier</p>
                        <p>Financed <br /> Amount</p>

                        <p>Purchase <br /> Price</p>
                    </div><br />
                    <div className="funding-request-product-details-parent-container w-100">
                        {
                            customer && customer.products && customer.products.length == 0 && (
                                
                                <div className="funding-request-product-details-parent-container-inside-div text-center">
                                        <p>No Data</p>
                                    </div>
                            )
                        }
                        {
                            customer && customer.products && customer.products.map((item, index) => {
                                return (
                                    <React.Fragment>
                                        <div className="funding-request-product-details-parent-container-inside-div" style={{marginBottom : index == customer.products.length - 1 ? 0 : 15}}>
                                            <p style={{textAlign : 'left', paddingLeft : 23}}><span className='finance-product-name' style={{color : "#b2d8f7"}}>{item.product}</span> {" "}
                                            {
                                                item.hello_sign &&
                                                item.hello_sign[0] &&
                                                item.hello_sign[0].last_viewed_at == null &&
                                                item.hello_sign[0].status_code == "error_invalid_email" &&
                                                <div style={{ display: 'flex', justifyContent: 'center' }} className="fundingRequestCard_svg">
                                                    <button>
                                                        {IconEnvelopeClosed}
                                                    </button>
                                                    <button>
                                                        {IconAwesomePenFancy}
                                                    </button>
                                                </div>
                                            }
                                            {
                                                item.hello_sign &&
                                                item.hello_sign[0] &&
                                                item.hello_sign[0].last_viewed_at == null &&
                                                item.hello_sign[0].status_code == "awaiting_signature" &&
                                                <div style={{ display: 'flex', justifyContent: 'center' }} className="fundingRequestCard_svg">
                                                    <button>
                                                        {IconEnvelopeClosed}
                                                    </button>
                                                    <button>
                                                        {IconAwesomePenFancy}
                                                    </button>
                                                </div>
                                            }
                                            {
                                                item.hello_sign &&
                                                item.hello_sign[0] &&
                                                item.hello_sign[0].last_viewed_at != null &&
                                                item.hello_sign[0].status_code == "signed" &&
                                                <div style={{ display: 'flex', justifyContent: 'center' }} className="fundingRequestCard_svg">
                                                    <button className="active">
                                                        {IconEnvelopeOpen}
                                                    </button>
                                                    <button className="active">
                                                        {IconAwesomePenFancyRight}
                                                    </button>
                                                </div>
                                            }
                                            {
                                                item.hello_sign &&
                                                item.hello_sign[0] &&
                                                item.hello_sign[0].last_viewed_at != null &&
                                                item.hello_sign[0].status_code == "awaiting_signature" &&
                                                <div style={{ display: 'flex', justifyContent: 'center' }} className="fundingRequestCard_svg">
                                                    <button className="active">
                                                        {IconEnvelopeOpen}
                                                    </button>
                                                    <button>
                                                        {IconAwesomePenFancy}
                                                    </button>
                                                </div>
                                            }
                                            </p>
                                            <p style={{color : "#b2d8f7"}} className="text-center">{item.tier}</p>
                                            <p style={{color : "#fff"}} className="text-center">${item.finance_amount}</p>
                                            <p style={{color : "#b2d8f7"}} className="text-center">${item.purchase_price}</p>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                    {/* <table className="schedule-table table-borderless">
                        <thead>
                            <tr className="title">
                                <th>Product Name</th>
                                <th>Tier</th>
                                <th>Financed<br></br>Amount</th>
                                <th>Purchase<br></br>Price</th>
                            </tr>
                        </thead>
                        <tbody className={`product-info`} style={{ height: 31 }}>
                            {customer && customer.products && customer.products.map((item) => {
                                return (
                                    <tr className="info" 
                                    onClick={() => setSummaryWarning(true)}>
                                        <td>
                                            <span className="finance-product-name"
                                            >{item.product}</span>

                                            {
                                                item.hello_sign &&
                                                item.hello_sign[0] &&
                                                item.hello_sign[0].last_viewed_at == null &&
                                                item.hello_sign[0].status_code == "error_invalid_email" &&
                                                <div style={{ display: 'flex', justifyContent: 'center' }} className="fundingRequestCard_svg">
                                                    <button>
                                                        {IconEnvelopeClosed}
                                                    </button>
                                                    <button>
                                                        {IconAwesomePenFancy}
                                                    </button>
                                                </div>
                                            }
                                            {
                                                item.hello_sign &&
                                                item.hello_sign[0] &&
                                                item.hello_sign[0].last_viewed_at == null &&
                                                item.hello_sign[0].status_code == "awaiting_signature" &&
                                                <div style={{ display: 'flex', justifyContent: 'center' }} className="fundingRequestCard_svg">
                                                    <button>
                                                        {IconEnvelopeClosed}
                                                    </button>
                                                    <button>
                                                        {IconAwesomePenFancy}
                                                    </button>
                                                </div>
                                            }
                                            {
                                                item.hello_sign &&
                                                item.hello_sign[0] &&
                                                item.hello_sign[0].last_viewed_at != null &&
                                                item.hello_sign[0].status_code == "signed" &&
                                                <div style={{ display: 'flex', justifyContent: 'center' }} className="fundingRequestCard_svg">
                                                    <button className="active">
                                                        {IconEnvelopeOpen}
                                                    </button>
                                                    <button className="active">
                                                        {IconAwesomePenFancyRight}
                                                    </button>
                                                </div>
                                            }
                                            {
                                                item.hello_sign &&
                                                item.hello_sign[0] &&
                                                item.hello_sign[0].last_viewed_at != null &&
                                                item.hello_sign[0].status_code == "awaiting_signature" &&
                                                <div style={{ display: 'flex', justifyContent: 'center' }} className="fundingRequestCard_svg">
                                                    <button className="active">
                                                        {IconEnvelopeOpen}
                                                    </button>
                                                    <button>
                                                        {IconAwesomePenFancy}
                                                    </button>
                                                </div>
                                            }
                                        </td>
                                        <td>{item.tier}</td>
                                        <td className="finance" style={{ fontWeight: 600, fontSize: 12, color : "#fff" }}>${item.finance_amount
                                            // .toFixed(2)
                                        }</td>
                                        <td className="finance" style={{ fontWeight: 600, fontSize: 12, color : "#b2d8f7" }}>${item.purchase_price
                                            // .toFixed(2)
                                        }</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table> */}

                   
                    <div style={{ marginTop: 22 }} className="reload-funding-desktop-parent-view">
                        {customer.funding_status == "funded" &&
                            <div>
                                {customer.products.map((item) => {
                                    return (
                                        <>
                                            <div>
                                                {item.product_status != "reversed_funding" &&
                                                    <div className="reload funding-reload-desktop-view" onClick={() => reverseItem(item.id)} style={{position : 'absolute', top : '-10px', transform : 'rotate(180deg)', right: 0}}><img src={IconReload} alt="reload" width="10" style={{transform : 'rotate(180deg'}} /></div>
                                                }
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        }
                    </div>

                </div>
                <div style={{ background: '#395D76', borderTop: '2px solid #3a5c77', borderColor: '#A4C8E5' }}>
                    <div className="amount" style={{maxWidth : 600, margin : 'auto'}}>
                        <div>
                            <span className="fund-total">Totals</span>
                        </div>
                        <div>
                            <span className="fund-details">
                                Financed Amount
                                <div className="fund-pricing">${getotalFAmount(customer.products)}</div>
                            </span>
                        </div>
                        <div>
                            <span className="fund-details">
                                Purchase Price
                                <div className="fund-pricing">${customer.total_purchase_price}</div>
                            </span>
                        </div>
                    </div>

                    <div className="borderedMiddle pre">
                        <table style={{width : '100%', maxWidth : 485, margin : 'auto' }}>
                            <tbody>
                                {customer.funding_adjustments && customer.funding_adjustments.map((adj) => {
                                    return (
                                        <tr style={{ background: "#273947", padding: 5, height: 35 }}>
                                            <td style={{ fontSize: 12 }}>{adj.item_description}</td>
                                            <td style={{ fontSize: 12, textAlign: 'right' }}>{adj.amount
                                                // .toFixed(2)
                                            }</td>
                                            {customer.funding_status !== "declined" && <td style={{ textAlign: 'right' }}>
                                                <button className="plus minus" onClick={() => removeItemAdjustment({ "funding_adjustment_id": adj.id }, isDealerScreen)}> <img src={IconCancel} alt="delete" width="8" /></button>
                                            </td>}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div style={{ background: '#395D76', 
                    // borderTop: '2px solid #3a5c77', 
                    borderColor: '#A4C8E5' }}></div>

                    {customer && customer.funding_status == "scheduled" &&

                        <div className="borderedMiddle" style={{
                            background : '#335169'
                        }}>
                            <div className="item-desc fundingRequestAdjustmentInput" style={{  margin: 'unset', padding: "10px 0" }}>
                                <Input className="fund-input" defaultText='Item Description' value={description} handleChange={(e) => setDescription(e.target.value)} />
                                <Input type='number' defaultText='$' className="amount-input" value={amount} handleChange={(e) => setAmount(e.target.value)} />
                                <button className="plus add_plus" onClick={() => submitAdjustment()}><span className='plus-text'>+</span></button>
                            </div>
                            {error && <p className='text-white text-center'>{error}</p>}
                        </div>
                    }


                    {/* {customer && customer.funding_status == 'funded' && customer.products.map((item, idx) => {

                        return (
                            <div className="borderedMiddle">

                                {item.product_status != 'reversed_funding' && idx == 0 &&
                                    <div className="item-desc" style={{ width: '100%', margin: 'unset', padding: "10px 0" }}>
                                        <Input className="fund-input" defaultText='Item Description' value={description} handleChange={(e) => setDescription(e.target.value)} />
                                        <Input type='number' defaultText='$' className="amount-input" value={amount} handleChange={(e) => setAmount(e.target.value)} />
                                        <button className="plus" onClick={() => submitAdjustment()}>+</button>
                                    </div>
                                }
                                {error && <p>{error}</p>}
                            </div>
                        )
                    })
                    } */}

                    {customer.funding_adjustments && customer.funding_adjustments.length > 0 &&
                        <div className="borderedMiddle pre" style={{ background: "rgb(194 222 238)", position : 'relative' }}>
                            <img src={Unionone} alt="arrow" style={{position : 'absolute', top : '50%', left : 0, transform : 'translate(0, -50%)'}}/>
                            <div style={{position : 'absolute', borderLeft : 'solid 5px #009d86', height : '50%', left : 0}}></div>
                            <table style={{ width : '100%', maxWidth  :485, margin : 'auto' }}>
                                <tbody>
                                    <tr style={{ background: "rgb(194 222 238)", padding: 5, height: 35, color: "#3d5a6b", fontWeight: 600 }}>
                                        <td style={{ fontSize: 14 }}>Total Funding</td>
                                        <td style={{ fontSize: 12, textAlign: 'right' }}>${customer.total_purchase_price}</td>
                                        {/* <td style={{ textAlign: 'right', opacity: 0 }}>test</td> */}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    actionLoading: state.dealer.actionLoading,
});

const mapDispatchToProps = (dispatch) => ({
    itemValueAdjustment: (data, page) => dispatch(itemValueAdjustment(data, page)),
    removeItemAdjustment: (data, page) => dispatch(removeItemAdjustment(data, page)),
    cancelScheduling: (data, page) => dispatch(cancelScheduling(data, page)),
    reverseScheduling: (data, page) => dispatch(reverseScheduling(data, page)),
    updateFundDate: (data, page) => dispatch(updateFundDate(data, page)),
    getCustomerApiInitiateForGenerateOrder: (data, history, path) => dispatch(getCustomerApiInitiateForGenerateOrder(data, history, path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FundingRequestCard);

import React, { useState, useEffect } from "react";
import {
    IconCancel,
    IconArrow,
    IconSearch,
    IconAwesomePaperPlaneSend,
    IconComment,
    TCPLogo,
    IconArrowLeft,
    IconFixIssueAwesomeSendRed
} from "assets/images";
import Header, {
    HeaderLeft,
    HeaderCenter,
    HeaderRight,
} from "../../../../components/Dealer/Header";
import { Form, Modal } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import Loader from "shared/Loader";
import { showSingleRowdata, cancelPreapprovalItem, requestFunding, getPreApprovals, getCustomerProfile } from "../../../../redux/actions/dealer";
import { commentReset, getProductSignatureDetailDirectForAdminReview } from '../../../../redux/actions/admin';
import { getCustomerApiInitiateForGenerateOrder } from '../../../../redux/actions/sales';
import "assets/css/dealer/dealerSales.scss";
import Checkbox from '../../../../components/commons/checkbox';
import { getFormattedDate } from '../../../../components/commons/utility';
import {
    CircleStatusIcon,
    HexagonStatusIcon,
    TriangleStatusIcon,
} from '../../../../components/commons/StatusIcons';



function SalesSection(props) {

    const {
        history,
        helloSig1nData,
        selectedRow,
        showSingleRowdata,
        getPreApprovals,
        getCustomerProfile,
        getProductSignatureDetailDirectForAdminReview,
        sales,
        requestFunding,
        actionLoading,
        getCustomerApiInitiateForGenerateOrder,
        cancelPreapprovalItem
    } = props;

    console.log('selectedRow:', props)

    const IconAwesomePaperPlane = <svg xmlns="http://www.w3.org/2000/svg" width="17.982" height="18.273" viewBox="0 0 17.982 18.273">
        <path d="M8.956 6.775v2.43c.931.211 1.759.557 2.626.8V7.567c-.928-.207-1.763-.553-2.626-.792zm7.969-4.389a10.42 10.42 0 0 1-4.175 1.138c-1.909 0-3.49-1.242-5.9-1.242a6.938 6.938 0 0 0-2.427.428 2 2 0 1 0-3.008.924v13.779a.854.854 0 0 0 .856.856h.571a.854.854 0 0 0 .856-.856v-3.369a9.95 9.95 0 0 1 4.082-.789c1.913 0 3.49 1.242 5.9 1.242a7.457 7.457 0 0 0 4.371-1.46 1.138 1.138 0 0 0 .492-.942V3.421a1.14 1.14 0 0 0-1.619-1.035zm-10.6 9.228a11.242 11.242 0 0 0-2.626.592V9.691a10.209 10.209 0 0 1 2.63-.621zm10.506-4.8a11.383 11.383 0 0 1-2.626.853V10.2a6.633 6.633 0 0 0 2.626-.928v2.516a5.766 5.766 0 0 1-2.626.967V10.2a6.036 6.036 0 0 1-2.626-.2v2.41a20.821 20.821 0 0 0-2.626-.76V9.205a7.932 7.932 0 0 0-2.624-.135v-2.5a12.593 12.593 0 0 0-2.629.748V4.8a10.219 10.219 0 0 1 2.626-.785v2.557a6.07 6.07 0 0 1 2.626.2V4.37a20.315 20.315 0 0 0 2.626.76v2.441a6.8 6.8 0 0 0 2.626.1V5.1a12.626 12.626 0 0 0 2.626-.8z" transform="translate(-.563 .003)" />
    </svg>

    const IconEnvelopeOpen = <svg xmlns="http://www.w3.org/2000/svg" width="17.266" height="17.266" viewBox="0 0 17.266 17.266">
        <path d="M8.633 0L0 4.316v12.95h17.266V4.316zm0 2.439l6.475 3.237v4.058l-6.475 3.237-6.475-3.237V5.676zM4.316 6.5v2.155l4.316 2.158 4.316-2.158V6.5z" />
    </svg>

    const IconAwesomePenFancy = <svg xmlns="http://www.w3.org/2000/svg" width="17.266" height="17.266" viewBox="0 0 17.266 17.266">
        <g>
            <path d="M2.67 9.541a1.079 1.079 0 0 0-.683.683L0 16.187l.158.158 3.132-3.132a1.038 1.038 0 0 1-.053-.263 1.079 1.079 0 1 1 1.079 1.079 1.038 1.038 0 0 1-.263-.053L.921 17.108l.158.158 5.963-1.988a1.079 1.079 0 0 0 .683-.683l1.115-2.834-3.335-3.335zM12.452.955L6.277 7.682l3.3 3.3L16.3 4.807c2.868-2.531-1.336-6.693-3.848-3.852z" />
        </g>
    </svg>

    const IconSend = <svg xmlns="http://www.w3.org/2000/svg" width="18.494" height="18.499" viewBox="0 0 18.494 18.499">
        <path d="M17.2.115L.452 9.774a.868.868 0 0 0 .079 1.561l3.84 1.611L14.75 3.8a.217.217 0 0 1 .311.3l-8.7 10.6v2.908a.867.867 0 0 0 1.535.571l2.294-2.792 4.5 1.886a.869.869 0 0 0 1.192-.657l2.6-15.606A.867.867 0 0 0 17.2.115z" transform="translate(-.001 .002)" />
    </svg>

    const IconEnvelopeClosed = <svg xmlns="http://www.w3.org/2000/svg" width="17.331" height="12.998" viewBox="0 0 17.331 12.998">
        <path d="M0 0v2.166L8.665 6.5l8.665-4.333V0zm0 4.333V13h17.331V4.333L8.665 8.665z" />
    </svg>

    const IconAwesomePenFancyRight = <svg xmlns="http://www.w3.org/2000/svg" width="17.875" height="19.445" viewBox="0 0 17.875 19.445">
        <g>
            <path d="M2.527 9.029a1.021 1.021 0 0 0-.646.646L0 15.318l.15.15L3.114 12.5a.982.982 0 0 1-.05-.249 1.021 1.021 0 1 1 1.021 1.021.982.982 0 0 1-.249-.05L.872 16.19l.149.149 5.643-1.881a1.021 1.021 0 0 0 .646-.646l1.055-2.682L5.21 7.974zM11.784.9L5.94 7.27l3.123 3.123 6.366-5.844c2.71-2.395-1.268-6.334-3.645-3.649z" transform="translate(1.5) rotate(-90 8.188 8.151)" />
            <path stroke="#c8e0ed" stroke-linecap="round" stroke-width="3px" d="M0 0h14.769" transform="translate(1.5) translate(0 17.945)" />
        </g>
    </svg>


    let services = JSON.parse(localStorage.getItem('aditinal_info'))
    let final_services = services && services.length > 0 ? services[0].sales_menu : [];

    const dealer_id = final_services && final_services.length > 0 ? final_services[0].dealer_id : 0
    const [showSearch, setShowSearch] = useState(null);
    const [cancelWarning, setCancelWarning] = useState(null);
    const [search, setSearch] = useState(null);
    const [selectedSalesStatus, setSelectedSalesStatus] = useState(false);
    const [selectedSales, setSelectedSales] = useState([]);
    const [filterCustomer, setFilterCustomer] = useState([]);
    const [summaryWarning, setSummaryWarning] = useState(false);
    const dispatch = useDispatch()

    console.log('filterCustom:', selectedSales)

    useEffect(() => {
        getPreApprovals(true);
        setSelectedSales([]);
        setSelectedSalesStatus(false);
        setCancelWarning(null);

        const intervalId = setInterval(function () {
            if (window.location.pathname === '/dealer/sales') {
                getPreApprovals(false);
            }
        }, 15000);

        return () => {
            clearInterval(intervalId)
        }

    }, []);

    // useEffect(() => {

    //     if (selectedRow && filterCustomer && filterCustomer.length > 0) {

    //         let temp_c = filterCustomer.filter(c => c.app_id == selectedRow)

    //         if (temp_c && temp_c.length > 0 && temp_c[0].products && temp_c[0].products.length > 0) {

    //             let signature_product = temp_c[0].products.filter(p => p.signature_status)

    //             signature_product.map((p_info) => {

    //                 let services = JSON.parse(localStorage.getItem('aditinal_info'))
    //                 let final_services = services && services.length > 0 ? services[0].sales_menu : [];

    //                 let temp = {
    //                     dealer_id: dealer_id,
    //                     app_id: selectedRow,
    //                     product_id: p_info.product_id
    //                 }
    //                 getProductSignatureDetailDirectForAdminReview(temp)
    //             })
    //         }
    //     }
    // }, [selectedRow])


    useEffect(() => {
        setFilterCustomer(sales && sales.data ? sales.data : []);
    }, [sales]);

    useEffect(() => {
        if (search != null && search !== '') {
            let temp = sales && sales.data && sales.data.filter(d => d.customer_name.toLowerCase().includes(search.toLowerCase()))
            setFilterCustomer(temp);
        }
        else {
            setFilterCustomer(sales && sales.data ? sales.data : []);
        }
    }, [search]);

    const handleArrowBack = () => {
        history.push({
            pathname: '/',
            search: '?tab=0'
        })
    }

    const getStatusIcon = (status, type) => {
        if (status === "in_process") {
            return <TriangleStatusIcon
                symbol={type}
                fill={true}
                disabled={false}
            />
        }
        else if (status === "approval") {
            return <CircleStatusIcon
                symbol={type}
                fill={true}
                disabled={false}
            />
        }
        else if (status === "rejection") {
            return <HexagonStatusIcon
                symbol={type}
                fill={true}
                disabled={false}
            />
        }
    }

    const salesAction = (id) => {

        let temp_data = selectedSales ? selectedSales : [];
        const index = temp_data.indexOf(id);

        if (index > -1) {
            temp_data.splice(index, 1);
        } else {
            temp_data.push(id);
        }

        if (temp_data.length > 0) {
            setSelectedSalesStatus(true)
        } else {
            setSelectedSalesStatus(false)
        }

        setSelectedSales(temp_data)
    }

    const requestFundingAction = () => {
        console.log(selectedSales);
        if (selectedSales && selectedSales.length > 0) {
            selectedSales.forEach(s => {
                requestFunding(s)
            });
        }
    }

    const renderPurchasedItems = (items) => {
        return items.map((item) => {
            return (
                <div className="customer-purchase-item" key={`product_${item.id}`}>
                    {item.product}
                </div>
            );
        });
    };

    const generateOrder = (data) => {

        let temp = {
            "customer_id": data.customer_id ? data.customer_id : null,
            "co_customer_id": data.co_customer_id ? data.co_customer_id : null,
            "include_products": true
        }
        localStorage.setItem('redirectionPath', window.location.pathname + window.location.search)
        getCustomerApiInitiateForGenerateOrder(temp, history, '/admin/applyApplicationSummary')
    }

    const goToComments = (app_id, customer_CIF) => {
        console.log('appid',app_id)
        console.log('customercif',customer_CIF)
        console.log('no')
        
        getCustomerProfile(null, app_id, customer_CIF, 'dealer', false).then(() => {
            dispatch(commentReset())
            localStorage.setItem('customerAppId',app_id)
            window.location.href = '/dealer/comments'
          
        })
    }

    return (
        <>
            {actionLoading && <Loader />}

            <div className="dealer" style={{position : 'sticky', top : 0, width : '100%', zIndex : 9}}>
                <Header>
                    <HeaderLeft>
                        <img src={IconArrowLeft} onClick={() => handleArrowBack()} alt="" />
                    </HeaderLeft>
                    <HeaderCenter>
                        <div className="header-main">
                            <img className="main-logo" src={TCPLogo} alt="" />
                        </div>
                    </HeaderCenter>
                    <HeaderRight>
                        <img src={IconSearch} alt="search" onClick={() => setShowSearch(!showSearch)} />
                    </HeaderRight>
                </Header>
            </div>

            {showSearch &&
                <div className="search-header">
                    <form action="javascript:void(0)">
                        <Form.Group>
                            <Form.Control 
                            value={search} 
                            placeholder="Search" 
                            onChange={(e) => setSearch(e.target.value)}></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            }

            <Modal show={cancelWarning ? true : false} onHide={() => setCancelWarning(null)} autoFocus={false}>
                <Modal.Body className="fixed-height">
                    {/* You are about to exit this page and go to the Summary screen for this particular order. Are you sure? */}
                    You are about to cancel the sale for this customer, are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn secondary" onClick={() => {
                        setCancelWarning(null)
                        cancelPreapprovalItem({ app_id: cancelWarning, action: "deleted", })
                    }}>
                        Yes
                    </button>
                    <button class="btn secondary" onClick={() => setCancelWarning(null)}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={summaryWarning} onHide={() => setSummaryWarning(false)} autoFocus={false}>
                <Modal.Body className="fixed-height">
                    You are about to exit this page and go to the Summary screen for this particular order. Are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn secondary" onClick={() => {
                        setSummaryWarning(false)
                        getCustomerApiInitiateForGenerateOrder(
                            {
                                "app_id": selectedRow,
                                "credit_app_id": null,
                                "include_products": true
                            },
                            history,
                            '/applyApplicationSummary'
                        )
                        localStorage.setItem('redirectionPath', window.location.pathname)

                    }}>

                        Yes
                    </button>
                    <button class="btn secondary" onClick={() => setSummaryWarning(false)}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>

            <table style={{ width: '100%', marginBottom: 60 }}>
                {filterCustomer && filterCustomer.map((item) => {
                    return (<>
                    <div style={{background : selectedRow == item.app_id ? "#335169" : "rgb(39, 57, 71)"}}>
                        <tr className={selectedRow == item.app_id ? " dealer-fund-detail active-dealer-sales pr-8" : "dealer-fund-detail  dealer-sales pr-8"} style={{background : 'transparent', maxWidth : 660}}>
                            <td 
                            // style={{ width: 180 }}
                            className='mobile-tab-width'
                            >
                                <div className="d-flex align-items-center">
                                    <Checkbox className="sales-checkbox" disabled={item.app_status == "approval" ? null : true} handleChange={() => salesAction(item.app_id)} positionRelativeTop3 />
                                    <div className="sales-mobile-display">
                                        <div className='fund-name-mobile-tab'>
                                        <h6 className="fund-name" onClick={() => getCustomerProfile(history, item.app_id, item.customer_CIF, 'dealer')} style={{background : selectedRow == item.app_id ? "#009d86" : "", color : selectedRow == item.app_id ? "#fff" : "#b2d8f7", marginBottom : 0}}>{item.customer_name}</h6>
                                        </div>
                                        <h6 className="fund-details fund-details-margin" style={{ color : "#6792b6" }}>
                                            {item.sales_name} <div>{getFormattedDate(item.sales_date)}</div>
                                        </h6>
                                    </div>
                                </div>
                            </td>
                            <td 
                            style={{ width: 100 }}
                            >
                                <div>
                                    <h6 className="fund-program">
                                        {renderPurchasedItems(item.products)}
                                    </h6>
                                </div>
                            </td>
                            <td 
                            style={{ 
                                // width: 30,
                                // display : 'flex'
                                 }} className='tab-display-flex tab-width-30'>
                                {getStatusIcon(item.app_status, 'S')}
                            </td>
                            <td>
                                <div>
                                    <img src={IconArrow} alt="arrow" onClick={() => showSingleRowdata(item.app_id)}
                                        className={selectedRow == item.app_id ? "down-arrow" : ""} style={{ padding: "3.5px 6.2px", background : selectedRow == item.app_id && 'transparent' }} />
                                </div>
                            </td>
                        </tr>
                        </div>
                        <tr className={selectedRow == item.app_id ? "active-dealer-sales" : ""} style={{background : "#273947"}}>
                            {selectedRow == item.app_id && (
                                <div style={{ marginBottom: 20, position : 'relative' }}>
                                    {/* <div className="divide-border" style={{ marginTop: 0, border : "none" }}></div> */}
                                    <div className="generate-order co-data-table tab-oder-padding" style={{position : 'initial', maxWidth: 540}}>
                                        <span className="cancel-block" onClick={() => setCancelWarning(item.app_id)} style={{top : 30}}>
                                            <img src={IconCancel} alt="cancel" className="" />
                                        </span>
                                        <div className='col-6'>
                                            <div className="row mt-2 mb-2">
                                                <h6 className="title" style={{minWidth : 55}}>Co-App: </h6>
                                                <span className="sub-title">{item && item.co_app && item.co_app.co_customer_name ? item.co_app.co_customer_name : 'N/A'}</span>
                                            </div>
                                            <div className="row  mb-2">
                                                <h6 className="title" style={{minWidth : 55}}>Address:</h6>{" "}
                                                {item && item.co_app && item.co_app.city || item && item.co_app && item.co_app.state || item && item.co_app && item.co_app.street ?
                                                    (<span className="sub-title">
                                                        {item && item.co_app && item.co_app.street},{item && item.co_app && item.co_app.city},<br />
                                                        {item && item.co_app && item.co_app.state}
                                                    </span>
                                                    )
                                                    :
                                                    (<span>N/A</span>)
                                                }
                                            </div>
                                            <div className="row  mb-2">
                                                <h6 className="title" style={{minWidth : 55}}>Email:</h6>{" "}
                                                <span className="sub-title">{item && item.co_app && item.co_app.email ? item && item.co_app && item.co_app.email : "N/A"}</span>
                                            </div>
                                            <div className="row mb-2">
                                                <h6 className="title" style={{minWidth : 55}}>Phone:</h6>{" "}
                                                <span className="sub-title">{item && item.co_app && item.co_app.phone_number ? item && item.co_app && item.co_app.phone_number : "N/A"}</span>
                                            </div>
                                        </div>
                                        <div className='col-6' style={{position : 'relative', top : 13}}>
                                            <div className="row">
                                                <div className='col-12'>
                                                        <div className='row mb-2'>
                                                            <div className="col-6">
                                                            <h6 className="title mb-0">Credit App on File:{" "}</h6>
                                                            </div>
                                                            {/* <div className='col-1'></div> */}
                                                            <div className="col-6">
                                                            <h6 className="sub-title">{item.credit_agreement_on_file == false ? 'No' : 'Yes'}</h6>
                                                            </div>
                                                        </div>
                                               
                                               
                                                </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                        {item.products && item.products.map((item) => {
                                                return (
                                                <>
                                                <div className="col-6 mb-2">
                                                    <h6 className="title" style={{minWidth : 2}}>{item.product}: </h6>
                                                    </div>
                                                    {/* <div className="col-1"></div> */}
                                                    <div className="col-6">
                                                    <span className="sub-title">Tier {item.tier}</span>
                                                    </div>
                                                
                                                </>
                                                )
                                            })
                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            

                                        </div>
                                    </div>

                                    <div className="table-entities tab-table-entities"
                                    //  onClick={() => setCancelWarning(item)}
                                    style={{width : '90%', display : 'flex', flexDirection : 'column', maxWidth: 540}}
                                    >
                                        <div className="table-entities-row dealerSaels-tableentities-row tabrow-padding-left" style={{paddingBottom : 0, borderRadius : 4, 
                                            // paddingTop : index > 0 && 0
                                            display : 'block'
                                            }}>
                                        {item.products && item.products.map((item, index) => {

                                            // let hello_sign = helloSig1nData.filter(h => (h.dealer_id == dealer_id && h.app_id == selectedRow && h.product_id == item.product_id))

                                            return (
                                            <>
                                                <div className="d-flex flex-wrap justify-content-between">
                                                <div onClick={() => setSummaryWarning(true)}>
                                                    <span style={{color : "rgb(178, 216, 247)", paddingLeft : 15}} className='tab-padding' >{item.product} <span className='ml-2'>-</span> </span>
                                                    <span className="ml-2" style={{color : "#fff"}}>${item.balance_amount}</span>
                                                </div>
                                                <div>
                                                    {/* {hello_sign && hello_sign.length > 0 && */}
                                                    <div className="d-flex hello-sign-status" style={{ height: 25, justifyContent: 'end', alignItems: 'center' }}>


                                                        {
                                                            item.hello_sign &&
                                                            item.hello_sign.last_viewed_at == null &&
                                                            item.hello_sign.status_code == "error_invalid_email" &&
                                                            <div style={{ display: 'flex' }}>
                                                                <button>
                                                                    {IconEnvelopeClosed}
                                                                </button>
                                                                <button>
                                                                    {IconAwesomePenFancy}
                                                                </button>
                                                            </div>
                                                        }

                                                        {
                                                            item.hello_sign &&
                                                            item.hello_sign.last_viewed_at == null &&
                                                            item.hello_sign.status_code == "awaiting_signature" &&
                                                            <div style={{ display: 'flex' }}>
                                                                <button>
                                                                    {IconEnvelopeClosed}
                                                                </button>
                                                                <button>
                                                                    {IconAwesomePenFancy}
                                                                </button>
                                                            </div>
                                                        }


                                                        {
                                                            item.hello_sign &&
                                                            item.hello_sign.last_viewed_at != null &&
                                                            item.hello_sign.status_code == "signed" &&
                                                            <div style={{ display: 'flex' }}>
                                                                <button className="active">
                                                                    {IconEnvelopeOpen}
                                                                </button>
                                                                <button className="active">
                                                                    {IconAwesomePenFancyRight}
                                                                </button>
                                                            </div>
                                                        }


                                                        {
                                                            item.hello_sign &&
                                                            item.hello_sign.last_viewed_at != null &&
                                                            item.hello_sign.status_code == "awaiting_signature" &&
                                                            <div style={{ display: 'flex' }}>
                                                                <button className="active">
                                                                    {IconEnvelopeOpen}
                                                                </button>
                                                                <button>
                                                                    {IconAwesomePenFancy}
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                    {/* } */}
                                                </div>
                                                </div>
                                           
                                            </>
                                            )
                                        })
                                        }
                                         </div>
                                    </div>

                                    <div className="dealerSales-btn">
                                        <button className="comment d-flex flex-wrap justify-content-center align-items-center clear-btn-sales" onClick={() => goToComments(item.app_id)} style={{ cursor: 'pointer' }}>
                                            <img src={IconComment} alt="comment" />
                                            Comments <span style={{marginLeft : 3}}>
                                                {item.comments_count == 0 ? '' : `(${item.comments_count})`}
                                            </span>
                                        </button>

                                        {item.app_status === 'approval' ?
                                            <button className="req-funding d-flex flex-wrap justify-content-center align-items-center clear-btn-sales" onClick={() => requestFunding(item.app_id)}>
                                                 <div style={{width : 24, height : 24, border : '2px solid #fff', borderRadius : '50%', display : 'flex', flexWrap : 'wrap', justifyContent : 'center', alignItems : 'center', marginRight : 10}}>
                                                <i class="fa fa-usd" aria-hidden="true"></i>
                                                </div>
                                                 Request Funding
                                                
                                            </button>
                                            :
                                            <button className="req-funding d-flex flex-wrap justify-content-center align-items-center next-btn-sales" disabled>
                                                <div style={{width : 25, height : 25, border : '2px solid #fff', borderRadius : '50%', display : 'flex', flexWrap : 'wrap', justifyContent : 'center', alignItems : 'center', marginRight : 10}}>
                                                <i class="fa fa-usd" aria-hidden="true"></i>
                                                </div> 
                                                Request Funding
                                            </button>
                                        }

                                    </div>
                                </div>
                            )}
                        </tr>
                    </>
                    );
                })}
            </table>

            <div className="footer-container p-3">
                <button className="req-funding" disabled={selectedSalesStatus ? null : true} onClick={() => requestFundingAction()} style={{display : 'flex', flexWrap : 'wrap', justifyContent : 'center', alignItems : 'baseline'}}>
                    {/* <img src={IconComment} alt="funding" /> */}
                    <div style={{width : 25, height : 25, border : '2px solid #fff', borderRadius : '50%', display : 'flex', flexWrap : 'wrap', justifyContent : 'center', alignItems : 'center', marginRight : 10}}>
                    <i class="fa fa-usd" aria-hidden="true"></i>
                    </div>
                    Request Funding
                </button>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    actionLoading: state.dealer.actionLoading,
    selectedRow: state.dealer.selectedRow,
    sales: state.dealer.sales,
    helloSig1nData: state.admin.helloSig1nData
});
const mapDispatchToProps = (dispatch) => ({
    getPreApprovals: (action) => dispatch(getPreApprovals(action)),
    showSingleRowdata: (app_id) => dispatch(showSingleRowdata(app_id)),
    cancelPreapprovalItem: (data) => dispatch(cancelPreapprovalItem(data)),
    requestFunding: (app_id) => dispatch(requestFunding(app_id)),
    getCustomerProfile: (history, data, cif, type, action = true) => dispatch(getCustomerProfile(history, data, cif, type, action)),
    getCustomerApiInitiateForGenerateOrder: (data, history, path) => dispatch(getCustomerApiInitiateForGenerateOrder(data, history, path)),
    getProductSignatureDetailDirectForAdminReview: (data) => dispatch(getProductSignatureDetailDirectForAdminReview(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SalesSection);

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getParamsValue } from '../../../../utils/utility';
import { connect, useSelector } from 'react-redux';
import { HomeTabBar } from './components/HomeTabBar';
import Review from './Review/Review';
import Funding from './Funding/Funding';
import { resetDealerToken } from 'redux/actions/admin';



export function PureAdminHome(props) {

    const {
        history, dealers, fundings
    } = props;

    const data = useSelector(state => console.log(state));

    const [activeTab, setActiveTab] = useState(getParamsValue('tab') ? Number(getParamsValue('tab')) : 0);
    const reviewHandlerRef = useRef(() => { });

    const handleClickTab = (tab) => {
        if (tab === 0 || tab === 1 && activeTab === tab) {
            reviewHandlerRef.current.call();
        }
        localStorage.setItem('mainTab', tab)
        setActiveTab(tab);
        if(tab === 0) {
            history.push({
                pathname: '/',
                search: '?tab=0'
            })
        } else {
            history.push({
                pathname: '/',
                search: '?tab=1'
            })
        }
    };

    const handleExpandHandler = (func) => {
        reviewHandlerRef.current = func;
    };

    const getDealerCount = () => {
        let count = 0;
        if(dealers && dealers.data) {
            dealers.data.map((tt) => {
                count += tt.preapproval_data.length + tt.sales_customer_data.length
            })
        }
        return count;
    }

    const getFundingCount = () => {
        let count = 0;
        if(fundings && fundings.data) {
            fundings.data.map((tt) => {
                count += tt.customer_data.length
            })
        }
        return count;
    }

    useEffect(() => {
        props.resetDealerToken()
        sessionStorage.removeItem('impersonatedDealerToken')
    },[])

    return (
        <div className="admin">
            {activeTab === 0 ? (
                <Review {...props} setExpandHandler={handleExpandHandler} />
            ) : (
                <Funding {...props} setExpandHandler={handleExpandHandler} />
            )}

            <HomeTabBar
                activeTab={activeTab}
                badges={[dealers && dealers.data ? getDealerCount() : '0', fundings && fundings.data ? getFundingCount() : '0']}
                onClickTab={handleClickTab}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    actionLoading: state.admin.actionLoading,
    dealers: state.admin.dealers,
    fundings: state.admin.fundings,
});

const mapDispatchToProps = (dispatch) => ({
    resetDealerToken: () => dispatch(resetDealerToken())
});

export default connect(mapStateToProps, mapDispatchToProps)(PureAdminHome);

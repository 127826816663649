import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getParamsValue } from '../../../../utils/utility';
import { connect } from 'react-redux';
import DealerHomeTab from './components/DealerHomeTab';
import DealerSettings from './DealerSettings';
import DealerDetails from './DealerDetails';

export function Home(props) {

    const {
        history, dealers, fundings
    } = props;

    console.log('homeprops',props)

    const [activeTab, setActiveTab] = useState(getParamsValue('tab') ? Number(getParamsValue('tab')) : 0);
    const reviewHandlerRef = useRef(() => { });

    const handleClickTab = (tab) => {
        if (tab === 0 && activeTab === tab) {
            reviewHandlerRef.current.call();
        }
        localStorage.setItem('mainTab', tab)
        setActiveTab(tab);
        if(tab === 0) {
            history.push({
                pathname: '/admin/dealer/settings',
                search: '?tab=0'
            })
        } else {
            history.push({
                pathname: '/admin/dealer/settings',
                search: '?tab=1'
            })
        }
    };

    const handleExpandHandler = (func) => {
        reviewHandlerRef.current = func;
    };


    return (
        <div className="admin">
            {activeTab === 0 ? (
                <DealerDetails {...props} setExpandHandler={handleExpandHandler} />
            ) : (
                <DealerSettings {...props} setExpandHandler={handleExpandHandler} />
            )}

            <DealerHomeTab
                activeTab={activeTab}
                // badges={[dealers && dealers.data ? getDealerCount() : '0', fundings && fundings.data ? getFundingCount() : '0']}
                onClickTab={handleClickTab}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    actionLoading: state.admin.actionLoading,
    dealers: state.admin.dealers,
    fundings: state.admin.fundings,
});

export default connect(mapStateToProps)(Home);

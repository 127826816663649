import React, { Fragment, useState, useEffect } from "react";
import { IconArrowLeft, IconSearchAdmin } from "assets/images";
import { connect, useSelector } from "react-redux";
import { Form } from 'react-bootstrap';
import { getFundedSchedules, removeGlobalItemAdjustment, updateGlobalItemAdjustment } from "../../../../redux/actions/dealer";
import "assets/css/dealer/schedule.scss";
import Header, { HeaderLeft, HeaderCenter, HeaderRight } from "../../../../components/Dealer/Header";
import { CircledImageButton } from '../../../Admin/style';
import Loader from '../../../../shared/Loader';
import FundingRequestCard from '../../../Admin/2.0/Home/Review/components/FundingRequestCard';
import DateSelect from '../../../../components/commons/DateSelect';
import DealerCustomerRow from "screens/Admin/2.0/Home/Review/components/DealerCustomerRow";
import Input from "components/commons/input";
import { IconCancel } from '../../../../assets/images';



function Scheduled(props) {

    const {
        history,
        actionLoading,
        scheduled,
        getFundedSchedules,
        totalScheduledAmount,
        updateGlobalItemAdjustment,
        removeGlobalItemAdjustment
    } = props;
    console.log(props)

    var tDate = new Date();
    tDate.setDate(tDate.getDate() + 1);

    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
    const [search, setSearch] = useState(null);
    const [activeCustomer, setActiveCustomer] = useState(localStorage.getItem('activeCustomer') ? localStorage.getItem('activeCustomer') : null);
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState(null);

    const [dateRange, setDateRange] = useState([new Date(), tDate]);
    const [filterCustomer, setFilterCustomer] = useState([]);
    console.log(filterCustomer)
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        localStorage.setItem('selectedFundedRange', JSON.stringify([startDate, endDate]))
        getFundedSchedules(startDate, endDate);
        setIsSearchBarVisible(false);

        const intervalId = setInterval(function () {
            if (window.location.pathname === '/dealer/scheduled') {
                const temp_date = JSON.parse(localStorage.getItem('selectedFundedRange'));
                getFundedSchedules(temp_date[0], temp_date[1], false);
            }
        }, 120000);

        return () => {
            clearInterval(intervalId)
        }
    }, []);

    useEffect(() => {
        if (search != null && search !== '') {
            let temp = scheduled && scheduled.data && scheduled.data.filter(d => d.customer_name.toLowerCase().includes(search.toLowerCase()))
            console.log(temp)
            setFilterCustomer(temp);
        }
        else {
            setFilterCustomer(scheduled.data)
        }
    }, [search])


    useEffect(() => {
        setFilterCustomer(scheduled.data);
    }, [scheduled]);

    const submitAdjustment = () => {
        if (description != "" && amount != "") {
            let arr = JSON.parse(localStorage.getItem('aditinal_info'))
            let someArr = arr[0].sales_menu
            const someData = someArr.some((item) => item.dealer_id)

        if(someData) {
            let temp = {
                "dealer_id": someArr[0].dealer_id,
                "amount": amount,
                "item_description": description
            };
            console.log("temp", temp);

            setError(null)

            updateGlobalItemAdjustment(temp, 2).then(() => {
                setDescription('');
                setAmount('');
            })

            // itemValueAdjustment(temp, isDealerScreen).then(() => {
            //     setDescription('');
            //     setAmount('')
            //     // window.location.reload();
            // })
        }
           
        }
        else {
            setError('Please enter correct data')
        }
    }


    const handleClickCustomer = (item) => {
        if (item.app_id === activeCustomer) {
            localStorage.setItem('activeCustomer', null)
            setActiveCustomer(null);
        } else {
            localStorage.setItem('activeCustomer', item.app_id)
            setActiveCustomer(item.app_id);
        }
    };

    const handleArrowBack = () => {
        history.push({
            pathname: '/',
            search: '?tab=1'
        })
    }

    const handleClickSearch = () => {
        setIsSearchBarVisible(!isSearchBarVisible);
    };

    const renderCustomerRows = (customers, type) => {

        return customers && customers.map((item) => (
            console.log(item),
            <Fragment key={item.app_id}>
                <DealerCustomerRow
                    history={history}
                    expanded={item.app_id == activeCustomer}
                    customer={item}
                    requestType={type}
                    onClick={() => {
                        handleClickCustomer(item);
                    }}
                />
                {item.app_id == activeCustomer && type === 'funding' &&
                    <FundingRequestCard
                        customer={item}
                        isDealerScreen={2}
                        funding_frequency={scheduled && scheduled.data && scheduled.data[0].funding_freq ? scheduled.data[0].funding_freq : null }
                    />
                }
            </Fragment>
        ));
    };

    const handleDatePickerClose = () => {
        getFundedSchedules(startDate, endDate);
    }

    const forwardClick = () => {

        var sDate = new Date(startDate);
        sDate.setDate(sDate.getDate() + 1);

        var eDate = new Date(endDate);
        eDate.setDate(eDate.getDate() + 1);

        setDateRange([sDate, eDate])
        localStorage.setItem('selectedFundedRange', JSON.stringify([sDate, eDate]))
        getFundedSchedules(sDate, eDate);
    }

    const backwardClick = () => {

        var sDate = new Date(startDate);
        sDate.setDate(sDate.getDate() - 1);

        var eDate = new Date(endDate);
        eDate.setDate(eDate.getDate() - 1);

        setDateRange([sDate, eDate])
        localStorage.setItem('selectedFundedRange', JSON.stringify([sDate, eDate]))
        getFundedSchedules(sDate, eDate);
    }

    return (

        <div className="admin dealer-seperate-list"
        style={{ position: 'relative', minHeight : '100%' }}
        >

            {actionLoading && <Loader />}
        <div style={{position : 'sticky', top : 0, zIndex: 2}}>
            <Header>
                <HeaderLeft>
                    <img src={IconArrowLeft} onClick={() => handleArrowBack()} alt="" />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main header-main-date">
                        <DateSelect
                            handleDateChange={(date) => {
                                setDateRange(date)
                                localStorage.setItem('selectedFundedRange', JSON.stringify(date))
                            }}
                            handleDatePickerClose={() => handleDatePickerClose()}
                            forwardClick={() => forwardClick()}
                            backwardClick={() => backwardClick()}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </div>
                </HeaderCenter>
                <HeaderRight>
                    <CircledImageButton active={isSearchBarVisible}>
                        <img
                            src={IconSearchAdmin}
                            alt=""
                            onClick={handleClickSearch}
                        />
                    </CircledImageButton>
                </HeaderRight>
            </Header>
            </div>

            {isSearchBarVisible && (
                <div className="search-header" style={{position : 'sticky', top: 48, zIndex : 2}}>
                    <form action="javascript:void(0)">
                        <Form.Group>
                            <Form.Control
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            )}


            {/* <div className="main"> */}
                <div className="list"
                style={{ 
                    // minHeight: '100%',
                    height : 'calc(100vh - 48px)',
                     overflow : 'scoll', 
                     paddingBottom : 130
                // height : funded && funded.data && funded.data.length > 6 && 'auto'
                 }}
                >
                    {/* {scheduled && scheduled.data && renderCustomerRows(scheduled.data, 'funding')} */}
                    {

                        filterCustomer && filterCustomer.length > 0 ?
                            renderCustomerRows(filterCustomer, 'funding')
                            :
                            filterCustomer && filterCustomer.length === 0 ? (
                                <div className="text-center"></div>
                            )
                                :

                                renderCustomerRows(scheduled.data, 'funding')

                    }
                    {/* {renderCustomerRows(scheduled.data, 'funding')} */}
                </div>
            {/* </div> */}
            
            <div style={{ background: '#6792b6', borderTop: '2px solid #3a5c77', borderColor: '#A4C8E5', position: 'fixed', width: '100%', bottom: 0 }}>
                <div className="amount" style={{ backgroundColor: '#6792b6', maxWidth : 600,  }}>
                    <div>
                        <span className="fund-total" style={{ color: '#fff' }}>Totals</span>
                    </div>
                    <div>
                        <span className="fund-details" style={{color : "#fff"}}>
                            Financed Amount
                            <div className="fund-pricing" style={{fontWeight : 'normal', marginTop : 5}}>${totalScheduledAmount && totalScheduledAmount.data && totalScheduledAmount.data.scheduled_total_finance_amount}</div>
                        </span>
                    </div>
                    <div>
                        <span className="fund-details" style={{color : "#fff"}}>
                            Purchase Price
                            <div className="fund-pricing" style={{fontWeight : 'normal', marginTop : 5}}>${totalScheduledAmount && totalScheduledAmount.data && totalScheduledAmount.data.scheduled_total_purchase_price}</div>
                        </span>
                    </div>
                </div>

                <div className="borderedMiddle pre" style={{
                    maxHeight : props.totalScheduledAmount && props.totalScheduledAmount.data && props.totalScheduledAmount.data.all_adjustment_items && props.totalScheduledAmount.data.all_adjustment_items.length > 0 ? "100px" : 0,
                    overflow : 'scroll',
                }}>
                {props.totalScheduledAmount && props.totalScheduledAmount.data && props.totalScheduledAmount.data.all_adjustment_items && props.totalScheduledAmount.data.all_adjustment_items.map((adj) => {
                                    return (
                                        <React.Fragment>
                                             <div className="amount" style={{maxWidth : 458, justifyContent : 'space-between'}}>
                    <div>
                        <span className="fund-total" style={{ color: '#fff' }}>{adj.item_description}</span>
                    </div>
                    <div>
                        <span className="fund-details">
                            <div className="fund-pricing">{adj.amount}</div>
                        </span>
                    </div>
                    <div>
                    <button className="plus minus" 
                                                onClick={() => removeGlobalItemAdjustment({ "adjustment_id": adj.id }, 2)}
                                                ><img src={IconCancel} alt="delete" width="8" /></button>
                    </div>
                    </div>
                    </React.Fragment>
                                    )
                                })}
                    </div>


                <div style={{ background: '#395D76', borderTop: '2px solid #2a4051', borderColor: '#2a4051' }}></div>

                <div className="borderedMiddle" style={{background : "#335169"}}>
        <div className="item-desc" style={{maxWidth : 460, justifyContent : 'space-between'}}>
            <Input className="fund-input" defaultText='Item Description' value={description} handleChange={(e) => setDescription(e.target.value)} />
            <Input type='number' defaultText='$' className="amount-input" value={amount} handleChange={(e) => setAmount(e.target.value)} />
            <button className="plus add_plus" onClick={() => submitAdjustment()}><span className='plus-text'>+</span></button>
        </div>
        {error && <p className="text-center text-white">{error}</p>}
    </div>


            </div>

        </div>
    );
}
const mapStateToProps = (state) => ({
    actionLoading: state.dealer.actionLoading,
    scheduled: state.dealer.scheduled,
    totalScheduledAmount: state.dealer.totalScheduledAmount,
    globalItemAdjustmentLoader : state.dealer.globalItemAdjustmentLoader
});

const mapDispatchToProps = (dispatch) => ({
    getFundedSchedules: (startDate, endDate) => dispatch(getFundedSchedules(startDate, endDate)),
    updateGlobalItemAdjustment: (data, page) => dispatch(updateGlobalItemAdjustment(data, page)),
    removeGlobalItemAdjustment: (data, page) => dispatch(removeGlobalItemAdjustment(data, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scheduled);

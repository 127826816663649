import React, { useEffect, useState } from 'react';
import { pushNotification } from 'utils/notification';
import { connect, useSelector } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { dropdownList } from 'shared/constant';
import { getParamsValue } from '../../../utils/utility';
import Header from '../../../components/Sales/Header';
import {
    TCPLogo,
    IconHome,
    IconArrowLeft,
    IconListWhite,
    IconStatusComplete,
    IconStatusSent,
    IconContactAcitve,
    IconSelectCheckBox,
    IconUploadActive,
    IconUpload,
    IconUnSelectCheckBox,
    IconPdf,
    IconCancel,
    statucCompleteEmailProduct,
    IconStatusSentSales,
    IconStatusSend,
    uploadArrowSvg,
} from '../../../assets/images';
import Loader from 'shared/Loader';
import Input from '../../../components/commons/input';
import Dropdown from '../../../components/commons/dropdown';
import Checkbox from '../../../components/commons/checkbox';
import {
    updateCustomer,
    resetCustomerSearchApiInitiate,
    updateSalesSlip,
    uploadDocumentFile,
    submiSalesApplication,
    submiSalesApplicationWithoutApplicant,
    uploadCADocumentFile,
    saveInProgressSalesApplication,
} from '../../../redux/actions/sales';
import { getFlooredFixedTwoDigit } from 'utils/helper';

function Summary(props) {
    const {
        history,
        customer,
        appFillStatus,
        emailValidate,
        actionLoading,
        uploadDocumentFile,
        updateSalesSlip,
        updateCustomer,
        submiSalesApplication,
        submiSalesApplicationWithoutApplicant,
        saveInProgressSalesApplication,
        uploadCADocumentFile,
        isCustomerFound,
        resetCustomerSearchApiInitiate,
    } = props;

    console.log(customer);
    console.log(props);

    const summrayStateData = useSelector((state) => console.log('summarystatedata',state))
    const saveInProgressDataArray = useSelector((state) => state?.sales?.salesDataOfProduct)

    const [activeTab, setActiveTab] = useState(
        getParamsValue('tab') ? getParamsValue('tab') : 'credit_details'
    );
    const [showWarning, setShowWarning] = useState(false);
    const [showWarningForWithoutCustomer, setShowWarningForWithoutCustomer] =
        useState(false);
    const [showWarningWithoutDoc, setShowWarningWithoutDoc] = useState(false);
    const [tempSave, setTempSave] = useState(false);
    const [userRoles, setUserRoles] = useState(null);
    const [manualCustomerData, setManualCustomerData] = useState(null);
    const [displayCreditAggrement, setDisplayCreditAggrement] = useState(true);
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

    // const dealerData = useSelector(state => console.log('dealerstate',state));
    const accessToken = useSelector((state) => state.admin.access.data);
    console.log('accessToken', accessToken);

    

    useEffect(() => {
        setTempSave(false);
    }, [JSON.stringify(customer)]);

    useEffect(() => {
        let userRole = '';
        if (localStorage.getItem('role').indexOf(',') > -1) {
            userRole = localStorage.getItem('role').split(',');
            console.log('userRole', userRole);
            setUserRoles(userRole);
        }
    }, []);

    useEffect(() => {
        let signatureAndProdIdArr =
            props &&
            props.customer &&
            props.customer.product_info &&
            props.customer.product_info.length > 0 &&
            props.customer.product_info.map((item) => {
                console.log(item);
                return item.signature_request || item.product_doc_id.length > 0;
            });
        console.log('signatureAndProdIdArr', signatureAndProdIdArr);
        if (signatureAndProdIdArr && signatureAndProdIdArr.includes(false)) {
            setDisableSubmitBtn(true);
        } else {
            setDisableSubmitBtn(false);
        }
    }, [props]);

    const handleAddCoApp = () => {
        let temp_customer = {
            ...customer,
            co_enabled: true,
        };
        if (customer && customer.isCustomerForGenerateOrder === undefined) {
            updateCustomer(
                history,
                '/applyApplicationBasicDetails',
                temp_customer
            );
        }
    };

    const handleAddApp = () => {
        let temp_customer = {
            ...customer,
        };
        // if (customer && customer.isCustomerForGenerateOrder === undefined) {
        updateCustomer(history, '/applyApplication', temp_customer);
        // }
    };

    const handleSubmit = (evt) => {
        let warning_status = false;
      

        if(localStorage.getItem('salesInProcessSavedId') && localStorage.getItem('salesInProcessSavedId') > 0) {
          
            if(customer.credit_application_status == "completed") {
                setShowWarning(true)
            } else {
                setShowWarningForWithoutCustomer(true)
            }
        } else {
        if (
            customer.credit_agrment &&
            customer.credit_agrment.length === 0 &&
            (customer.credit_agreement_status === undefined ||
                customer.credit_agreement_status === false)
        ) {
            warning_status = true;
        }

        if (customer.product_info && customer.product_info.length > 0) {
            customer.product_info.forEach((p) => {
                if (
                    p &&
                    p.product_doc_id &&
                    p.product_doc_id.length === 0 &&
                    p.signature_request === false
                ) {
                    warning_status = true;
                    return false;
                }
            });
        }

        if (warning_status) {
            setShowWarningWithoutDoc(true);
        } else {
            if (!isCustomerFound && appFillStatus == 'in_app_with_salesslip') {
                setShowWarningForWithoutCustomer(true);
            } else {
                setShowWarning(true);
            }
        }
    }
    };

    const handleSecondSubmit = (evt) => {
        if (!isCustomerFound && appFillStatus == 'in_app_with_salesslip') {
            setShowWarningForWithoutCustomer(true);
        } else {
            setShowWarning(true);
        }
    };

    const closeandTakeToIntitialPage = (evt) => {
        evt.preventDefault();
        resetCustomerSearchApiInitiate(false);

        if (customer && customer.isCustomerForGenerateOrder === undefined) {
            history.replace('/applyHome');
        } else {
            let path = localStorage.getItem('redirectionPath')
                ? localStorage.getItem('redirectionPath')
                : '/dealer/profile';
            history.replace(path);
        }
    };

    const handleHomeScreen = () => {
        resetCustomerSearchApiInitiate(false);

        if (customer && customer.isCustomerForGenerateOrder === undefined) {
            history.replace('/applyCustomerSearch');
        } else {
            let path = localStorage.getItem('redirectionPath')
                ? localStorage.getItem('redirectionPath')
                : '/dealer/profile';
            history.replace(path);
        }
    };

    const handleTabChange = (tab) => {
        console.log('tab', tab);
        setActiveTab(tab);
    };

    const temphandleSalesSlipScreen = (type) => {
        console.log(type);
        history.push({
            pathname: '/createSalesSlip',
            search: '?type=' + type,
        });
    };

    const handleSelectedSalesSlipScreen = (index, type) => {
        updateSalesSlip(customer.product_info[index]);
        history.push({
            pathname: '/createSalesSlip',
            search: '?salesslip=' + index + '&type=' + type,
        });
    };

    // const getDealerProviderServices = () => {
    let services = JSON.parse(localStorage.getItem('aditinal_info'));
    let final_services =
        services && services.length > 0 ? services[0].sales_menu : [];
    let final_services_products = final_services.products
        ? final_services.products
        : final_services;
    console.log('final service', final_services);
    // };

    // const getDealerProviderServices = () => {
    //     let services = JSON.parse(localStorage.getItem('aditinal_info'));
    //     return services && services.length > 0 ? services[0].sales_menu : [];
    // };

    const deleteProduct = (index) => {
        let temp_customer = { ...customer };
        let temp_products = [...customer.product_info];
        temp_products.splice(index, 1);
        if (temp_products.length === 0) {
            temp_customer.credit_agrment = [];
            temp_customer.credit_agrment_url = [];
            temp_customer.credit_agrment_name = [];
            temp_customer.credit_agreement_status = false;
            temp_customer.credit_agreement_saved = false;
        }

        let temp_customer2 = {
            ...temp_customer,
            credit_agreement_saved: false,
            products: temp_products,
            product_info: temp_products,
        };
        updateCustomer(history, '/applyApplicationSummary', temp_customer2);
    };

    const onFileChange = (event, index) => {
        if (event.target.files && event.target.files.length > 0) {
            let temp_file_url = [];
            let temp_file_name = [];

            const files = event.target.files;

            for (let i = 0; i < files.length; i++) {
                temp_file_url.push(URL.createObjectURL(files[i]));
                temp_file_name.push(files[i].name);
            }

            uploadDocumentFile(event.target.files).then((res) => {
                let temp_customer = { ...customer };
                temp_customer.product_info[index].product_doc_id = temp_customer
                    .product_info[index].product_doc_id
                    ? [
                          ...temp_customer.product_info[index].product_doc_id,
                          ...res.data.product_doc_id,
                      ]
                    : res.data.product_doc_id;
                temp_customer.product_info[index].product_doc_url =
                    temp_customer.product_info[index].product_doc_url
                        ? [
                              ...temp_customer.product_info[index]
                                  .product_doc_url,
                              ...temp_file_url,
                          ]
                        : temp_file_url;
                temp_customer.product_info[index].product_doc_name =
                    temp_customer.product_info[index].product_doc_name
                        ? [
                              ...temp_customer.product_info[index]
                                  .product_doc_name,
                              ...temp_file_name,
                          ]
                        : temp_file_name;
                temp_customer.product_info[index].signature_request = false;
                temp_customer.credit_agreement_saved = false;

                updateCustomer(null, null, temp_customer);
            });
        }
    };

    const onCreditApplicationSendStatus = () => {
        let temp_customer = { ...customer };
        if (
            temp_customer.credit_agrment &&
            temp_customer.credit_agrment.length === 0
        ) {
            temp_customer.credit_agreement_status =
                temp_customer.credit_agreement_status === undefined
                    ? true
                    : !temp_customer.credit_agreement_status;
            temp_customer.credit_agreement_saved = false;

            updateCustomer(null, null, temp_customer);
        } else if (temp_customer.credit_agrment === undefined) {
            temp_customer.credit_agreement_status =
                temp_customer.credit_agreement_status === undefined
                    ? true
                    : !temp_customer.credit_agreement_status;
            temp_customer.credit_agreement_saved = false;

            updateCustomer(null, null, temp_customer);
        } else {
            // pushNotification("Please upload any document first", 'warning', 'TOP_RIGHT', 3000);
        }
    };

    const updateSendApplicationCheck = (e, index) => {
        let temp_customer = { ...customer };

        if (
            temp_customer &&
            temp_customer.product_info &&
            temp_customer.product_info[index] &&
            temp_customer.product_info[index].product_doc_id &&
            temp_customer.product_info[index].product_doc_id.length === 0
        ) {
            temp_customer.product_info[index].signature_request =
                temp_customer.product_info[index].signature_request ===
                undefined
                    ? true
                    : !temp_customer.product_info[index].signature_request;
            temp_customer.credit_agreement_saved = false;

            updateCustomer(null, null, temp_customer);
        } else {
            // pushNotification("Please upload any document first", 'warning', 'TOP_RIGHT', 3000);
        }
    };

    const mergeArrays = (arr1, arr2) => {
        arr2.forEach(item2 => {
          const match = arr1.find(item1 => item1.type == item2.type);
          if (match) {
            match.data = item2.data;
          } else {
            arr1.push(item2);
          }
        });
        return arr1;
      }

    const savedPopup = () => {
        setTempSave(true);
        let temp_customer = { ...customer };
        temp_customer.products = customer.product_info;
        temp_customer.sales_application_id = customer.app_id;
        temp_customer.credit_agreement_status =
            customer.credit_agrment && customer.credit_agrment.length > 0
                ? true
                : customer.credit_agreement_status ? customer.credit_agreement_status : customer.credit_aggrement_signature_request ? customer.credit_aggrement_signature_request : false;
        console.log(temp_customer);
        for(let i=0;i<temp_customer.products.length;i++) {
            temp_customer.products[i].balance = temp_customer.products[i].balance || temp_customer.products[i].balance_amount
            temp_customer.products[i].balance_amount = temp_customer.products[i].balance || temp_customer.products[i].balance_amount 
        }
        let arr1 = temp_customer.sales_product || []
        let arr2 = saveInProgressDataArray

        let uniqueArr = mergeArrays(arr1,arr2)
        if(temp_customer?.products?.length == uniqueArr.length) {
            temp_customer.sales_product = uniqueArr
        } else {
            const typesArr1 = temp_customer.products.map(obj => obj.product_type);
            let data = uniqueArr.filter(obj => typesArr1.includes(obj.type));
            temp_customer.sales_product = data
        }
       
        
        //   console.log('mergeArrays',mergeArrays)
        saveInProgressSalesApplication(temp_customer, accessToken);
        // pushNotification('Saved', 'success', 'TOP_RIGHT', 3000);
    };

    const onCraditAggrementFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            let temp_file_url = [];
            let temp_file_name = [];

            const files = event.target.files;

            for (let i = 0; i < files.length; i++) {
                temp_file_url.push(URL.createObjectURL(files[i]));
                temp_file_name.push(files[i].name);
            }

            uploadCADocumentFile(event.target.files).then((res) => {
                let temp_customer = { ...customer };
                temp_customer.credit_agrment = temp_customer.credit_agrment
                    ? [
                          ...temp_customer.credit_agrment,
                          ...res.data.product_doc_id,
                      ]
                    : res.data.product_doc_id;
                temp_customer.credit_agrment_url =
                    temp_customer.credit_agrment_url
                        ? [
                              ...temp_customer.credit_agrment_url,
                              ...temp_file_url,
                          ]
                        : temp_file_url;
                temp_customer.credit_agrment_name =
                    temp_customer.credit_agrment_name
                        ? [
                              ...temp_customer.credit_agrment_name,
                              ...temp_file_name,
                          ]
                        : temp_file_name;
                temp_customer.credit_agreement_status = false;
                temp_customer.credit_agreement_saved = false;

                updateCustomer(null, null, temp_customer);
            });
        }
    };

    const removeProductDoc = (index, pindex) => {
        let temp_customer = { ...customer };
        console.log(temp_customer)
        temp_customer.product_info[index].product_doc_id.splice(pindex, 1);
        temp_customer.product_info[index].product_doc_url.splice(pindex, 1);
        temp_customer.product_info[index].product_doc_name.splice(pindex, 1);
        temp_customer.product_info[index].signature_request = false;
        temp_customer.credit_agreement_saved = false;

        if (temp_customer.product_info[index].product_doc_id.length === 0) {
            temp_customer.product_info[index].signature_request = false;
        }

        updateCustomer(null, null, temp_customer);
    };

    const removeCAProductDoc = (index) => {
        let temp_customer = { ...customer };
        temp_customer.credit_agrment.splice(index, 1);
        temp_customer.credit_agrment_url.splice(index, 1);
        temp_customer.credit_agrment_name.splice(index, 1);
        temp_customer.credit_agreement_saved = false;

        if (temp_customer.credit_agrment.length === 0) {
            temp_customer.credit_agreement_status = false;
        }

        updateCustomer(null, null, temp_customer);
    };

    const removeAllCAProductDoc = () => {
        let temp_customer = { ...customer };
        if (
            temp_customer.credit_agrment &&
            temp_customer.credit_agrment.length > 0
            //  ||
            // temp_customer.credit_agreement_status
        ) {
            temp_customer.credit_agrment = [];
            temp_customer.credit_agrment_url = [];
            temp_customer.credit_agrment_name = [];

            temp_customer.credit_agreement_status = false;
            temp_customer.credit_agreement_saved = false;

            updateCustomer(null, null, temp_customer);
            console.log(temp_customer);
        }
        console.log(temp_customer);
        setDisplayCreditAggrement(false);
    };

    const changeHandler = (e) => {
        let temp_customer = { ...customer };
        temp_customer.main_app[e.target.name] = e.target.value;
        setManualCustomerData(temp_customer);
    };

    const submitProductSalesApplication = (customer, accessToken) => {
        console.log(customer);
        let temp_customer = { ...customer };
        temp_customer.products = customer.product_info;
        temp_customer.sales_application_id = customer.app_id;
        temp_customer.credit_agreement_status =
            customer.credit_agrment && customer.credit_agrment.length > 0
                ? true
                : customer.credit_agreement_status ? customer.credit_agreement_status : customer.credit_aggrement_signature_request ? customer.credit_aggrement_signature_request : false;
        console.log(temp_customer);
        for(let i=0;i<temp_customer.products.length;i++) {
            temp_customer.products[i].balance = temp_customer.products[i].balance || temp_customer.products[i].balance_amount
            temp_customer.products[i].balance_amount = temp_customer.products[i].balance || temp_customer.products[i].balance_amount 
        }
        console.log(temp_customer);
        submiSalesApplication(temp_customer, accessToken);
    };

    const returnSubmitBtnCheck = () => {
        let check =
            !customer.credit_agreement_saved ||
            customer.product_info.filter((cp) => cp.saved == false).length > 0
                ? true
                : false;

        if (
            customer &&
            customer.product_info &&
            customer.product_info.length > 0
        ) {
            if (check) {
                return (
                    <>
                        <button
                            className={`btn tertiary ${(tempSave || (userRoles &&
                                userRoles.includes('admin') &&
                                !accessToken)) ? 'next-btn-sales' : 'clear-btn-sales'}`}
                            type="button"
                            disabled={
                                tempSave ||
                                (userRoles &&
                                    userRoles.includes('admin') &&
                                    !accessToken)
                                    ? true
                                    : false
                            }
                            onClick={() => savedPopup(customer, accessToken)}
                        >
                            Save Progress
                        </button>
                        {console.log(userRoles && userRoles.includes('admin'))}
                        <button
                            className={`btn tertiary ${ ((localStorage.getItem('role') ==
                            'sales,dealer,admin'
                                && !accessToken) || (disableSubmitBtn == true)) ? 'next-btn-sales' : 'clear-btn-sales'
                                   }`}
                            type="submit"
                            disabled={
                                localStorage.getItem('role') ==
                                'sales,dealer,admin'
                                    ? !accessToken
                                        ? true
                                        : disableSubmitBtn
                                    : disableSubmitBtn
                            }
                        >
                            Submit
                        </button>
                    </>
                );
            } else {
                return (
                    <>
                        <button className="btn tertiary clear-btn-sales" type="button" disabled>
                            Save Progress
                        </button>
                        <button className="btn tertiary clear-btn-sales" type="submit" disabled>
                            Submit
                        </button>
                    </>
                );
            }
        } else {
            return '';
        }
    };

    return (
        <div className="sales">
            {actionLoading && <Loader />}

            <Modal
                show={showWarning}
                onHide={() => setShowWarning(false)}
                autoFocus={false}
            >
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body className="fixed-height">
                    If you choose to proceed, your customer will receive an
                    email at the email address you have entered prompting them
                    to press a link that will take them to a secure website
                    where they will be asked to review and sign the selected
                    document.
                </Modal.Body>
                <Modal.Footer>
                    <button
                        class="btn secondary"
                        onClick={() => {
                            setShowWarning(false);
                            submitProductSalesApplication(
                                customer,
                                accessToken
                            );
                        }}
                    >
                        Proceed
                    </button>
                    <button
                        class="btn secondary"
                        onClick={() => setShowWarning(false)}
                    >
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showWarningForWithoutCustomer}
                onHide={() => setShowWarningForWithoutCustomer(false)}
                autoFocus={false}
            >
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body className="fixed-height">
                    You are submitting this order for review without completing
                    a credit application. Would you like to proceed anyway?
                </Modal.Body>
                <Modal.Footer>
                    <button
                        class="btn secondary"
                        onClick={() => {
                            setShowWarningForWithoutCustomer(false);
                            // submiSalesApplicationWithoutApplicant(customer);
                            console.log('customer', customer);
                            console.log(manualCustomerData);
                            let temp_customer = { ...customer };
                            temp_customer.products = customer.product_info;
                            temp_customer.sales_application_id =
                                customer.sales_application_id;
                            console.log(temp_customer);
                            if (manualCustomerData) {
                                submiSalesApplicationWithoutApplicant(
                                    manualCustomerData
                                );
                            } else {
                                submiSalesApplicationWithoutApplicant(
                                    temp_customer
                                );
                            }
                        }}
                    >
                        Yes
                    </button>
                    <button
                        class="btn secondary"
                        onClick={() => setShowWarningForWithoutCustomer(false)}
                    >
                        No
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showWarningWithoutDoc}
                onHide={() => setShowWarningWithoutDoc(false)}
                autoFocus={false}
            >
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body className="fixed-height">
                    You are sending a signature request for one document and not
                    for another document that has also not yet been signed. Are
                    you sure you’d like to proceed?
                </Modal.Body>
                <Modal.Footer>
                    <button
                        class="btn secondary"
                        onClick={(e) => {
                            setShowWarningWithoutDoc(false);
                            handleSecondSubmit(e);
                        }}
                    >
                        Yes
                    </button>
                    <button
                        class="btn secondary"
                        onClick={() => setShowWarningWithoutDoc(false)}
                    >
                        No
                    </button>
                </Modal.Footer>
            </Modal>

            {customer && customer.isCustomerForGenerateOrder === undefined ? (
                <Header isHome={true} history={history}>
                    {activeTab === 'summary_list' ? (
                        appFillStatus === 'in_app_with_salesslip' ? (
                            <img
                                src={IconArrowLeft}
                                alt="home"
                                className="icon-back"
                                onClick={() =>
                                    history.push('/applyApplication')
                                }
                            />
                        ) : (
                            <img
                                src={IconArrowLeft}
                                alt="home"
                                className="icon-back"
                                onClick={() => history.push('/preSummary')}
                            />
                        )
                    ) : (
                        <img
                            src={IconHome}
                            alt="home"
                            className="icon-logo"
                            onClick={() => handleHomeScreen()}
                        />
                    )}
                    <img src={TCPLogo} alt="logo" className="main-logo" />
                </Header>
            ) : (
                <Header isHome={true} history={history}>
                    <img
                        src={IconArrowLeft}
                        alt="home"
                        className="icon-back"
                        onClick={() => handleHomeScreen()}
                    />
                    <img src={TCPLogo} alt="logo" className="main-logo" />
                </Header>
            )}

            <div className="sub-header">
                <button
                    className={`btn ${activeTab === 'profile' ? 'active' : ''}`}
                    onClick={() => handleTabChange('profile')}
                >
                    <img src={IconContactAcitve} alt="" />
                    {activeTab === 'profile' && (
                        <span className="arrow-down"></span>
                    )}
                </button>

                {(appFillStatus === 'in_app' ||
                    appFillStatus === 'send_link' ||
                    appFillStatus === 'in_app_with_salesslip') && (
                    <>
                        {customer.product_info === undefined ||
                        customer.product_info.length === 0 ? (
                            <button
                                style={{ minWidth: 238 }}
                                className={`btn ${
                                    activeTab === 'summary_list' ? 'active' : ''
                                }`}
                                onClick={() => handleTabChange('summary_list')}
                            >
                                <span className='mt-0'>Product/Service</span>
                                {activeTab === 'summary_list' && (
                                    <span className="arrow-down"></span>
                                )}
                            </button>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    minWidth: 238,
                                    overflow: 'auto',
                                }}
                                className="multiple-serviceslider-btn"
                            >
                                {customer.product_info.length > 0 &&
                                    customer.product_info.map((p, index) => {
                                        console.log('p', p);
                                        // let total = p.price +
                                        //     p.tax -
                                        //     (
                                        //         p.add_discount +
                                        //         p.coupon +
                                        //         p.cash_credit +
                                        //         p.check +
                                        //         p.down_payment
                                        //     )
                                        let total = p.unpaid_balance;
                                        return (
                                            <button
                                                className={`btn ${
                                                    activeTab === 'summary_list'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    handleSelectedSalesSlipScreen(
                                                        index,
                                                        p.product_type
                                                    )
                                                }
                                            >
                                                <span>{`${
                                                    p.product_type
                                                } $${getFlooredFixedTwoDigit(
                                                    p.unpaid_balance ||
                                                        p.balance_amount ||
                                                        total,
                                                    2
                                                )}`}</span>
                                            </button>
                                        );
                                    })}
                            </div>
                        )}
                    </>
                )}

                <button
                    className={`btn ${
                        activeTab === 'credit_details' ? 'active' : ''
                    }`}
                    onClick={() => handleTabChange('credit_details')}
                    style={{  marginLeft :  customer?.product_info?.length > 0 ? '2.3px' : 0}}
                >
                    <img src={IconListWhite} alt="" />
                    {activeTab === 'credit_details' && (
                        <span className="arrow-down"></span>
                    )}
                </button>
            </div>

            {activeTab === 'profile' && (
                <>
                    <div
                        className="container summary-container"
                        style={{ marginBottom: 0 }}
                    >
                        <div className="styled-form">
                            <Form.Group className="mb-18">
                                <Input
                                    name="first_name"
                                    type="text"
                                    defaultValue={customer.main_app.first_name}
                                    label="Applicant First Name"
                                    defaultText="Applicant First Name"
                                    handleChange={(e) => changeHandler(e)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-18">
                                <Input
                                    name="last_name"
                                    type="text"
                                    defaultValue={customer.main_app.last_name}
                                    label="Applicant Last Name"
                                    defaultText="Applicant Last Name"
                                    handleChange={(e) => changeHandler(e)}
                                />
                            </Form.Group>

                           

                            <Form.Group className="mb-18">
                                <Input
                                    name="email"
                                    type="email"
                                    regex="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
                                    value={customer.main_app.email}
                                    label="Email"
                                    defaultText="Email"
                                    handleChange={(e) => changeHandler(e)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-18">
                                <Input
                                    name="cell_phone"
                                    type="hidden"
                                    value={customer.main_app.cell_phone}
                                    label="Phone"
                                    className="medium-input"
                                    defaultText="(123) 456-7890"
                                    regex="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                                    mask="(999) 999-9999"
                                    handleChange={(e) => changeHandler(e)}
                                />
                            </Form.Group>

                            {appFillStatus === 'in_app' && (
                                <>
                                    <Form.Group className="mb-18">
                                        <Input
                                            name="date_of_birth"
                                            type="hidden"
                                            value={
                                                customer.main_app.dobY &&
                                                customer.main_app.dobM &&
                                                customer.main_app.dobD &&
                                                customer.main_app.dobY != 'NONE'
                                                    ? `${customer.main_app.dobM}/${customer.main_app.dobD}/${customer.main_app.dobY}`
                                                    : null
                                            }
                                            label="Date of Birth"
                                            className="medium-input"
                                            defaultText="MM / DD / YYYY"
                                            isDate={true}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-18">
                                        <Input
                                            name="driver_license"
                                            type="hidden"
                                            value={
                                                customer.main_app.driver_license
                                            }
                                            label="Driver License Number"
                                            defaultText="#"
                                            mask="**************"
                                            maskChar=" "
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-18">
                                        <Input
                                            name="no_of_dependents"
                                            type="text"
                                            value={
                                                customer.main_app
                                                    .no_of_dependents
                                            }
                                            label="Number of Dependants"
                                            defaultText="#"
                                            regex="\b\d{1,2}\b"
                                            className="small-input"
                                        />
                                    </Form.Group>
                                    {appFillStatus === 'in_app' && (
                                <>
                                    <Form.Group className="mb-18">
                                        <Input
                                            name="street"
                                            type="text"
                                            value={customer.main_app.street}
                                            label="Street"
                                            defaultText="Street"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-18">
                                        <Input
                                            name="city"
                                            type="text"
                                            value={customer.main_app.city}
                                            label="City"
                                            defaultText="City"
                                        />
                                    </Form.Group>
                                    <div className="styled-row">
                                        <Form.Group className="styled-column mb-18">
                                            <Dropdown
                                                name="state"
                                                type="dropdown"
                                                label="State"
                                                defaultText="State"
                                                defaultValue={
                                                    customer.main_app.state
                                                }
                                                options={dropdownList}
                                            />
                                        </Form.Group>
                                        <Form.Group className="styled-column mb-18">
                                            <Input
                                                name="zip"
                                                type="text"
                                                value={customer.main_app.zip}
                                                label="Zip Code"
                                                defaultText="Zip Code"
                                            />
                                        </Form.Group>
                                    </div>
                                </>
                            )}
                                    <Form.Group className="mb-18">
                                        <Checkbox
                                            name="have_co_applicant"
                                            type="checkbox"
                                            label="Add co-applicant"
                                            checked={
                                                customer.co_enabled
                                            }
                                            // afterLeftPosition={true}
                                            coApplicantRadio={true}
                                        />
                                    </Form.Group>

                                    {customer.co_enabled && (
                                        <>
                                            <span className="divider">
                                                <span className="title">
                                                    Co-applicant
                                                </span>
                                            </span>

                                            <Form.Group className="mb-18">
                                                <Input
                                                    name="co_first_name"
                                                    type="text"
                                                    value={
                                                        customer.co_app
                                                            .first_name
                                                    }
                                                    label="Co-applicant First Name"
                                                    defaultText="Co-applicant First Name"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-18">
                                                <Input
                                                    name="co_last_name"
                                                    type="text"
                                                    value={
                                                        customer.co_app
                                                            .last_name
                                                    }
                                                    label="Co-applicant Last Name"
                                                    defaultText="Co-applicant Last Name"
                                                />
                                            </Form.Group>
                                            {/* <Form.Group
                                                className="mb-18"
                                                style={{ marginTop: 35 }}
                                            >
                                                <Checkbox
                                                    name="co_have_co_applicant_same_address"
                                                    type="checkbox"
                                                    label="Same as Applicant Address"
                                                    checked={
                                                        customer.co_app
                                                            .co_have_co_applicant_same_address
                                                            ? true
                                                            : null
                                                    }
                                                />
                                            </Form.Group> */}
                                            <Form.Group className="mb-18">
                                                <Input
                                                    name="co_street"
                                                    type="text"
                                                    label="Street"
                                                    defaultText="Street"
                                                    defaultValue={
                                                        customer.co_app.street
                                                    }
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-18">
                                                <Input
                                                    name="co_city"
                                                    type="text"
                                                    label="City"
                                                    defaultValue={
                                                        customer.co_app.city
                                                    }
                                                    defaultText="City"
                                                />
                                            </Form.Group>
                                            <div className="styled-row">
                                                <Form.Group className="styled-column mb-18">
                                                    <Dropdown
                                                        name="co_state"
                                                        type="dropdown"
                                                        label="State"
                                                        defaultText="State"
                                                        defaultValue={
                                                            customer.co_app
                                                                .state
                                                        }
                                                        options={dropdownList}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="styled-column mb-18">
                                                    <Input
                                                        name="co_zip"
                                                        type="text"
                                                        defaultValue={
                                                            customer.co_app.zip
                                                        }
                                                        label="Zip Code"
                                                        defaultText="Zip Code"
                                                    />
                                                </Form.Group>
                                            </div>
                                            <Form.Group className="mb-18">
                                                <Input
                                                    name="co_email"
                                                    type="email"
                                                    regex="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
                                                    value={
                                                        customer.co_app.email
                                                    }
                                                    label="Email"
                                                    defaultText="Email"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-18">
                                                <Input
                                                    name="co_cell_phone"
                                                    type="hidden"
                                                    value={
                                                        customer.co_app
                                                            .cell_phone
                                                    }
                                                    label="Phone"
                                                    className="medium-input"
                                                    defaultText="(123) 456-7890"
                                                    regex="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                                                    mask="(999) 999-9999"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-18">
                                                <Input
                                                    name="co_date_of_birth"
                                                    type="hidden"
                                                    value={
                                                        customer.co_app.dobY &&
                                                        customer.co_app.dobM &&
                                                        customer.co_app.dobD &&
                                                        customer.co_app.dobY !=
                                                            'NONE'
                                                            ? `${customer.co_app.dobM}/${customer.co_app.dobD}/${customer.co_app.dobY}`
                                                            : null
                                                    }
                                                    label="Date of Birth"
                                                    className="medium-input"
                                                    defaultText="MM / DD / YYYY"
                                                    isDate={true}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-18">
                                                <Input
                                                    name="co_driver_license"
                                                    type="hidden"
                                                    value={
                                                        customer.co_app
                                                            .driver_license
                                                    }
                                                    label="Driver License Number"
                                                    defaultText="#"
                                                    mask="**************"
                                                    maskChar=" "
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-18">
                                                <Input
                                                    name="co_no_of_dependents"
                                                    type="text"
                                                    value={
                                                        customer.co_app
                                                            .no_of_dependents
                                                    }
                                                    label="Number of Dependants"
                                                    defaultText="#"
                                                    regex="\b\d{1,2}\b"
                                                    className="small-input"
                                                />
                                            </Form.Group>
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                        {appFillStatus === 'in_app' && (
                            <div className="styled-form pointer-none">
                                <div className="box center-box">
                                    <label
                                        class="form-label"
                                        style={{
                                            textAlign: 'center',
                                            width: '100%',
                                            padding: 0,
                                        }}
                                    >
                                        Do you own or rent your home?
                                    </label>
                                    <div className="radio-box center">
                                        <Form.Group className="mb-18 radio-filed">
                                            <Input
                                                id="own"
                                                name="own_or_rent"
                                                type="radio"
                                                className="radio-width"
                                                inputClass="regular-radio"
                                                defaultValue="own"
                                                checked={
                                                    customer.main_app
                                                        .own_or_rent
                                                        ? customer.main_app.own_or_rent.toLowerCase() ==
                                                          'own'
                                                            ? true
                                                            : null
                                                        : null
                                                }
                                                // afterLeftPosition={true}
                                            />
                                            <label
                                                for="own"
                                                class="form-label "
                                                id="own-label"
                                            >
                                                Own
                                            </label>
                                        </Form.Group>
                                        <Form.Group className="mb-18 radio-filed">
                                            <Input
                                                id="rent"
                                                name="own_or_rent"
                                                type="radio"
                                                className="radio-width"
                                                inputClass="regular-radio"
                                                defaultValue="rent"
                                                checked={
                                                    customer.main_app
                                                        .own_or_rent
                                                        ? customer.main_app.own_or_rent.toLowerCase() ==
                                                          'rent'
                                                            ? true
                                                            : null
                                                        : null
                                                }
                                                // afterLeftPosition={true}
                                            />
                                            <label
                                                for="rent"
                                                class="form-label "
                                                id="rent-label"
                                            >
                                                Rent
                                            </label>
                                        </Form.Group>
                                    </div>
                                </div>

                                <Form.Group className="mb-18">
                                    <Input
                                        name="years_there_first"
                                        type="text"
                                        defaultValue={
                                            customer.main_app.years_there_first
                                        }
                                        label="How many years did you live there?"
                                        defaultText="0"
                                        required={true}
                                        className="single-line-input"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-18">
                                    <Input
                                        name="monthly_rent_mortgage_payment"
                                        type="text"
                                        defaultText={
                                            customer.main_app
                                                .monthly_rent_mortgage_payment
                                                ? customer.main_app
                                                      .monthly_rent_mortgage_payment
                                                : '0'
                                        }
                                        label={`${
                                            customer.main_app.own_or_rent ===
                                            'own'
                                                ? 'Monthly Mortgage Payment:'
                                                : 'Monthly Rent Payment:'
                                        }`}
                                        isAmount={true}
                                        // defaultText="0"
                                        className="single-line-input width-112"
                                    />
                                </Form.Group>

                                <div
                                    className="box center-box"
                                    style={{ width: 290, marginTop: 22 }}
                                >
                                    <label
                                        class="form-label"
                                        style={{
                                            textAlign: 'center',
                                            width: '100%',
                                            padding: 0,
                                        }}
                                    >
                                        Are you currently employed?
                                    </label>
                                    <div className="radio-box center">
                                        <Form.Group className="mb-18 radio-filed employed-radio-filed">
                                            <Input
                                                id="employed_status"
                                                name="employement_status"
                                                type="radio"
                                                className="radio-width"
                                                inputClass="regular-radio"
                                                defaultValue="employed"
                                                checked={
                                                    customer && customer.main_app && customer.main_app.employement_status && customer.main_app.employement_status.toLowerCase() ==
                                                    'employed'
                                                        ? true
                                                        : null
                                                }
                                            />
                                            <label
                                                for="employed_status"
                                                class="form-label"
                                                id="employed_status-label"
                                            >
                                                Employed
                                            </label>
                                        </Form.Group>
                                        <Form.Group className="mb-18 radio-filed">
                                            <Input
                                                id="not_employed_status"
                                                name="employement_status"
                                                type="radio"
                                                className="radio-width"
                                                inputClass="regular-radio regular-radio2"
                                                defaultValue="not employed"
                                                checked={
                                                    customer && customer.main_app && customer.main_app.employement_status && customer.main_app.employement_status.toLowerCase() ==
                                                    'not employed'
                                                        ? true
                                                        : null
                                                }
                                            />
                                            <label
                                                for="not_employed_status"
                                                class="form-label"
                                                id="not_employed_status-label"
                                            >
                                                Not Employed
                                            </label>
                                        </Form.Group>
                                    </div>
                                </div>

                                {customer && customer.main_app && customer.main_app.employement_status && customer.main_app.employement_status.toLowerCase() ==
                                    'employed' && (
                                    <>
                                        <div className="styled-row">
                                            <Form.Group className="styled-column mb-18">
                                                <Input
                                                    name="present_employer"
                                                    type="text"
                                                    // defaultValue={customer.main_app.present_employer}
                                                    label="Present Employer"
                                                    defaultText={
                                                        customer.main_app
                                                            .present_employer
                                                            ? customer.main_app
                                                                  .present_employer
                                                            : 'Present Employer'
                                                    }
                                                    optionalParams={{
                                                        style: { width: 190 },
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="styled-column mb-18">
                                                <Input
                                                    name="years_there_second"
                                                    type="text"
                                                    // defaultValue={customer.main_app.years_there_second}
                                                    label="Years There"
                                                    defaultText={
                                                        customer.main_app
                                                            .years_there_second
                                                            ? customer.main_app
                                                                  .years_there_second
                                                            : 0
                                                    }
                                                    optionalParams={{
                                                        style: { width: 87 },
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>

                                        <Form.Group className="mb-18">
                                            <Input
                                                name="job_title"
                                                type="text"
                                                // defaultValue={customer.main_app.job_title}
                                                label="Job Title/Position"
                                                defaultText={
                                                    customer.main_app.job_title
                                                        ? customer.main_app
                                                              .job_title
                                                        : 'Job Title/Position'
                                                }
                                            />
                                        </Form.Group>

                                        <div className="styled-row">
                                            <Form.Group className="styled-column mb-18">
                                                <Input
                                                    name="employer_phone"
                                                    type="hidden"
                                                    // defaultValue={customer.main_app.employer_phone}
                                                    label="Employer's Phone No."
                                                    defaultText={
                                                        customer.main_app
                                                            .employer_phone
                                                            ? customer.main_app
                                                                  .employer_phone
                                                            : '(123) 456-7890'
                                                    }
                                                    regex="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                                                    mask="(999) 999-9999"
                                                    optionalParams={{
                                                        style: { width: 146 },
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="styled-column mb-18">
                                                <Input
                                                    name="monthly_income"
                                                    type="text"
                                                    // defaultValue={customer.main_app.monthly_income}
                                                    label="Monthly Income"
                                                    isAmount={true}
                                                    defaultText={
                                                        customer.main_app
                                                            .monthly_income
                                                            ? customer.main_app
                                                                  .monthly_income
                                                            : 0
                                                    }
                                                    optionalParams={{
                                                        style: { width: 118 },
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                    </>
                                )}

                                <div
                                    className="box center-box"
                                    style={{ width: 290, marginTop: 22 }}
                                >
                                    <label
                                        class="form-label"
                                        style={{
                                            textAlign: 'center',
                                            width: '100%',
                                            padding: 0,
                                        }}
                                    >
                                        Do you have any other sources of income?
                                    </label>
                                    {
                                        console.log(customer.main_app,'customer.main_app', customer.main_app.source)
                                    }
                                    <div className="radio-box center">
                                        <Form.Group className="mb-18 radio-filed">
                                            <Input
                                                id="yes"
                                                name="additional_income_status"
                                                type="radio"
                                                className="radio-width"
                                                inputClass="regular-radio"
                                                defaultValue="yes"
                                                checked={
                                                    customer.main_app
                                                        .additional_income_status ===
                                                    'yes' || customer.main_app.source
                                                        ? true
                                                        : null
                                                }
                                                // afterLeftPosition={true}
                                            />
                                            <label
                                                for="yes"
                                                class="form-label "
                                                id="yes-label"
                                            >
                                                Yes
                                            </label>
                                        </Form.Group>
                                        <Form.Group className="mb-18 radio-filed">
                                            <Input
                                                id="no"
                                                name="additional_income_status"
                                                type="radio"
                                                className="radio-width"
                                                inputClass="regular-radio regular-radio2"
                                                defaultValue="no"
                                                checked={
                                                    customer.main_app
                                                        .additional_income_status ===
                                                    'no' || !customer.main_app.source
                                                        ? true
                                                        : null
                                                }
                                            />
                                            <label
                                                for="no"
                                                class="form-label "
                                                id="no-label"
                                            >
                                                No
                                            </label>
                                        </Form.Group>
                                    </div>
                                </div>

                                {customer?.main_app?.additional_income_status ===
                                    'yes' || customer.main_app.source && (
                                    <div className="styled-row">
                                        <Form.Group className="styled-column mb-18">
                                            <Input
                                                name="source"
                                                type="text"
                                                defaultText={
                                                    customer.main_app.source
                                                        ? customer.main_app
                                                              .source
                                                        : 'no'
                                                }
                                                label="Source"
                                                // defaultText="Source"
                                                optionalParams={{
                                                    style: { width: 166 },
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="styled-column mb-18">
                                            <Input
                                                name="additional_income"
                                                type="text"
                                                defaultText={
                                                    customer.main_app
                                                        .additional_income
                                                        ? customer.main_app
                                                              .additional_income
                                                        : '0'
                                                }
                                                label="Monthly Income"
                                                // defaultText="0"
                                                isAmount={true}
                                                optionalParams={{
                                                    style: { width: 118 },
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                )}

                                {customer.co_enabled && (
                                    <>
                                        <span className="divider">
                                            <span className="title">
                                                Co-applicant
                                            </span>
                                        </span>

                                        <div className="box center-box">
                                            <label
                                                class="form-label"
                                                style={{
                                                    textAlign: 'center',
                                                    width: '100%',
                                                    padding: 0,
                                                }}
                                            >
                                                Do you own or rent your home?
                                            </label>
                                            <div className="radio-box center">
                                                <Form.Group className="mb-18 radio-filed">
                                                    <Input
                                                        id="co_own"
                                                        name="co_own_or_rent"
                                                        type="radio"
                                                        className="radio-width"
                                                        inputClass="regular-radio"
                                                        defaultValue="own"
                                                        checked={
                                                            customer.co_app
                                                                .own_or_rent
                                                                ? customer.co_app.own_or_rent.toLowerCase() ==
                                                                  'own'
                                                                    ? true
                                                                    : null
                                                                : null
                                                        }
                                                        // afterLeftPosition={true}
                                                    />
                                                    <label
                                                        for="co_own"
                                                        class="form-label "
                                                        id="co_own-label"
                                                    >
                                                        Own
                                                    </label>
                                                </Form.Group>
                                                <Form.Group className="mb-18 radio-filed">
                                                    <Input
                                                        id="co_rent"
                                                        name="co_own_or_rent"
                                                        type="radio"
                                                        className="radio-width"
                                                        inputClass="regular-radio"
                                                        defaultValue="rent"
                                                        checked={
                                                            customer.co_app
                                                                .own_or_rent
                                                                ? customer.co_app.own_or_rent.toLowerCase() ==
                                                                  'rent'
                                                                    ? true
                                                                    : null
                                                                : null
                                                        }
                                                        // afterLeftPosition={true}
                                                    />
                                                    <label
                                                        for="co_rent"
                                                        class="form-label "
                                                        id="co_rent-label"
                                                    >
                                                        Rent
                                                    </label>
                                                </Form.Group>
                                            </div>
                                        </div>

                                        {/* <Form.Group className="mb-18">
                                            <Checkbox
                                                name="have_co_applicant_with_same_answers"
                                                type="checkbox"
                                                theme="light-label"
                                                label="The answers are the same as the answers<br>given by the applicant"
                                                checked={
                                                    customer.co_app
                                                        .have_co_applicant_with_same_answers
                                                        ? true
                                                        : null
                                                }
                                            />
                                        </Form.Group> */}

                                        <Form.Group className="mb-18">
                                            <Input
                                                name="co_years_there_first"
                                                type="text"
                                                defaultValue={
                                                    customer.co_app
                                                        .years_there_first
                                                }
                                                label="How many years did you live there?"
                                                defaultText="0"
                                                required={true}
                                                className="single-line-input"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-18">
                                            <Input
                                                name="co_monthly_rent_mortgage_payment"
                                                type="text"
                                                defaultText={
                                                    customer.co_app
                                                        .monthly_rent_mortgage_payment
                                                        ? customer.co_app
                                                              .monthly_rent_mortgage_payment
                                                        : '0'
                                                }
                                                label={`${
                                                    customer.co_app
                                                        .own_or_rent === 'own'
                                                        ? 'Monthly Mortgage Payment:'
                                                        : 'Monthly Rent Payment:'
                                                }`}
                                                // defaultText="0"
                                                isAmount={true}
                                                required={true}
                                                className="single-line-input width-112"
                                            />
                                        </Form.Group>

                                        <div
                                            className="box center-box"
                                            style={{
                                                width: 290,
                                                marginTop: 22,
                                            }}
                                        >
                                            <label
                                                class="form-label"
                                                style={{
                                                    textAlign: 'center',
                                                    width: '100%',
                                                    padding: 0,
                                                }}
                                            >
                                                Are you currently employed?
                                            </label>
                                            <div className="radio-box center">
                                                <Form.Group className="mb-18 radio-filed employed-radio-filed">
                                                    <Input
                                                        id="co_employed_status"
                                                        name="co_employed_status"
                                                        type="radio"
                                                        className="radio-width"
                                                        inputClass="regular-radio" 
                                                        // summary-radio-coapp-left3"
                                                        defaultValue="employed"
                                                        checked={
                                                            customer.co_app.employement_status.toLowerCase() ==
                                                            'employed'
                                                                ? true
                                                                : null
                                                        }
                                                    />
                                                    <label
                                                        for="co_employed_status"
                                                        class="form-label"
                                                        id="co_employed_status-label"
                                                    >
                                                        Employed
                                                    </label>
                                                </Form.Group>
                                                <Form.Group className="mb-18 radio-filed">
                                                    <Input
                                                        id="co_not_employed_status"
                                                        name="co_not_employed_status"
                                                        type="radio"
                                                        className="radio-width"
                                                        inputClass="regular-radio regular-radio2 "
                                                        // summary-radio-coapp-left3"
                                                        defaultValue="not employed"
                                                        checked={
                                                            customer.co_app.employement_status.toLowerCase() ==
                                                            'not employed'
                                                                ? true
                                                                : null
                                                        }
                                                        // afterLeftPosition={true}
                                                    />
                                                    <label
                                                        for="co_not_employed_status"
                                                        class="form-label"
                                                        id="co_not_employed_status-label"
                                                    >
                                                        Not Employed
                                                    </label>
                                                </Form.Group>
                                            </div>
                                        </div>

                                        {customer.co_app.employement_status.toLowerCase() ==
                                            'employed' && (
                                            <>
                                                <div className="styled-row">
                                                    <Form.Group className="styled-column mb-18">
                                                        <Input
                                                            name="co_present_employer"
                                                            type="text"
                                                            defaultValue={
                                                                customer.co_app
                                                                    .present_employer
                                                            }
                                                            label="Present Employer"
                                                            defaultText="Present Employer"
                                                            optionalParams={{
                                                                style: {
                                                                    width: 190,
                                                                },
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="styled-column mb-18">
                                                        <Input
                                                            name="co_years_there_second"
                                                            type="text"
                                                            defaultValue={
                                                                customer.co_app
                                                                    .years_there_second
                                                            }
                                                            label="Years There"
                                                            defaultText="0"
                                                            optionalParams={{
                                                                style: {
                                                                    width: 87,
                                                                },
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>

                                                <Form.Group className="mb-18">
                                                    <Input
                                                        name="co_job_title"
                                                        type="text"
                                                        defaultValue={
                                                            customer.co_app
                                                                .job_title
                                                        }
                                                        label="Job Title/Position"
                                                        defaultText="Job Title/Position"
                                                    />
                                                </Form.Group>

                                                <div className="styled-row">
                                                    <Form.Group className="styled-column mb-18">
                                                        <Input
                                                            name="co_employer_phone"
                                                            type="hidden"
                                                            defaultValue={
                                                                customer.co_app
                                                                    .employer_phone
                                                            }
                                                            label="Employer's Phone No."
                                                            defaultText="(123) 456-7890"
                                                            regex="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                                                            mask="(999) 999-9999"
                                                            optionalParams={{
                                                                style: {
                                                                    width: 145,
                                                                },
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="styled-column mb-18">
                                                        <Input
                                                            name="co_monthly_income"
                                                            type="text"
                                                            defaultValue={
                                                                customer.co_app
                                                                    .monthly_income
                                                            }
                                                            label="Monthly Income"
                                                            defaultText="0"
                                                            optionalParams={{
                                                                style: {
                                                                    width: 118,
                                                                },
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </>
                                        )}

                                        <div
                                            className="box center-box"
                                            style={{
                                                width: 290,
                                                marginTop: 22,
                                            }}
                                        >
                                            <label
                                                class="form-label"
                                                style={{
                                                    textAlign: 'center',
                                                    width: '100%',
                                                    padding: 0,
                                                }}
                                            >
                                                Do you have any other sources of
                                                income?
                                            </label>
                                            <div className="radio-box center">
                                                <Form.Group className="mb-18 radio-filed">
                                                    <Input
                                                        id="co_yes"
                                                        name="co_additional_income_status"
                                                        type="radio"
                                                        className="radio-width"
                                                        inputClass="regular-radio"
                                                        defaultValue="yes"
                                                        checked={
                                                            customer.co_app
                                                                .additional_income_status ===
                                                            'yes' || customer.co_app.source
                                                                ? true
                                                                : null
                                                        }
                                                        // afterLeftPosition={true}
                                                    />
                                                    <label
                                                        for="co_yes"
                                                        class="form-label "
                                                        id="co_yes-label"
                                                    >
                                                        Yes
                                                    </label>
                                                </Form.Group>
                                                <Form.Group className="mb-18 radio-filed">
                                                    <Input
                                                        id="co_no"
                                                        name="co_additional_income_status"
                                                        type="radio"
                                                        className="radio-width"
                                                        inputClass="regular-radio regular-radio2"
                                                        defaultValue="no"
                                                        checked={
                                                            customer.co_app
                                                                .additional_income_status ===
                                                            'no' || !customer.co_app.source
                                                                ? true
                                                                : null
                                                        }
                                                    />
                                                    <label
                                                        for="co_no"
                                                        class="form-label "
                                                        id="co_no-label"
                                                    >
                                                        No
                                                    </label>
                                                </Form.Group>
                                            </div>
                                        </div>

                                        {customer.co_app
                                            .additional_income_status ===
                                            'yes' || customer.co_app.source && (
                                            <div className="styled-row">
                                                <Form.Group className="styled-column mb-18">
                                                    <Input
                                                        name="co_source"
                                                        type="text"
                                                        defaultValue={
                                                            customer.co_app
                                                                .source
                                                        }
                                                        label="Source"
                                                        defaultText="Source"
                                                        optionalParams={{
                                                            style: {
                                                                width: 166,
                                                            },
                                                        }}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="styled-column mb-18">
                                                    <Input
                                                        name="co_additional_income"
                                                        type="text"
                                                        defaultValue={
                                                            customer.co_app
                                                                .additional_income
                                                        }
                                                        label="Monthly Income"
                                                        defaultText="0"
                                                        optionalParams={{
                                                            style: {
                                                                width: 118,
                                                            },
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </>
            )}

            {activeTab === 'summary_list' && (
                <form action="javascript:void(0)" noValidate>
                    <div className="container black-box center-aligned" >
                        <div className="styled-form">
                            <div
                                className="box center-box"
                                style={{ width: 290, marginTop: 22 }}
                            >
                                <div className="v-radio-box center">
                                    {
                                        // final_services && final_services.products &&
                                        final_services_products.map(
                                            (ser, index) => {
                                                console.log('ser', ser);
                                                if (
                                                    (customer.product_info ||
                                                        customer.products) ===
                                                        undefined ||
                                                    (
                                                        customer.product_info ||
                                                        customer.products
                                                    ).length === 0 ||
                                                    (
                                                        customer.product_info ||
                                                        customer.products
                                                    ).filter(
                                                        (p) =>
                                                            p.product_type ===
                                                            ser.menuname
                                                    ).length === 0
                                                ) {
                                                    return (
                                                        <Form.Group
                                                            className="mb-18 radio-filed employed-radio-filed"
                                                            onClick={(e) =>
                                                                temphandleSalesSlipScreen(
                                                                    ser.menuname
                                                                )
                                                            }
                                                        >
                                                            <Input
                                                                id="employed_status"
                                                                name="employement_status"
                                                                type="radio"
                                                                className="radio-width"
                                                                inputClass="regular-radio"
                                                                defaultValue="employed"
                                                                optionalParams={{
                                                                    autoFocus:
                                                                        index ===
                                                                        0
                                                                            ? true
                                                                            : false,
                                                                }}
                                                            />
                                                            <label
                                                                for="employed_status"
                                                                class="form-label"
                                                                id="employed_status-label"
                                                            >
                                                                {ser.menuname}
                                                            </label>
                                                        </Form.Group>
                                                    );
                                                }
                                            }
                                        )
                                    }
                                </div>

                                {/* 
                                <div
                                    className="box center-box"
                                    style={{ width: 290, marginTop: 22 }}
                                >
                                    <div className="v-radio-box center">
                                        {getDealerProviderServices().map(
                                            (ser, index) => {
                                                console.log('ser', ser)
                                                if (
                                                    (customer.product_info || customer.products) ===
                                                    undefined ||
                                                    (customer.product_info || customer.products).length ===
                                                    0 ||
                                                    (customer.product_info || customer.products).filter(
                                                        (p) =>
                                                            p.product_type ===
                                                            ser.menuname
                                                    ).length === 0
                                                ) {
                                                    return (
                                                        <Form.Group
                                                            className="mb-18 radio-filed employed-radio-filed"
                                                            onClick={(e) =>
                                                                temphandleSalesSlipScreen(
                                                                    ser.menuname
                                                                )
                                                            }
                                                        >
                                                            <Input
                                                                id="employed_status"
                                                                name="employement_status"
                                                                type="radio"
                                                                className="radio-width"
                                                                inputClass="regular-radio"
                                                                defaultValue="employed"
                                                                optionalParams={{
                                                                    autoFocus:
                                                                        index === 0
                                                                            ? true
                                                                            : false,
                                                                }}
                                                            />
                                                            <label
                                                                for="employed_status"
                                                                class="form-label"
                                                                id="employed_status-label"
                                                            >
                                                                {ser.menuname}
                                                            </label>
                                                        </Form.Group>
                                                    );
                                                }
                                            }
                                        )}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="footer-container">
                        {activeTab === 'summary_list' ? (
                            <button
                                className="btn secondary"
                                type="button"
                                onClick={() => {
                                    // history.push('/preSummary')
                                    history.goBack();
                                }}
                            >
                                Cancel
                            </button>
                        ) : (
                            <button
                                className="btn secondary"
                                type="button"
                                onClick={() => handleHomeScreen()}
                            >
                                Cancel
                            </button>
                        )}
                    </div>
                </form>
            )}

            {activeTab === 'credit_details' && (
                <form
                    action="javascript:void(0)"
                    onSubmit={(e) => (emailValidate ? handleSubmit(e) : '')}
                    noValidate
                >
                    <div
                        className="container black-box"
                        style={{ paddingBottom: 50, paddingRight : 0 }}
                    >
                        <div className="table-div">
                            <table className="summary-row">
                                <tr>
                                    <td>
                                        <span>
                                            <b>Name: </b>{' '}
                                            {customer.main_app.first_name}{' '}
                                            {customer.main_app.last_name}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {customer.co_enabled ? (
                                                `${customer.co_app.first_name} ${customer.co_app.last_name}`
                                            ) : (
                                                <span className="hide">
                                                    text for aligementtext for
                                                    aligemsdffsdf
                                                </span>
                                            )}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span
                                            className={`${
                                                customer.main_app.state
                                                    ? ''
                                                    : 'red-color'
                                            }`}
                                        >
                                            <b>Address: </b>{' '}
                                            {customer.main_app.street}{' '}
                                            {customer.main_app.city}{' '}
                                            {customer.main_app.state}{' '}
                                            {customer.main_app.zip}
                                        </span>
                                    </td>
                                    <td>
                                        {customer.co_enabled ? (
                                            <span>
                                                {customer.co_app.street}{' '}
                                                {customer.co_app.city}{' '}
                                                {customer.co_app.state}{' '}
                                                {customer.co_app.zip}
                                            </span>
                                        ) : appFillStatus === 'in_app' &&
                                          customer &&
                                          customer.isCustomerForGenerateOrder ===
                                              undefined ? (
                                            <button
                                                className="btn secondary clear-btn-sales"
                                                type="button"
                                                onClick={() => handleAddCoApp()}
                                            >
                                                Add Co-App
                                            </button>
                                        ) : (
                                            <button
                                                className="btn secondary next-btn-sales"
                                                type="button"
                                                disabled
                                            >
                                                Add Co-App
                                            </button>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <b>Email: </b>{' '}
                                            {customer.main_app.email}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {customer.co_enabled
                                                ? customer.co_app.email
                                                : ''}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <b>Phone: </b>{' '}
                                            {customer.main_app.cell_phone}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {customer.co_enabled
                                                ? customer.co_app.cell_phone
                                                : ''}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            {!isCustomerFound &&
                                appFillStatus === 'in_app_with_salesslip' && (
                                    <div className="table-div status">
                                        <div className="row other-details summary-row">
                                            <div className="col">
                                                <span className="status">
                                                    Credit application
                                                </span>
                                                {/* {customer &&
                                                customer.isCustomerForGenerateOrder ===
                                                    undefined ? ( */}
                                                <button
                                                    className="btn secondary clear-btn-sales"
                                                    type="button"
                                                    onClick={() =>
                                                        handleAddApp()
                                                    }
                                                    style={{
                                                        marginLeft: 50,
                                                    }}
                                                >
                                                    Initiate
                                                </button>
                                                {/* ) : (
                                                    <button
                                                        className="btn secondary"
                                                        type="button"
                                                        disabled
                                                        style={{
                                                            marginLeft: 50,
                                                        }}
                                                    >
                                                        Initiate
                                                    </button>
                                                )} */}
                                            </div>
                                            <div className="col"></div>
                                        </div>
                                    </div>
                                )}
                        </div>

                        {!actionLoading &&
                            appFillStatus !== 'in_app_with_salesslip' && (
                                <div className="table-div status">
                                    <div className="row other-details summary-row">
                                        <div className="col">
                                            <span className="status">
                                                Credit application
                                            </span>
                                            {/* {(appFillStatus === "in_app") ?
                                            <span className="status-icon status-icon-2">
                                                <img src={IconStatusComplete} alt="" />
                                                completed
                                            </span>
                                            : */}
                                            { customer.credit_application_status == 'sent' || customer.onlink_aggrement_status == 'sent' ? 
                                            <>
                                            <span className="custom-status-icon">
                                             {/* <div className='d-flex flex-wrap justify-content-end'>
                                             <span style={{ marginRight: 5, background : "#009d86", borderRadius : '50%', padding : 5, display : 'flex', flexWrap : 'wrap', justifyContent : 'center', alignItems : 'center' }}> */}
                                                <div style={{float : 'left', background : "#009d86", borderRadius : 12, width : 22, height : 22, margin : '-3px'}} className="d-flex flex-wrap justify-content-center align-items-center"><img src={IconStatusSentSales} style={{position : 'relative', right : 1, }}/></div>
                                             {/* </span>  */}
                                            {customer.credit_application_status || customer.onlink_aggrement_status}
                                             {/* </div>  */}
                                             </span>
                                            </>
                                             : 
                                             <>
                                               {customer.credit_application_status ===
                                            null  ? (
                                                <span className="custom-status-icon"  onClick={() =>
                                                    handleAddApp()
                                                }>
                                                    <div style={{float : 'left', background : "#6793b7", borderRadius : 12, width : 22, height : 22, margin : '-3px'}} className="d-flex flex-wrap justify-content-center align-items-center clear-btn-sales"><img src={IconStatusSentSales} style={{position : 'relative', right : 1, }}/></div>
                                                    {/* <img
                                                        src={IconStatusSentSales}
                                                        alt=""
                                                    /> */}
                                                    initiate
                                                </span>
                                            ) : (
                                                <>{
                                                    customer.credit_application_id ? <span className="status-icon status-icon-2">
                                                    <img
                                                   src={IconStatusComplete}
                                                   alt=""
                                               />
                                               Complete
                                               </span>
                                                 : 
                                                <>
                                                    {
                                                        customer.credit_application_status == 'sent' || customer.onlink_aggrement_status == 'sent' ? 
                                                        <>
                                                        <span className="custom-status-icon">
                                                         {/* <div className='d-flex flex-wrap justify-content-end'>
                                                         <span style={{ marginRight: 5, background : "#009d86", borderRadius : '50%', padding : 5, display : 'flex', flexWrap : 'wrap', justifyContent : 'center', alignItems : 'center' }}> */}
                                                            <div style={{float : 'left', background : "#009d86", borderRadius : 12, width : 22, height : 22, margin : '-3px'}} className="d-flex flex-wrap justify-content-center align-items-center"><img src={IconStatusSentSales} style={{position : 'relative', right : 1, }}/></div>
                                                         {/* </span>  */}
                                                        {customer.credit_application_status || customer.onlink_aggrement_status}
                                                         {/* </div>  */}
                                                         </span>
                                                        </>
                                                        :
                                                        <>
                                                        <span className="status-icon status-icon-2">
                                                         <img
                                                        src={customer.credit_application_status == 'open' ? IconStatusSend : (customer.invite_status == 'COMPLETED' || customer.credit_application_status == 'complete' ?  IconStatusComplete : IconStatusSentSales)}
                                                        alt=""
                                                    />
                                                    {customer.credit_application_status || (customer.invite_status == 'COMPLETED' ? 'Complete' : customer.invite_status == 'SENT' ? 'Sent' : '')}
                                                    </span>
                                                        </>
                                                    }
                                                    </>
}
                                                    </>
                                            )}
                                             </>
                                            }
                                          
                                        </div>
                                        <div
                                            className={`col ${
                                                !customer.co_enabled
                                                    ? 'button-col'
                                                    : null
                                            }`}
                                        >
                                            {customer.co_enabled && (
                                                <>
                                                    <span className="status">
                                                        Credit application
                                                    </span>
                                                    {/* {(appFillStatus === "in_app") ?
                                                    <span className="status-icon status-icon-2">
                                                        <img src={IconStatusComplete} alt="" />
                                                        completed
                                                    </span>
                                                    : */}
                                                      {customer.credit_application_status ===
                                            null  ? (
                                                <span className="custom-status-icon"  onClick={() =>
                                                    handleAddApp()
                                                }>
                                                    <div style={{float : 'left', background : "#6793b7", borderRadius : 12, width : 22, height : 22, margin : '-3px'}} className="d-flex flex-wrap justify-content-center align-items-center clear-btn-sales"><img src={IconStatusSentSales} style={{position : 'relative', right : 1, }}/></div>
                                                    {/* <img
                                                        src={IconStatusSentSales}
                                                        alt=""
                                                    /> */}
                                                    initiate
                                                </span>
                                            ) : (
                                                <>{
                                                    customer.credit_application_id ? <span className="status-icon status-icon-2">
                                                    <img
                                                   src={IconStatusComplete}
                                                   alt=""
                                               />
                                               Complete
                                               </span>
                                                 : 
                                                <>
                                                    {
                                                        customer.credit_application_status == 'sent' || customer.onlink_aggrement_status == 'sent' ? 
                                                        <>
                                                        <span className="custom-status-icon">
                                                         {/* <div className='d-flex flex-wrap justify-content-end'>
                                                         <span style={{ marginRight: 5, background : "#009d86", borderRadius : '50%', padding : 5, display : 'flex', flexWrap : 'wrap', justifyContent : 'center', alignItems : 'center' }}> */}
                                                            <div style={{float : 'left', background : "#009d86", borderRadius : 12, width : 22, height : 22, margin : '-3px'}} className="d-flex flex-wrap justify-content-center align-items-center"><img src={IconStatusSentSales} style={{position : 'relative', right : 1, }}/></div>
                                                         {/* </span>  */}
                                                        {customer.credit_application_status || customer.onlink_aggrement_status}
                                                         {/* </div>  */}
                                                         </span>
                                                        </>
                                                        :
                                                        <>
                                                        <span className="status-icon status-icon-2">
                                                         <img
                                                        src={customer.credit_application_status == 'open' ? IconStatusSend : IconStatusComplete}
                                                        alt=""
                                                    />
                                                    {customer.credit_application_status}
                                                    </span>
                                                        </>
                                                    }
                                                    </>
}
                                                    </>
                                            )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                        {(appFillStatus === 'in_app' ||
                            appFillStatus === 'send_link' ||
                            appFillStatus === 'in_app_with_salesslip') &&
                            customer.product_info &&
                            customer.product_info.length > 0 && (
                                <div className="row order-summary table-div">
                                    <label className="title">
                                        Your Order Includes the following
                                        Documents
                                    </label>

                                    <div
                                        className="flex-box"
                                        style={{
                                            display:
                                                !displayCreditAggrement &&
                                                'none',
                                                position : 'relative'
                                        }}
                                    >
                                        <div className="summary-box sales-summary-box">
                                            <label className="title">
                                                1. Credit Agreement
                                            </label>
                                            <label className="status">
                                                {customer.credit_agreement_on_file
                                                    ? 'sent'
                                                    : 'not sent'}
                                            </label>
                                        </div>
                                        <button
                                            className="close sales-close"
                                            type="button"
                                            onClick={() =>
                                                removeAllCAProductDoc()
                                            }
                                        >
                                              <img src={IconCancel} alt="delete" width="8" />
                                        </button>
                                    </div>

                                    <div
                                        className="document-section"
                                        style={{
                                            display:
                                                !displayCreditAggrement &&
                                                'none',
                                        }}
                                    >
                                        <div
                                            className={`btn secondary clear-btn-sales ${
                                                customer.credit_agrment &&
                                                customer.credit_agrment.length >
                                                    0
                                                    ? 'dark'
                                                    : 'light'
                                            }`}
                                            style={{
                                                padding: 5,
                                                position: 'relative',
                                                display: 'flex',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <img
                                                className="checkbox-selection"
                                                src={
                                                    customer.credit_agrment &&
                                                    customer.credit_agrment
                                                        .length > 0
                                                        ? uploadArrowSvg
                                                        : uploadArrowSvg
                                                }
                                                alt=""
                                                style={{width : 14, marginLeft : 5}}
                                            />
                                            <span 
                                            // style={{cursor : 'pointer',
                                            //  position: 'relative', left : 5
                                            // }}
                                            id="uploadSpan"
                                            >
                                                Upload Signed Documents/Files
                                            </span>
                                            <input
                                                type="file"
                                                multiple
                                                onChange={(e) =>
                                                    onCraditAggrementFileChange(
                                                        e
                                                    )
                                                }
                                                style={{
                                                    opacity: 0,
                                                    width: 113,
                                                    position: 'absolute',
                                                    // left: 5,
                                                    height: 35,
                                                    // top: 2,
                                                    zIndex:3,
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="btn secondary light clear-btn-sales"
                                            style={{
                                                padding: 5,
                                                position: 'relative',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                color: 'white',
                                                background:
                                                    customer.credit_agreement_status
                                                        ? '#3a9e8a'
                                                        : '#6793b7',
                                                opacity:
                                                    customer.credit_agrment &&
                                                    customer.credit_agrment
                                                        .length > 0
                                                        ? 0.5
                                                        : 1,
                                            }}
                                        >
                                            <div className='row'>
                                                <div className="col-3 d-flex flex-wrap justify-content-center align-items-center">
                                                {customer.credit_agreement_status  ? (
                                                <span>
                                                <img
                                                    className="checkbox-selection"
                                                    src={statucCompleteEmailProduct}
                                                    alt=""
                                                    style={{background : "#fff", padding : 2, width : 18, height : 18}}
                                                />
                                                </span>
                                            ) : (
                                                <img
                                                    className="checkbox-selection"
                                                    src={IconUnSelectCheckBox}
                                                    alt=""
                                                    style={{width : 18}}
                                                />
                                            )}
                                                    </div>
                                                    <div className="col-9">
                                                    Email Signature Request
                                                        </div>
                                                </div>
                                          
                                           
                                            <input
                                                type="checkbox"
                                                style={{
                                                    opacity: 0,
                                                    width: 113,
                                                    position: 'absolute',
                                                    left: 5,
                                                    height: 35,
                                                    top: 2,
                                                }}
                                                onChange={() =>
                                                    onCreditApplicationSendStatus()
                                                }
                                            />
                                        </div>
                                    </div>

                                    {customer.credit_agrment &&
                                        customer.credit_agrment.length > 0 &&
                                        customer.credit_agrment.map(
                                            (doc_id, pindex) => {
                                                return (
                                                    <div className="uploaded-document">
                                                        <div className="doc">
                                                            {customer.credit_agrment_name && customer.credit_agrment_name[
                                                                pindex
                                                            ].indexOf(
                                                                '.pdf'
                                                            ) === -1 ? (
                                                                <img
                                                                    src={
                                                                        customer
                                                                            .credit_agrment_url[
                                                                            pindex
                                                                        ]
                                                                    }
                                                                    onClick={() =>
                                                                        window
                                                                            .open(
                                                                                customer
                                                                                    .credit_agrment_url[
                                                                                    pindex
                                                                                ],
                                                                                '_blank'
                                                                            )
                                                                            .focus()
                                                                    }
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        IconPdf
                                                                    }
                                                                    alt=""
                                                                />
                                                            )}
                                                            <span
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() =>
                                                                    removeCAProductDoc(
                                                                        pindex
                                                                    )
                                                                }
                                                                className='sales-remove-doc-container d-flex flex-wrap justify-content-center align-items-center'
                                                            >
                                                                <img src={IconCancel} alt="delete" width="8" />
                                                            </span>
                                                        </div>
                                                        {customer
                                                            .credit_agrment_name[
                                                            pindex
                                                        ] &&
                                                            customer
                                                                .credit_agrment_name[
                                                                pindex
                                                            ]}
                                                    </div>
                                                );
                                            }
                                        )}

                                    <hr />

                                    {customer.product_info &&
                                        customer.product_info.length > 0 &&
                                        customer.product_info.map(
                                            (p, index) => {
                                                console.log('pproduct', p);
                                                // let total = p.price +
                                                //     (isNaN(p.tax) ? 0 : p.tax) -
                                                //     (
                                                //         p.add_discount +
                                                //         p.coupon +
                                                //         p.cash_credit +
                                                //         p.check +
                                                //         p.down_payment
                                                //     )
                                                //     console.log('totoalp',total)
                                                let total = p.unpaid_balance;
                                                return (
                                                    <>
                                                        <div className="flex-box" style={{position : 'relative'}}>
                                                            <div className="summary-box sales-summary-box">
                                                                <label className="title">{`${
                                                                    index + 2
                                                                }. ${
                                                                    p.product_type
                                                                } $${getFlooredFixedTwoDigit(
                                                                    (p.unpaid_balance ||
                                                                        p.balance_amount ||
                                                                        total),
                                                                    2
                                                                )}`}</label>
                                                                <label className="status">
                                                                    {customer.sales_application_id
                                                                        ? 'sent'
                                                                        : 'not sent'}
                                                                </label>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="close sales-close"
                                                                onClick={() =>
                                                                    deleteProduct(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                               <img src={IconCancel} alt="delete" width="8" />
                                                            </button>
                                                        </div>
                                                        <label className="period">
                                                            {p.finance_period}{' '}
                                                            months
                                                        </label>

                                                        <div className="document-section">
                                                            <div
                                                                className={`btn secondary clear-btn-sales ${
                                                                    p.product_doc_id &&
                                                                    p
                                                                        .product_doc_id
                                                                        .length >
                                                                        0
                                                                        ? 'dark'
                                                                        : 'light'
                                                                }`}
                                                                style={{
                                                                    padding: 5,
                                                                    position:
                                                                        'relative',
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <img
                                                                    className="checkbox-selection"
                                                                    src={
                                                                        p.product_doc_id &&
                                                                        p
                                                                            .product_doc_id
                                                                            .length >
                                                                            0
                                                                            ? uploadArrowSvg
                                                                            : uploadArrowSvg
                                                                    }
                                                                    alt=""
                                                                    style={{width : 14, marginLeft : 5}}
                                                                />
                                                                <span 
                                                                // style={{cursor : 'pointer', left : 5, position : 'relative'}}
                                                                >
                                                                    Upload
                                                                    Signed
                                                                    Documents/Files
                                                                </span>
                                                                <input
                                                                    type="file"
                                                                    multiple
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        onFileChange(
                                                                            e,
                                                                            index
                                                                        )
                                                                    }
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 113,
                                                                        position:
                                                                            'absolute',
                                                                        // left: 5,
                                                                        height: 35,
                                                                        // top: 2,
                                                                        cursor:'pointer',
                                                                        zIndex: 3
                                                                    }}
                                                                />
                                                            </div>
                                                            <div
                                                                className="btn secondary light clear-btn-sales"
                                                                style={{
                                                                    padding: 5,
                                                                    position:
                                                                        'relative',
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'space-between',
                                                                    alignItems:
                                                                        'center',
                                                                    color: 'white',
                                                                    background:
                                                                        p.signature_request
                                                                            ? '#3a9e8a'
                                                                            : '#6793b7',
                                                                    opacity:
                                                                        p.product_doc_id &&
                                                                        p
                                                                            .product_doc_id
                                                                            .length >
                                                                            0
                                                                            ? 0.5
                                                                            : 1,
                                                                }}
                                                            >
                                                                <div className='row'>
                                                                    <div className='col-3 d-flex flex-wrao justify-content-center align-items-center'>
                                                                    {p.signature_request ? (
                                                                    <img
                                                                        className="checkbox-selection"
                                                                        src={
                                                                            statucCompleteEmailProduct
                                                                        }
                                                                        alt=""
                                                                        style={{background : "#fff", padding : 2, width : 18, height : 18}}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="checkbox-selection"
                                                                        src={
                                                                            IconUnSelectCheckBox
                                                                        }
                                                                        alt=""
                                                                        style={{width : 18}}
                                                                    />
                                                                )}
                                                                    </div>
                                                                    <div className="col-9">
                                                                    Email Signature
                                                                Request
                                                                    </div>
                                                                </div>
                                                               
                                                               
                                                                <input
                                                                    type="checkbox"
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 113,
                                                                        position:
                                                                            'absolute',
                                                                        left: 5,
                                                                        height: 35,
                                                                        top: 2,
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateSendApplicationCheck(
                                                                            e,
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {p.product_doc_id &&
                                                            p.product_doc_id
                                                                .length > 0 &&
                                                            p.product_doc_id.map(
                                                                (
                                                                    doc_id,
                                                                    pindex
                                                                ) => {
                                                                    return (
                                                                        <div className="uploaded-document">
                                                                            <div className="doc">
                                                                                {p.product_doc_name[
                                                                                    pindex
                                                                                ].indexOf(
                                                                                    '.pdf'
                                                                                ) ===
                                                                                -1 ? (
                                                                                    <img
                                                                                        src={
                                                                                            p
                                                                                                .product_doc_url[
                                                                                                pindex
                                                                                            ]
                                                                                        }
                                                                                        onClick={() =>
                                                                                            window
                                                                                                .open(
                                                                                                    p
                                                                                                        .product_doc_url[
                                                                                                        pindex
                                                                                                    ],
                                                                                                    '_blank'
                                                                                                )
                                                                                                .focus()
                                                                                        }
                                                                                        alt=""
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={
                                                                                            IconPdf
                                                                                        }
                                                                                        alt=""
                                                                                    />
                                                                                )}
                                                                                <span
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        removeProductDoc(
                                                                                            index,
                                                                                            pindex
                                                                                        )
                                                                                    }
                                                                                    className='sales-remove-doc-container d-flex flex-wrap justify-content-center align-items-center'
                                                                                >
                                                                                    <img src={IconCancel} alt="delete" width="8" />
                                                                                </span>
                                                                            </div>
                                                                            {p
                                                                                .product_doc_name[
                                                                                pindex
                                                                            ] &&
                                                                                p
                                                                                    .product_doc_name[
                                                                                    pindex
                                                                                ]}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </>
                                                );
                                            }
                                        )}
                                </div>
                            )}
                    </div>
                    {appFillStatus === 'in_app' ||
                    appFillStatus === 'send_link' ||
                    appFillStatus === 'in_app_with_salesslip' ? (
                        <div className="footer-container">
                            {(customer.product_info == undefined ||
                                (customer.product_info 
                                    &&
                                    customer.product_info.length < 3 
                                    // customer.product_info.length > 0
                                    )) && (
                                <button
                                    className={`btn tertiary ${userRoles &&
                                        userRoles.includes('admin') &&
                                        !accessToken ? 'next-btn-sales' : 'clear-btn-sales'}`}
                                    type="button"
                                    disabled={
                                        userRoles &&
                                        userRoles.includes('admin') &&
                                        !accessToken
                                            ? true
                                            : false
                                    }
                                    onClick={(e) =>
                                        handleTabChange('summary_list')
                                    }
                                >
                                    Add
                                </button>
                            )}
                            {returnSubmitBtnCheck()}
                        </div>
                    ) : (
                        <div className="footer-container">
                            <button
                                className="btn secondary clear-btn-sales"
                                type="button"
                                onClick={(e) => closeandTakeToIntitialPage(e)}
                            >
                                Close
                            </button>
                        </div>
                    )}
                </form>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    appFillStatus: state.sales.appFillStatus,
    customer: state.sales.customer,
    actionLoading: state.sales.actionLoading,
    emailValidate: state.sales.emailValidate,
    isCustomerFound: state.sales.isCustomerFound,
});

const mapDispatchToProps = (dispatch) => ({
    updateCustomer: (history, path, data) =>
        dispatch(updateCustomer(history, path, data)),
    updateSalesSlip: (data) => dispatch(updateSalesSlip(data)),
    resetCustomerSearchApiInitiate: () =>
        dispatch(resetCustomerSearchApiInitiate()),
    uploadDocumentFile: (files) => dispatch(uploadDocumentFile(files)),
    uploadCADocumentFile: (files) => dispatch(uploadCADocumentFile(files)),
    submiSalesApplication: (data, dealerToken) =>
        dispatch(submiSalesApplication(data, dealerToken)),
    saveInProgressSalesApplication: (data, dealerToken) =>
        dispatch(saveInProgressSalesApplication(data, dealerToken)),
    submiSalesApplicationWithoutApplicant: (data) =>
        dispatch(submiSalesApplicationWithoutApplicant(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);

import actions from 'redux-form/lib/actions';
import {
    GET_PREAPPROVALS,
    GET_PREAPPROVALS_SUCCESS,
    GET_PREAPPROVALS_FAILED,
    SET_SELECTED_PREAPPROVAL,
    CHANGE_SELECTED_PREAPPROVAL_STATUS,
    SET_SELECTED_PENDING_APPLICATION,
    CHANGE_SELECTED_PENDING_APPLICATION_STATUS,
    GET_FUNDING_REQUESTS,
    GET_FUNDING_REQUESTS_SUCCESS,
    GET_FUNDING_REQUESTS_FAILED,
    SET_SELECTED_FUNDING_REQUEST,
    CHANGE_SELECTED_FUNDING_REQUEST_REQUEST,
    CHANGE_SELECTED_FUNDING_REQUEST_STATUS,
    CHANGE_SELECTED_FUNDING_REQUEST_FAILED,
    CHANGE_SELECTED_PREAPPROVAL_REQUEST,
    CHANGE_SELECTED_PREAPPROVAL_FAILED,
    GET_DEALERS_REQUESTS,
    GET_DEALERS_REQUESTS_SUCCESS,
    GET_DEALERS_REQUESTS_FAILED,
    SET_DEALER_REQUEST,
    ADD_DEALER_REQUEST,
    ADD_DEALER_REQUEST_SUCCESS,
    ADD_DEALER_REQUEST_FAILED,
    UPDATE_DEALER_REQUEST,
    UPDATE_DEALER_REQUEST_SUCCESS,
    UPDATE_DEALER_REQUEST_FAILED,
    DELETE_DEALER_REQUEST,
    DELETE_DEALER_REQUEST_SUCCESS,
    DELETE_DEALER_REQUEST_FAILED,
    UPDATE_DEALER_DATA_REQUEST_FAILED,
    UPDATE_DEALER_DATA_REQUEST_SUCCESS,
    UPDATE_DEALER_DATA_REQUEST,

    GET_DEALERS_HISTORY_REQUEST,
    GET_DEALERS_HISTORY_SUCCESS,
    GET_DEALERS_HISTORY_FAILED,

    GET_DEALERS_PAPERWORK_REQUEST,
    GET_DEALERS_PAPERWORK_SUCCESS,
    GET_DEALERS_PAPERWORK_FAILED,

    GET_ADMIN_CUSTOMER_PROFILE_REQUEST,
    GET_ADMIN_CUSTOMER_PROFILE_SUCCESS,
    GET_ADMIN_CUSTOMER_PROFILE_FAILED,

    GET_UPDATED_PRODUCT_TIER_SUCCESS,
    GET_UPDATED_PRODUCT_TIER_FAILED,

    GET_UPDATED_DATE_SUCCESS,
    GET_UPDATED_DATE_FAILED,

    GET_PREAPPROVAL_STATUS_REQUEST,
    GET_PREAPPROVAL_STATUS_SUCCESS,
    GET_PREAPPROVAL_STATUS_FAILED,

    CANCEL_SCHEDULE_STATUS_REQUEST,
    CANCEL_SCHEDULE_STATUS_SUCCESS,
    CANCEL_SCHEDULE_STATUS_FAILED,

    REVERSE_SCHEDULE_STATUS_REQUEST,
    REVERSE_SCHEDULE_STATUS_SUCCESS,
    REVERSE_SCHEDULE_STATUS_FAILED,

    UPDATE_SCHEDULE_DATE_REQUEST,
    UPDATE_SCHEDULE_DATE_SUCCESS,
    UPDATE_SCHEDULE_DATE_FAILED,

    SET_EXPANDED_DEALER_SUCCESS,

    SET_SELECTED_DEALER_REQUEST,
    SET_SELECTED_DEALER_SUCCESS,
    SET_SELECTED_DEALER_FAILED,

    PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST,
    PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS,
    PRODUCT_HELLO_SIGN_SIGNATURE_FAILED,

    PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST,
    PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS,
    PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED,

    PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_REQUEST,
    PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_SUCCESS,
    PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_FAILED,

    GET_DEALER_SEARCH_REQUEST,
    GET_DEALER_SEARCH_SUCCESS,
    GET_DEALER_SEARCH_FAILED,

    GET_DEALERS_CUSTOMER_HISTORY_REQUEST,
    GET_DEALERS_CUSTOMER_HISTORY_SUCCESS,
    GET_DEALERS_CUSTOMER_HISTORY_FAILED,

    GET_SENT_COMMENT_REQUEST,
    GET_SENT_COMMENT_SUCCESS,
    GET_SENT_COMMENT_FAILED,

    GET_VIEW_COMMENT_REQUEST,
    GET_VIEW_COMMENT_SUCCESS,
    GET_VIEW_COMMENT_FAILED,


    GET_CREDIT_APP_REQUEST,
    GET_CREDIT_APP_SUCCESS,
    GET_CREDIT_APP_FAILED,


    GET_DEALER_STATUS_REQUEST,
    GET_DEALER_STATUS_SUCCESS,
    GET_DEALER_STATUS_FAILED,

    UPDATE_EARLIEST_DATE_DELIVERY_REQUEST,
    UPDATE_EARLIEST_DATE_DELIVERY_SUCCESS,
    UPDATE_EARLIEST_DATE_DELIVERY_FAILED,

    GET_ACCOUNT_DEALER_REQUEST,
    GET_ACCOUNT_DEALER_SUCCESS,
    GET_ACCOUNT_DEALER_FAILED,

    SEND_DEALER_SETTING_REQUEST,
    SEND_DEALER_SETTING_SUCCESS,
    SEND_DEALER_SETTING_FAILED,

    VIEW_DEALER_SETTING_DATA,
    VIEW_DEALER_SETTING_SUCCESS,
    VIEW_DEALER_SETTING_FAILED,

    GET_DEALER_ACCESS_REQUEST,
    GET_DEALER_ACCESS_SUCCESS,
    GET_DEALER_ACCESS_FAILED,

    RESET_DEALER_TOKEN,
    COMMENT_RESET

} from '../actions/admin';

const INIT_STATE = {
    preapprovals: {
        loading: false,
        data: [],
    },

    loans: {
        loading: false,
        data: [],
    },

    paperwork: {
        loading: false,
        data: [],
    },

    cProfile: {
        loading: false,
        data: null,
    },

    access: {
        loading: false,
        data: null,
    },

    helloSig1nData: [],

    selectedPreapproval: {},
    selectedPendingApplication: {},
    fundings: {
        loading: false,
        data: [],
    },
    selectedFunding: {},
    submitPreapprovalLoading: false,
    setFundingLoading: false,
    dealers: {
        loading: false,
        data: [],
    },
    selectedDealer: {},
    expandedDealers: {},

    dealerCustomers: {
        loading: false,
        data: [],
    },

    dealerHistory: {
        loading: false,
        data: [],
    },

    history: {
        loading: false,
        data: [],
    },

    comment: {
        loading: false,
        data: [],
    },

    credit: {
        loading: false,
        data: [],
    },

    status: {
        loading: false,
        data: [],
    },

    dealerSetting: {
        loading: false,
        data: [],
    },


    dealer: [],



    actionLoading: false,
};


export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case GET_PREAPPROVALS:
            return {
                ...state,
                preapprovals: {
                    loading: true,
                    data: [],
                },
            };
        case GET_PREAPPROVALS_SUCCESS:
            return {
                ...state,
                preapprovals: {
                    loading: false,
                    data: action.payload,
                },
            };
        case GET_PREAPPROVALS_FAILED:
            return {
                ...state,
                preapprovals: {
                    loading: false,
                    data: [],
                },
            };
        case SET_SELECTED_PREAPPROVAL:
            return {
                ...state,
                selectedPreapproval: action.payload,
            };
        case CHANGE_SELECTED_PREAPPROVAL_REQUEST:
            return {
                ...state,
                submitPreapprovalLoading: true,
            };
        case CHANGE_SELECTED_PREAPPROVAL_STATUS:
            return {
                ...state,
                selectedPreapproval: {
                    ...state.selectedPreapproval,
                    status: action.payload,
                },
                submitPreapprovalLoading: false,
            };
        case CHANGE_SELECTED_PREAPPROVAL_FAILED:
            return {
                ...state,
                submitPreapprovalLoading: false,
            };
        case SET_SELECTED_PENDING_APPLICATION:
            return {
                ...state,
                selectedPendingApplication: action.payload,
            };
        case CHANGE_SELECTED_PENDING_APPLICATION_STATUS:
            return {
                ...state,
                selectedPreapproval: {
                    ...state.selectedPendingApplication,
                    status: action.payload,
                },
            };
        case GET_FUNDING_REQUESTS:
            return {
                ...state,
                fundings: {
                    loading: true,
                    data: [],
                },
            };
        case GET_FUNDING_REQUESTS_SUCCESS:
            return {
                ...state,
                fundings: {
                    loading: false,
                    data: action.payload.data,
                },
            };
        case GET_FUNDING_REQUESTS_FAILED:
            return {
                ...state,
                fundings: {
                    loading: false,
                    data: [],
                },
            };
        case SET_SELECTED_FUNDING_REQUEST:
            return {
                ...state,
                selectedFunding: action.payload,
            };
        case CHANGE_SELECTED_FUNDING_REQUEST_REQUEST:
            return {
                ...state,
                setFundingLoading: true,
            };
        case CHANGE_SELECTED_FUNDING_REQUEST_STATUS:
            return {
                ...state,
                selectedFunding: {
                    ...state.selectedFunding,
                    status: action.payload,
                },
                setFundingLoading: false,
            };
        case CHANGE_SELECTED_FUNDING_REQUEST_FAILED:
            return {
                ...state,
                setFundingLoading: false,
            };







        case GET_DEALERS_REQUESTS:
            return {
                ...state,
                dealers: {
                    loading: true,
                    data: [],
                },
            };
        case GET_DEALERS_REQUESTS_SUCCESS:
            return {
                ...state,
                dealers: {
                    loading: false,
                    data: action.payload.data,
                },
            };
        case GET_DEALERS_REQUESTS_FAILED:
            return {
                ...state,
                dealers: {
                    loading: false,
                    data: [],
                },
            };













        case GET_DEALERS_HISTORY_REQUEST:
            return {
                ...state,
                loans: {
                    loading: true,
                    data: [],
                },
            };
        case GET_DEALERS_HISTORY_SUCCESS:
            return {
                ...state,
                loans: {
                    loading: true,
                    data: [],
                },
            };
        case GET_DEALERS_HISTORY_FAILED:
            return {
                ...state,

                loans: {
                    loading: false,
                    data: [],
                },
            };















        case SET_DEALER_REQUEST:
            return {
                ...state,
                selectedDealer: action.payload,
            };
        case UPDATE_DEALER_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case UPDATE_DEALER_REQUEST_SUCCESS:
            return {
                ...state,
                selectedDealer: {},
                actionLoading: false,
            };
        case UPDATE_DEALER_REQUEST_FAILED:
            return {
                ...state,
                actionLoading: false,
            };
        case UPDATE_DEALER_DATA_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case UPDATE_DEALER_DATA_REQUEST_SUCCESS: {
            const index = state.dealers.data.findIndex(
                (d) => d.id === action.payload.id
            );
            state.dealers.data.splice(index, 1, action.payload);
            return {
                ...state,
                actionLoading: false,
            };
        }
        case UPDATE_DEALER_DATA_REQUEST_FAILED:
            return {
                ...state,
                actionLoading: false,
            };
        case ADD_DEALER_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case ADD_DEALER_REQUEST_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case ADD_DEALER_REQUEST_FAILED:
            return {
                ...state,
                actionLoading: false,
            };
        case DELETE_DEALER_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case DELETE_DEALER_REQUEST_SUCCESS:
            const index = state.dealers.data.findIndex(
                (d) => d.id == action.payload
            );
            state.dealers.data.splice(index, 1);

            return {
                ...state,
                actionLoading: false,
            };
        case DELETE_DEALER_REQUEST_FAILED:
            return {
                ...state,
                actionLoading: false,
            };




        case GET_DEALERS_PAPERWORK_REQUEST:
            return {
                ...state,
                paperwork: {
                    loading: true,
                    data: [],
                },
            };
        case GET_DEALERS_PAPERWORK_SUCCESS:
            return {
                ...state,
                paperwork: {
                    loading: false,
                    data: action.payload,
                },
            };
        case GET_DEALERS_PAPERWORK_FAILED:
            return {
                ...state,
                paperwork: {
                    loading: false,
                    data: [],
                },
            };


        case GET_ADMIN_CUSTOMER_PROFILE_REQUEST:
            return {
                ...state,
                actionLoading: true,
                cProfile: {
                    loading: true,
                    data: null,
                },
            };
        case GET_ADMIN_CUSTOMER_PROFILE_SUCCESS:

            // let new_customer = action.payload.data

            // state.cProfile && state.cProfile.data && state.cProfile.data.app_inprogress && state.cProfile.data.app_inprogress[0].data.length > 0 && state.cProfile.data.app_inprogress[0].data.forEach(pp => {

            //     let p_index = new_customer.app_inprogress[0].data.findIndex(p => p.product_id == pp.product_id)

            //     if (p_index !== -1) {
            //         new_customer.app_inprogress[0].data[p_index] = { ...new_customer.app_inprogress[0].data[p_index], hello_sign: state.cProfile.data.app_inprogress[0].data[p_index].hello_sign }
            //     }
            // });

            // let temp_customer3 = {
            //     ...new_customer,
            //     app_inprogress: [{
            //         ...new_customer.app_inprogress[0],
            //         data: new_customer.app_inprogress[0].data
            //     }]
            // }

            return {
                ...state,
                actionLoading: false,
                cProfile: {
                    loading: false,
                    data: action.payload.data,
                },
            };
        case GET_ADMIN_CUSTOMER_PROFILE_FAILED:
            return {
                ...state,
                actionLoading: false,
                cProfile: {
                    loading: false,
                    data: null,
                },
            };




        case PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST:
            return {
                ...state,
                actionLoading: true
            };
        case PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS:

            let customer = state.cProfile.data;

            let p_index = customer.app_inprogress[0].data.findIndex(p => p.product_id == action.payload.product_id)
            customer.app_inprogress[0].data[p_index] = { ...customer.app_inprogress[0].data[p_index], hello_sign: action.payload.hello_sign }

            let temp_customer = {
                ...customer,
                app_inprogress: [{
                    ...customer.app_inprogress[0],
                    data: customer.app_inprogress[0].data
                }]
            }

            return {
                ...state,
                actionLoading: false,
                cProfile: {
                    loading: false,
                    data: temp_customer,
                },
            };
        case PRODUCT_HELLO_SIGN_SIGNATURE_FAILED:
            return {
                ...state,
                actionLoading: false,
            };




        case PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_REQUEST:
            return {
                ...state,
                actionLoading: true
            };
        case PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_SUCCESS:

            let customer2 = state.cProfile.data;

            let p_index2 = customer2.app_inprogress[0].data.findIndex(p => p.product_id == action.payload.product_id)
            customer2.app_inprogress[0].data[p_index2] = { ...customer2.app_inprogress[0].data[p_index2], hello_sign: action.payload.hello_sign }

            let temp_customer2 = {
                ...customer2,
                app_inprogress: [{
                    ...customer2.app_inprogress[0],
                    data: customer2.app_inprogress[0].data
                }]
            }

            return {
                ...state,
                actionLoading: false,
                cProfile: {
                    loading: false,
                    data: temp_customer2,
                },
            };
        case PAPER_WORK_PRODUCT_HELLO_SIGN_SIGNATURE_FAILED:
            return {
                ...state,
                actionLoading: false,
            };




        case GET_UPDATED_PRODUCT_TIER_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case GET_UPDATED_PRODUCT_TIER_FAILED:
            return {
                ...state,
                actionLoading: false,
            };


        case GET_UPDATED_DATE_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case GET_UPDATED_DATE_FAILED:
            return {
                ...state,
                actionLoading: false,
            };


        case GET_PREAPPROVAL_STATUS_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case GET_PREAPPROVAL_STATUS_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case GET_PREAPPROVAL_STATUS_FAILED:
            return {
                ...state,
                actionLoading: false,
            };




        case CANCEL_SCHEDULE_STATUS_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case CANCEL_SCHEDULE_STATUS_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case CANCEL_SCHEDULE_STATUS_FAILED:
            return {
                ...state,
                actionLoading: false,
            };




        case REVERSE_SCHEDULE_STATUS_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case REVERSE_SCHEDULE_STATUS_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case REVERSE_SCHEDULE_STATUS_FAILED:
            return {
                ...state,
                actionLoading: false,
            };




        case UPDATE_SCHEDULE_DATE_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case UPDATE_SCHEDULE_DATE_SUCCESS:
            return {
                ...state,
                actionLoading: false,
            };
        case UPDATE_SCHEDULE_DATE_FAILED:
            return {
                ...state,
                actionLoading: false,
            };





        case SET_EXPANDED_DEALER_SUCCESS:
            return {
                ...state,
                expandedDealers: action.payload,
            };





        case SET_SELECTED_DEALER_REQUEST:
            return {
                ...state,
                dealer: [],
                actionLoading: true,
            };
        case SET_SELECTED_DEALER_SUCCESS:
            return {
                ...state,
                dealer: action.payload.data,
                actionLoading: false,
            };
        case SET_SELECTED_DEALER_FAILED:
            return {
                ...state,
                dealer: [],
                actionLoading: false,
            };




        case GET_DEALER_SEARCH_REQUEST:
            return {
                ...state,
                dealerCustomers: {
                    loading: true,
                    data: [],
                }
            };
        case GET_DEALER_SEARCH_SUCCESS:

            // let temp_data = action.payload ? action.payload.filter(c => c.sales_app_id !== null) : null
            console.log(action.payload)
            return {
                ...state,
                dealerCustomers: {
                    loading: false,
                    data: action.payload,
                }
            };
        case GET_DEALER_SEARCH_FAILED:
            return {
                ...state,
                loading: false,
            };




        case GET_DEALERS_CUSTOMER_HISTORY_REQUEST:
            return {
                ...state,
                dealerHistory: {
                    loading: true,
                }
            };
        case GET_DEALERS_CUSTOMER_HISTORY_SUCCESS:
            return {
                ...state,
                dealerHistory: {
                    loading: false,
                    data: action.payload.data,
                }
            };
        case GET_DEALERS_CUSTOMER_HISTORY_FAILED:
            return {
                ...state,
                dealerHistory: {
                    loading: false,
                }
            };




        case GET_SENT_COMMENT_REQUEST:
            return {
                ...state,
                comment: {
                    loading: action.payload.loading,
                    data: state.comment.data,
                }
            };
        case GET_SENT_COMMENT_SUCCESS:
            return {
                ...state,
                comment: {
                    loading: false,
                    data: state.comment.data,
                }
            };
        case GET_SENT_COMMENT_FAILED:
            return {
                ...state,
                comment: {
                    loading: false,
                    data: state.comment.data,
                }
            };




        case GET_VIEW_COMMENT_REQUEST:
                console.log('action',action)
            return {
                ...state,
                comment: {
                    loading: action.payload.loading,
                    data: state.comment.data,
                }
            };
        case GET_VIEW_COMMENT_SUCCESS:
            return {
                ...state,
                comment: {
                    loading: false,
                    data: action.payload.data.messages,
                }
            };
        case GET_VIEW_COMMENT_FAILED:
            return {
                ...state,
                comment: {
                    loading: false,
                    data: state.comment.data,
                }
            };

        case COMMENT_RESET: 
        return {
            ...state,
            comment : {
                data : [],
                loading : true
            }
        }    


        case PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_REQUEST:
            return {
                ...state,
                actionLoading: true,
            };
        case PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_SUCCESS:

            // let temp_reviews = state.dealers.data;

            // let d_index = temp_reviews.findIndex(d => d.id == action.payload.dealer_id)

            // let c_index = temp_reviews[d_index].sales_customer_data && temp_reviews[d_index].sales_customer_data.findIndex(c => c.app_id == action.payload.app_id)

            // let p_index4 = temp_reviews[d_index].sales_customer_data[c_index].products.findIndex(p => p.id == action.payload.product_id)

            // temp_reviews[d_index].sales_customer_data[c_index].products[p_index4] = { ...temp_reviews[d_index].sales_customer_data[c_index].products[p_index4], hello_sign: action.payload.hello_sign }

            // return {
            //     ...state,
            //     actionLoading: false,
            //     dealers: {
            //         loading: false,
            //         data: temp_reviews,
            //     },
            // };


            let temp_helloSig1nData = state.helloSig1nData && state.helloSig1nData.length > 0 ? state.helloSig1nData : [];

            let d_index = temp_helloSig1nData.findIndex(h => (h.dealer_id == action.payload.dealer_id && h.app_id == action.payload.app_id && h.product_id == action.payload.product_id))

            if (d_index != -1) {
                temp_helloSig1nData[d_index] = action.payload
            }
            else {
                temp_helloSig1nData = [...temp_helloSig1nData, action.payload]
            }

            return {
                ...state,
                actionLoading: false,
                helloSig1nData: temp_helloSig1nData
            };

        case PRODUCT_HELLO_SIGN_SIGNATURE_FOR_ADMIN_REVIEW_FAILED:
            return {
                ...state,
                actionLoading: false,
            };





        case GET_CREDIT_APP_REQUEST:
            return {
                ...state,
                credit: {
                    loading: true,
                    data: [],
                }
            };
        case GET_CREDIT_APP_SUCCESS:
            return {
                ...state,
                credit: {
                    loading: false,
                    data: action.payload.data,
                }
            };
        case GET_CREDIT_APP_FAILED:
            return {
                ...state,
                credit: {
                    loading: false,
                    data: [],
                }
            };


        case GET_DEALER_STATUS_REQUEST:
            return {
                ...state,
                credit: {
                    loading: true,
                    data: [],
                }
            };
        case GET_DEALER_STATUS_SUCCESS:
            return {
                ...state,
                credit: {
                    loading: false,
                    data: action.payload.data,
                }
            };
        case GET_DEALER_STATUS_FAILED:
            return {
                ...state,
                credit: {
                    loading: false,
                    data: [],
                }
            };



        case UPDATE_EARLIEST_DATE_DELIVERY_REQUEST:
            return {
                ...state,
                loading: true,
                data: [],
            };
        case UPDATE_EARLIEST_DATE_DELIVERY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
            };
        case UPDATE_EARLIEST_DATE_DELIVERY_FAILED:
            return {
                ...state,
                loading: false,
                data: [],
            };






        case GET_ACCOUNT_DEALER_REQUEST:
            return {
                ...state,
                dealers: {
                    loading: true,
                    data: [],
                },
            };
        case GET_ACCOUNT_DEALER_SUCCESS:
            return {
                ...state,
                dealers: {
                    loading: false,
                    data: action.payload.data,
                },
            };
        case GET_ACCOUNT_DEALER_FAILED:
            return {
                ...state,
                dealers: {
                    loading: true,
                    data: [],
                },
            };




        case SEND_DEALER_SETTING_REQUEST:
            return {
                ...state,
                loading: true,
                data: [],
            };
        case SEND_DEALER_SETTING_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
            };
        case SEND_DEALER_SETTING_FAILED:
            return {
                ...state,
                loading: true,
                data: [],
            };




        case VIEW_DEALER_SETTING_DATA:
            return {
                ...state,
                dealerSetting: {
                    loading: true,
                    data: [],
                },
            };
        case VIEW_DEALER_SETTING_SUCCESS:
            return {
                ...state,
                dealerSetting: {
                    loading: false,
                    data: action.payload.data,
                },
            };
        case VIEW_DEALER_SETTING_FAILED:
            return {
                ...state,
                dealerSetting: {
                    loading: true,
                    data: [],
                },
            };




        case GET_DEALER_ACCESS_REQUEST:
            return {
                ...state,
                access: {
                    loading: true,
                    data: [],
                },
            };
        case GET_DEALER_ACCESS_SUCCESS:
            return {
                ...state,
                access: {
                    loading: false,
                    data: action.payload.token,
                },
            };
        case GET_DEALER_ACCESS_FAILED:
            return {
                ...state,
                access: {
                    loading: true,
                    data: [],
                },
            };
        case RESET_DEALER_TOKEN:
            return {
                ...state,
                access: {
                    loading: false,
                    data: null,
                }
            }



          






        default:
            return state;
    }
}

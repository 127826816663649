
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCustomerHistory } from '../../../../redux/actions/admin';
import Loader from '../../../../shared/Loader';
import Header, {
    HeaderCenter,
    HeaderLeft,
    HeaderRight,
} from '../../../../components/Dealer/Header';
import {
    IconArrowLeft, IconSearchAdmin,
} from '../../../../assets/images';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getFormattedDate } from '../../../../components/commons/utility';
import { CircledImageButton, CircledImageButtonAdminHistory } from 'screens/Sales/style';
import { Form } from 'react-bootstrap';

export function PureHistory(props) {
    
    const { 
        history, 
        actionLoading, 
        getCustomerHistory, 
        dealerHistory 
    } = props;      
    const [manualDealerHistory, setManualDealerHistory] = useState([])
    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
    const [search, setSearch] = useState('');

    const handleClickSearch = () => {
        setIsSearchBarVisible(!isSearchBarVisible);
    };
    
    useEffect(() => {
        getCustomerHistory();

        const intervalId = setInterval(function () {
            if(window.location.pathname === '/admin/history') {
                getCustomerHistory();
            }
        }, 120000);

        return () => {
            clearInterval(intervalId)
        }
    }, []);


    console.log(props)

    useEffect(() => {
        if(dealerHistory && dealerHistory.data && dealerHistory.data.length > 0) {
            setManualDealerHistory(dealerHistory.data)
        }
    },[dealerHistory])

    useEffect(() => {
        if(search !== '' && search.length > 0) {
            let data = manualDealerHistory.filter((item) => item.customer_name.toLowerCase().includes(search.toLowerCase()))
            setManualDealerHistory(data)
        } else {
            setManualDealerHistory(dealerHistory.data)
        }
    },[search])


    const handleArrowBack = () => {
        history && history.push('/')
    };

    return (
        <div className="admin">

            {dealerHistory && dealerHistory.loading && <Loader />}
            
            <Header>
                <HeaderLeft>
                    <img
                        src={IconArrowLeft}
                        onClick={() => handleArrowBack()}
                        alt=""
                    />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">HISTORY</div>
                </HeaderCenter>
                <HeaderRight>
                <CircledImageButtonAdminHistory active={isSearchBarVisible}>
                        <img
                            src={IconSearchAdmin}
                            alt=""
                            onClick={handleClickSearch}
                        />
                    </CircledImageButtonAdminHistory>
                </HeaderRight>
            </Header>

            {isSearchBarVisible && (
                <div className="search-header">
                    <form
                    //  action="javascript:void(0)"
                    >
                        <Form.Group>
                            <Form.Control
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            )}

            <div className="main">
                <div className="list fill-screen">
                    <Fragment>
                        {manualDealerHistory && manualDealerHistory.map((item) => {
                                return (
                                    <Row className="single-row">
                                        <div className="w-100 d-flex history-row mx-md-5">
                                            <Col xs={6} md={6}>
                                                <div className="customer-name-wrapper">
                                                    <span className="customer-name">
                                                        {item.customer_name}
                                                    </span>
                                                </div>
                                                <br className="d-md-none" />
                                                <span className="sales-name">
                                                    {item.sales_name}
                                                </span>
                                            </Col>
                                            <Col
                                                xs={6}
                                                md={6}
                                                className="text-right"
                                            >
                                                <span className="history-date">
                                                    {getFormattedDate(
                                                        item.date
                                                    )}
                                                </span>
                                                <br className="d-md-none" />
                                                <div className="history-status-wrapper">
                                                    <span className="history-status">
                                                        {item.sales_status}
                                                    </span>
                                                </div>
                                            </Col>
                                        </div>
                                    </Row>
                                );
                            })}
                    </Fragment>
                </div>
            </div>
        </div>
    );
}

PureHistory.propTypes = {
    dealerHistory: PropTypes.func,
};

const mapStateToProps = (state) => ({
    dealerHistory: state.admin.dealerHistory,
});

const mapDispatchToProps = (dispatch) => ({
    getCustomerHistory: () => dispatch(getCustomerHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PureHistory);

import styled from 'styled-components';

export const FormFieldsContainer = styled.div``;

export const SearchFieldContainer = styled.div`
    margin-top: 165px!important;
    @media screen and (max-width:570px){
        margin-top: 120px!important;
    }
    @media screen and (max-width: 320px) {
        margin-top: 80px!important;
    }
`;

export const CancelFormContainer = styled.div`
    text-align: right;
    font-size: 18px;
    color: white;
    cursor: pointer;
`;

export const CircledImageButton = styled.span`
    display: inline-block;
    padding: 3px 6px;
    border-radius: 20px;
    ${({ active }) =>
        active &&
        `
    background: #009d86;
    `}
`;

export const CircledImageButtonAdminHistory = styled.span`
display : flex;
flex-wrap : wrap;
align-items : center;
border-radius: 20px;
padding: 6px;
${({ active }) =>
        active &&
        `
    background: #009d86;
    `}
`

export const CirculedImageButtonSalesFilter = styled.span`
display : flex;
flex-wrap : wrap;
justify-content : center;
align-items : center;
width : 25px;
height : 25px;
border-radius : 50%;
${({ active }) =>
active &&
`
background: #009d86;
`}
`;
import React from 'react';

const Checkbox = (props) => {

    const {
        className = '',
        label,
        name = 'checkbox',
        id,
        disabled = false,
        error = {},
        validationResult = false,
        handleChange,
        checked,
        theme = '',
        afterLeftPosition,
        positionRelativeTop3,
        coApplicantRadio
    } = props;
    // console.log(props)

    let extraProps = {},
        errorLabel = '';

    const checkboxChange = (event) => {

        typeof handleChange === 'function' && handleChange(event);
    };

    if ( checked !== undefined ) {

        extraProps.checked = checked;
    }

    if (validationResult && validationResult[name] && error) {

        errorLabel = error[validationResult[name].error] || null;


    }

    return (
        <div className={`input-holder checkbox ${ className } ${ disabled ? 'disabled' : '' } ${theme}`}>
            <div className='input-container'>
                <div className='checkbox-field-container'>
                    <input
                        type='checkbox'
                        name={ name }
                        id={ id || name }
                        onChange={ checkboxChange }
                        disabled={disabled}
                        className={`form-control regular-checkbox ${coApplicantRadio && 'co-applicantradio'} ${afterLeftPosition && 'left-3'} ${name == 'have_co_applicant_with_same_answers' || name == 'co_have_co_applicant_same_address' ? 'left-3-desktop' : ''}`}
                        { ...extraProps }
                        style={positionRelativeTop3 && {
                            position : 'relative',
                            top : 3
                        }}
                    />
                    <span className='icon-check'/>
                </div>
                {label &&
                    <label className={`body-copy-secondary ${theme}`} htmlFor={ id || name }>
                        <span dangerouslySetInnerHTML={{ __html: label }} />
                    </label>
                }
            </div>
            {errorLabel && (
                <div className='error-label body-copy-secondary'>
                    <span className='icon-info'></span>
                    { errorLabel}
                </div>
            )}
        </div>
    );
};

export default Checkbox;
import React, { Fragment, useState, useEffect } from 'react';
import { IconArrowLeft, IconSearchAdmin } from 'assets/images';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getFundedSchedules } from '../../../../redux/actions/dealer';
import 'assets/css/dealer/schedule.scss';
import Header, {
    HeaderLeft,
    HeaderCenter,
    HeaderRight,
} from '../../../../components/Dealer/Header';
import { CircledImageButton } from '../../../Admin/style';
import Loader from '../../../../shared/Loader';
import CustomerRow from '../../../Admin/2.0/Home/Review/components/CustomerRow';
import FundingRequestCard from '../../../Admin/2.0/Home/Review/components/FundingRequestCard';
import DateSelect from '../../../../components/commons/DateSelect';
import DealerCustomerRow from 'screens/Admin/2.0/Home/Review/components/DealerCustomerRow';
import { getFlooredFixedTwoDigit } from 'utils/helper';

function Funded(props) {
    const { history, actionLoading, funded, getFundedSchedules, totalFundAmount } = props;
    console.log('props', props);
    var tDate = new Date();
    tDate.setDate(tDate.getDate() + 1);

    const [isSearchBarVisible, setIsSearchBarVisible] = useState(null);
    const [search, setSearch] = useState(null);
    const [filterCustomer, setFilterCustomer] = useState([]);
    const [activeCustomer, setActiveCustomer] = useState(
        localStorage.getItem('activeCustomer')
            ? localStorage.getItem('activeCustomer')
            : null
    );
    const [totalFundingAmount, setTotalFundingAmount] = useState(0)

    const [dateRange, setDateRange] = useState([new Date(), tDate]);
    const [startDate, endDate] = dateRange;

    console.log(startDate);

    useEffect(() => {
        localStorage.setItem(
            'selectedFundedRange',
            JSON.stringify([startDate, endDate])
        );
        getFundedSchedules(startDate, endDate);
        setIsSearchBarVisible(false);

        const intervalId = setInterval(function () {
            if (window.location.pathname === '/dealer/funded') {
                const temp_date = JSON.parse(
                    localStorage.getItem('selectedFundedRange')
                );
                getFundedSchedules(temp_date[0], temp_date[1], false);
            }
        }, 35000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if(props.totalFundAmount && props.totalFundAmount.data && props.totalFundAmount.data.total_funding ) {
            console.log(props.totalFundAmount.data.total_funding, typeof props.totalFundAmount.data.total_funding)
            setTotalFundingAmount(props.totalFundAmount.data.total_funding)
        }
    },[props])

    useEffect(() => {
        if (search != null && search !== '') {
            let temp = funded && funded.data && funded.data.filter(d => d.customer_name.toLowerCase().includes(search.toLowerCase()))
            console.log(temp)
            setFilterCustomer(temp);
        }
        else {
            setFilterCustomer(funded.data)
        }
    }, [search]);

    useEffect(() => {
        setFilterCustomer(funded.data);
    }, [funded]);

    const handleClickCustomer = (item) => {
        if (item.app_id === activeCustomer) {
            localStorage.setItem('activeCustomer', null);
            setActiveCustomer(null);
        } else {
            localStorage.setItem('activeCustomer', item.app_id);
            setActiveCustomer(item.app_id);
        }
    };

    const handleArrowBack = () => {
        history.push({
            pathname: '/',
            search: '?tab=1',
        });
    };

    const renderCustomerRows = (customers, type) => {
        return customers.map((item) => (
            <Fragment key={item.app_id}>
                <DealerCustomerRow
                    history={history}
                    expanded={item.app_id == activeCustomer}
                    customer={item}
                    requestType={type}
                    profileType={'dealer'}
                    onClick={() => {
                        handleClickCustomer(item);
                    }}
                />
                {item.app_id == activeCustomer && type === 'funding' && (
                    <FundingRequestCard
                        customer={item}
                        isDealerScreen={2}
                        funding_frequency={
                            funded &&
                            funded.data &&
                            funded.data[0] &&
                            funded.data[0].funding_freq
                        }
                    />
                )}
            </Fragment>
        ));
    };

    const handleDatePickerClose = () => {
        getFundedSchedules(startDate, endDate);
    };

    const forwardClick = () => {
        var sDate = new Date(startDate);
        sDate.setDate(sDate.getDate() + 1);

        var eDate = new Date(endDate);
        eDate.setDate(eDate.getDate() + 1);

        setDateRange([sDate, eDate]);
        localStorage.setItem(
            'selectedFundedRange',
            JSON.stringify([sDate, eDate])
        );
        getFundedSchedules(sDate, eDate);
    };

    const backwardClick = () => {
        var sDate = new Date(startDate);
        sDate.setDate(sDate.getDate() - 1);

        var eDate = new Date(endDate);
        eDate.setDate(eDate.getDate() - 1);

        setDateRange([sDate, eDate]);
        localStorage.setItem(
            'selectedFundedRange',
            JSON.stringify([sDate, eDate])
        );
        getFundedSchedules(sDate, eDate);
    };

    return (
        // <div style={{minHeight : '100%', position : 'relative'}}>
        <div className="admin dealer-seperate-list" 
        style={{ position: 'relative', minHeight : '100%' }}
        >
            {actionLoading && <Loader />}
        <div style={{position : 'sticky', top : 0, zIndex : 9}}>
            <Header>
                <HeaderLeft>
                    <img
                        src={IconArrowLeft}
                        onClick={() => handleArrowBack()}
                        alt=""
                    />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main header-main-date">
                        <DateSelect
                            handleDateChange={(date) => {
                                setDateRange(date);
                                localStorage.setItem(
                                    'selectedFundedRange',
                                    JSON.stringify(date)
                                );
                            }}
                            handleDatePickerClose={() =>
                                handleDatePickerClose()
                            }
                            forwardClick={() => forwardClick()}
                            backwardClick={() => backwardClick()}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </div>
                </HeaderCenter>
                <HeaderRight>
                    <CircledImageButton active={isSearchBarVisible}>
                        <img
                            src={IconSearchAdmin}
                            alt=""
                            onClick={() =>
                                setIsSearchBarVisible(!isSearchBarVisible)
                            }
                        />
                    </CircledImageButton>
                </HeaderRight>
            </Header>
            </div>

            <div style={{position : 'sticky', top : 48, zIndex : 9}}>
            {isSearchBarVisible && (
                <div className="search-header">
                    <form action="javascript:void(0)">
                        <Form.Group>
                            <Form.Control
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            )}
                </div>

            {/* <div className="main"> */}
                <div className="list" style={{ 
                    // minHeight: '100%',
                    height : 'calc(100vh - 48px)',
                     overflow : 'scoll', 
                     paddingBottom : 130
                // height : funded && funded.data && funded.data.length > 6 && 'auto'
                 }}>
                    {/* {funded && funded.data && renderCustomerRows(funded.data, 'funding')} */}
                    {filterCustomer && filterCustomer.length > 0 ? (
                        renderCustomerRows(filterCustomer, 'funding')
                    ) : filterCustomer && filterCustomer.length === 0 ? (
                        <div className="text-center"></div>
                    ) : (
                        funded &&
                        funded.data &&
                        renderCustomerRows(funded.data, 'funding')
                    )}
                </div>
              
            {/* </div> */}
            
            <div style={{ background: 'rgb(103, 146, 182)', borderTop: '2px solid #3a5c77', borderColor: '#A4C8E5', position: 'fixed', width: '100%', bottom: 0 }}>
                <div className="amount" style={{ backgroundColor: 'rgb(103, 146, 182)', padding : '10px 0', maxWidth : 600 }}>
                    <div>
                        <span className="fund-total" style={{ color: '#fff' }}>Totals</span>
                    </div>
                    <div>
                        <span className="fund-details" style={{color : "#fff"}}>
                            Financed Amount
                            <div className="fund-pricing" style={{fontWeight : 'normal', marginTop : 5}}>${getFlooredFixedTwoDigit(totalFundAmount && totalFundAmount.data && totalFundAmount.data.grand_total_finance_amount, 2)}</div>
                        </span>
                    </div>
                    <div>
                        <span className="fund-details" style={{color : "#fff"}}>
                            Purchase Price
                            <div className="fund-pricing" style={{fontWeight : 'normal', marginTop : 5}}>${getFlooredFixedTwoDigit(totalFundAmount && totalFundAmount.data && totalFundAmount.data.grand_total_purchase_price, 2)}</div>
                        </span>
                    </div>
                </div>
                <div className="borderedMiddle pre" style={{ maxHeight: 72, overflow: 'scroll' }}>
                    <table style={{ width: '100%', maxWidth :470, margin : 'auto' }}>
                        <tbody>
                            {totalFundAmount && totalFundAmount.data && totalFundAmount.data.all_adjustment_items.map((adjust) => {
                                return (

                                    <tr style={{ background: "#273947", padding: 5, height: 35 }}>
                                        <td style={{ fontSize: 12, color: '#ffffff' }}>{adjust.item_description}</td>
                                        <td style={{ fontSize: 12, textAlign: 'right', color: '#ffffff' }}>${adjust.amount}</td>
                                    </tr>

                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="borderedMiddle pre" style={{ background: "rgb(194 222 238)" }}>
                    <table style={{ width: '100%' ,maxWidth : 470, margin : 'auto' }}>
                        <tbody>
                            <tr style={{ background: "rgb(194 222 238)", padding: 5, height: 35, color: "#2a4051", fontWeight: 600 }}>
                                <td style={{ fontSize: 14 }}>Total Funding</td>
                                <td style={{ fontSize: 12, textAlign: 'right', fontWeight : 500 }}>{totalFundAmount && totalFundAmount.data && totalFundAmount.data.total_funding ? `$${getFlooredFixedTwoDigit(totalFundAmount.data.total_funding, 2)}` : '$0'}</td>
                                {/* <td style={{ textAlign: 'right', opacity: 0 }}>test</td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        // </div>
    );
}

const mapStateToProps = (state) => ({
    actionLoading: state.dealer.actionLoading,
    funded: state.dealer.funded,
    totalFundAmount: state.dealer.totalFundAmount,
});

const mapDispatchToProps = (dispatch) => ({
    getFundedSchedules: (startDate, endDate, action = true) =>
        dispatch(getFundedSchedules(startDate, endDate, action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Funded);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    getPreApprovals,
    getCustomerProfile
} from "../../../../redux/actions/dealer";
import { Link } from 'react-router-dom';
import {
    CircleStatusIcon,
    HexagonStatusIcon,
    TriangleStatusIcon,
} from '../../../../components/commons/StatusIcons';

function SalesTab(props) {

    const {
        getPreApprovals,
        getCustomerProfile,
        preaprovals,
        sales,
        history,
    } = props;

    console.log(props)

    useEffect(() => {
        getPreApprovals();
    }, []);

    const getStatusIcon = (status, type) => {
        console.log(status, type)
        if (status === "in_process") {
            return <TriangleStatusIcon
                symbol={type}
                fill={true}
                disabled={false}
            />
        }
        else if (status === "approval") {
            return <CircleStatusIcon
                symbol={type}
                fill={true}
                disabled={false}
            />
        }
        else if (status === "rejection") {
            return(
            <div style={{position : 'relative', right : 2}}>
            <HexagonStatusIcon
                symbol={type}
                fill={true}
                disabled={false}
            />
            </div>
            )
        }
    }

    const getProductFoodStatus = (products) => {


        let p = products && products.length > 0 && products.filter(t => t.product === "Food/Mac" || t.product === "Food");

        console.log(p)

        if (p && p.length > 0 && p[0].product_status == "in_process") {
            return <>
             <div style={{position : 'relative', left : 1}}>
                <TriangleStatusIcon
                    symbol={'P'}
                    fill={true}
                    disabled={false}
                />
                </div>
                {/* <span className="date" style={{ fontSize: 12, color: "#83b0d4" }}>{p[0].earliest_delivery_date != null ? p[0].earliest_delivery_date : 'MM/DD/YY'}</span> */}
            </>
        }
        else if (p && p.length > 0 && p[0].product_status === "approval") {
            return <>
                <CircleStatusIcon
                    symbol={"P"}
                    fill={true}
                    disabled={false}
                />
                {/* <span className="date" style={{ fontSize: 12, color: "#83b0d4" }}>{p[0].earliest_delivery_date != null ? p[0].earliest_delivery_date : 'MM/DD/YY'}</span> */}
            </>
        }
        else {
            return <>
            <div style={{position : 'relative', right : 2}}>
                <HexagonStatusIcon
                    symbol={'P'}
                    fill={true}
                    disabled={false}
                />
                </div>
                {/* <span className="date" style={{ fontSize: 10, color: "#83b0d4" }}>{p[0].earliest_delivery_date != null ? p[0].earliest_delivery_date : 'MM/DD/YY'}</span> */}
            </>
        }
    }


    const getProductFSPStatus = (products) => {

        let p = products && products.length > 0 && products.filter(t => t.product === "Other Appliance");
        console.log(p)
        if (p && p.length > 0 && p[0].product_status == "in_process") {
            return (
                <div style={{position : 'relative', left : 1}}>
            <TriangleStatusIcon
                symbol={'P'}
                fill={true}
                disabled={false}
            />
            </div>
            )
        }
        else if (p && p.length > 0 && p[0].product_status == "approval") {
            return <CircleStatusIcon
                symbol={"P"}
                fill={true}
                disabled={false}
            />
        }
        else {
            return (
                <div style={{position : 'relative', right : 2}}>
            <HexagonStatusIcon
                symbol={'P'}
                fill={true}
                disabled={false}
            />
            </div>)
        }
    }

    return (
        <>
            <div className="dealerSales mt-4">
                <Link to="/dealer/pre-approval">
                    <div className="heading dealer-heading-hover">
                        <h6>PreApprovals</h6>
                    </div>
                </Link>
                <div className="details-main">
                    <div className="preApp-data">
                        <table style={{ width: '100%' }}>
                            {preaprovals && preaprovals.data && preaprovals.data.map((item) => {
                                return (
                                    <tr className="details">
                                        <td className="cursor-pointer">
                                            <h6 className="dealer-name mb-0" onClick={() => getCustomerProfile(history, item.app_id, item.customer_CIF, 'dealer')} style={{color : "#b2d8f7"}}>{item.customer_name}</h6>
                                            {item.city || item.state ?
                                                <h6 className="location" style={{color : "#6792b6"}}>
                                                    {/* {item.street},  */}
                                                    {item.city}, {item.state}
                                                </h6> : <h6></h6>
                                            }
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            {getProductFoodStatus(item.products)}
                                        </td>
                                        <td style={{ textAlign: "right", width: 140 }}>
                                            {getProductFSPStatus(item.products)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                </div>
            </div>

            <div className="dealerSales">
                <Link to="/dealer/sales">
                    <div className="heading dealer-heading-hover">
                        <h6>Sales</h6>
                    </div>
                </Link>
                <div className="details-main">
                    <div className="preApp-data">
                        <table style={{ width: '100%' }}>
                            {sales && sales.data && sales.data.map((item) => {
                                return (
                                    <tr className="details">
                                        <td className="cursor-pointer">
                                            <h6 onClick={() => getCustomerProfile(history, item.app_id, item.customer_CIF, 'dealer')} className="name" style={{color : "#b2d8f7"}}>{item.customer_name}</h6>
                                            <h6 className="location">{item.sales_name}</h6>
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            <h6 className="location" style={{color : "#6792b6"}}>
                                                {item.products && item.products.length > 0 && item.products.map(tt => {
                                                    return (
                                                        <>{tt.product}<br></br></>
                                                    )
                                                })}
                                            </h6>
                                        </td>
                                        <td style={{ textAlign: "right", width: 140 }}>
                                            {getStatusIcon(item.app_status, 'S')}
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}


const mapStateToProps = (state) => ({
    preaprovals: state.dealer.preaprovals,
    sales: state.dealer.sales
});

const mapDispatchToProps = (dispatch) => ({
    getPreApprovals: () => dispatch(getPreApprovals()),
    getCustomerProfile: (history, data, cif, type) => dispatch(getCustomerProfile(history, data, cif, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesTab);

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
    setExpandedDealers,
    getDealerAcessRequest
} from '../../../../../redux/actions/admin';
import { Form } from 'react-bootstrap';
import { DealerRow } from '../components/DealerRow';
import Header, {
    HeaderCenter,
    HeaderLeft,
    HeaderRight,
} from '../../../../../components/Dealer/Header';
import { IconSearchAdmin, IconArrowLeft, IconKey, IconSetting, IconCogsvg, keySvg } from '../../../../../assets/images';
import IconCog from '../../../../../assets/images/IconCog.png'
import keyIconHeader from '../../../../../assets/images/keyIconHeader.png'
import { CircledImageButton } from '../../../style';
import Loader from '../../../../../shared/Loader';
import Input from 'components/commons/input';
import { removeGlobalItemAdjustment, updateGlobalItemAdjustment } from 'redux/actions/dealer';
import {  IconCancel } from '../../../../../assets/images';

export function PureFunding(props) {
    const {
        history,
        setExpandHandler,
        dealer,
        setExpandedDealers,
        expandedDealers,
        getDealerAcessRequest,
        expandAllFunding,
        changeExandAllFundinhHandler,
        updateGlobalItemAdjustment,
        globalItemAdjustmentLoader,
        removeGlobalItemAdjustment,
        actionLoading } = props;

        console.log('dealerprops', props)

    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [filterDealer, setFilterDealer] = useState(null);

    const totalFundInfo = useSelector((state) => state.admin && state.admin.dealer && state.admin.dealer[0] && state.admin.dealer[0].total_fund_info && state.admin.dealer[0].total_fund_info)


    const handleClickSearch = () => {
        setIsSearchBarVisible(!isSearchBarVisible);
    };

    const submitAdjustment = () => {
        if (description != "" && amount != "") {

            let temp = {
                "dealer_id": dealer[0].id,
                "amount": amount,
                "item_description": description
            };
            console.log("temp", temp);

            setError(null)

            updateGlobalItemAdjustment(temp, 1).then(() => {
                setDescription('');
                setAmount('');
            })

            // itemValueAdjustment(temp, isDealerScreen).then(() => {
            //     setDescription('');
            //     setAmount('')
            //     // window.location.reload();
            // })
        }
        else {
            setError('Please enter correct data')
        }
    }


    useEffect(() => {
        if (search != null && search !== '') {
            let temp_dealers = [];
            
            dealer.forEach(dealer => {
               
                let temp_funding_data = [];
                if (dealer.funding_data.length > 0) {
                   
                    temp_funding_data = dealer.funding_data.filter((d) =>
                        d.customer_name.toLowerCase().includes(search.toLowerCase())
                    );
                    console.log(temp_funding_data)
                }

                if (temp_funding_data.length > 0) {
                    temp_dealers = temp_dealers.length > 0 ?
                        [...temp_dealers, {
                            ...dealer,
                            funding_data: temp_funding_data
                        }]
                        : [{
                            ...dealer.funding_data,
                            funding_data: temp_funding_data
                        }]
                        console.log(temp_dealers.funding_data)
                }

            });
            console.log(temp_dealers)
            if(temp_dealers[0] && temp_dealers[0].funding_data && temp_dealers[0].funding_data.length > 0){
            setFilterDealer(temp_dealers[0].funding_data)
            } else {
                setFilterDealer(null)
            }
        } else {
            setFilterDealer(dealer);
        }
    }, [search]);


    useEffect(() => {
        setFilterDealer(dealer);
    }, [dealer]);

    // useEffect(() => {
    //     setFilterDealer(dealer);
    // },[])

    useEffect(() => {
        setExpandHandler && setExpandHandler(handleExpand);
        return () => {
            setExpandHandler && setExpandHandler(() => { });
        };
    });

    const handleExpand = () => {
        const activeIndex = expandedDealers[toOpenIndex(dealer[0].id)]

        expandedDealers[toOpenIndex(dealer[0].id)] = dealer[0].funding_data.length > 0 ? !activeIndex : false;
        setExpandedDealers({ ...expandedDealers });
    };

    const handleOpenDealerAction = (id) => {
        console.log(id)
        const key = toOpenIndex(id);
        const currentValue = expandedDealers[key];
        setExpandedDealers({
            ...expandedDealers,
            [key]: !currentValue,
        });
    };


    const toOpenIndex = (id) => {
        return 'map' + id;
    };

    const renderDealers = () => {
        console.log('renderDealers', dealer)
        return (
            <DealerRow
                history={history}
                key={dealer.id}
                statusName={""}
                dealer={dealer}
                filterDealer={filterDealer}
                // expanded={expandedDealers[toOpenIndex(dealer && dealer.length > 0 && dealer[0].id)]}
                expanded={true}
                // expanded={expandedDealers[toOpenIndex(dealer[0].id)]}
                onClick={handleOpenDealerAction}
                type="funding"
                expandAllFunding={expandAllFunding}
                changeExandAllFundinhHandler={changeExandAllFundinhHandler}
            />
        )
    };

    const handleArrowBack = () => {
        // let path = localStorage.getItem('redirectionPath') ? localStorage.getItem('redirectionPath') : '/?tab=1'
        // history.push(path)
        history && history.push('/');
    }

    const handleSetting = () => {
        // history && history.push('/admin/dealer/settings')
        window.location.assign('/admin/dealer/settings')
    }

    const handleAccess = () => {
        getDealerAcessRequest(dealer[0].id);
        // history && history.push('/login')
    }

    return (
        <>
            {actionLoading && <Loader />}
            <div style={{position : 'sticky', top : 0, zIndex : 9}}>
            <Header>
                <HeaderLeft>
                    <img src={IconArrowLeft} onClick={() => handleArrowBack()} alt="" />
                    <img src={IconCogsvg} width="22px" className='ml-3' onClick={handleSetting} />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">
                        {dealer && dealer[0] && dealer[0].company_name ? dealer[0].company_name : ""}
                    </div>
                </HeaderCenter>
                <HeaderRight>
                <img src={keySvg} width="27px" className='mr-3' onClick={handleAccess} />
                    <CircledImageButton active={isSearchBarVisible}>
                        <img
                            src={IconSearchAdmin}
                            alt=""
                            onClick={handleClickSearch}
                        />
                    </CircledImageButton>
                </HeaderRight>
            </Header>
            </div>

            {isSearchBarVisible && (
                <div className="search-header">
                    <form 
                    // action="javascript:void(0)"
                    >
                        <Form.Group>
                            <Form.Control
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            )}

            <div className="main">
                <div className={`list ${isSearchBarVisible ? 'expand' : ''}`} style={{minHeight : history.location.pathname == "/admin/dealer" && history.location.search == "?tab=1" && '100%',
                // height : history.location.pathname == "/admin/dealer" && history.location.search == "?tab=1" && 'auto', 
                overflow : history.location.pathname == "/admin/dealer" && history.location.search == "?tab=1" && 'scroll'}}>
                    {filterDealer && renderDealers(filterDealer)}
                    {!filterDealer && <div className="no-data text-center mt-3">No data found</div>}
                </div>

                        <div style={{ background: '#6792b6', borderTop: '2.5px solid #fff', borderColor: '#A4C8E5', position: 'sticky', width: '100%', bottom: 0, height : 275 }}>
                <div className="amount admin-dealer-funding-amount" style={{ backgroundColor: '#6792b6', maxWidth : 600, justifyContent : 'space-between', padding : 10 }}>
                    <div>
                        <span className="fund-total" style={{ color: '#fff' }}>Totals</span>
                    </div>
                    <div>
                        <span className="fund-details" style={{ color: '#fff' }}>
                            Financed Amount
                            <div className="fund-pricing">${totalFundInfo && totalFundInfo.grand_total_finance_amount && totalFundInfo.grand_total_finance_amount}</div>
                        </span>
                    </div>
                    <div>
                        <span className="fund-details" style={{ color: '#fff' }}>
                            Purchase Price
                            <div className="fund-pricing">${totalFundInfo && totalFundInfo.grand_total_purchase_price && totalFundInfo.grand_total_purchase_price}</div>
                        </span>
                    </div>
                </div>


                <div className="borderedMiddle pre" style={{
                    height : 100,
                    overflow : 'auto',
                }}>
               
                {dealer && dealer[0] && dealer[0].total_fund_info && dealer[0].total_fund_info.all_adjustment_items &&  dealer[0].total_fund_info.all_adjustment_items.map((adj) => {
                                    return (
                                        <React.Fragment>
                                             <div className="amount" style={{maxWidth : 600, justifyContent : 'space-between'}}>
                    <div>
                        <span className="fund-total" style={{ color: '#b0d6f5' }}>{adj.item_description}</span>
                    </div>
                    <div>
                        <span className="fund-details">
                            <div className="fund-pricing">{adj.amount}</div>
                        </span>
                    </div>
                    <div>
                    <button className="plus minus" 
                                                onClick={() => removeGlobalItemAdjustment({ "adjustment_id": adj.id }, 1)}
                                                ><img src={IconCancel} alt="delete" width="8" /></button>
                    </div>
                    </div>
                    </React.Fragment>
                                    )
                                })}
                    </div>


                <div style={{ background: '#395D76', borderColor: '#A4C8E5' }}></div>

    <div className="borderedMiddle" style={{background : "#335169", borderTop : 'solid 2px #2a4051'}}>
        <div className="item-desc funding-item-parent-desc" style={{maxWidth : 600, justifyContent : 'space-between'}}>
            <Input className="fund-input" defaultText='Item Description' value={description} handleChange={(e) => setDescription(e.target.value)} />
            <Input type='number' defaultText='$' className="amount-input" value={amount} handleChange={(e) => setAmount(e.target.value)} />
            <button className="plus add_plus" onClick={() => submitAdjustment()}><span style={{position : 'absolute', top : '50%', transform : 'translate(-50%, -50%'}}>+</span></button>
        </div>
        {error && <p>{error}</p>}
    </div>
               
               
            </div>
            </div>

    
        </>
    );
}

const mapStateToProps = (state) => ({
    actionLoading: state.admin.actionLoading,
    expandedDealers: state.admin.expandedDealers,
    dealer: state.admin.dealer,
    globalItemAdjustmentLoader : state.dealer.globalItemAdjustmentLoader
});

const mapDispatchToProps = (dispatch) => ({
    setExpandedDealers: (data) => dispatch(setExpandedDealers(data)),
    getDealerAcessRequest: (data) => dispatch(getDealerAcessRequest(data)),
    updateGlobalItemAdjustment: (data, page) => dispatch(updateGlobalItemAdjustment(data, page)),
    removeGlobalItemAdjustment: (data, page) => dispatch(removeGlobalItemAdjustment(data, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PureFunding);
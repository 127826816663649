import React, { useEffect } from 'react';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import Loader from 'shared/Loader';

import { Col, Row, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getCustomerCredit } from '../../../../redux/actions/dealer';
import { IconAwesomeCheckCircle } from '../../../../assets/images';

function CustomerCredit(props) {
    const {
        history,
        credit: {
            applicant,
            co_applicant: coApplicant,
            combined_stats: combinedStats,
            delinquency_30: delinquency30,
            delinquency_60: delinquency60,
            delinquency_90: delinquency90,
            inquiries,
            revolving_percentage: revolvingPercentage,
            balance_stats: balanceStats,
            comment,
        },
        actionLoading,
        getCustomerCredit,
        selectedCustomer,
        cProfile: { loading, data: app },
    } = props;

    const accessToken = useSelector((state) => state.admin.access.data);


    console.log(selectedCustomer);
    console.log(props);
    console.log(credit);

    useEffect(() => {
        if (app && app.app_id) {
            getCustomerCredit(app.app_id);
        }
    }, []);

    const goToSales = () => {
        history && history.push('/applyApplication');
    };

    var credit = selectedCustomer && selectedCustomer.credit;

    return (
        <>
            {actionLoading && <Loader />}
            <div className="customer-credit-wrapper list">
                <div className="mobile-sized-container">
                    <Row className="applicant-credit-list" style={{position : 'relative'}}>
                        <Col className="col-left" xs={6}>
                            <div className="applicant-credit">
                                <p className="color-dim-blue">
                                    {credit &&
                                        credit.customer_info &&
                                        credit.customer_info.main_applicant &&
                                        credit.customer_info.main_applicant
                                            .name}
                                </p>
                                <p className="text-white">
                                    Income $
                                    {credit &&
                                        credit.customer_info &&
                                        credit.customer_info.main_applicant &&
                                        credit.customer_info.main_applicant
                                            .income}
                                </p>
                                <p className="text-white">
                                    FICO:
                                    {credit &&
                                        credit.customer_info &&
                                        credit.customer_info.main_applicant &&
                                        credit.customer_info.main_applicant
                                            .fico}
                                    / VAN:
                                    {credit &&
                                        credit.customer_info &&
                                        credit.customer_info.main_applicant &&
                                        credit.customer_info.main_applicant.van}
                                </p>
                                <p className="text-white">
                                    Credit Shop: &nbsp;
                                    {credit &&
                                    credit.customer_info &&
                                    credit.customer_info.main_applicant &&
                                    credit.customer_info.main_applicant
                                        .credit_shop == true ? (
                                        <img
                                            src={IconAwesomeCheckCircle}
                                            alt=""
                                            width={15}
                                        />
                                    ) : (
                                        'false'
                                    )}
                                </p>
                                <p className="text-white font-italic">
                                    {credit &&
                                        credit.customer_info &&
                                        credit.customer_info.main_applicant &&
                                        credit.customer_info.main_applicant
                                            .credit_date}
                                </p>
                            </div>
                        </Col>
                        {credit &&
                        credit.customer_info &&
                        credit.customer_info.co_applicant ? (
                            <Col className="col-right" xs={6}>
                                <div className="applicant-credit">
                                    <p className="color-dim-blue">
                                        {credit &&
                                            credit.customer_info &&
                                            credit.customer_info.co_applicant &&
                                            credit.customer_info.co_applicant
                                                .name}
                                    </p>
                                    <p className="text-white">
                                        Income $
                                        {credit &&
                                            credit.customer_info &&
                                            credit.customer_info.co_applicant &&
                                            credit.customer_info.co_applicant
                                                .income}
                                    </p>
                                    <p className="text-white">
                                        FICO:
                                        {credit &&
                                            credit.customer_info &&
                                            credit.customer_info.co_applicant &&
                                            credit.customer_info.co_applicant
                                                .fico}
                                        / VAN:
                                        {credit &&
                                            credit.customer_info &&
                                            credit.customer_info.co_applicant &&
                                            credit.customer_info.co_applicant
                                                .van}
                                    </p>
                                    <p className="text-white">
                                        Credit Shop: &nbsp;
                                        {credit &&
                                        credit.customer_info &&
                                        credit.customer_info.co_applicant &&
                                        credit.customer_info.co_applicant
                                            .credit_shop == true ? (
                                            <img
                                                src={IconAwesomeCheckCircle}
                                                alt=""
                                                width={15}
                                            />
                                        ) : (
                                            'false'
                                        )}
                                    </p>
                                    <p className="text-white font-italic">
                                        {credit &&
                                            credit.customer_info &&
                                            credit.customer_info.co_applicant &&
                                            credit.customer_info.co_applicant
                                                .credit_date}
                                    </p>
                                </div>
                            </Col>
                        ) : (
                            <button
                                className="request-generate"
                                onClick={() => goToSales()}
                                disabled={
                                    localStorage.getItem('role') ===
                                        'sales,dealer,admin' && !accessToken
                                        ? true
                                        : false
                                }
                                style={{
                                    cursor :  localStorage.getItem('role') ===
                                    'sales,dealer,admin' && !accessToken ? 'not-allowed' : 'pointer',
                                    position : 'absolute',
                                    top : '50%',
                                    transform : 'translate(0, -50%)',
                                    right : 0

                                }}
                            >
                                Add Co-applicant
                            </button>
                        )}
                    </Row>
                </div>
                <div className="mobile-sized-container mt-3">
                    <div className="combined-credit-stats">
                        <Row>
                            <Col className="col-left color-grey-blue">
                                Combined Past Due:
                            </Col>
                            <Col className="col-right color-light-blue">
                                $
                                {credit &&
                                    credit.combined_data &&
                                    credit.combined_data.combined_past_due}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-left color-grey-blue">
                                Combined Income:
                            </Col>
                            <Col className="col-right color-light-blue">
                                $
                                {credit &&
                                    credit.combined_data &&
                                    credit.combined_data.combined_income}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-left color-grey-blue">
                                Combined Payments:
                            </Col>
                            <Col className="col-right color-light-blue">
                                $
                                {credit &&
                                    credit.combined_data &&
                                    credit.combined_data.combined_payments}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-left color-grey-blue">
                                Combined DTI:
                            </Col>
                            <Col className="col-right color-light-blue">
                                {credit &&
                                    credit.combined_data &&
                                    credit.combined_data.combined_dtl}
                                %
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="mobile-sized-container mt-3">
                    <Row>
                        <Col className="text-white">Delinquency 30/60/90:</Col>
                        <Col className="color-light-blue text-right">
                            {credit && credit.delinqucy}
                        </Col>
                    </Row>
                </div>
                <div className="mobile-sized-container mt-3">
                    <Row>
                        <Col className="text-white">
                            Inquiries during last 6 months:
                        </Col>
                        <Col className="color-light-blue text-right">
                            {credit && credit.last_inquiry}
                        </Col>
                    </Row>
                </div>
                <div className="mobile-sized-container mt-3">
                    <Row>
                        <Col className="text-white">
                            Revolving Available Percentage:
                        </Col>
                        <Col className="color-light-blue text-right">
                            {credit && credit.resolving_avl}%
                        </Col>
                    </Row>
                </div>
                <div className="mt-3">
                    <div className="balance-stats-table">
                        <Row>
                            <Col xs={3} />
                            <Col
                                xs={3}
                                className="balance-col color-grey-blue text-center"
                            >
                                Total Balance
                            </Col>
                            <Col
                                xs={3}
                                className="payment-col color-grey-blue text-center"
                            >
                                Total Monthly Payments
                            </Col>
                            <Col
                                xs={3}
                                className="past-due-col color-grey-blue text-center"
                            >
                                Past Due Amounts
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} className="color-grey-blue">
                                Revolving:
                            </Col>
                            <Col
                                xs={3}
                                className="text-white text-center pr-xs-3 pr-md-4"
                            >
                                $
                                {credit &&
                                    credit.agreegate_data &&
                                    credit.agreegate_data
                                        .resolving_total_balance}
                            </Col>
                            <Col
                                xs={3}
                                className="text-white text-center pr-xs-3 pr-md-4"
                            >
                                $
                                {credit &&
                                    credit.agreegate_data &&
                                    credit.agreegate_data
                                        .resolving_total_monthly_payments}
                            </Col>
                            <Col
                                xs={3}
                                className="text-white text-center pr-xs-3 pr-md-4"
                            >
                                $
                                {credit &&
                                    credit.agreegate_data &&
                                    credit.agreegate_data
                                        .resolving_past_dueamounts}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3} className="color-grey-blue">
                                Mortrage:
                            </Col>
                            <Col
                                xs={3}
                                className="text-white text-center pr-xs-3 pr-md-4"
                            >
                                $
                                {credit &&
                                    credit.agreegate_data &&
                                    credit.agreegate_data
                                        .mortgage_total_balance}
                            </Col>
                            <Col
                                xs={3}
                                className="text-white text-center pr-xs-3 pr-md-4"
                            >
                                $
                                {credit &&
                                    credit.agreegate_data &&
                                    credit.agreegate_data
                                        .mortgage_total_monthly_payments}
                            </Col>
                            <Col
                                xs={3}
                                className="text-white text-center pr-xs-3 pr-md-4"
                            >
                                $
                                {credit &&
                                    credit.agreegate_data &&
                                    credit.agreegate_data
                                        .mortgage_past_dueamounts}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={3} className="color-grey-blue">
                                Installment:
                            </Col>
                            <Col
                                xs={3}
                                className="text-white text-center pr-xs-3 pr-md-4"
                            >
                                $
                                {credit &&
                                    credit.agreegate_data &&
                                    credit.agreegate_data
                                        .installment_total_balance}
                            </Col>
                            <Col
                                xs={3}
                                className="text-white text-center pr-xs-3 pr-md-4"
                            >
                                $
                                {credit &&
                                    credit.agreegate_data &&
                                    credit.agreegate_data
                                        .installment_past_dueamounts}
                            </Col>
                            <Col
                                xs={3}
                                className="text-white text-center pr-xs-3 pr-md-4"
                            >
                                $
                                {credit &&
                                    credit.agreegate_data &&
                                    credit.agreegate_data
                                        .installment_past_dueamounts}
                            </Col>
                        </Row>
                        {/* ))} */}
                        <div className="total-row">
                            <Row className="text-white">
                                <Col xs={3}>TOTAL</Col>
                                <Col
                                    xs={3}
                                    className="text-center pr-xs-3 pr-md-4"
                                >
                                    $
                                    {credit &&
                                        credit.agreegate_data &&
                                        credit.agreegate_data.total_resolving}
                                </Col>
                                <Col
                                    xs={3}
                                    className="text-center pr-xs-3 pr-md-4"
                                >
                                    $
                                    {credit &&
                                        credit.agreegate_data &&
                                        credit.agreegate_data.total_mortgage}
                                </Col>
                                <Col
                                    xs={3}
                                    className="text-center pr-xs-3 pr-md-4"
                                >
                                    $
                                    {credit &&
                                        credit.agreegate_data &&
                                        credit.agreegate_data.total_installment}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="mobile-sized-container mt-3">
                    <div className="color-grey-blue">Comment</div>
                    <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        value={comment}
                        style={{ height: '71px' }}
                    />
                </div>
            </div>
        </>
    );
}

CustomerCredit.propTypes = {
    credit: PropTypes.shape({
        applicant: PropTypes.object,
        co_applicant: PropTypes.object,
        combined_stats: PropTypes.object,
        delinquency_30: PropTypes.number,
        delinquency_60: PropTypes.number,
        delinquency_90: PropTypes.number,
        inquiries: PropTypes.number,
        revolving_percentage: PropTypes.number,
        balance_stats: PropTypes.object,
        comment: PropTypes.string,
    }),
};

const mapStateToProps = (state) => ({
    actionLoading: state.dealer.actionLoading,
    selectedCustomer: state.dealer.selectedCustomer,
    cProfile: state.admin.cProfile,
});
const mapDispatchToProps = (dispatch) => ({
    getCustomerCredit: (id) => dispatch(getCustomerCredit(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerCredit);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ExpandIcon } from './ExpandIcon';
import StatusIcon from './StatusIcons/StatusIcon';
import { toAbbrName } from '../../../../../../utils/helper';
import {
    IconYellowP,
    IconYellowS,
    IconGreenp,
    TCPLogo,
    IconBgDown,
    IconSmallArrowRight,
    IconStatusWaiting,
    IconBorderGreen,
    IconCalendarRightWhite,
} from 'assets/images';
import {
    getCustomerProfile,
    getDealerAdditionalInfo,
} from '../../../../../../redux/actions/admin';
import {
    CircleStatusIcon,
    HexagonStatusIcon,
    TriangleStatusIcon,
} from './StatusIcons';
import { getCustomerApiInitiateForGenerateOrder } from '../../../../../../redux/actions/sales';
import { useHistory, useParams } from 'react-router-dom';

function CustomerRow(props) {
    const {
        applicant: applicant,
        co_applicant: coApplicant,
        food_date: foodDate,
        food_status: foodStatus,
        other_status: otherStatus,
        items: items,
        status: status,
        review_mode: reviewMode,
        food_review_mode: foodReviewMode,
        other_review_mode: otherReviewMode,
        statusName,
        history,
        expanded,
        expandAllFunding,
        changeExandAllFundinhHandler,
        requestType,
        onClick,
        type,
        getCustomerProfile,
        getDealerAdditionalInfo,
        getCustomerApiInitiateForGenerateOrder,
        dealer,
        profileType = 'admin',
        customer: {
            app_id,
            customer_CIF,
            products,
            customer_name = '',
            city = '',
            state = '',
            street = '',
            sales_date = '',
            earliest_delivery_date = null,
            app_status = '',
            funding_status = '',
            sales_name = '',
        },
    } = props;

    console.log(props);

    console.log(history)

    const formatDate = (date) => {
        return moment(date).format('MM/DD/YY');
    };

    const renderFood = (mode, status, date) => {
        return (
            <div>
                {getStatusIcon(status)}
                <span className="customer-food-date">
                    {date ? formatDate(date) : 'N/A'}
                </span>
            </div>
        );
    };

    const renderPurchasedItems = (items) => {
        return (
            items &&
            items.map((item) => {
                return (
                    <div
                        className="customer-purchase-item"
                        key={`product_${item.id}`}
                    >
                        {item.product}
                    </div>
                );
            })
        );
    };

    const renderPurchasedItemPrice = (items) => {
        return (
            items &&
            items.map((item) => {
                return (
                    <div
                        className="customer-purchase-item"
                        key={`product_${item.id}`}
                    >
                        ${item.finance_amount.toFixed(2)}
                    </div>
                );
            })
        );
    };

    const getStatusIcon = (status) => {
        if (status === 'in_process') {
            return (
                <TriangleStatusIcon
                    symbol={requestType == 'order' ? 'S' : 'P'}
                    fill={true}
                    disabled={false}
                />
            );
        } else if (status === 'approval') {
            return (
                <CircleStatusIcon
                    symbol={requestType == 'order' ? 'S' : 'P'}
                    fill={true}
                    disabled={false}
                />
            );
        } else if (status === 'rejection') {
            return (
                <HexagonStatusIcon
                    symbol={requestType == 'order' ? 'S' : 'P'}
                    fill={true}
                    disabled={false}
                />
            );
        }
    };

    const getCustomerInformation = () => {
        console.log(props);
        let data = {
            customer_id: props.customer.customer_id,
            co_customer_id: props.customer.co_customer_id,
            include_products: true,
            app_id: app_id,
        };
        getCustomerApiInitiateForGenerateOrder(data, history, null);
    };

    return (
        <Row
            className={`single-row ${
                expanded || expandAllFunding ? 'expanded' : ''
            }`}
            style={{backgroundColor : expanded ? "#335169" : ""}}
        >
            <div className={`customer-row ${expanded && history.location.search == '?tab=1' && 'funding-customer-row'} height-70`} style={{alignItems : 'center'}}>
                <Col style={{position : history.location.search == '?tab=0' && 'relative', top : history.location.search == '?tab=0' && 9, alignItems : 'center', flexWrap : 'wrap'}} className="admin-review-customer-row col-6">
                    <div className={`${requestType === 'order' && 'mobile-margin'}`} style={{marginTop  : 0}}>
                       <div className='admin-review-customer-row-customer_name'>
                        <span
                            className={`customer-name badge-pill badge-primary ${
                                requestType === 'funding' && funding_status
                            }`}
                            onClick={() => {
                                localStorage.setItem('dealer_type', type);
                                getCustomerProfile(
                                    app_id,
                                    customer_CIF,
                                    history,
                                    profileType
                                );
                                if (Array.isArray(dealer)) {
                                    getDealerAdditionalInfo(
                                        dealer && dealer[0].id
                                    );
                                } else {
                                    getDealerAdditionalInfo(
                                        dealer && dealer.id
                                    );
                                }
                                getCustomerInformation();
                            }}
                            style={{
                                backgroundColor: expanded
                                    ? '#009d86'
                                    : '#2d4a5f',
                                    color : expanded ? "#fff" : "#b2d8f7",
                            }}
                        >
                            {customer_name ? customer_name : 'N/A'}
                        </span>
                        </div>
                    </div>
                    {requestType !== 'order' && (
                        <div>
                            <span className="customer-location dealer-row-customer-location" style={{marginTop : 0}}>
                                {city ? city : 'N/A'},&nbsp;
                                {/* {street ? street : "N/A"},&nbsp; */}
                                {state ? state : 'N/A'}
                            </span>
                        </div>
                    )}
                    {requestType === 'order' && (
                        <div className="customer-location dealer-row-customer-location position-top-for-tab">
                            <p className="mb-0">{sales_name}</p>
                            <p>{sales_date ? formatDate(sales_date) : 'N/A'}</p>
                        </div>
                    )}
                </Col>
                <Col
                    xs={4}
                    // className="content-col text-left"
                    className='content-col col-lg-3 col-md-3 col-xs-4 col-sm-4'
                    onClick={() => {
                        onClick();
                        // if(changeExandAllFundinhHandler) {
                        // changeExandAllFundinhHandler();
                        // }
                    }}
                    style={{padding : 0}}
                >
                    {requestType === 'order' || requestType === 'funding'
                        ? renderPurchasedItems(products)
                        : renderFood(
                              true,
                              products &&
                                  products[0] &&
                                  products[0].product_status,
                              products &&
                                  products[0] &&
                                  products[0].earliest_delivery_date
                          )}
                </Col>
                <Col
                    xs={3}
                    // className="action-col text-right"
                        className='action-col col-lg-3 col-md-3 col-xs-3 col-sm-3'    
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    onClick={() => {
                        onClick();
                        // if(changeExandAllFundinhHandler) {
                        // changeExandAllFundinhHandler();
                        // }
                    }}
                >
                    {requestType === 'funding' ? (
                        <div>{renderPurchasedItemPrice(products)}</div>
                    ) : (
                        // getStatusIcon(app_status)
                        getStatusIcon(
                            products &&
                                products[1] &&
                                products[1].product_status
                        )
                    )}
                    <span
                        onClick={() => {
                            onClick();
                            //     if(changeExandAllFundinhHandler) {
                            // changeExandAllFundinhHandler();
                            // }
                        }}
                    >
                        {/* <ExpandIcon /> */}
                        <img src={IconCalendarRightWhite} style={{transform : expanded && 'rotate(90deg)', position : 'relative', right : 6}} />
                    </span>
                </Col>
            </div>
        </Row>
    );
}

CustomerRow.propTypes = {
    customer: PropTypes.object.isRequired,
    expanded: PropTypes.bool,
    onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    getCustomerProfile: (id, cif, history, profileType) =>
        dispatch(getCustomerProfile(id, cif, history, profileType)),
    getDealerAdditionalInfo: (id) => dispatch(getDealerAdditionalInfo(id)),
    getCustomerApiInitiateForGenerateOrder: (data, history, path) =>
        dispatch(getCustomerApiInitiateForGenerateOrder(data, history, path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRow);

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import Header, {
    HeaderLeft,
    HeaderCenter,
    HeaderRight,
} from '../../../components/Dealer/Header';
import { Form, Row, Col } from 'react-bootstrap';
import {
    TCPLogo,
    IconAdd,
    IconArrowLeft,
    IconDeleteNew,
} from '../../../assets/images';
import Loader from 'shared/Loader';
import CircuralInactive from '../../../assets/images/CircuralInactive.svg';
import CircuralActive from '../../../assets/images/CircuralActive.svg';
import {
    setStaff,
    getStaffs,
    deleteStaff,
} from '../../../redux/actions/dealer';
import { getSalesHistory } from 'redux/actions/sales';

function StaffManagements(props) {
    const {
        history,
        staffs,
        getStaffs,
        setStaff,
        deleteStaff,
        actionLoading,
        getSalesHistory,
    } = props;

    const [openStaffIndex, setOpenStaffIndex] = useState(null);
    const [search, setSearch] = useState(null);
    const [filterStaff, setFilterStaff] = useState([]);

    useEffect(() => {
        getStaffs();
    }, []);

    useEffect(() => {
        if (search != null && search != '') {
            let temp = staffs.data.filter((d) =>
                d.first_name.toLowerCase().includes(search.toLowerCase())
            );
            setFilterStaff(temp);
        } else {
            setFilterStaff(staffs.data);
        }
    }, [search]);

    useEffect(() => {
        setFilterStaff(staffs.data);
    }, [staffs]);

    const handleArrowBack = () => {
        history.push('/');
    };

    const handleAddEditStaff = (item) => {
        setStaff(item);
        history.push(`/dealer/addStaff`);
    };

    const handleDeleteStaff = (item) => {
        Swal.fire({
            title: 'Delete ' + item.first_name + ' ' + item.last_name + '?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteStaff(item.id);
            }
        });
    };

    const handleOpenStaffAction = (staff) => {
        if ('map' + staff.id === openStaffIndex) setOpenStaffIndex(null);
        else setOpenStaffIndex('map' + staff.id);
    };

    const IconSmallArrowRight = (props) => {
        return (
            <svg
                className={`arrow-icon ${
                    props.id === openStaffIndex && 'arrow-icon-active'
                }`}
                enableBackground="new 0 0 12 12"
                height="12px"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 32 32"
                width="32px"
            >
                <path d="M24.291,14.276L14.705,4.69c-0.878-0.878-2.317-0.878-3.195,0l-0.8,0.8c-0.878,0.877-0.878,2.316,0,3.194  L18.024,16l-7.315,7.315c-0.878,0.878-0.878,2.317,0,3.194l0.8,0.8c0.878,0.879,2.317,0.879,3.195,0l9.586-9.587  c0.472-0.471,0.682-1.103,0.647-1.723C24.973,15.38,24.763,14.748,24.291,14.276z" />
            </svg>
        );
    };

    const goToSalesHistory = () => {
        let temp = {
            archive: false,
            filter_status: false,
            orderby: 'newtoold',
        };
        getSalesHistory(temp);
        history && history.push('/salesHistory');
    };

    return (
        <div className="dealer">
            {staffs.loading && <Loader />}

            {actionLoading && <Loader />}

            <Header>
                <HeaderLeft>
                    <img
                        src={IconArrowLeft}
                        onClick={() => handleArrowBack()}
                        alt=""
                    />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="center-text">Staff Management</div>
                </HeaderCenter>
                <HeaderRight>
                    <img
                        src={IconAdd}
                        onClick={() => handleAddEditStaff({})}
                        alt=""
                    />
                </HeaderRight>
            </Header>

            {/* <div className="search-header">
                <form action="javascript:void(0)">
                    <Form.Group>
                        <Form.Control value={search} placeholder="Search" onChange={(e) => setSearch(e.target.value)}></Form.Control>
                    </Form.Group>
                </form>
            </div> */}

            <div className="main">
                <div className="list">
                    {filterStaff &&
                        filterStaff.map((item) => (
                            <>
                                <div
                                    key={item.id}
                                    className="single-row"
                                    onClick={() => handleOpenStaffAction(item)}
                                    style={{ position: 'relative' }}
                                >
                                    <div
                                        className={
                                            openStaffIndex === 'map' + item.id
                                                ? 'border-bottom-dealer-staff'
                                                : ''
                                        }
                                    ></div>
                                    <div
                                        className="dealer-row"
                                        style={{
                                            padding: 12,
                                            width: '95%',
                                            borderBottomWidth: '1px',
                                            borderBottom : '2px solid rgb(46, 73, 94)'
                                        }}
                                    >
                                        <Col xs={10}>
                                            <span
                                                className="dealer-name"
                                                style={{
                                                    background:
                                                        openStaffIndex ===
                                                        'map' + item.id
                                                            ? '#009d86'
                                                            : '#2d4a5f',
                                                    color:
                                                        openStaffIndex ===
                                                        'map' + item.id
                                                            ? '#fff'
                                                            : '#B2D8F7',
                                                }}
                                            >
                                                {item.first_name}{' '}
                                                {item.last_name}
                                            </span>
                                        </Col>
                                        <Col
                                            xs={2}
                                            className="dealer-action"
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignItems:
                                                    openStaffIndex ===
                                                    'map' + item.id
                                                        ? 'end'
                                                        : 'center',
                                                justifyContent: 'space-evenly',
                                            }}
                                        >
                                            <span
                                                className="staff_identifier"
                                                style={{ color: '#5080a0' }}
                                            >
                                                {item.role[0] === 'sales'
                                                    ? 'S'
                                                    : 'D'}
                                            </span>
                                            <span>
                                                {openStaffIndex ===
                                                'map' + item.id ? (
                                                    <img
                                                        src={CircuralActive}
                                                        alt="CircuralActive"
                                                    />
                                                ) : (
                                                    <img
                                                        src={CircuralInactive}
                                                        alt="CircuralInactive"
                                                    />
                                                )}
                                            </span>
                                        </Col>
                                    </div>
                                    {openStaffIndex === 'map' + item.id && (
                                        <Row
                                            key={item.id}
                                            className="single-row-details single-row-details-management"
                                            style={{
                                                margin: 'auto',
                                                // width: '380px',
                                                alignItems: 'center',
                                                padding: '0 20px',
                                                background : "#20313e"
                                            }}
                                        >
                                            <div
                                                // xs={7}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginRight : 15
                                                }}
                                            >
                                                {/* <button className="delete-staff" onClick={() => handleDeleteStaff(item)} style={{width : 30}}>
                                                <img src={IconDeleteNew} alt="" width={12} />
                                            </button> */}
                                                <div
                                                    style={{
                                                        width: 30,
                                                        height: 30,
                                                        background: '#9b1c4d',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                    onClick={() =>
                                                        handleDeleteStaff(item)
                                                    }
                                                >
                                                    <img
                                                        src={IconDeleteNew}
                                                        alt=""
                                                        width={12}
                                                    />
                                                </div>
                                                <button
                                                    className="edit"
                                                    onClick={() =>
                                                        handleAddEditStaff(item)
                                                    }
                                                >
                                                    Edit
                                                </button>
                                            </div>
                                            <div 
                                            // xs={5}
                                            >
                                                <button
                                                    className="edit"
                                                    style={{
                                                        backgroundColor:
                                                            'rgb(0, 157, 134)',
                                                    }}
                                                    onClick={goToSalesHistory}
                                                >
                                                    My Sales
                                                </button>
                                            </div>
                                        </Row>
                                    )}
                                </div>
                            </>
                        ))}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    staffs: state.dealer.staffs,
    actionLoading: state.dealer.actionLoading,
});

const mapDispatchToProps = (dispatch) => ({
    getStaffs: () => dispatch(getStaffs()),
    setStaff: (data) => dispatch(setStaff(data)),
    deleteStaff: (id) => dispatch(deleteStaff(id)),
    getSalesHistory: (data) => dispatch(getSalesHistory(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffManagements);

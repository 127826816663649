import { Row, Col, Form, NavItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Dropdown from '../../../../../../components/commons/dropdown';
import {
    CalendarContainer,
    StatusChoice,
    StatusText,
    TierSelect,
} from '../../../../style';
import {
    CircleStatusIcon,
    HexagonStatusIcon,
    TriangleStatusIcon,
} from './StatusIcons';
import DatePicker from 'react-datepicker';
import { ToggleButton } from './ToggleButton/ToggleButton';
import { IconCalenderHeader } from '../../../../../../assets/images';
import {
    statusApproval,
    statusInProcess,
    statusRejection,
} from '../../../../constant';
import { updateProductTier, updateDate, updatePreApprovalStatus, getCreditApp, updateEarliestDateDelivery } from '../../../../../../redux/actions/admin';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';


function PreApprovalRequestCard(props) {

    const { 
        updateProductTier, 
        updateDate, 
        customer, 
        actionLoading, 
        updatePreApprovalStatus, 
        reviewMode = 'auto', 
        isDealerScreen = 0, 
        getCreditApp,
        funding_frequency,
        updateEarliestDateDelivery
    } = props;

    console.log(customer)
    const history = useHistory()

    const changedScheduledDate = useSelector((state) => state && state.admin && state.admin.data && state.admin.data.scheduled_date)
    const preProductId = useSelector((state) => state && state.admin && state.admin.data && state.admin.data.pre_product_id)

    console.log(changedScheduledDate)

    // useEffect(() => {
    //     setScheduledDate(changedScheduledDate)
    // },[changedScheduledDate])

    const tierOptions = [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },
    ];

    const [shceduledDate, setScheduledDate] = React.useState(null)

    const {
        food_tier: foodTier,
        food_review_mode: foodReviewMode,
        food_status: foodStatus,
        food_date: foodDate,
    } = customer;


    const isDisabled = (date) => {
        const day = date.getDay(date);
        return funding_frequency.includes(day);
     }


    const DateInput = ({ value, onChange }) => {
     
        return (
            <CalendarContainer className="calendar-approve">
                <DatePicker
                    selected={value}
                    onChange={onChange}
                    minDate={new Date()}
                    className="calendar-approve"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YY"
                    filterDate={funding_frequency && funding_frequency.length > 0 ? isDisabled : null}
                />
                <span className="calendar-badge">
                    <img src={IconCalenderHeader} alt="calendar" style={{ verticalAlign: 'super' }} />
                </span>
            </CalendarContainer>
        );
    }

    const handleDateChange = (date, id) => {
        setScheduledDate(date)
        const formatedDate = moment(date).format('DD/MM/YY');
        updateEarliestDateDelivery({ pre_product_id: id, scheduled_date: formatedDate }, isDealerScreen)
    };

    const changeItemActive = (customer, isActive) => {
        console.log(isActive)
        let temp = {
            "app_id" : customer.app_id,
            "credit_app_required": isActive == true ? false : true
        }
        if (isActive == false) {
            getCreditApp(temp);
        }
    };

    return (
        <div className="preapproval-request-card">
            {customer.products && customer.products.map((item) => {
                console.log('itemcustomerproduct',item)
                let date;
                if(shceduledDate) {
                     date = new Date(shceduledDate);
                }
                else if(item.id == preProductId && changedScheduledDate) {
                    date = new Date(changedScheduledDate)
                } else {
                     date = new Date(item.earliest_delivery_date);
                }
                console.log('date',date)
                return (
                    <div className="request-wrapper">
                        <div className="request-title">
                            <label>PRODUCT/SERVICE:&nbsp;</label>
                            <span className="product-name">{item.product}</span>
                        </div>
                        <div className="request-detail">
                            <Row style={{ textAlign: 'center' }}>
                                <Col>
                                    <TierSelect className="tier-input">
                                        <label> { item && item.product == 'Food' || item.product == 'Food/Mac' ? 'Food Tier:' : 'Other Tier:'} </label>
                                        <Dropdown
                                            value={foodTier}
                                            defaultValue={item.tier}
                                            options={tierOptions}
                                            handleChange={(e) => 
                                                updateProductTier({ product_id: item.id, product_tier: e,  pre_app_product  : true }, isDealerScreen)
                                            }
                                        />
                                    </TierSelect>
                                </Col>
                                { item && item.product == 'Food' || item.product == 'Food/Mac' &&
                                    <Col>
                                    <label>Earliest delivery date: </label>
                                    <DateInput
                                    value={!item.earliest_delivery_date && !shceduledDate ? null : date}
                                        onChange={(e) => handleDateChange(e, item.id)}
                                        defaultValue={updateDate}
                                    />
                                </Col>}
                            </Row>
                            <Row className="mt-3">
                                <Col onClick={() => updatePreApprovalStatus({ action: 'approve_preapproval', pre_product_id: item.id }, isDealerScreen)}>
                                    <StatusChoice>
                                        <CircleStatusIcon
                                            symbol="P"
                                            fill={reviewMode === 'auto'}
                                            disabled={item.product_status === 'approval' ? false : true}
                                        />
                                        <StatusText className="status-text">
                                            Approve
                                        </StatusText>
                                    </StatusChoice>
                                </Col>
                                <Col className="text-center" 
                                // onClick={() => updatePreApprovalStatus({ action: 'request_preapproval', app_id: customer.app_id })}
                                >
                                    <StatusChoice>
                                        <TriangleStatusIcon
                                            symbol="P"
                                            fill={reviewMode === 'auto'}
                                            disabled={item.product_status === 'in_process' ? false : true}
                                        />
                                        <StatusText className="status-text">
                                            Req.Review
                                        </StatusText>
                                    </StatusChoice>
                                </Col>
                                <Col className="text-right" onClick={() => updatePreApprovalStatus({ action: 'decline_preapproval', pre_product_id: item.id }, isDealerScreen)}>
                                    <StatusChoice>
                                        <HexagonStatusIcon
                                            symbol="P"
                                            fill={reviewMode === 'auto'}
                                            disabled={item.product_status === 'rejection' ? false : true}
                                        />
                                        <StatusText className="status-text">
                                            Decline
                                        </StatusText>
                                    </StatusChoice>
                                </Col>
                            </Row>
                            <div className="text-center mt-3 require-credit-app">
                                <label>Requires credit app?</label>
                                <ToggleButton className="ml-3"  
                                yesText={'Yes'}
                                            noText="No"
                                            // defaultChecked={}
                                            onChange={(isActive) => {
                                                changeItemActive(
                                                    customer,
                                                    isActive
                                                );
                                            }} />
                            </div>
                        </div>
                    </div>
                )
            })
            }
        </div>
    );
}

PreApprovalRequestCard.propTypes = {
    customer: PropTypes.object.isRequired,
    updateProductTier: PropTypes.func.isRequired,
    updateDate: PropTypes.func.isRequired,
    updatePreApprovalStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    updateProductTier: (data, page) => dispatch(updateProductTier(data, page)),
    updateDate: (data, page) => dispatch(updateDate(data, page)),
    updatePreApprovalStatus: (data, page) => dispatch(updatePreApprovalStatus(data, page)),
    getCreditApp: (data) => dispatch(getCreditApp(data)),
    updateEarliestDateDelivery: (data) => dispatch(updateEarliestDateDelivery(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PreApprovalRequestCard);



import React, { useEffect, useState } from 'react';
import { IconMenu, IconProfileEmptyUser } from '../../../assets/images';
import 'assets/css/side-menu.scss';
import { Dropdown } from 'react-bootstrap';

export function SideMenu(props) {
    const { history } = props;

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // console.log(localStorage.getItem('dealer_name'))

    const handleMenuClick = (e) => {
        e.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        console.log('sidebar')
    }, [])

    const logoutSupport = () => {
        window.localStorage.clear();
        window.sessionStorage.clear()
        window.location.reload();
    }

    const goToProfile = () => {
        window.location.href='/profile'
    }

    return (
        <>
            <img
                className="icon-menu"
                src={IconMenu}
                onClick={handleMenuClick}
                alt=""
            />
            {isMenuOpen && (
                <div className="nav-menu-overlay" onClick={handleMenuClick} />
            )}
            <nav className={isMenuOpen ? 'nav-menu active' : 'nav-menu'}>
                <div className="nav-menu-header">
                    <button
                        className="btn-menu-close"
                        onClick={handleMenuClick}
                    >
                        x
                    </button>
                    <div
                        className="center-align"
                        style={{ flexDirection: 'column' }}
                    >
                        {/* <img
                            className=""
                            src={IconProfileEmptyUser}
                            alt=""
                            width={55}
                        /> */}

                        {/* <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ marginTop: 10, backgroundColor: 'transparent', borderColor: 'transparent' }}>
                           
                                {sessionStorage.getItem('dealer_name') || localStorage.getItem('dealer_name')}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href='/profile'>Profile</Dropdown.Item>
                                <Dropdown.Item onClick={logoutSupport}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        {/* <select style={{ marginTop: 10 }}>
                            <option>hi</option>
                        </select> */}
                          <div className='center-align' style={{ flexDirection: 'column', cursor : 'pointer' }} 
                          onClick={goToProfile}
                          >
                        <img
                            className=""
                            src={IconProfileEmptyUser}
                            alt=""
                            width={55}
                        />
                        <label style={{ marginTop: 10, cursor : 'pointer' }}> {sessionStorage.getItem('dealer_name') || localStorage.getItem('dealer_name')}</label>
                    </div>
                    </div>
                </div>
                <div className="nav-menu-content">
                    <ul className="nav-menu-items">
                        <li >
                            <a href="/dealer/staff" style={{ color: 'white' }}>Staff Management</a>
                        </li>
                        <li>
                            <a href="/sales" style={{ color: 'white' }}>Sales App</a>
                        </li>
                        {sessionStorage.getItem('impersonatedDealerToken') &&
                            localStorage.getItem('role') === 'sales,dealer,admin' && (
                                <li>
                                    <a onClick={() => {
                                        sessionStorage.removeItem('impersonatedDealerToken');
                                        // window.location.assign('/')
                                        window.close();
                                    }}
                                        className="exit-text-color"
                                    >Exit</a>
                                </li>
                            )}
                    </ul>
                    <span onClick={logoutSupport}  style={{position : 'absolute', bottom : 26, borderTop : 'solid 3px #3a5b77', width : '100%', paddingTop : 10, paddingLeft : 56, fontSize : 14, cursor : 'pointer'}}><span style={{position : 'relative', top : 6, color : "#fff"}}>Logout</span></span>
                </div>
            </nav>
        </>
    );
}

import React from 'react'


export const TCPLogo = require('./images/TCPlogo.svg');
export const IconHome2 = require('./images/icon-home.svg');

export const IconProfile = require('./images/icon-profile.svg');
export const IconProfileActive = require('./images/icon-profile-active.png');
export const IconProfileEmptyUser = require('./images/icon-profile-user-empty.svg');
export const IconLogout = require('./images/icon-logout.svg');

export const IconContactAcitve = require('./images/icon-contact-active.svg');
export const IconContactInAcitve = require('./images/icon-contact-inactive.svg');
export const IconSummaryActive = require('./images/icon-summary-active.svg');
export const IconSummaryInActive = require('./images/icon-summary-inactive.svg');

export const IconCustomerNew = require('./images/icon-customer-new.svg');
export const IconCustomerEdit = require('./images/icon-customer-edit.svg');
export const IconCustomerReorder = require('./images/icon-customer-reorder.svg');
export const IconCustomerPre = require('./images/icon-customer-prequalify.svg');
export const IconCustomerHistory = require('./images/icon-customer-prequalify.svg');

export const IconDelete = require('./images/icon-delete.svg');
export const IconEnvelops = require('./images/icon-envelopes.svg');

export const IconStatusWaiting = require('./images/icon-status-waiting.svg');
export const HourGlassSvg = require('./images/hourglass.svg');
export const IconStatusConfirmed = require('./images/icon-status-confirmed.svg');

export const iconPaid = require('./images/icon-paid.svg');
export const iconNotify = require('./images/icon-notify.svg');
export const IconSmallArrowRight = require('./images/icon-small-right-arrow.svg');
export const IconSortInActive = require('./images/icon-sort-inactive.svg');
export const IconSortActive = require('./images/icon-sort-active.svg');
export const IconSearchInActive = require('./images/icon-search-inactive.svg');
export const IconSearchActive = require('./images/icon-search-active.svg');
export const IconFilterInActive = require('./images/icon-filter-inactive.svg');
export const IconFilterActive = require('./images/icon-filter-active.svg');
export const IconAwesomePaperPlaneSend = require('./images/Icon-awesome-paper-plane-send.svg');
export const IconAwesomePaperPlane = require('./images/Icon-awesome-paper-plane.svg');
export const IconAwesomePenFancy = require('./images/Icon-awesome-pen-fancy.svg');
export const IconAwesomePenFancyRight = require('./images/Icon-awesome-pen-fancy-right.svg');
export const IconEnvelopeClosed = require('./images/Icon-envelope-closed.svg');
export const IconEnvelopeOpen = require('./images/Icon-envelope-open.svg');
export const IconCaledar = require('./images/Icon-metro-calendar.svg');

export const IconFixIssueAwesomePaperPlane = require('./images/fix-issues/Icon-awesome-paper-plane.svg');
export const IconFixIssueEnvelopeOpen = require('./images/fix-issues/Icon-open-envelope.svg');
export const IconFixIssueAwesomePenFancy = require('./images/fix-issues/Icon-awesome-pen-fancy-writen.svg');
export const IconFixIssueEnvelopeClosed = require('./images/fix-issues/Icon-closed-envelope.svg');
export const IconFixIssueAwesomePenFancyRight = require('./images/fix-issues/Icon-awesome-pen-fancy.svg');
export const IconFixIssueAwesomeSendRed = require('./images/fix-issues/Icon-awesome-paper-plane-red.svg');

export const IconDashboardSales = require('./images/icon-dashboard-sales.svg');
export const IconDashboardStaff = require('./images/icon-dashboard-staff.svg');
export const IconDashboardSalesApp = require('./images/icon-dashboard-cart.svg');
export const IconDashboardPending = require('./images/icon-dashboard-pending.svg');
export const IconDashboardDeclined = require('./images/icon-dashboard-declined.svg');
export const IconDashboardApproved = require('./images/icon-dashboard-approved.svg');
export const IconDashboardFunded = require('./images/icon-dashboard-funded.svg');
export const IconDashboardCanceled = require('./images/icon-dashboard-canceled.svg');
export const IconDashboardPreApprovals = require('./images/icon-dashboard-preapprovals.svg');
export const IconDashboardIncomplete = require('./images/icon-dashboard-incomplete.svg');

export const IconPreapprovalWaiting = require('./images/icon-preapproval-waiting.svg');
export const IconPreapprovalDeclined = require('./images/icon-preapproval-declined.svg');
export const IconTrash = require('./images/icons8-trash.svg');

export const UnderConstruction = require('./images/underconst.svg');

export const IconMenu = require('./images/icon-menu.svg');
export const IconCalenderHeader = require('./images/icon-calender.svg');
export const IconCancel = require('./images/icon-cancel.svg');
export const IconArrow = require('./images/icon-right.svg');
export const IconSearch = require('./images/icon-search.svg');
export const IconReload = require('./images/icon-reload.svg');
export const IconYellowP = require('./images/icon-yellowTriangleP.svg');
export const IconYellowS = require('./images/icon-yellowTriangleS.svg');
export const IconBorderGreen = require('./images/icon-borderedGreen.svg');
export const IconFilled = require('./images/icon-filledP.svg');
export const IconSignature = require('./images/icon-signature.svg');
export const IconComment = require('./images/icon-comment.svg');
export const IconCommentBg = require('./images/icon-commentbg.svg');
export const IconDoorClosed = require('./images/icon-door-closed.svg');
export const IconBgDown = require('./images/icon-bgDownArrow.svg');
export const IconBackArrow = require('./images/back.svg');
export const IconSideMenu = require('./images/icon-sidemenu.svg');
export const IconLeft = require('./images/icon-left.svg');
export const IconCalenderRight = require('./images/icon-calender-right.svg');
export const IconCalendarRightWhite = require('./images/icon-calender-right-white.svg');
export const IconColorDown = require('./images/icon-bgDown.svg');
export const IconGreenp = require('./images/icon-border-p.png');

export const CircularInactive = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.213"
            height="22.213"
            viewBox="0 0 22.213 22.213"
        >
            <g id="CircuralInactive" transform="translate(8692.946 783)">
                <circle
                    id="Ellipse_456"
                    data-name="Ellipse 456"
                    cx="11.106"
                    cy="11.106"
                    r="11.106"
                    transform="translate(-8692.946 -760.787) rotate(-90)"
                    fill="#3a5b77"
                />
                <path
                    id="Icon_feather-chevron-right"
                    data-name="Icon feather-chevron-right"
                    d="M1112.289,577.843a1,1,0,0,1-.707-1.707l3.794-3.792-3.794-3.793a1,1,0,0,1,1.414-1.414l4.5,4.5a1,1,0,0,1,0,1.414l-4.5,4.5A1,1,0,0,1,1112.289,577.843Z"
                    transform="translate(-9795.755 -1344.343)"
                    fill="#b2d8f7"
                />
            </g>
        </svg>
    );
};

export const IconAdd = require('./images/add.svg');
export const IconDeleteNew = require('./images/delete.svg');
export const IconDownArrow = require('./images/downArrow.svg');
export const IconRight = require('./images/right.svg');
export const IconList = require('./images/list.svg');
export const IconSend = require('./images/send.svg');
export const IconArrowLeft = require('./images/back.svg');
export const IconListWhite = require('./images/list-white.svg');
export const IconStatusComplete = require('./images/status-complete.svg');
export const IconStatusSend = require('./images/status-send.svg');
export const IconStatusSent = require('./images/status-sent.svg');
export const IconStatusSentSales = require('./images/sentMailSvg.svg');
export const IconHome = require('./images/home.svg');
export const IconEyeShow = require('./images/eye_show.svg');
export const IconEyeHide = require('./images/eye_hide.svg');
export const IconSelectCheckBox = require('./images/checked.png');
export const IconUpload = require('./images/upload.png');
export const IconUploadActive = require('./images/upload_active.png');
export const IconUnSelectCheckBox = require('./images/EmptyCircleSVG.svg');
export const IconPdf = require('./images/pdf.png');

export const IconChatBubble = require('./images/eye_show.svg');
export const IconFeatherCalendar = require('./images/eye_show.svg');
export const IconMetroCalendar = require('./images/eye_show.svg');
export const IconSearchAdmin = require('./images/icon-search-admin.svg');
export const greenIcon = require('./images/sign/green.png');
export const redIcon = require('./images/sign/red.png');
export const yellowIcon = require('./images/sign/yellow.png');
export const IconDoubleCheckIcon = require('./images/dobletick.svg');
export const IconSingleCheckIcon = require('./images/comment-single-tick.svg');
export const IconAwesomeCheckCircle = require('./images/icon-awesome-check-circle.svg');
export const IconKey = require('./images/icon-key.png');
export const IconSetting = require('./images/icon-setting.png');
export const ArchiveIconSvg = require('./images/ArchiveIcon.svg');
export const CheckMarkSalesHistoryTick = require('./images/CheckmarkSales.svg');
export const CheckMarkSalesHistoryTickWhite = require('./images/CheckmarkSalesWhtie.svg');
export const ArchiveIconOriginalSvg = require('./images/ArchiveIconOriginal.svg');
export const greenTickCircle = require('./images/greenCircle.svg');
export const statucCompleteEmailProduct = require('./images/status-complete-email-product.svg');
export const redCircleSalesScg = require('./images/redCircleSalesSvg.svg');
export const uploadArrowSvg = require('./images/ArrowUpSalesSlip.svg');
export const IconCogsvg = require('./images/IconCogSvg.svg');
export const keySvg = require('./images/keySVG.svg');
export const crossSvg = require('./images/crosSvg.svg')
export const yellowCirleNoBg = require('./images/yellowCircleNobg.svg')
export const preApprovalArrow = require('./images/preApprovalArrow.svg')
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { pushNotification } from 'utils/notification';
import { dropdownList } from 'shared/constant';
import { Modal } from 'react-bootstrap';
import Header from '../../../components/Sales/Header';
import { TCPLogo, IconHome, IconContactAcitve, IconArrowLeft, IconEyeShow, IconEyeHide } from '../../../assets/images';
import justChevron from '../../../assets/images/justChevron.svg'
import Input from '../../../components/commons/input';
import Dropdown from '../../../components/commons/dropdown';
import Checkbox from '../../../components/commons/checkbox';
import Loader from 'shared/Loader';

import { getFromData } from '../../../components/commons/utility';
import { updateApplicationFilledStatus, updateCustomer, resetCustomerSearchApiInitiate, submitCustomreBasicDetails } from '../../../redux/actions/sales';

function AddDealer(props) {

    const {
        history,
        customer,
        isCustomerFound,
        updateCustomer,
        resetCustomerSearchApiInitiate,
        submitCustomreBasicDetails,
        actionLoading,
        updateApplicationFilledStatus
    } = props;

    console.log('props', props)

    const basicDetailForm = useRef(null);

    const [editWarning, setEditWarning] = useState(false);
    // const [data, setData] = useState(props.customer.length > 0 ? [...data, customer]: [])
    const [data, setData] = useState(props.customer);
    const [editWarningConfirmation, setEditWarningConfirmation] = useState(false);
    const [ssnHidden, setSsnHidden] = useState(true);
    const [coSsnHidden, setCoSsnHidden] = useState(true);

    const [tempStreet, setTempStreet] = useState(customer ? customer.main_app.street : null);
    const [tempCity, setTempCity] = useState(customer ? customer.main_app.city : null);
    const [tempState, setTempState] = useState(customer ? customer.main_app.state : null);
    const [tempZip, setTempZip] = useState(customer ? customer.main_app.zip : null);

    const [coTempStreet, setCoTempStreet] = useState(customer && customer.co_enabled ? customer.co_app.street : null);
    const [coTempCity, setCoTempCity] = useState(customer && customer.co_enabled ? customer.co_app.city : null);
    const [coTempState, setCoTempState] = useState(customer && customer.co_enabled ? customer.co_app.state : null);
    const [coTempZip, setCoTempZip] = useState(customer && customer.co_enabled ? customer.co_app.zip : null);

    const [validationResult, setValidationResult] = useState(null);
    const [haveCoApplicant, setHaveCoApplicant] = useState(customer.co_enabled ? customer.co_enabled : false);
    const [haveCoApplicantSameAddress, setHaveCoApplicantSameAddress] = useState(customer.co_enabled && customer.co_app.co_have_co_applicant_same_address ? customer.co_app.co_have_co_applicant_same_address : false);

    const updateCoApplicantAddress = (check) => {
        setCoTempStreet(tempStreet);
        setCoTempCity(tempCity);
        setCoTempState(tempState);
        setCoTempZip(tempZip);
        setHaveCoApplicantSameAddress(check);
    }

    const handleArrowBack = () => {
        if (editWarning) {
            setEditWarningConfirmation(true)
        } else {
            // history.replace('/applyCustomerSearch');
            history.goBack()
        }
    }

    const showHideCoInputField = (check) => {
        setHaveCoApplicant(check)
    }

    const handleChange = (e) => {
        if (!e.target) {
            console.log('dropdown', e, tempState, coTempState)
        } else {
            console.log(e)
        }
        const { name, value } = e.target;
        console.log(name, value);
        // Object.keys(data).forEach(key => {

        //     )
        setData({ ...data, [name]: value });
        console.log(data)
    }

    const saveAndExitHandler = () => {
        console.log('state', data)
        let date = customer.main_app.date_of_birth ? new Date(customer.main_app.date_of_birth).getDate() : new Date(data.date_of_birth).getDate();
        let month = customer.main_app.date_of_birth ? new Date(customer.main_app.date_of_birth).getMonth() + 1 : new Date(data.date_of_birth).getMonth() + 1;
        console.log(date, month)

        let co_date = null;
        let co_month = null;

        if (haveCoApplicant) {
            co_date = customer.co_app.date_of_birth ? new Date(customer.co_app.co_date_of_birth).getDate() : new Date(data.co_date_of_birth).getDate();
            co_month = customer.co_app.date_of_birth ? new Date(customer.co_app.date_of_birth).getMonth() + 1 : new Date(data.co_date_of_birth).getMonth() + 1;
        }
        console.log(data)
        console.log(customer)
        // "main_app": {
        //     "id": "1655",
        //     "name": "Glen Genness",
        //     "first_name": "Glen",
        //     "last_name": "Genness",
        //     "email": "gleng1234@comcast.net",
        //     "dobY": "1999",
        //     "dobM": "10",
        //     "dobD": "11",
        //     "ssn": "054-74-6611",
        //     "driver_license": "78654345676543",
        //     "no_of_dependents": "1",
        //     "cell_phone": "6037597991",
        //     "home_phone": "",
        //     "street": "14 sherman dr",
        //     "city": "Raymond",
        //     "state": "NH",
        //     "zip": "03077-1858",
        //     "years_there_first": "1",
        //     "own_or_rent": "Rent",
        //     "employement_status": "Not employed",
        //     "present_employer": "",
        //     "years_there_second": "",
        //     "job_title": "",
        //     "employer_phone": "",
        //     "monthly_income": "",
        //     "additional_income": "",
        //     "source": "No",
        //     "additional_income_status": "yes",
        //     "landlord_mortgage_holder": "",
        //     "monthly_rent_mortgage_payment": "1",
        //     "cif_number": 1,
        //     "nortridge_cif_number": "15501"
        // },
        // "co_app": {
        //     "id": "1656",
        //     "name": "Kenneth Sweeney",
        //     "first_name": "Kenneth",
        //     "last_name": "Sweeney",
        //     "email": null,
        //     "dobY": "1999",
        //     "dobM": "10",
        //     "dobD": "10",
        //     "ssn": "456-76-5456",
        //     "driver_license": "56787656787676",
        //     "no_of_dependents": "1",
        //     "cell_phone": "7876765678",
        //     "home_phone": "",
        //     "street": "14 sherman dr",
        //     "city": "Raymond",
        //     "state": "NH",
        //     "zip": "03077-1858",
        //     "years_there_first": "1",
        //     "own_or_rent": "Rent",
        //     "employement_status": "Not employed",
        //     "present_employer": "",
        //     "years_there_second": "",
        //     "job_title": "",
        //     "employer_phone": "",
        //     "monthly_income": "",
        //     "additional_income": "",
        //     "source": "",
        //     "additional_income_status": "no",
        //     "landlord_mortgage_holder": "",
        //     "monthly_rent_mortgage_payment": "1",
        //     "cif_number": 1,
        //     "nortridge_cif_number": "15501"
        // },
        // "co_enabled": true,
        // "invite_status": "COMPLETED",
        // "nortridge_cif_number": "15501"
        let temp_customer = {
            ...data,
            "main_app": {
                ...data.main_app,
                "name": `${data && data.first_name ? data.first_name : customer.main_app.first_name} ${data && data.last_name ? data.last_name : customer.main_app.last_name}`,
                "first_name": data && data.first_name ? data.first_name : customer.main_app.first_name,
                "last_name": data && data.last_name ? data.last_name : customer.main_app.last_name,
                "email": data && data.email ? (data.email || "") : (customer.main_app.email || ""),
                "dobY": new Date(customer.main_app.date_of_birth || data.date_of_birth).getFullYear(),
                "dobM": month < 9 ? '0' + month : month ? month : null,
                "dobD": date < 9 ? '0' + date : date ? date : null,
                "ssn": data && data.ssn ? (data.ssn || "") : (customer.main_app.ssn || ""),
                "driver_license": data && data.driver_license ? (data.driver_license || null) : (customer.main_app.driver_license || null),
                "no_of_dependents": data && data.no_of_dependents ? (data.no_of_dependents || null) : (customer.main_app.no_of_dependents || null),
                "cell_phone": data && data.cell_phone ? (data.cell_phone || null) : (customer.main_app.cell_phone || null),
                "home_phone": data && data.home_phone ? (data.home_phone || null) : (customer.main_app.home_phone || null),
                "street": data && data.street ? (data.street || null) : (customer.main_app.street || null),
                "city": data && data.city ? (data.city || null) : (customer.main_app.city || null),
                "state": tempState || customer.main_app.state || null,
                "zip": data && data.zip ? (data.zip || null) : (customer.main_app.zip || null),
                "id": customer.main_app.id || null,
            },
            "co_enabled": haveCoApplicant,
            "co_app": {
                ...data.co_app,
                "name": haveCoApplicant ? `${data && data.co_first_name ? data.co_first_name : customer.co_app.first_name} ${data && data.co_last_name ? data.co_last_name || "" : customer.co_app.last_name || ""}` : null,
                "first_name": haveCoApplicant ? data && data.co_first_name ? data.co_first_name : customer.co_app.first_name : "",
                "last_name": haveCoApplicant ? data && data.co_last_name ? data.co_last_name : customer.co_app.last_name : "",
                "email": haveCoApplicant ? data && data.co_email ? (data.co_email || "") : (customer.co_app.email || "") : "",
                "dobY": haveCoApplicant ? new Date(data.co_date_of_birth || data.co_date_of_birth).getFullYear() : null,
                "dobM": haveCoApplicant ? (co_month < 9 ? "0" + co_month : (co_month ? co_month : null)) : null,
                "dobD": haveCoApplicant ? (co_date < 9 ? "0" + co_date : (co_date ? co_date : null)) : null,
                "co_have_co_applicant_same_address": haveCoApplicantSameAddress ? data && data.co_have_co_applicant_same_address ? data.co_have_co_applicant_same_address : customer.co_app.co_have_co_applicant_same_address : null,
                "ssn": haveCoApplicant ? data && data.co_ssn ? (data.co_ssn || "") : (customer.co_app.ssn || "") : "",
                "driver_license": haveCoApplicant ? data && data.co_driver_license ? (data.co_driver_license || null) : (customer.co_app.driver_license || null) : null,
                "no_of_dependents": haveCoApplicant ? data && data.co_no_of_dependents ? (data.co_no_of_dependents || 0) : (customer.co_app.no_of_dependents || 0) : null,
                "cell_phone": haveCoApplicant ? data && data.co_cell_phone ? (data.co_cell_phone || null) : (customer.co_app.cell_phone || null) : null,
                "home_phone": haveCoApplicant ? data && data.co_home_phone ? (data.co_home_phone || null) : (customer.co_app.home_phone || null) : null,
                "street": haveCoApplicant ? (data.co_have_co_applicant_same_address == 'on' ? (data.co_street && data.co_street.length > 0 ? data.co_street : (data.street || customer.main_app.street)) : data.co_street && data.co_street.length > 0 ? data.co_street : data.co_app.street && data.co_app.street.length > 0 ? data.co_app.street : null) : null,
                "city": haveCoApplicant ? (data.co_have_co_applicant_same_address == 'on' ? (data.co_city && data.co_city.length > 0 ? data.co_city : (data.city || customer.main_app.city)) : data.co_city && data.co_city.length > 0 ? data.co_city : data.co_app.city && data.co_app.city.length > 0 ? data.co_app.city : null) : null,
                "state": coTempState || customer.co_app.state || null,
                "zip": haveCoApplicant ? (data.co_have_co_applicant_same_address == 'on' ? (data.co_zip && data.co_zip.length > 0 ? data.co_zip : (data.zip || customer.main_app.zip)) : data.co_zip && data.co_zip.length > 0 ? data.co_zip : data.co_app.zip && data.co_app.zip.length > 0 ? data.co_app.zip : null) : null,
                "id": customer.co_app.id || null,
            },
            "action": "save&exit"
        }

        console.log(temp_customer)
        console.log(customer)

        // if(true) {
        //     temp_customer = {
        //         ...temp_customer,
        //         "main_app": {
        //             ...temp_customer.main_app,
        //             "id": customer.main_app.id || null
        //         },
        //         "co_app": {
        //             ...temp_customer.co_app,
        //             "id": haveCoApplicant ? customer.co_app.id : null
        //         }
        //     }
        // }

        console.log(temp_customer)

        submitCustomreBasicDetails(history, temp_customer);

        // updateCustomer(history, '/applyApplicationHomeDetails', temp_customer)
        // updateApplicationFilledStatus('in_app', null, null);
    }

    const handleSubmit = evt => {

        evt.preventDefault();
        const formData = getFromData(evt);

        console.log(formData.validationResult)

        setValidationResult(formData.validationResult);

        if (!formData.validationResult) {

            let data = formData.formData
            console.log('data', data)

            let date = new Date(data.date_of_birth).getDate();
            let month = new Date(data.date_of_birth).getMonth() + 1;

            let co_date = null;
            let co_month = null;

            if (haveCoApplicant) {
                co_date = new Date(data.co_date_of_birth).getDate();
                co_month = new Date(data.co_date_of_birth).getMonth() + 1;
            }

            let temp_customer = {
                ...customer,
                "main_app": {
                    ...customer.main_app,
                    "name": data.first_name + " " + data.last_name,
                    "first_name": data.first_name,
                    "last_name": data.last_name,
                    "email": data.email,
                    "dobY": new Date(data.date_of_birth).getFullYear(),
                    "dobM": month > 9 ? month : "0" + month,
                    "dobD": date > 9 ? date : "0" + date,
                    "ssn": data.ssn,
                    "driver_license": data.driver_license,
                    "no_of_dependents": data.no_of_dependents,
                    "cell_phone": data.cell_phone,
                    "home_phone": customer.main_app.home_phone,
                    "street": data.street,
                    "city": data.city,
                    "state": data.state,
                    "zip": data.zip
                },
                "co_enabled": haveCoApplicant,
                "co_app": {
                    ...customer.co_app,
                    "name": haveCoApplicant ? data.co_first_name + " " + data.co_last_name : null,
                    "first_name": haveCoApplicant ? data.co_first_name : null,
                    "last_name": haveCoApplicant ? data.co_last_name : null,
                    "email": haveCoApplicant ? data.co_email : null,
                    "dobY": haveCoApplicant ? new Date(data.co_date_of_birth).getFullYear() : null,
                    "dobM": haveCoApplicant ? co_month > 9 ? co_month : "0" + co_month : null,
                    "dobD": haveCoApplicant ? co_date > 9 ? co_date : "0" + co_date : null,
                    "co_have_co_applicant_same_address": haveCoApplicantSameAddress ? data.co_have_co_applicant_same_address : null,
                    "ssn": haveCoApplicant ? data.co_ssn : null,
                    "driver_license": haveCoApplicant ? data.co_driver_license : null,
                    "no_of_dependents": haveCoApplicant ? data.co_no_of_dependents : null,
                    "cell_phone": haveCoApplicant ? data.co_cell_phone : null,
                    "home_phone": haveCoApplicant ? customer.co_app.home_phone : null,
                    "street": haveCoApplicant ? data.co_street : null,
                    "city": haveCoApplicant ? data.co_city : null,
                    "state": haveCoApplicant ? data.co_state : null,
                    "zip": haveCoApplicant ? data.co_zip : null
                }
            }

            if (isCustomerFound) {
                temp_customer = {
                    ...temp_customer,
                    "main_app": {
                        ...temp_customer.main_app,
                        "id": customer.main_app.id
                    },
                    "co_app": {
                        ...temp_customer.co_app,
                        "id": haveCoApplicant ? customer.co_app.id : null
                    }
                }
            }

            console.log(temp_customer)


            updateCustomer(history, '/applyApplicationHomeDetails', temp_customer)
            updateApplicationFilledStatus('in_app', null, null);
        }
        else {
            let data = Object.values(formData.validationResult).map((item) => item['error'])
            if (data.includes('invalid')) {
                pushNotification('The fields marked in Red need to be filled with appropriate data.', 'error', 'TOP_RIGHT', 3000);
            } else {
                pushNotification('You must complete all fields in order to proceed.', 'error', 'TOP_RIGHT', 3000);
            }
        }
    }

    return (
        <div className="sales">

            {actionLoading && <Loader />}

            <Header isHome={true} history={history} >
                <img src={IconArrowLeft} alt="home" className="icon-logo" onClick={() => handleArrowBack()} style={{width : 18, height : 18}} />
                <img src={TCPLogo} alt="logo" className="main-logo" />
            </Header>

            <Modal show={editWarningConfirmation} onHide={() => setEditWarningConfirmation(false)}>
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body> Would you like to exit this session? Your changes have been saved </Modal.Body>
                <Modal.Footer>
                    <button class="secondary" onClick={() => {
                        // setEditWarningConfirmation(false);
                        // resetCustomerSearchApiInitiate(false)
                        // history.replace('/applyCustomerSearch');
                        saveAndExitHandler();
                    }}>
                        Yes
                    </button>
                    <button class="secondary" onClick={() => {
                        setEditWarningConfirmation(false);
                        resetCustomerSearchApiInitiate(false)
                        // history.replace('/applyCustomerSearch');    
                    }}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>

            <div className="sub-header">
                <button className="active">
                    <img src={IconContactAcitve} alt=""  style={{position : 'relative', right : 10}}/>
                    <span>Applicant(s) Details</span>
                    <span className='arrow-down'></span>
                </button>
            </div>

            <form action="javascript:void(0)" onSubmit={(e) => handleSubmit(e)} noValidate>
                {customer.main_app.id &&
                    <Input
                        name="id"
                        type="hidden"
                        value={customer.main_app.id}
                    />
                }
                <div className="container app-basic-detail-scroll">
                    <div className="styled-form sales-applicationDetail-form">
                        <Form.Group className="mb-18">
                            <Input
                                name="first_name"
                                type="text"
                                defaultValue={customer.main_app.first_name}
                                label="Applicant First Name"
                                defaultText="Applicant First Name"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                optionalParams={{
                                    autoFocus: true
                                }}
                                validationResult={validationResult}
                                handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="last_name"
                                type="text"
                                defaultValue={customer.main_app.last_name}
                                label="Applicant Last Name"
                                defaultText="Applicant Last Name"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                                handleChange={(e) => { setEditWarning(true); handleChange(e) }}

                            />
                        
                        <Form.Group className="mb-18">
                            <Input
                                name="email"
                                type="email"
                                regex="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
                                defaultValue={customer.main_app.email}
                                label="Email"
                                defaultText="Email"
                                required={true}
                                error={{
                                    'invalid': "Please enter valid Email address",
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                                handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="cell_phone"
                                type="hidden"
                                defaultValue={customer.main_app.cell_phone}
                                label="Phone"
                                className="medium-input"
                                defaultText="(123) 456-7890"
                                regex="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                                mask="(999) 999-9999"
                                required={true}
                                error={{
                                    'invalid': "Please enter 10 digit number",
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                                handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            {/* <Input
                                name="date_of_birth"
                                type="hidden"
                                defaultValue={customer.main_app.dobY && customer.main_app.dobM && customer.main_app.dobD ? `${customer.main_app.dobY}-${customer.main_app.dobM}-${customer.main_app.dobD}` : null}
                                    label="Date of Birth"
                                className="medium-input"
                                defaultText="MM / DD / YYYY"
                                required={true}
                                isDate={true}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                            /> */}
                            <Input
                                name="date_of_birth"
                                type="hidden"
                                defaultValue={customer.main_app.dobY && customer.main_app.dobM && customer.main_app.dobD ? `${customer.main_app.dobM}/${customer.main_app.dobD}/${customer.main_app.dobY}` : null}
                                label="Date of Birth"
                                className="medium-input"
                                defaultText="MM / DD / YYYY"
                                required={true}
                                regex="^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$"
                                mask="99/99/9999"
                                error={{
                                    'empty': " ",
                                    'invalid': "Please enter valid date",
                                    'ageError': "The applicant should be 18 years old or above"
                                }}
                                validationResult={validationResult}
                                handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18" style={{ position: "relative" }}>
                            <Input
                                name="ssn"
                                type="hidden"
                                label="SSN"
                                defaultText="#"
                                defaultValue={customer.main_app.ssn ? customer.main_app.ssn : null}
                                regex={customer.main_app.id ? customer.ssn : '^(?!0{3}|6{3}|9[0-9]{2})[0-9]{3}-(?!0{2})[0-9]{2}-(?!0{4})[0-9]{4}$'}
                                mask="999-99-9999"
                                optionalParams={{ style: { color: ssnHidden ? "transparent" : "white", caretColor: "#ccdbe7" } }}
                                isHidden={ssnHidden}
                                required={true}
                                isMatched={customer.main_app.id ? true : false}
                                error={{
                                    'empty': " ",
                                    'invalid': "Please enter correct SSN number"
                                }}
                                validationResult={validationResult}
                                handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                            />
                            {!isCustomerFound &&
                                <img className="ssn-eye" src={ssnHidden ? IconEyeShow : IconEyeHide} alt="" onClick={() => setSsnHidden(!ssnHidden)} />
                            }
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="driver_license"
                                type="hidden"
                                defaultValue={customer.main_app.driver_license ? customer.main_app.driver_license : null}
                                label="Driver License Number"
                                defaultText="#"
                                mask="**************"
                                maskChar=" "
                                // required={true}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                                handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="no_of_dependents"
                                type="text"
                                defaultValue={customer.main_app.no_of_dependents}
                                label="Number of Dependants"
                                defaultText="#"
                                regex="^[0-9]{1,1}$"
                                className="small-input"
                                required={true}
                                error={{
                                    'empty': " ",
                                    'invalid': "Please enter valid number"
                                }}
                                validationResult={validationResult}
                                handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                            />
                        </Form.Group>
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="street"
                                type="text"
                                defaultValue={tempStreet}
                                label="Street"
                                defaultText="Street"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                                handleChange={(e) => {
                                    setTempStreet(e.target.value)
                                    setEditWarning(true)
                                    handleChange(e)
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-18">
                            <Input
                                name="city"
                                type="text"
                                defaultValue={tempCity}
                                label="City"
                                defaultText="City"
                                required={true}
                                error={{
                                    'empty': " "
                                }}
                                validationResult={validationResult}
                                handleChange={(e) => {
                                    setTempCity(e.target.value)
                                    setEditWarning(true)
                                    handleChange(e)
                                }}
                            />
                        </Form.Group>
                        <div className="styled-row">
                            <Form.Group className="styled-column mb-18">
                                <Dropdown
                                    name="state"
                                    type="dropdown"
                                    defaultValue={tempState}
                                    label="State"
                                    defaultText="State"
                                    required={true}
                                    options={dropdownList}
                                    error={{
                                        'empty': " "
                                    }}
                                    validationResult={validationResult}
                                    handleChange={(e) => {
                                        setTempState(e)
                                        setEditWarning(true)
                                        // handleChange(e)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="styled-column mb-18">
                                <Input
                                    name="zip"
                                    type="text"
                                    defaultValue={tempZip}
                                    regex="^\d{5}(?:-\d{4})?$"
                                    label="Zip Code"
                                    defaultText="Zip Code"
                                    required={true}
                                    error={{
                                        'invalid': " ",
                                        'empty': " "
                                    }}
                                    isZipcode={true}
                                    validationResult={validationResult}
                                    handleChange={(e) => {
                                        setTempZip(e.target.value)
                                        setEditWarning(true)
                                        handleChange(e)
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-18">
                            <Checkbox
                                name="have_co_applicant"
                                type="checkbox"
                                label="Add co-applicant" 
                                inputClass="regular-radio"
                                checked={haveCoApplicant ? true : null}
                                handleChange={(e) => {
                                    showHideCoInputField(e.target.checked)
                                    setEditWarning(true)
                                    // handleChange(e)
                                }}
                                coApplicantRadio={true}
                                // afterLeftPosition={true}
                            />
                        </Form.Group>

                        {haveCoApplicant &&

                            <>

                                <span className="divider">
                                    <span className="title">Co-applicant</span>
                                </span>

                                <Form.Group className="mb-18">
                                    <Input
                                        name="co_first_name"
                                        type="text"
                                        defaultValue={customer.co_app.first_name}
                                        label="Co-applicant First Name"
                                        defaultText="Co-applicant First Name"
                                        required={haveCoApplicant ? true : false}
                                        error={{
                                            'empty': " "
                                        }}
                                        validationResult={validationResult}
                                        handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-18">
                                    <Input
                                        name="co_last_name"
                                        type="text"
                                        defaultValue={customer.co_app.last_name}
                                        label="Co-applicant Last Name"
                                        defaultText="Co-applicant Last Name"
                                        required={haveCoApplicant ? true : false}
                                        error={{
                                            'empty': " "
                                        }}
                                        validationResult={validationResult}
                                        handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                    />
                                </Form.Group>
                               
                                <Form.Group className="mb-18">
                                    <Input
                                        name="co_email"
                                        type="email"
                                        regex="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
                                        defaultValue={customer.co_app.email}
                                        label="Email"
                                        defaultText="Email"
                                        required={false}
                                        error={{
                                            'invalid': "Please enter valid Email address"
                                        }}
                                        validationResult={validationResult}
                                        handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-18">
                                    <Input
                                        name="co_cell_phone"
                                        type="hidden"
                                        defaultValue={customer.co_app.cell_phone}
                                        label="Phone"
                                        className="medium-input"
                                        defaultText="(123) 456-7890"
                                        regex="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                                        mask="(999) 999-9999"
                                        required={haveCoApplicant ? true : false}
                                        error={{
                                            'invalid': "Please enter 10 digit number",
                                            'empty': " "
                                        }}
                                        validationResult={validationResult}
                                        handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-18">
                                    {/* <Input
                                    name="co_date_of_birth"
                                    type="hidden"
                                    defaultValue={customer.co_app.dobY && customer.co_app.dobM && customer.co_app.dobD ? `${customer.co_app.dobY}-${customer.co_app.dobM}-${customer.co_app.dobD}` : null}
                                    label="Date of Birth"
                                    className="medium-input"
                                    defaultText="MM / DD / YYYY"
                                    isDate={true}
                                    required={haveCoApplicant ? true : false}
                                    error={{
                                        'empty': " "
                                    }}
                                    validationResult={validationResult}
                                /> */}
                                    <Input
                                        name="co_date_of_birth"
                                        type="hidden"
                                        defaultValue={customer.co_app.dobY && customer.co_app.dobM && customer.co_app.dobD ? `${customer.co_app.dobM}/${customer.co_app.dobD}/${customer.co_app.dobY}` : null}
                                        label="Date of Birth"
                                        className="medium-inpu/${customer.co_app.dobY}t"
                                        defaultText="MM / DD / YYYY"
                                        required={true}
                                        regex="^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$"
                                        mask="99/99/9999"
                                        error={{
                                            'empty': " ",
                                            'invalid': "Please enter valid date",
                                            'ageError': "The applicant should be 18 years old or above"
                                        }}
                                        validationResult={validationResult}
                                        handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-18" style={{ position: "relative" }}>
                                    <Input
                                        name="co_ssn"
                                        type="hidden"
                                        label="SSN"
                                        defaultText="#"
                                        defaultValue={customer.main_app.ssn && haveCoApplicant ? customer.co_app.ssn : null}
                                        regex={customer.main_app.id && haveCoApplicant ? customer.ssn : '^(?!0{3}|6{3}|9[0-9]{2})[0-9]{3}-(?!0{2})[0-9]{2}-(?!0{4})[0-9]{4}$'}
                                        mask="999-99-9999"
                                        optionalParams={{ style: { color: coSsnHidden ? "transparent" : "white", caretColor: "#ccdbe7" } }}
                                        isHidden={coSsnHidden}
                                        required={haveCoApplicant ? true : false}
                                        isMatched={customer.main_app.id && haveCoApplicant ? true : false}
                                        error={{
                                            'empty': " ",
                                            'invalid': "Please enter correct SSN number"
                                        }}
                                        validationResult={validationResult}
                                        handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                    />
                                    {!isCustomerFound &&
                                        <img className="ssn-eye" src={coSsnHidden ? IconEyeShow : IconEyeHide} alt="" onClick={() => setCoSsnHidden(!coSsnHidden)} />
                                    }
                                </Form.Group>
                                <Form.Group className="mb-18">
                                    <Input
                                        name="co_driver_license"
                                        type="hidden"
                                        defaultValue={customer.co_app.driver_license}
                                        label="Driver License Number"
                                        defaultText="#"
                                        mask="**************"
                                        maskChar=" "
                                        // required={haveCoApplicant ? true : false}
                                        error={{
                                            'empty': " "
                                        }}
                                        validationResult={validationResult}
                                        handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-18">
                                    <Input
                                        name="co_no_of_dependents"
                                        type="text"
                                        defaultValue={customer.co_app.no_of_dependents}
                                        label="Number of Dependants"
                                        defaultText="#"
                                        regex="^[0-9]{1,1}$"
                                        className="small-input"
                                        required={haveCoApplicant ? true : false}
                                        error={{
                                            'empty': " ",
                                            'invalid': "Please enter valid number"
                                        }}
                                        validationResult={validationResult}
                                        handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-18" style={{ marginTop: 35 }}>
                                    <Checkbox
                                        name="co_have_co_applicant_same_address"
                                        type="checkbox"
                                        label="Same as Applicant Address"
                                        checked={haveCoApplicantSameAddress ? true : null}
                                        handleChange={(e) => {
                                            updateCoApplicantAddress(e.target.checked)
                                            setEditWarning(true)
                                            handleChange(e)
                                        }}
                                        coApplicantRadio={true}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-18">
                                    <Input
                                        name="co_street"
                                        type="text"
                                        {...(haveCoApplicantSameAddress ? {
                                            value: tempStreet
                                        } : {
                                            defaultValue: coTempStreet
                                        })}
                                        label="Street"
                                        defaultText="Street"
                                        required={haveCoApplicant ? true : false}
                                        error={{
                                            'empty': " "
                                        }}
                                        validationResult={validationResult}
                                        handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-18">
                                    <Input
                                        name="co_city"
                                        type="text"
                                        {...(haveCoApplicantSameAddress ? {
                                            value: tempCity
                                        } : {
                                            defaultValue: coTempCity
                                        })}
                                        label="City"
                                        defaultText="City"
                                        required={haveCoApplicant ? true : false}
                                        error={{
                                            'empty': " "
                                        }}
                                        validationResult={validationResult}
                                        handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                    />
                                </Form.Group>
                                <div className="styled-row">
                                    <Form.Group className="styled-column mb-18">
                                        <Dropdown
                                            name="co_state"
                                            type="dropdown"
                                            defaultValue={coTempState}
                                            {...(haveCoApplicantSameAddress ? {
                                                value: tempState
                                            } : {
                                                value: coTempState
                                            })}
                                            label="State"
                                            defaultText="State"
                                            required={haveCoApplicant ? true : false}
                                            options={dropdownList}
                                            error={{
                                                'empty': " "
                                            }}
                                            validationResult={validationResult}
                                            handleChange={(e) => {
                                                setEditWarning(true);
                                                setCoTempState(e)
                                                // !haveCoApplicant ? setCoTempState(e) : null  
                                                // handleChange(e)
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group className="styled-column mb-18">
                                        <Input
                                            name="co_zip"
                                            type="text"
                                            {...(haveCoApplicantSameAddress ? {
                                                value: tempZip
                                            } : {
                                                defaultValue: coTempZip
                                            })}
                                            regex="^\d{5}(?:-\d{4})?$"
                                            label="Zip Code"
                                            defaultText="Zip Code"
                                            required={haveCoApplicant ? true : false}
                                            error={{
                                                'invalid': " ",
                                                'empty': " "
                                            }}
                                            isZipcode={true}
                                            validationResult={validationResult}
                                            handleChange={(e) => { setEditWarning(true); handleChange(e) }}
                                        />
                                    </Form.Group>
                                </div>
                            </>
                        }


                    </div>
                </div>
                <div className="footer-container">
                    <input ref={basicDetailForm} className="btn secondary next_btn_basic_detail clear-btn-sales" type="submit" value="Next"  />
                </div>
            </form>

        </div>
    )
}

const mapStateToProps = state => ({
    customer: state.sales.customer,
    isCustomerFound: state.sales.isCustomerFound,
    actionLoading: state.sales.actionLoading
});

const mapDispatchToProps = dispatch => ({
    updateCustomer: (history, path, data) => dispatch(updateCustomer(history, path, data)),
    resetCustomerSearchApiInitiate: () => dispatch(resetCustomerSearchApiInitiate()),
    updateApplicationFilledStatus: (data, history, path) => dispatch(updateApplicationFilledStatus(data, history, path)),
    submitCustomreBasicDetails: (history, data) => dispatch(submitCustomreBasicDetails(history, data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDealer);
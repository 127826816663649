import React, { useEffect, useState } from "react";
import { Form, Row, Col } from 'react-bootstrap';
import { IconYellowP, TCPLogo, IconSearch, IconArrowLeft, IconDashboardSalesApp, IconCancel, preApprovalArrow } from "assets/images";
import { Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import Header, { HeaderLeft, HeaderCenter, HeaderRight } from "../../../../components/Dealer/Header";
import { showSingleRowdata, getCustomerProfile, getPreApprovals, cancelPreapprovalItem } from "../../../../redux/actions/dealer";
import { getCustomerApiInitiateForGenerateOrder } from '../../../../redux/actions/sales';
import Loader from "shared/Loader";
import {
    CircleStatusIcon,
    HexagonStatusIcon,
    TriangleStatusIcon,
} from '../../../../components/commons/StatusIcons';


function PreApproval(props) {

    const {
        history,
        preaprovals,
        selectedRow,
        showSingleRowdata,
        getCustomerProfile,
        actionLoading,
        cancelPreapprovalItem,
        getPreApprovals,
        getCustomerApiInitiateForGenerateOrder
    } = props;

    console.log('preapprovalprops', props)

    const [showSearch, setShowSearch] = useState(null);
    const [search, setSearch] = useState(null);
    const [cancelWarning, setCancelWarning] = useState(null);
    const [filterCustomer, setFilterCustomer] = useState([]);

    console.log(filterCustomer)

    useEffect(() => {
        getPreApprovals(true);
        setCancelWarning(null);

        setInterval(function () {
            if (window.location.pathname === '/dealer/pre-approval') {
                getPreApprovals(false);
            }
        }, 120000);
    }, []);

    useEffect(() => {
        if (search != null && search !== '') {
            let temp = preaprovals.data.filter(d => d.customer_name.toLowerCase().includes(search.toLowerCase()))
            setFilterCustomer(temp);
        }
        else {
            setFilterCustomer(preaprovals.data);
        }
    }, [search]);


    useEffect(() => {
        setFilterCustomer(preaprovals.data);
    }, [preaprovals]);


    const handleArrowBack = () => {
        history.push({
            pathname: '/',
            search: '?tab=0'
        })
    }

    const handleOpenCustomerAction = (customer) => {
        if ('map' + customer.app_id === selectedRow)
            showSingleRowdata(null)
        else
            showSingleRowdata('map' + customer.app_id)
    };


    const IconSmallArrowRight = (props) => {
        return (
            <svg className={`arrow-icon ${props.app_id === selectedRow && "arrow-icon-active"}`} enableBackground="new 0 0 12 12" height="12px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="32px">
                <path d="M24.291,14.276L14.705,4.69c-0.878-0.878-2.317-0.878-3.195,0l-0.8,0.8c-0.878,0.877-0.878,2.316,0,3.194  L18.024,16l-7.315,7.315c-0.878,0.878-0.878,2.317,0,3.194l0.8,0.8c0.878,0.879,2.317,0.879,3.195,0l9.586-9.587  c0.472-0.471,0.682-1.103,0.647-1.723C24.973,15.38,24.763,14.748,24.291,14.276z" />
            </svg>
        )
    }

    const generateOrder = (data) => {

        let temp = {
            "customer_id": data.customer_id ? data.customer_id : null,
            "co_customer_id": data.co_customer_id ? data.co_customer_id : null,
            "include_products": false
        }
        localStorage.setItem('redirectionPath', window.location.pathname + window.location.search)
        getCustomerApiInitiateForGenerateOrder(temp, history, '/admin/applyApplicationSummary')
    }


    const getProductFoodStatus = (products) => {


        let p = products && products.length > 0 && products.filter(t => t.product === "Food/Mac" || t.product === "Food");

        console.log(p)

        if (p && p.length > 0 && p[0].product_status == "in_process") {
            return <>
                <TriangleStatusIcon
                    symbol={'P'}
                    fill={true}
                    disabled={false}
                />
                <span className="date" style={{ fontSize: 12, color: "#83b0d4" }}>{p[0].earliest_delivery_date != null ? p[0].earliest_delivery_date : 'N/A'}</span>
            </>
        }
        else if (p && p.length > 0 && p[0].product_status === "approval") {
            return <>
                <CircleStatusIcon
                    symbol={"P"}
                    fill={true}
                    disabled={false}
                />
                <span className="date" style={{ fontSize: 12, color: "#83b0d4" }}>{p[0].earliest_delivery_date != null ? p[0].earliest_delivery_date : 'N/A'}</span>
            </>
        }
        else {
            return <>
                <HexagonStatusIcon
                    symbol={'P'}
                    fill={true}
                    disabled={false}
                />
                <span className="date" style={{ fontSize: 10, color: "#83b0d4" }}>{p[0].earliest_delivery_date != null ? p[0].earliest_delivery_date : 'N/A'}</span>
            </>
        }
    }


    const getProductFSPStatus = (products) => {

        let p = products && products.length > 0 && products.filter(t => t.product === "Other Appliance");
        console.log(p)
        if (p && p.length > 0 && p[0].product_status == "in_process") {
            return <TriangleStatusIcon
                symbol={'P'}
                fill={true}
                disabled={false}
            />
        }
        else if (p && p.length > 0 && p[0].product_status == "approval") {
            return <CircleStatusIcon
                symbol={"P"}
                fill={true}
                disabled={false}
            />
        }
        else {
            return <HexagonStatusIcon
                symbol={'P'}
                fill={true}
                disabled={false}
            />
        }
    }

    return (
        <div className="dealer">

            {actionLoading && <Loader />}

        <div style={{position : 'sticky', top : 0, zIndex:2}}>
            <Header>
                <HeaderLeft>
                    <img src={IconArrowLeft} onClick={() => handleArrowBack()} alt="" />
                </HeaderLeft>
                <HeaderCenter>
                    <div className="header-main">
                        <img className="main-logo" src={TCPLogo} alt="" />
                    </div>
                </HeaderCenter>
                <HeaderRight>
                    <img src={IconSearch} alt="search" onClick={() => setShowSearch(!showSearch)} />
                </HeaderRight>
            </Header>
            </div>
            <Modal show={cancelWarning ? true : false} onHide={() => setCancelWarning(null)} autoFocus={false}>
                <Modal.Body className="fixed-height">
                    You are about to cancel the pre-approval request for this customer, are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn secondary" onClick={() => {
                        setCancelWarning(null)
                        cancelPreapprovalItem({
                            app_id: cancelWarning,
                            action: "cancel_preapproval",
                        })
                    }}>
                        Yes
                    </button>
                    <button class="btn secondary" onClick={() => setCancelWarning(null)}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>

            {showSearch &&
                <div className="search-header">
                    <form action="javascript:void(0)">
                        <Form.Group>
                            <Form.Control value={search} placeholder="Search" onChange={(e) => setSearch(e.target.value)}></Form.Control>
                        </Form.Group>
                    </form>
                </div>
            }

            <div className="search-header" style={{ flexDirection: "column", height: 24,position : 'sticky', top : 48, zIndex: 3, background : "#2d4457" }}>
                <Row className="single-row" style={{ marginBottom: 0, color: '#a9c3d8', height : 25 }}>
                    <Row className="dealer-row dealer-preapproval-cols" style={{ padding: 0, fontSize: 12, borderBottom: "none", width: '100%', 
                    maxWidth: 600, margin : "auto",
                     background : "#2D4457", alignItems : 'center' }}>
                        {/* <Col xs={5}></Col> */}
                        <Col xs={6} className="dealer-action dealer-action-food">Food</Col>
                        <Col xs={6} className="dealer-action dealer-action-appliance">Appliance</Col>
                        {/* <Col xs={0} className="dealer-action"></Col> */}
                    </Row>
                </Row>
            </div>

            <div className="main">
                <div className="list custom-dealer-preapproval-list" style={{height : 'auto'}}>
                    {filterCustomer && filterCustomer.map((item) => (
                        <>
                            <Row key={item.id} className="single-row">
                                <div className={`dealer-row ${selectedRow === 'map' + item.app_id ? "expanded" : ""}`} style={{ padding: "14px 12px", alignItems: "center", borderBottom : 'none' }}>
                                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent:'space-between', alignItems:'center', margin: 'auto', maxWidth: 600, width: '100%'}}>
                                    <Col>
                                        <span className="dealer-name" onClick={() => getCustomerProfile(history, item.app_id, item.customer_CIF, 'dealer')} style={{background : `${selectedRow === 'map' + item.app_id ? "rgb(0, 157, 134)" : ""}`, color : `${selectedRow === 'map' + item.app_id ? "#fff" : ""}`}}>{item.customer_name}</span><br />
                                        {(item.city || item.state) &&
                                            <h6 className="location_date mb-0" style={{marginLeft : 10, marginTop : 8}}>{item.city || 'N/A'},{item.state || 'N/A'}
                                            </h6>
                                        }
                                        {/* {item.state &&
                                            <h6 className="location_date mb-0" style={{marginLeft : 10}}>{item.state}
                                            </h6>
                                        } */}
                                    </Col>
                                    <Col xs={4} className="dealer-action">
                                        {getProductFoodStatus(item.products)}
                                    </Col>
                                    <Col xs={2} className="dealer-action" style={{paddingLeft : 20}}>
                                        {getProductFSPStatus(item.products)}
                                    </Col>
                                    <Col xs={1} className="dealer-action" onClick={() => handleOpenCustomerAction(item)}>
                                        <span className='mobile-padding-left-20'>
                                            {/* <IconSmallArrowRight app_id={'map' + item.app_id} /> */}
                                            
                                                <img src={preApprovalArrow} alt='arrow' style={{transform :  selectedRow === 'map' + item.app_id && 'rotate(90deg)'}}/> 
                                            
                                            </span>
                                    </Col>
                                    </div>
                                </div>
                                {(selectedRow === ('map' + item.app_id)) && (
                                    <Col key={item.id} xs={12} className="single-row-details" style={{ fontSize: 12, background : "#2a4051" }}>
                                        <span className="delete mt-2 delete-rotate" onClick={() => setCancelWarning(item.app_id)} style={{ left:0, top: 20}}>
                                            <img src={IconCancel} alt="delete" width="8" />
                                        </span>

                                        <div className="dealer-row" style={{ padding: "10px 35px", marginTop: 25, maxWidth: 600, margin:'auto', borderBottom:'none' }}>

                                            <Col xs={8}>
                                                <table className='dealer-custom-tr'>
                                                    <tr>
                                                        <td style={{ whiteSpace: "nowrap" }}>Co-App: &nbsp;&nbsp;</td>
                                                        <td>{item && item.co_app && item.co_app.co_customer_name == null ? "N/A" : item && item.co_app && item.co_app.co_customer_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Address: &nbsp;&nbsp;</td>
                                                        {item && item.co_app && item.co_app.street || item && item.co_app && item.co_app.city || item && item.co_app && item.co_app.state == null ?
                                                            <td>N/A</td> :
                                                            <td>
                                                                {item && item.co_app && item.co_app.city}, {item && item.co_app && item.co_app.state}
                                                            </td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Email: &nbsp;&nbsp;</td>
                                                        <td>{item && item.co_app && item.co_app.email == null ? "N/A" : item && item.co_app && item.co_app.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Phone: &nbsp;&nbsp;</td>
                                                        <td>{item && item.co_app && item.co_app.phone_number == null ? "N/A" : item && item.co_app && item.co_app.phone_number}</td>
                                                    </tr>
                                                </table>

                                            </Col>
                                            {item.app_status === 'approval' || item.app_status === 'in_process'}
                                            <Col xs={2} className="dealer-action">
                                                {item && item.products && item.products[0] && item.products[0].product_status == "approval"
                                                    || item && item.products && item.products[1] && item.products[1].product_status == "approval"
                                                    || item && item.products && item.products[2] && item.products[2].product_status == "approval"

                                                    ?
                                                    <button type="button" onClick={() => generateOrder(item)} style={{ background: "transparent", border: "none", color: 'white', position : 'absolute', top:"50%", left : "50%", transform: 'translate(-50%,-50%)' }} >
                                                        <div className="generate-order" style={{ border: 'none', width: 68, marginBottom: 10, borderRadius : 22 }}><img src={IconDashboardSalesApp} alt="" width={28} /></div>
                                                        <span style={{ whiteSpace: "nowrap" }}>Generate an Order</span>
                                                    </button>
                                                    :
                                                    <button type="button" onClick={() => generateOrder(item)} style={{ background: "transparent", border: "none", color: 'white', cursor: 'not-allowed', position : 'absolute', top:"50%", left : "50%", transform: 'translate(-50%,-50%)' }} disabled>
                                                        <div className="generate-order" style={{ border: 'none', width: 68, marginBottom: 10, borderRadius : 22 }}><img src={IconDashboardSalesApp} alt="" width={28} /></div>
                                                        <span style={{ whiteSpace: "nowrap" }}>Generate an Order</span>
                                                    </button>
                                                }
                                            </Col>
                                        </div>

                                    </Col>
                                )}
                            </Row>
                        </>
                    ))}
                </div>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedRow: state.dealer.selectedRow,
    preaprovals: state.dealer.preaprovals,
    actionLoading: state.dealer.actionLoading,
});
const mapDispatchToProps = (dispatch) => ({
    getPreApprovals: (action) => dispatch(getPreApprovals(action)),
    showSingleRowdata: (app_id) => dispatch(showSingleRowdata(app_id)),
    cancelPreapprovalItem: (data) => dispatch(cancelPreapprovalItem(data)),
    getCustomerProfile: (history, data, cif, type) => dispatch(getCustomerProfile(history, data, cif, type)),
    getCustomerApiInitiateForGenerateOrder: (data, history, path) => dispatch(getCustomerApiInitiateForGenerateOrder(data, history, path))
});
export default connect(mapStateToProps, mapDispatchToProps)(PreApproval);

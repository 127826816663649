import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Header from '../../../components/Sales/Header';
import Loader from 'shared/Loader';
import { IconList, IconSend, IconArrowLeft, TCPLogo } from '../../../assets/images';
import { pushNotification } from 'utils/notification';

import { updateApplicationFilledStatus, resetCustomerSearchApiInitiate, submiCreditApplicationByMail, submiCustomerDataToNorthbridge } from '../../../redux/actions/sales';

function HomeScreen(props) {

    const {
        history,
        customer,
        actionLoading,
        isCustomerFound,
        emailValidate,
        submiCustomerDataToNorthbridge,
        updateApplicationFilledStatus,
        resetCustomerSearchApiInitiate,
        submiCreditApplicationByMail    
    } = props;

    const [showWarning, setShowWarning] = useState(false);
    const [editWarningConfirmation, setEditWarningConfirmation] = useState(false);

    const handleCompleteOnDeviceClick = () => {
        if(customer.invite_status == "COMPLETED")
        {
            pushNotification("Credit application already submitted", 'warning', 'TOP_RIGHT', 3000);   
        }
        else
        {
            updateApplicationFilledStatus('in_app', history, '/applyApplicationBasicDetails');
        }
    }

    const handleSendLink = () => {
        setShowWarning(true)
    }

    const handleArrowBack = () => {
        // history.replace('/applyCustomerSearch');        
        history.goBack()
    }   

    const handleClearWithHomeScreen = () => {
        resetCustomerSearchApiInitiate(false)
        setEditWarningConfirmation(false)
        history.replace('/applyCustomerSearch');        
    }

    const handleSalesSlipredirection = () => {
        updateApplicationFilledStatus('in_app_with_salesslip', null, null).then(() => {
            history.push({
                pathname: '/applyApplicationSummary',
                search: '?tab=summary_list'
            })
        });
    }

    return (
        <div className="sales">

            { actionLoading && <Loader />}

            <div style={{position : 'sticky', top : 0}}>
            <Header isHome={true} history={history}>
                <img src={IconArrowLeft} alt="home" className="icon-back" onClick={() => {handleArrowBack(); localStorage.removeItem('salesInProcessSavedId')}} />
                <img src={TCPLogo} alt="logo" className="main-logo"/>
            </Header>
            </div>


            <Modal show={showWarning} onHide={() => setShowWarning(false)}>
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body> If you choose to proceed, your customer will receive an email at the email address you have entered prompting them to press a link that will take them to a secure website where they will be asked to provide information required to submit a credit application. They will also be able to add a co-applicant should they choose to do so.</Modal.Body>
                <Modal.Footer>
                <button class="secondary" onClick={() => {
                    setShowWarning(false);
                    updateApplicationFilledStatus('send_link', null, null);
                    submiCreditApplicationByMail(history, customer);
                }}>
                    Proceed
                </button>
                <button class="secondary" onClick={() => setShowWarning(false)}>
                    Close
                </button>
                </Modal.Footer>
            </Modal>

            <Modal show={editWarningConfirmation} onHide={() => setEditWarningConfirmation(false)}>
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body> You're about to lose the information that you’ve inputted for this application. Are you sure? </Modal.Body>
                <Modal.Footer>
                <button class="secondary" onClick={() => {
                    handleClearWithHomeScreen()
                }}>
                    Yes
                </button>
                <button class="secondary" onClick={() => {
                    setEditWarningConfirmation(false);   
                }}>
                    No
                </button>
                </Modal.Footer>
            </Modal>

            <div className="apply-application">

                {!emailValidate && !actionLoading &&
                    <div style={{textAlign:"center"}}>
                        <label className="error-label" style={{marginLeft: 0}}>Invalid email address</label>
                    </div>
                }

                <button autoFocus={true} className="btn button" onClick={() => emailValidate ? handleCompleteOnDeviceClick() : ''}>
                    <div className="icon">
                        <img className="icon-new-customer" src={IconList} alt="new" />
                    </div>
                    <div className="label">Complete Credit Application on This Device</div>
                </button>


                <button className="btn button" onClick={() => emailValidate ? handleSendLink() : ''}>
                    <div className="icon d-flex flex-wrap justify-content-center align-items-center">
                        <img className="icon-reorder-customer" src={IconSend} alt="reorder" />
                    </div>
                    <div className="label">Send Credit Application Link to a Customer</div>
                </button>

                {/* { isCustomerFound && */}
                    <button className="btn button" onClick={() => emailValidate ? handleSalesSlipredirection() : ''}>
                        <div className="icon">
                            {/* <img className="icon-reorder-customer" src={IconSend} alt="reorder" /> */}
                            <i class="fa fa-paperclip icon-reorder-customer" aria-hidden="true"></i>
                        </div>
                        <div className="label">Create Sales Slips</div>
                    </button>
                {/* } */}

            </div>

            <div className="footer-container">
                { !actionLoading && <button className="secondary" type="submit" onClick={()=>
                 
                    setEditWarningConfirmation(true)
                    }>Cancel</button> }
                { !isCustomerFound && emailValidate &&
                    <button className="secondary" type="submit" onClick={()=> submiCustomerDataToNorthbridge(history, customer)}>Save & Exit</button>
                }
            </div>

        </div>
    )
}

const mapStateToProps = state => ({    
    customer: state.sales.customer,
    actionLoading: state.sales.actionLoading,
    isCustomerFound: state.sales.isCustomerFound,
    emailValidate: state.sales.emailValidate
});

const mapDispatchToProps = dispatch => ({
    updateApplicationFilledStatus: (data, history, path) => dispatch(updateApplicationFilledStatus(data, history, path)),
    submiCreditApplicationByMail: (history, data) => dispatch(submiCreditApplicationByMail(history, data)),
    submiCustomerDataToNorthbridge: (history, data) => dispatch(submiCustomerDataToNorthbridge(history, data)),
    resetCustomerSearchApiInitiate: () => dispatch(resetCustomerSearchApiInitiate())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeScreen);
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from 'shared/Loader';

import Header, {
    HeaderLeft,
    HeaderCenter,
    HeaderRight,
} from '../../../../components/Dealer/Header';
import CustomerFooter from './CustomerFooter';
import {
    IconSmallArrowRight,
    IconBackArrow,
    IconDoorClosed,
} from 'assets/images';
import {
    getCustomerHistory,
    getHistoryNorthridgeData,
} from '../../../../redux/actions/dealer';
import { getFormattedDate } from '../../../../components/commons/utility';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import moment from 'moment';

function History(props) {
    const {
        history,
        actionLoading,
        getCustomerHistory,
        selectedCustomer,
        northridgeHistory,
        getHistoryNorthridgeData,
        nortridgeLoading,
    } = props;

    console.log(props);

    useEffect(() => {
        if (selectedCustomer.app_id || selectedCustomer.cif_number) {
            getCustomerHistory(selectedCustomer.app_id, selectedCustomer.cif_number);
        }
    }, []);

    let mainTab = localStorage.getItem('mainTab')
        ? localStorage.getItem('mainTab')
        : 0;

    return (
        <>
            {actionLoading && <Loader />}

            <div className="dealer">
                <Header>
                    <HeaderLeft>
                        <img
                            src={IconBackArrow}
                            alt="back"
                            onClick={() =>
                                history.push({
                                    pathname: '/',
                                    search: '?tab=' + mainTab,
                                })
                            }
                        />
                    </HeaderLeft>
                    <HeaderCenter>
                        <div className="center-text">
                            {selectedCustomer.customer_info.main_app.full_name}
                        </div>
                    </HeaderCenter>
                    <HeaderRight></HeaderRight>
                </Header>
            </div>

            <div className="main custom_history_accordian">
                {/* <table style={{ width: '100%' }}>
                    {selectedCustomer.history && selectedCustomer.history.map((item) => {

                        return (
                            <tr className="history-tab">
                                <td className="cursor-pointer" style={{ paddingRight: 0 }}>
                                    <img src={IconDoorClosed} alt="door-closed" />
                                </td>
                                <td>
                                    <h6 className="item">{item.product_type}</h6>
                                    <h6 className="item-desc">{getFormattedDate(item.order_placed_date)}</h6>
                                </td>
                                <td>
                                    <h6 className=" item-desc">Loan No.{item.Loan_no}</h6>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                    <img src={IconSmallArrowRight} alt="expand" />
                                </td>
                            </tr>
                        );
                    })}
                </table> */}

                <Accordion>
                    {selectedCustomer &&
                        selectedCustomer.history &&
                        selectedCustomer.history.map((item) => {
                            return (
                                <AccordionItem
                                    key={item?.Acctrefno}
                                    onClick={() =>
                                        getHistoryNorthridgeData(
                                            item?.Acctrefno
                                        )
                                    }
                                >
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <table style={{ width: '100%' }}>
                                                <tr className="history-tab">
                                                    <td
                                                        className="cursor-pointer"
                                                        style={{
                                                            paddingRight: 0,
                                                        }}
                                                    >
                                                        <img
                                                            src={IconDoorClosed}
                                                            alt="door-closed"
                                                        />
                                                    </td>
                                                    <td>
                                                        <h6 className="item">
                                                            {item.product_type}
                                                        </h6>
                                                        <h6 className="item-desc">
                                                            {getFormattedDate(
                                                                item.Open_Date
                                                            )}
                                                        </h6>
                                                    </td>
                                                    <td>
                                                        <h6 className=" item-desc">
                                                            Loan No.
                                                            {item.Loan_Number}
                                                        </h6>
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                IconSmallArrowRight
                                                            }
                                                            alt="expand"
                                                        />
                                                    </td>
                                                </tr>
                                            </table>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className="total_container">
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Total Balance
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Total_Balance}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Maturity Date
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Maturity_Date
                                                            ? moment(
                                                                item.Details
                                                                    .Maturity_Date
                                                            ).format(
                                                                'DD-MMM-YYYY'
                                                            )
                                                            : '-'}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Total Curr Date
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Total_Curr_Due}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Total Past Date
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Total_Past_Due}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Days Past Due
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Days_Past_Due}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h6 className="text-right mr-4rem">
                                                        <strong>
                                                            Next Principal
                                                            Billing and Due Date
                                                        </strong>
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <h6>
                                                        ${' '}
                                                        {item &&
                                                            item.Details &&
                                                            item.Details
                                                                .Last_Payment_Date}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <table
                                            style={{ width: '100%' }}
                                            className="customer_history_payment_history_table"
                                        >
                                            <thead>
                                                <th
                                                    colSpan={5}
                                                    className="text-center"
                                                    style={{
                                                        background: '#c8e0ed',
                                                        padding:
                                                            '5px 15px 15px 15px',
                                                        color: '#515b77',
                                                    }}
                                                >
                                                    Payment History
                                                </th>
                                            </thead>
                                            <thead className="payment_next_head">
                                                <th>Due Date</th>
                                                <th>Payment Effective Date</th>
                                                <th>Description</th>
                                                <th>Payment Method</th>
                                                <th>Amount Paid</th>
                                            </thead>
                                            <tbody>
                                                {console.log(
                                                    northridgeHistory.data
                                                )}
                                                {nortridgeLoading ? (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td
                                                                colSpan={5}
                                                                className="text-center"
                                                            >
                                                                <div class="lds-ring">
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {northridgeHistory &&
                                                            northridgeHistory.data &&
                                                            northridgeHistory.data.map(
                                                                (data) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                { data.Date_Due
                                                                                    ? moment(
                                                                                        data.Date_Due
                                                                                    ).format(
                                                                                        'DD-MMM-YYYY'
                                                                                    )
                                                                                    : '-'}
                                                                            </td>
                                                                            <td>
                                                                                {data.Payment_Effective_Date
                                                                                    ? moment(
                                                                                        data.Payment_Effective_Date
                                                                                    ).format(
                                                                                        'DD-MMM-YYYY'
                                                                                    )
                                                                                    : '-'}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    data.Description
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    data.Payment_Method
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                $
                                                                                {
                                                                                    data.Amount_Paid
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                    </React.Fragment>
                                                )}
                                            </tbody>
                                        </table>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            );
                        })}
                </Accordion>
            </div>

            <CustomerFooter history={history} activeTab={2} />
        </>
    );
}

const mapStateToProps = (state) => ({
    actionLoading: state.dealer.actionLoading,
    selectedCustomer: state.dealer.selectedCustomer,
    northridgeHistory: state.dealer.northridgeHistory,
    nortridgeLoading: state.dealer.northridgeHistory.loading,
});
const mapDispatchToProps = (dispatch) => ({
    getCustomerHistory: (id, cif) => dispatch(getCustomerHistory(id, cif)),
    getHistoryNorthridgeData: (id) => dispatch(getHistoryNorthridgeData(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(History);

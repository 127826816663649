export const message = {
    'FORM_SUBMISSION_SUCCESS': 'Forms have been sent successfully.',
    'EMPTY_PASSWORD': 'Please provide password',
    'PASSWORD_LENGTH': 'Password must contain atleast 8 characters',
    'PASSWORD_NOT_MATCH': 'The password and repeated password do not match',    
    'EMPTY_CONFIRM_PASSWORD': 'Please provide confirm password',
    'PASSWORD_CHANGE_SUCCESS': 'Password has been changed successfully',
    'EMPTY_EMAIL': 'Please provide email',
    'INVALID_EMAIL': 'Please provide valid email',
    'USER_UPDATE_SUCCESS': 'User has been changed successfully',
    'REQUEST_SUBMISSION_SUCCESS': 'Request has been submitted.',
    'REQUEST_SUCCESS': 'Request has been submitted successfully.',
    'PROVIDE_NAME': 'Please provide customer name.',
    'DISPOSITION_SUCCESS': 'Disposition has been logged.',
    'OFFLINE_MESSAGE': 'INTERNET CONNECTIVITY ISSUE',
    'DELETE_SUCCESSFULLY': 'Deleted successfully.',
    'NO_CREDIT_CHECK_TEXT': 'no credit application required',
    'WITH_CREDIT_CHECK_TEXT': "credit application req'd for appliance",
    'INVITE_SUBMISSION_SUCCESS': 'Invitation has been sent successfully.',
    'EMPTY_FIRST_NAME': 'Please provide first name',
    'EMPTY_LAST_NAME': 'Please provide last name',
    'RESEND_EMAIL_SUCCESS': 'Resend successfully.',
};

export const firebaseConfig = {
    apiKey: "AIzaSyCb0Dv-wseaUUbg0-F5a2Bu6mLh5OZzAi8",
    authDomain: "tcp-293717.firebaseapp.com",
    projectId: "tcp-293717",
    storageBucket: "tcp-293717.appspot.com",
    messagingSenderId: "733665276412",
    appId: "1:733665276412:web:6af4dcc3388ca71ffa8279",
    measurementId: "G-JY0XPKX533"
};

export const dropdownList = [
    {
        label: "Alabama",
        value: "AL"
    },
    {
        label: "Alaska",
        value: "AK"
    },
    {
        label: "Arizona",
        value: "AZ"
    },
    {
        label: "Arkansas",
        value: "AR"
    },
    {
        label: "California",
        value: "CA"
    },
    {
        label: "Colorado",
        value: "CO"
    },
    {
        label: "Connecticut",
        value: "CT"
    },
    {
        label: "Delaware",
        value: "DE"
    },
    {
        label: "Florida",
        value: "FL"
    },
    {
        label: "Georgia",
        value: "GA"
    },
    {
        label: "Idaho",
        value: "ID"
    },
    {
        label: "Hawaii",
        value: "HI"
    },
    {
        label: "Illinois",
        value: "IL"
    },
    {
        label: "Indiana",
        value: "IN"
    },
    {
        label: "Iowa",
        value: "IA"
    },
    {
        label: "Kansas",
        value: "KS"
    },
    {
        label: "Kentucky",
        value: "KY"
    },
    {
        label: "Louisiana",
        value: "LA"
    },
    {
        label: "Maine",
        value: "ME"
    },
    {
        label: "Maryland",
        value: "MD"
    },
    {
        label: "Massachusetts",
        value: "MA"
    },
    {
        label: "Michigan",
        value: "MI"
    },
    {
        label: "Minnesota",
        value: "MN"
    },
    {
        label: "Mississippi",
        value: "MS"
    },
    {
        label: "Missouri",
        value: "MO"
    },
    {
        label: "Montana",
        value: "MT"
    },
    {
        label: "Nebraska",
        value: "NE"
    },
    {
        label: "Nevada",
        value: "NV"
    },
    {
        label: "New Hampshire",
        value: "NH"
    },
    {
        label: "New Jersey",
        value: "NJ"
    },
    {
        label: "New Mexico",
        value: "NM"
    },
    {
        label: "New York",
        value: "NY"
    },
    {
        label: "North Carolina",
        value: "NC"
    },
    {
        label: "North Dakota",
        value: "ND"
    },
    {
        label: "Ohio",
        value: "OH"
    },
    {
        label: "Oklahoma",
        value: "OK"
    },
    {
        label: "Oregon",
        value: "OR"
    },
    {
        label: "Pennsylvania",
        value: "PA"
    },
    {
        label: "Rhode Island",
        value: "RI"
    },
    {
        label: "South Carolina",
        value: "SC"
    },
    {
        label: "South Dakota",
        value: "SD"
    },
    {
        label: "Tennessee",
        value: "TN"
    },
    {
        label: "Texas",
        value: "TX"
    },
    {
        label: "Utah",
        value: "UT"
    },
    {
        label: "Vermont",
        value: "VT"
    },
    {
        label: "Virginia",
        value: "VA"
    },
    {
        label: "Washington",
        value: "WA"
    },
    {
        label: "West Virginia",
        value: "WV"
    },
    {
        label: "Wisconsin",
        value: "WI"
    },
    {
        label: "Wyoming",
        value: "WY"
    }
]

export const historyFilterBy = [
    {
        label: "All",
        value: "all"
    },
    {
        label: "24 Hours",
        value: "24hrs"
    },
    {
        label: "48 Hours",
        value: "48hrs"
    },
    {
        label: "One Week",
        value: "one_week"
    },
    {
        label: "Two Week",
        value: "two_week"
    }
]

export const historySortBy = [
    {
        label: "Newest to Oldest",
        value: "newtoold"
    },
    {
        label: "Oldest to Newest",
        value: "oldtonew"
    },
    {
        label: "First Name (A-Z)",
        value: "firstnameatoz"
    },
    {
        label: "Last Name (A-Z)",
        value: "lastnameatoz"
    }
]
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { 
    getCustomerProfile, 
    getFundedSchedules
} from "../../../../redux/actions/dealer";
import { Link } from 'react-router-dom';

function SalesTab(props) {

    const {
        getFundedSchedules,
        getCustomerProfile,
        scheduled, 
        funded,
        history,
    } = props;

    console.log(props)

    useEffect(() => {
        getFundedSchedules();
    }, []);

    return (
        <>
            <div className="dealerSales mt-4">
                <Link to="/dealer/scheduled">
                    <div className="heading dealer-heading-hover">
                        <h6>Scheduled</h6>
                    </div>
                </Link>
                <div className="details-main">
                    <div className="preApp-data">
                        <table style={{ width: '100%' }}>
                            {scheduled && scheduled.data && scheduled.data.map((item) => {
                                return (
                                    <tr className="details">
                                        <td className="cursor-pointer">
                                            <h6 onClick={() => getCustomerProfile(history, item.app_id, item.customer_CIF, 'dealer')} className="name">{item.customer_name}</h6>
                                            <h6 className="location">{item.sales_name}</h6>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <h6 className="location">
                                                {item.products && item.products.length > 0 && item.products.map(tt => {
                                                    return (
                                                        <>{tt.product}<br></br></>
                                                    )
                                                })}
                                            </h6>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <h6 className="location">
                                                {item.products && item.products.length > 0 && item.products.map(tt => {
                                                    return (
                                                        <>${tt.purchase_price}<br></br></>
                                                    )
                                                })}
                                            </h6>
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                </div>
            </div>
            
            <div className="dealerSales">
                <Link to="/dealer/funded">
                    <div className="heading dealer-heading-hover">
                        <h6>Funded</h6>
                    </div>
                </Link>
                <div className="details-main">
                    <div className="preApp-data">
                        <table style={{ width: '100%' }}>
                            {funded && funded.data && funded.data.map((item) => {
                                return (
                                    <tr className="details">
                                        <td className="cursor-pointer">
                                            <h6 onClick={() => getCustomerProfile(history, item.app_id, item.customer_CIF, 'dealer')} className="name">{item.customer_name}</h6>
                                            <h6 className="location">{item.sales_name}</h6>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <h6 className="location">
                                                {item.products && item.products.length > 0 && item.products.map(tt => {
                                                    return (
                                                        <>{tt.product}<br></br></>
                                                    )
                                                })}
                                            </h6>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <h6 className="location">
                                                {item.products && item.products.length > 0 && item.products.map(tt => {
                                                    return (
                                                        <>${tt.purchase_price}<br></br></>
                                                    )
                                                })}
                                            </h6>
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}


const mapStateToProps = (state) => ({
    scheduled: state.dealer.scheduled,
    funded: state.dealer.funded
});

const mapDispatchToProps = (dispatch) => ({
    getFundedSchedules: () => dispatch(getFundedSchedules()),
    getCustomerProfile: (history, data, cif, type) => dispatch(getCustomerProfile(history, data, cif, type))
});
export default connect(mapStateToProps, mapDispatchToProps)(SalesTab);
